import {
  FcGlobe,
  FcDatabase,
  FcFilledFilter,
  FcBusinessman,
  FcConferenceCall,
  FcNews,
  FcSurvey,
  FcReadingEbook,
  FcOldTimeCamera,
  FcAbout,
  FcComboChart,
  FcSelfie,
} from 'react-icons/fc';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsMeta } from 'react-icons/bs';

export const data = [
  // {
  //   name: 'Social Media',
  //   icon: FcReadingEbook,
  //   link: '/social-media',
  //   description:
  //   'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //   submenu: [
  //     {
  //       name: 'Content',
  //       icon: FcOldTimeCamera,
  //       link: '/social-media/content',
  //     },
  //     {
  //       name: 'Publish',
  //       icon: FcOldTimeCamera,
  //       link: '/social-media/publish',
  //     },

  //     {
  //       name: 'Engagement',
  //       icon: FcAbout,
  //       link: '/social-media/engagement',
  //     },
  //     {
  //       name: 'Analytics',
  //       icon: FcComboChart,
  //       link: '/social-media/analytics',
  //     },
  //     {
  //       name: 'Account',
  //       icon: FcSelfie,
  //       link: '/social-media/account',
  //     },
  //   ],
  // },
  {
    name: 'Meta',
    icon: FaFacebookSquare,
    link: '/meta',
    description: ' Streamlined ad creation. Integrated to Meta Ads Same form, superior results. Unlock your advertising potential.',
    submenu: [
      {
        name: 'Ads',
        icon: BsMeta,
        link: '/marketing/meta/ads',
      },
      {
        name: 'Integration',
        icon: FcDatabase,
        link: '/marketing/meta/integration',
      },
      {
        name: 'Payment History',
        icon: FcSurvey,
        link: '/marketing/meta/payment-history',
      },
    ],
  },
  {
    name: 'Sites',
    icon: FcFilledFilter,
    link: '/funnels',
    description: 'Create, Customize, Convert! Craft beautiful pages in minutes with our drag-and-drop editor and professional templates. ',
    submenu: [
      {
        name: 'Landing Page',
        icon: FcFilledFilter,
        link: '/marketing/funnels/',
      },
      // {
      //   name: 'Website Builder',
      //   icon: FcTemplate,
      //   link: '/marketing/funnels/website-builder',
      // },
      // {
      //   name: 'Website Pageview',
      //   icon: FcServices,
      //   link: '/marketing/funnels/website-configuration',
      // },
      {
        name: 'Domains',
        icon: FcGlobe,
        link: '/marketing/funnels/domains',
      },
    ],
  },
  {
    name: 'Team',
    icon: FcConferenceCall,
    link: '/team',
    submenu: [
      {
        name: 'Project',
        icon: FcNews,
        link: '/team/project',
      },
      {
        name: 'Users',
        icon: FcBusinessman,
        link: '/team/users',
      },
    ],
  },
];
