import {
  backgroundAttachment,
  backgroundClip,
  bgColor,
  bgRepeat,
  bgSize,
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  fontVariant,
  fontWeight,
  letterSpacing,
  lineHeight,
  margin,
  padding,
  tableBorder,
  tableLayout,
  textAlignment,
  textColor,
  textDecoration,
  textFontSize,
  textOpacity,
  textOverflow,
  textTransformation,
  verticalAlign,
  wordBreak,
} from './tailwind_classes';

// eslint-disable-next-line no-unused-vars
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;

  dc.getTypes().forEach((type) => {
    if (type.id === 'text' || type.id === 'link') {
      dc.addType(type.id, {
        model: {
          defaults: {
            traits: [
              ...type.model.prototype.defaults.traits,
              {
                type: 'class_select',
                name: 'color',
                options: textColor.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Text Color',
              },
              {
                type: 'class_select',
                name: 'align',
                options: textAlignment.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Text Alignment',
              },
              {
                type: 'class_select',
                name: 'opacity',
                options: textOpacity.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Text Opacity',
              },
              {
                type: 'class_select',
                name: 'decoration',
                options: textDecoration.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Text Decoration',
              },
              {
                type: 'class_select',
                name: 'transform',
                options: textTransformation.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Text Transformation',
              },
              {
                type: 'class_select',
                name: 'overflow',
                options: textOverflow.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Text Overflow',
              },
              {
                type: 'class_select',
                name: 'fontSize',
                options: textFontSize.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Font Size',
              },
              {
                type: 'class_select',
                name: 'fontWeight',
                options: fontWeight.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Font Weight',
              },
              {
                type: 'class_select',
                name: 'variant',
                options: fontVariant.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Font Variant',
              },
              {
                type: 'class_select',
                name: 'letter-spacing',
                options: letterSpacing.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Letter Spacing',
              },
              {
                type: 'class_select',
                name: 'lineHeight',
                options: lineHeight.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Line Height',
              },
              {
                type: 'class_select',
                name: 'verticalAlign',
                options: verticalAlign.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Vertical Align',
              },
              {
                type: 'class_select',
                name: 'wordBreak',
                options: wordBreak.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Word Break',
              },
            ],
          },
        },
      });
    }

    if (type.id === 'table') {
      dc.addType(type.id, {
        model: {
          defaults: {
            traits: [
              ...type.model.prototype.defaults.traits,
              {
                type: 'class_select',
                options: tableBorder.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Table Border',
              },
              {
                type: 'class_select',
                options: tableLayout.map((color) => ({
                  name: color,
                  value: color,
                })),
                label: 'Table Layout',
              },
            ],
          },
        },
      });
    }

    if (type.id === 'svg' || type.id === 'svg-in') {
      dc.addType(type.id, {
        model: {
          defaults: {
            traits: [
              ...dc.getType(type.id).model.prototype.defaults.traits,
              {
                type: 'class_select',
                options: [{ name: 'fill-current', value: 'fill-current' }],
                label: 'Fill',
              },
              {
                type: 'class_select',
                options: [{ name: 'stroke-current', value: 'stroke-current' }],
                label: 'Stroke',
              },
              {
                type: 'class_select',
                options: [
                  { name: 'stroke-0', value: 'stroke-0' },
                  { name: 'stroke-1', value: 'stroke-1' },
                  { name: 'stroke-2', value: 'stroke-2' },
                ],
                label: 'Stroke Width',
              },
            ],
          },
        },
      });
    }

    let defaultTraits = [];
    if (dc.getType(type.id).model.prototype.defaults.traits) {
      defaultTraits = dc.getType(type.id).model.prototype.defaults.traits;
    }
    
    dc.addType(type.id, {
      model: {
        defaults: {
          traits: [
            ...defaultTraits,
            {
              type: 'class_select',
              options: backgroundAttachment.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'BG Attachment',
            },
            {
              type: 'class_select',
              name: 'bgClip',
              options: backgroundClip.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'BG Clip',
            },
            {
              type: 'class_select',
              name: 'bgColor',
              options: bgColor.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'BG Color',
            },
            {
              type: 'class_select',
              name: 'bgRepeat',
              options: bgRepeat.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'BG Repeat',
            },
            {
              type: 'class_select',
              name: 'bgSize',
              options: bgSize.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'BG Size',
            },

            {
              type: 'class_select',
              name: 'borderRadius',
              options: borderRadius.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'Border Radius',
            },
            {
              type: 'class_select',
              name: 'borderWidth',
              options: borderWidth.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'Border Width',
            },
            {
              type: 'class_select',
              name: 'borderColor',
              options: borderColor.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'Border Color',
            },
            {
              type: 'class_select',
              name: 'borderStyle',
              options: borderStyle.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'Border Style',
            },
            {
              type: 'class_select',
              name: 'margin',
              options: margin.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'Margin',
            },
            {
              type: 'class_select',
              name: 'padding',
              options: padding.map((color) => ({
                name: color,
                value: color,
              })),
              label: 'Padding',
            },
          ],
        },
      },
    });
  });
};
