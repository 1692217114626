import { FaMousePointer, FaPodcast, FaTags, FaTty, FaUsers, FaWeixin } from 'react-icons/fa';

export const objective = [
  {
    name: 'Awareness',
    description: 'Show ads to people who are most likely to remember them.',
    icon: FaPodcast,
  },
  {
    name: 'Traffic',
    description: 'Send people to a destination, like website app, Instagram profile or Facebook events.',
    icon: FaMousePointer,
  },
  {
    name: 'Engagement',
    description: 'Get more messages, video views, post engagement, Page likes or event responses.',
    icon: FaWeixin,
  },
  {
    name: 'Leads',
    description: 'Collect leads for your business or brand.',
    icon: FaTty,
  },
  {
    name: 'App Promotion',
    description: 'Find new people to install and continue to using it.',
    icon: FaUsers,
  },
  {
    name: 'Sales',
    description: 'Find people likely to increase product or service.',
    icon: FaTags,
  },
]

export const goals = [
  {
    name: 'Leads goals',
    goals: ['Contacts', 'Sign-ups']
  },
  {
    name: 'Additional goals',
    goals: ['Begin checkout', 'Engagements', 'Other', 'Page views', 'Purchases', 'Subscribe']
  },
]

export const campaignType = [
  {
    name: 'Search',
    description: 'Get in front of high-intent customers at the right time on Google Search.'
  },
  {
    name: 'Video',
    description: 'Reach viewers on YoutTube and get conversions.'
  }
]

export const feedsPlacement = [
  'Facebook Feed', 
  'Instagram Feed', 
  'Instagram profile feed', 
  'Facebook Marketplace', 
  'Facebook video feeds', 
  'Facebook right column', 
  'Instagram Explore', 
  'Instagram Explore home', 
  'Messenger inbox'
]

export const storiesPlacement = [
  'Instagram Stories', 
  'Facebook Stories', 
  'Messenger Stories', 
  'Instagram Reels', 
  'Facebook Reels', 
]