import { Box, HStack, Heading, Image, Stack, Text, VStack,useToast } from '@chakra-ui/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { formatFrice } from '../../Utils/Helper';
import { FaRegCopy } from 'react-icons/fa'



const banks = [
  { name: 'bni', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png' },
  { name: 'bca', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png' },
  { name: 'mandiri', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png' },
  { name: 'permata', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png' },
  { name: 'bri', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png' },
  { name: 'cimb', uri: 'https://career.amikom.ac.id/images/company/cover/1637497490.jpeg' },
];




const CheckoutFrontPayment = () => {
  const { state: { data } } = useLocation();
  const toast  = useToast();
  const handleStringCopy = (string) => {
    navigator.clipboard.writeText(string);
    toast({
      description: 'Wallet Address Copied!',
      status: 'success',
      duration: 2000,
      position : 'top'
    })
  };

  console.log(data)

  return (
    <>
      <VStack h={'100vh'} justifyContent={'center'} p={[3, 5, 10]}>
        <Heading align={'center'} size='md'>Terima Kasih sudah melakukan order</Heading>
        <Text align={'center'}>Untuk menyelesaikan pesanan, silakan melakukan pembayaran di bawah ini : </Text>

        <Heading color='green' size='lg'>IDR {formatFrice(data?.amount)}</Heading>



        {data?.payment_information?.payment_type === 'gopay' ?
          <>
            <VStack p={5}>
              <Image src='https://antinomi.org/wp-content/uploads/2022/03/logo-gopay-vector.png' width={'10em'} alt='gopay' />
              <VStack p={5}>
                <Text align={'center'}>Silakan buka link GOPAY di bawah ini pada smartphone kamu:</Text>
                <Box px={100}>
                  <Link
                    to={data?.payment_information?.actions[1]?.url}
                  >
                    <Text
                      color={'blue'}
                      fontWeight={'bold'}
                      align={'center'}
                    >
                      {data?.payment_information?.actions[1]?.url}
                    </Text>
                  </Link>
                </Box>
              </VStack>
            </VStack>
          </> :
          data?.payment_information?.payment_type === 'bank_transfer' ||
          data?.payment_information?.payment_type === 'echannel' ||
            data?.payment_information?.payment_type === 'permata' ?
            <>
              <VStack p={5}>
                <Image
                  w={100}
                  src={
                    findBankImage(
                      banks,
                      data?.payment_information?.bank
                    )
                  }
                />
                <HStack>
                  <Text>{data?.payment_information?.va_number?.bank?.toUpperCase()} VA :<strong>{data?.payment_information?.va_number || data?.payment_information?.va_numbers[0].va_number }</strong> </Text>
                  <Box
                    _hover={{
                      transform: 'scale(1.1)'
                    }}
                    cursor='pointer'
                  >
                    <FaRegCopy
                      onClick={() => handleStringCopy(
                        data?.payment_information?.va_number
                      )}
                    />
                  </Box>
                </HStack>
              </VStack>
            </> :
            data?.payment_information?.payment_type === 'qr' ?
              <>
                <Stack>
                  <Stack>
                    <HStack w={300} justify={'space-between'}>
                      <Image
                        w={150}
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/QRIS_logo.svg/384px-QRIS_logo.svg.png'}
                        alt={'qris logo'}
                      />
                      <Image
                        w={10}
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Gerbang_Pembayaran_Nasional_logo.svg/1200px-Gerbang_Pembayaran_Nasional_logo.svg.png'}
                        alt={'gpn'}
                      />
                    </HStack>
                  </Stack>
                  <Image
                    w={300}
                    src={data?.payment_information?.actions[0]?.url}
                    alt={'qris payment'}
                  />
                </Stack>
              </>
              :
              <>No data</>
        }

        <Text mt={20} align={'center'}>Jangan lupa untuk menympan screenshot bukti pembayaran dan order id</Text>
      </VStack>
    </>
  )
}

export default CheckoutFrontPayment



function findBankImage(banks, bankName) {
  const selected = banks?.find((x) => x?.name?.toLowerCase() == bankName);
  const img = selected?.uri;
  return img;
}