/* eslint-disable no-prototype-builtins */
import {
  Box,
  Button,
  HStack,
  Spacer,
  Text,
  Flex,
  Heading,
  Stack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  SimpleGrid,
  Image,
  Select,
  useToast,
  FormLabel,
  Divider,
} from '@chakra-ui/react';
import React,{ useState,useEffect } from 'react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  getCollectionFirebase,
  arrayUnionFirebase,
  updateDocumentFirebase,
  setDocumentFirebase,
} from '../../../Api/firebaseApi';
import { FcMultipleDevices,FcPlus } from 'react-icons/fc';
import { TiImageOutline } from 'react-icons/ti';
import { Link,useNavigate,useParams } from 'react-router-dom';
import { AddIcon,CloseIcon } from '@chakra-ui/icons';
import useUserStore from '../../../Hooks/Zustand/Store';
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2';

function MarketingFunnelsViewPage() {
  const param = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const globalState = useUserStore();
  const [title,setTitle] = useState();
  const modalTemplate = useDisclosure();
  const [dataFunnel,setDataFunnel] = useState();
  const [dataFunnelPage,setDataFunnelPage] = useState();
  const [modalNewPage,setModalNewPage] = useState(false);
  const [editedPage,setEditedPage] = useState([]);
  const [titlePage,setTitlePage] = useState('');
  const [isLoading,setIsLoading] = useState(false);
  const [templates,setTemplates] = useState([]);
  const [detailTemplates,setDetailTemplates] = useState();
  const [selectedTemplate,setSelectedTemplate] = useState({});
  const [domainPath,setDomainPath] = useState('');
  const [selectedDomain,setSelectedDomain] = useState();
  const [domainData,setDomainData] = useState([]);
  const [landingPageTitle,setLandingPageTitle] = useState('')
  const [gtmCode,setGtmCode] = useState('')
  const [pixelId,setPixelId] = useState('')
  const openDeleteAlert = useDisclosure()
  const [selectedPage,setSelectedPage] = useState()
  const [templateConfig,setTemplateConfig] = useState('')
  const [notAllowedChar,setNotAllowedChar] = useState(false)

  const [page,setPage] = useState();

  const getDomain = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
    ];

    const res = await getCollectionFirebase('domain_landing_page',conditions);

    setDomainData(res);
  };

  const handleAddFunnel = () => {
    setIsLoading(true);

    // if (domainPath === '' || domainPath === undefined || titlePage === '' || landingPageTitle === '' || notAllowedChar) {
    //   setIsLoading(false)
    //   return toast({
    //     description: 'Please fill the required input',
    //     title: "Error",
    //     status: 'error',
    //     duration: 4000
    //   })
    // }

    addDocumentFirebase(
      `funnels/${param.id}/page`,
      {
        title: title,
        title_page: titlePage,
        projectId: globalState?.currentProject,
      },
      globalState.currentCompany
    )
      .then((id) => {
        arrayUnionFirebase('funnels',param.id,'funnels',[id])
          .then(() => {
            setModalNewPage(false);
            setIsLoading(false);
            getFunnel();
          })
          .catch((err) => {
            throw new Error(err.message)
          });

        setDocumentFirebase(
          'websites',
          id,
          {
            html: detailTemplates?.html || '',
            css: detailTemplates?.css || '',
            projectId: globalState?.currentProject,
            companyId: globalState?.currentCompany,
            domainName: selectedDomain || 'lpage.id',
            domainPath: domainPath,
            folderPath: `${selectedDomain || 'lpage.id'}/${domainPath}`,
            landingpageTitle: landingPageTitle,
            gtmCode: gtmCode,
            pixelId: pixelId
          },
          globalState.currentCompany
        );
      })
      .catch((err) => {
        throw new Error(err.message)
      });
  };

  const handleAddFunnelWithTemplate = async () => {
    setIsLoading(true);
    try {
      await updateDocumentFirebase(
        'websites',
        page?.id,
        {
          html: detailTemplates?.html,
          templateName: detailTemplates?.title,
          config: templateConfig || '',
        },
        globalState.currentCompany
      );
      
    } catch (error) {
      throw new Error(error)
    } finally {
      setIsLoading(false);
      modalTemplate?.onClose();
      getEditedFunnelPage();
    }
  };

  const handleDomainPath = (value) => {
    const allowedChar = /^[a-zA-Z0-9-_.]+$/;

    const checkIfContains = allowedChar.test(value);
    setDomainPath(value)
    if (!checkIfContains) {
      setNotAllowedChar(true)
    } else {
      setNotAllowedChar(false)
    }

  }

  const handleGetTemplateDetail = async (template) => {
    setSelectedTemplate(template);

    await getCollectionFirebase(`templates/${template?.id}/data`);

    setDetailTemplates(template);
    setTemplateConfig(template?.config)
  };

  const handlePage = (id) => {
    getSingleDocumentFirebase(`funnels/${param.id}/page`,id).then((x) => {
      setPage({ ...x,id });
    });
  };

  const getFunnel = () => {
    getSingleDocumentFirebase('funnels',param.id)
      .then(async (x) => {
        setDataFunnel(x);
        setTitle(x?.title);

        const dataFunnel = x.funnels;

        if (dataFunnel?.length > 0) {
          const conditions = [];
          const sortBy = { field: 'createdAt',direction: 'desc' };

          try {
            const res = await getCollectionFirebase(
              `funnels/${param.id}/page`,
              conditions,
              sortBy
            );
            setDataFunnelPage(res);
          } catch (error) {
            throw new Error(error)
          }
        }
      })
      .catch((err) => {
        throw new Error(err.message)
      });
  };

  const getEditedFunnelPage = async () => {
    try {
      const editedFunnel = await getSingleDocumentFirebase(
        'websites',
        page?.id
      );
      setEditedPage(editedFunnel);
    } catch (error) {
      throw new Error(error)
    }
  };

  const getTemplateCollection = async () => {
    const conditions = [
      { field: 'type',operator: '==',value: 'landingPage' },
    ];
    const res = await getCollectionFirebase('templates',conditions);
    setTemplates(res)
    console.log(res)
    // const res = await getSingleDocumentFirebase('templates','4NBUzZV622DbMiqtDFXj');
    // setTemplates([res]);
  };

  const handleModalTemplate = () => {
    getTemplateCollection();
    modalTemplate?.onOpen();
  };

  const handleModalDelete = (page) => {
    setSelectedPage(page)
    openDeleteAlert.onOpen()
  }

  const handleDeletePage = async () => {
    setIsLoading(true)
    try {
      await deleteDocumentFirebase(`funnels/${param.id}/page`,selectedPage)
      await deleteDocumentFirebase('websites',selectedPage)

      getFunnel()

      toast({
        status: 'success',
        title: 'Page deleted',
        duration: 3000
      })
    } catch (error) {
      toast({
        status: 'error',
        title: 'Failed to delete data',
        duration: 3000
      })
    } finally {
      getEditedFunnelPage()
      setIsLoading(false)
      openDeleteAlert?.onClose()
    }
  }

  useEffect(() => {
    getEditedFunnelPage();
    // if (page) {
    getDomain();
    // }

    return () => {
      setDomainData([]);
    };
  },[page]);

  useEffect(() => {
    getFunnel();

    //   getDomain();
    // getAllDomain();

    return () => { };
  },[]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading size={'lg'} textTransform="capitalize">
          {dataFunnel?.title}
        </Heading>
      </HStack>
      <Flex
        my={5}
        gap={3}
        borderRadius="md"
        shadow="base"
        minH="80vh"
        flexDir={['column',null,'row']}
      >
        <Box
          border="1px"
          borderRadius="md"
          p="5"
          minWidth="20%"
          borderColor={'gray.200'}
          bgColor={'white'}
        >
          <HStack>
            <Heading fontSize="lg" p="2">
              Pages
            </Heading>
            <Spacer />
          </HStack>

          <Box>
            <Divider width={'2px'} />
          </Box>

          <Box h={'75vh'} p={2} overflowY={'scroll'} sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: 'gray.200',
            },
          }} 
          >
            <SimpleGrid 
              columns={2} 
              spacing={3} 
            >
              {dataFunnel?.funnels?.map((z,i) => {
                const funnelPage = dataFunnelPage?.find((page) => page.id === z);
                if (funnelPage) {
                  return (
                    <>
                      <Stack
                        py={2}
                        align={'center'}
                        justify={'center'}
                        key={i}
                        borderRadius={'md'}
                        borderColor={'gray.100'}
                        border="1px"
                        h={'6rem'}
                        cursor={'pointer'}
                        _hover={{ transform: 'scale(1.03)', transition: '200ms ease-in-out' }}
                        onClick={() => handlePage(z)}
                      >
                        <TiImageOutline color='gray.200' size={25} />
                        <Text w={'4rem'} align={'center'}
                        // noOfLines={1}
                          fontSize={'xs'}
                          textTransform="capitalize"
                        >
                          {funnelPage.title_page}
                        </Text>
                      </Stack>
                    </>
                  );
                } else {
                  return null;
                }
              })}
              <Stack 
                py={2}
                align={'center'}
                justify={'center'}
                borderRadius={'md'}
                borderColor={'gray.100'}
                border="1px"
                h={'6rem'}
                cursor={'pointer'}
                _hover={{ transform: 'scale(1.03)', transition: '200ms ease-in-out' }}
                onClick={() => setModalNewPage(true)}>
                <FcPlus />
              </Stack>
            </SimpleGrid>
          </Box>

        </Box>

        <Stack
          w={'full'}
          p="5"
          overflowY="auto"
          bgColor={'white'}
          borderRadius={'md'}
          borderColor={'gray.200'}
        >
          {page ? (
            editedPage && editedPage.hasOwnProperty('domainName') &&
              editedPage.hasOwnProperty('domainPath') ? (
                <Box>
                  <HStack>
                    <Input
                      w={'fit-content'}
                      isDisabled={true}
                      value={editedPage?.domainName}
                      type="text"
                      maxW="2xs"
                      onChange={(e) => setDomainPath(e.target.value)}
                    />
                    <Text>/</Text>
                    <Input
                      w={'fit-content'}
                      isDisabled={true}
                      value={editedPage?.domainPath}
                      type="text"
                      maxW="2xs"
                      onChange={(e) => setDomainPath(e.target.value)}
                    />
                    <Spacer />
                    <Button
                      size={'sm'}
                      mt="2"
                      colorScheme="green"
                      onClick={() =>
                        window.open(
                          `https://www.${editedPage?.domainName}/${editedPage?.domainPath}`
                        )
                      }
                    >
                    View
                    </Button>

                    <Button
                      size={'sm'}
                      mt="2"
                      colorScheme="red"
                      onClick={() =>
                        handleModalDelete(page?.id)
                      }
                    >
                    Delete
                    </Button>
                    <Button
                      size={'sm'}
                      mt="2"
                      colorScheme="blue"
                      onClick={() =>
                        navigate(
                          `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                          { state: page }
                        )
                      }
                    >
                    Edit
                    </Button>
                    {/* <Button
                    colorScheme="blue"
                    mt="2"
                    // onClick={() =>
                    //   window.open(`http://${page?.domain}`, "_blank")
                    // toastComingSoon('View feature')
                    // }
                  >
                    View
                  </Button> */}
                  </HStack>
                  <Stack>
                    {editedPage?.html !== '' ? (
                      <>
                        <Stack bgColor={'white'} mt="2" py={3} h={'75vh'} overflowY={'scroll'}>
                          {/* <Text>Result</Text> */}
                          <Stack
                            // shadow={'base'}
                            // bgColor={'white'}
                            // border={'1px'}
                            // color="black"
                          >
                            <div
                            // style={{
                            //   backgroundColor: 'white!important',
                            //   color: 'black!important',
                            // }}
                              dangerouslySetInnerHTML={{
                                __html: `<script src="https://cdn.tailwindcss.com"></script>${editedPage?.html}`
                              }}
                            />
                          </Stack>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <Stack
                          mt={10}
                          justifyContent="center"
                          alignItems="center"
                          h={'full'}
                          spacing={5}
                          bgColor={'white'}
                        >
                          <Heading size={'lg'} textTransform="capitalize">
                          Create new {page.title_page}
                          </Heading>
                          <HStack>
                            {/* <Link
                            to={`/marketing/funnel/create/${param.id}/lp-builder/${page.id}`}
                            target="_blank"
                          > */}
                            <Button
                              colorScheme="yellow"
                              onClick={() =>
                                navigate(
                                  `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                                  { state: page }
                                )
                              }
                            >
                            Create From Scratch
                            </Button>
                            {/* </Link> */}
                            <Button
                              colorScheme="teal"
                              onClick={handleModalTemplate}
                            >
                            Create With Template
                            </Button>
                          </HStack>
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Box>
              ) : (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  h={'full'}
                  spacing={5}
                  bgColor={'white'}
                >
                  <Heading size={'lg'} textTransform="capitalize">
                  Create new {page.title_page}
                  </Heading>
                  <HStack>
                    <Link
                      to={`/marketing/funnel/create/${param.id}/lp-builder/${page.id}`}
                      target="_blank"
                    >
                      <Button
                        colorScheme="yellow"
                        // onClick={() =>
                        //   navigate(
                        //     `/marketing/funnel/create/${param.id}/lp-builder/${page.id}`,
                        //     { state: page }
                        //   )
                        // }
                      >
                      Create From Scratch
                      </Button>
                    </Link>
                    <Button colorScheme="teal" onClick={handleModalTemplate}>
                    Create With Template
                    </Button>
                  </HStack>
                </Stack>
              )
          ) : (
            <Stack
              alignItems="center"
              justifyContent={'center'}
              h="full"
              bgColor={'white'}
            >
              <Heading size={'lg'} textTransform="capitalize">
                Please select your funnel page
              </Heading>
            </Stack>
          )}
        </Stack>
      </Flex>

      <Modal
        isOpen={modalNewPage}
        onClose={() => setModalNewPage(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Page</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel>
              <Stack>
                <Box>
                  <Text fontWeight={500} mb={3}>
                    Page Name
                  </Text>
                  <Input
                    isRequired
                    size={'sm'}
                    type="text"
                    placeholder="Page Name"
                    onChange={(e) => setTitlePage(e.target.value)}
                  />
                </Box>
                <Box>
                  <Text fontWeight={500} mb={3}>
                    Landingpage Title
                  </Text>
                  <Input
                    isRequired
                    size={'sm'}
                    type="text"
                    placeholder="Title"
                    onChange={(e) => setLandingPageTitle(e.target.value)}
                  />
                </Box>
                <Box>
                  <Text fontWeight={500}>Domain</Text>
                  <HStack w={'100%'}>
                    <Select
                      size={'sm'}
                      defaultValue={'lpage.id'}
                      onChange={(e) => setSelectedDomain(e?.target.value)}
                      width="40%"
                      placeholder="Select option"
                    >
                      <option value={'lpage.id'}>lpage.id</option>
                      {domainData?.map((x) => (
                        <option key={x?.id} value={x?.name}>
                          {x?.name}
                        </option>
                      ))}
                    </Select>
                    <Text>/</Text>
                    <Stack spacing={1} pos={'relative'}>
                      <Input
                        borderColor={notAllowedChar ? 'red' : null}
                        placeholder='Enter domain path here'
                        isRequired
                        size={'sm'}
                        // w={'60%'}
                        defaultValue={editedPage?.domainPath}
                        type="text"
                        maxW="2xs"
                        onChange={(e) => handleDomainPath(e.target.value)}
                      />
                      {notAllowedChar && (
                        <Text bottom={-4} pos={'absolute'} fontSize={9} color={'red'}>Contain non allowed character, please change</Text>
                      )}
                    </Stack>
                  </HStack>
                  <Stack align={'center'} spacing={'1'} mt={5} mb={4}>
                    <Text align={'center'} fontSize={12}>If you have a domain that has been configured before, you can create in domain page by click the button below</Text>
                    <Button size={'xs'} variant={'outline'} colorScheme='blue' onClick={() => navigate('/marketing/funnels/domains')}>Create Here</Button>
                  </Stack>
                </Box>
                <Box>
                  <Text fontWeight={500} mb={3}>
                    GTM Code
                  </Text>
                  <Input
                    size={'sm'}
                    type="text"
                    placeholder="GTM Code"
                    onChange={(e) => setGtmCode(e.target.value)}
                  />
                </Box>
                <Box>
                  <Text fontWeight={500} mb={3}>
                    Pixel ID
                  </Text>
                  <Input
                    size={'sm'}
                    type="text"
                    placeholder="Pixel ID"
                    onChange={(e) => setPixelId(e.target.value)}
                  />
                </Box>
              </Stack>
            </FormLabel>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                isLoading={isLoading}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddFunnel()}
              >
                Add New
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalNewPage(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        // size={'xl'}
        isOpen={modalTemplate?.isOpen}
        onClose={modalTemplate?.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxWidth={'56rem'}>
          <ModalHeader>Select Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <Text align={'center'} fontWeight={500}>
                Available Template Themes
              </Text>
              <Box
                h={400}
                overflow={'auto'}
                sx={{
                  '&::-webkit-scrollbar': {
                    w: '2',
                    h: '3',
                  },
                  '&::-webkit-scrollbar-track': {
                    w: '6',
                    h: '5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10',
                    bg: 'gray.200',
                  },
                }}
              >
                <SimpleGrid columns={3} spacing={3} p={2}>
                  {templates?.map((template, i) => (
                    <Stack
                      key={i}
                      spacing={3}
                      cursor={'pointer'}
                      align={'center'}
                      _hover={{ border: '2px', borderColor: 'blue.400', transform: 'scale(1.02)', transition: '0.2s ease-in-out' }}
                      border={
                        selectedTemplate?.id === template?.id ? '2px' : '1px'
                      }
                      borderColor={
                        selectedTemplate?.id === template?.id
                          ? 'blue.400'
                          : 'gray.100'
                      }
                      borderRadius={'md'}
                      p={3}
                      shadow={'base'}
                      onClick={() => handleGetTemplateDetail(template)}
                    >
                      <Image w={300} src={template?.image} />
                      <Text align={'center'} fontSize={14} fontWeight={500}>
                        {template?.title}
                      </Text>
                    </Stack>
                  ))}
                </SimpleGrid>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button size={'sm'}
                isLoading={isLoading}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddFunnelWithTemplate()}
              >
                Create
              </Button>
              <Button size={'sm'}
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={modalTemplate?.onClose}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2 isLoading={isLoading} onClose={openDeleteAlert?.onClose} isOpen={openDeleteAlert?.isOpen} title={'Delete this page'} onAction={handleDeletePage} />
    </Box>
  );
}

export default MarketingFunnelsViewPage;
