import { Box,Container,Flex,HStack,Spacer,Stack,Text } from '@chakra-ui/react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { HiOutlineSquare2Stack } from 'react-icons/hi2'
import React from 'react'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import PropTypes from 'prop-types';

const InstagramExplore = ({ marketingState }) => {
  return (
    <Container alignSelf="start" w={'60%'}>
      <Flex flexDir={'column'} w={400} border={'1px'} h={500} gap={2} overflow={'hidden'} borderColor={'gray.100'}>
        <Flex gap={2} w={'100%'}>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
        </Flex>
        <Flex gap={2} w={'100%'}>
          <Flex w={'33.3%'} bg={'gray.100'}>
            <Box h={100} bg={'gray.100'}></Box>
            <Box h={100} bg={'gray.100'}></Box>
          </Flex>
          <Stack w={'66.6%'} h={200} bgImage={`url('${marketingState?.adsCreation?.mediaLink[0].link || marketingState?.adsCreation?.carousel[0].link}')`} bgSize={'cover'} bgPos={'center'}>
            <HStack p={2} color={'white'} bgGradient="linear(to-t, rgba(125, 125, 125,0) 0%, rgba(125, 125, 125,0.6) 100%)">
              <HStack bg={'#7d7d7d'} fontSize={12} p={1} borderRadius={'md'}>
                <Text noOfLines={1}>{marketingState?.adsCreation?.businessName}</Text>
                <Text>Sponsored</Text>
              </HStack>
              <Spacer />
              <HStack>
                <HiOutlineSquare2Stack size={20} />
                <HiDotsHorizontal size={20} />
              </HStack>
            </HStack>
            <Spacer />
            <HStack p={2} color={'white'} bgGradient="linear(to-b, rgba(125, 125, 125,0) 0%, rgba(125, 125, 125,0.6) 100%)">
              <Text fontSize={12} fontWeight={500}>Send message</Text>
              <Spacer />
              <MdOutlineArrowForwardIos />
            </HStack>
          </Stack>

          {/* <Box  w={'33.3%'} h={100} bg={'gray.100'}></Box> */}
        </Flex>
        <Flex w={'100%'} gap={2}>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
        </Flex>
        <Flex w={'100%'} gap={2}>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
          <Box w={'33.3%'} h={100} bg={'gray.100'}></Box>
        </Flex>
      </Flex>
    </Container >
  )
}

export default InstagramExplore

InstagramExplore.propTypes = {
  marketingState: PropTypes.any,
  data: PropTypes.any,
  param: PropTypes.string,
}