import { getCollectionFirebase, getSingleDocumentFirebase } from '../../../../Api/firebaseApi';
import { typeCustomCode } from './utils';

let warehouseDataArray = [];
let productVariantArray = [];
let formList = []

export default (editor, opts = {}) => {
  const bm = editor.Blocks

  bm.add(typeCustomCode, {
    label: 'Custom Forms',
    media: `
      <svg viewBox="0 0 24 24">
        <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
      </svg>
    `,
    category: 'Custom',
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...opts
  });

  editor.on('block:drag:start', async (block) => { 
    
    const currentCompany = localStorage.getItem('currentCompany')
    const currentProject = localStorage.getItem('currentProject')

    const conditions = [
      { field: 'companyId', operator: '==', value: currentCompany },
      { field: 'projectId', operator: '==', value: currentProject},
    ];

    const res = await getCollectionFirebase('product', conditions)
    formList = res

    const warehousePromises = []; // Store promises in an array
    const productPromises = []

    res.forEach((product) => {
      if (product?.type === 'physical') {
        const warehousePromise = getSingleDocumentFirebase('warehouses', product.warehouseId);
        warehousePromises.push(warehousePromise);
      }

      const productVariant = getCollectionFirebase('product_variants', [{ field: 'productId', operator: '==', value: product?.id }])
      productPromises.push(productVariant)
    });

    // Wait for all promises to resolve
    const warehouseArray = await Promise.all(warehousePromises);
    const productArray = await Promise.all(productPromises)

    warehouseDataArray = warehouseArray
    productVariantArray = productArray
  });
}

export { warehouseDataArray, productVariantArray, formList };