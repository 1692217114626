import { Container, Divider, Flex, Stack } from '@chakra-ui/react'
import React from 'react'
import useUserStore from '../../../Hooks/Zustand/Store';
import SocialAccountComponents from '../SocialAccountComponents';
import BackButtons from '../../../Components/Buttons/BackButtons';
import InstagramTab from '../SocialEngagement/InstagramTab';
import TiktokTab from '../SocialEngagement/TiktokTab';


function SocialEngagementPage() {

  const globalState = useUserStore();


  const height = window.innerHeight


  return (
    <Flex direction={['column', 'row', 'row']} gap={3} >
      <Stack
        w='300px'
        height="auto"
        overflowY="auto"
        borderWidth="1px"
        position="sticky"
        top={0}
        borderRadius="md"
        shadow={'md'}
        h={height}
        p={[1, 1, 5]}
      >
        <SocialAccountComponents />
      </Stack>
      <Container maxW='5xl' >

        <Stack overflowY="auto" spacing={5} w="full">

          <BackButtons />
          

          <Divider />
          <Stack>
            {globalState?.socialActive?.platform === 'instagram' ? (
              <InstagramTab />
            ) :  globalState?.socialActive?.platform === 'tiktok' ? (
              <TiktokTab />
            ) :  null}

          </Stack>
        </Stack>


      </Container>
    </Flex>
  )
}

export default SocialEngagementPage