import { AspectRatio,Box,Button,Container,Flex,HStack,Heading,Image,Text } from '@chakra-ui/react'
import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types';

const FacebookVideoFeeds = ({ marketingState,param }) => {
  const options = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: '10px'
  };
  return (
    <>
      {marketingState?.adsCreation?.mediaLink?.length === 0 || (marketingState?.adsCreation?.format === 'carousel' && marketingState?.adsCreation?.carousel?.length <= 1) ? (
        <Text align={'center'} p={5} fontSize={12}>Add more media to see display</Text>
      ) : (
        <Container alignSelf="start" w={'60%'}>
          <Box borderRadius={'md'} shadow={'base'} overflow={'hidden'}>
            <HStack p={2}>
              <Image
                boxSize={70}
                borderRadius="full"
                src={
                  marketingState?.adsCreation?.businessLogo === ''
                    ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                    : marketingState?.adsCreation?.businessLogo
                }
                alt="your brand name"
              />
              <Box>
                <Heading size="md">
                  {marketingState?.adsCreation?.businessName === ''
                    ? 'Your Business Name'
                    : marketingState?.adsCreation?.businessName}
                </Heading>
                <Text>Sponsored</Text>
              </Box>
            </HStack>
            <Text mt="5" p={2}>
              {marketingState?.adsCreation?.businessName === ''
                ? 'Your Primary Text'
                : marketingState?.adsCreation?.primaryText}
              {/* 𝟱 𝗝𝗮𝗺 𝗣𝗿𝗮𝗸𝘁𝗲𝗸 𝗶𝗺𝗽𝗼𝗿𝘁 & 𝗥𝗶𝘀𝗲𝘁 𝗣𝗿𝗼𝗱𝘂𝗸 𝗟𝗮𝗿𝗶𝘀 berdasarkan data dari
                    Tokopedia/Shopee dengan Omset 100 Juta per bulan😱 . Kamu akan
                    di bimbing dari 0 hingga mendapatkan 1 Produk potensi CUAN 100
                    Juta Pertama Hanya dari 1 Produk Saja😍 . Yuk Catat tanggal &
                    waktunya */}
            </Text>
            <Box bgColor="F2F2F2" align={'center'}>
              {param?.type === 'edit' ? (
                <>
                  {marketingState?.adsCreation?.mediaLink?.length === 0 ? (
                    <AspectRatio maxW={'500px'} ratio={1}>
                      <Image alt='adsImage' src={'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'} />
                    </AspectRatio>
                  ) : (
                    <>
                      {marketingState?.adsCreation?.mediaLink[0]?.type ===
                        'video' ? (
                          <Box align={'center'}>
                            <iframe
                              width={'100%'}
                              src={
                                marketingState?.adsCreation?.mediaLink[0]?.link
                              }
                              allowFullScreen
                            />
                          </Box>
                        ) : (
                          <AspectRatio maxW={'500px'} ratio={9 / 16}>
                            <Image
                              w={300}
                              src={
                                marketingState?.adsCreation?.mediaLink[0]?.link
                              }
                              alt="ads Image"
                            />
                          </AspectRatio>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {marketingState?.adsCreation?.format === 'carousel' ? (
                    <>
                      {marketingState?.adsCreation?.carousel?.length > 1 ? (
                        <>
                          <Slider {...options}>
                            {marketingState?.adsCreation?.carousel?.map((x, i) => (
                              <Box key={i}>
                                <AspectRatio ratio={1}>

                                  {x?.type === 'video' ? (
                                    <iframe
                                      // objectFit={'cover'}
                                      src={
                                        x?.link
                                      }
                                      alt="ads Image"
                                    />
                                  ) : (
                                    <Image
                                      objectFit={'cover'}
                                      src={
                                        x?.link
                                      }
                                      alt="ads Image"
                                    />

                                  )}

                                </AspectRatio>
                              </Box>
                            ))}
                          </Slider>
                          <HStack>
                            {marketingState?.adsCreation?.carousel[0]?.type === 'video' ? (
                              <iframe width={100} height={100}
                                // objectFit={'cover'}
                                src={
                                  marketingState?.adsCreation?.carousel[1]?.link
                                }
                                alt="ads Image"
                              />
                            ) : (
                              <Image w={100} h={100}
                                objectFit={'cover'}
                                src={
                                  marketingState?.adsCreation?.carousel[1]?.link
                                }
                                alt="ads Image"
                              />

                            )}




                            {marketingState?.adsCreation?.carousel[0]?.type === 'video' ? (
                              <iframe width={100} height={100}
                                // objectFit={'cover'}
                                src={
                                  marketingState?.adsCreation?.carousel[2]?.link
                                }
                                alt="ads Image"
                              />
                            ) : (
                              <Image w={100} h={100}
                                overflow={'hidden'}
                                objectFit={'cover'}
                                objectPosition={'center'}
                                src={
                                  marketingState?.adsCreation?.carousel[2]?.link
                                }
                                alt="ads Image"
                              />

                            )}
                            <Flex w={100} h={100} borderRadius={'sm'} align={'center'} justify={'center'} border={'1px'} borderColor={'gray.100'} bg={'gray.100'} bgImage={`url('${marketingState?.adsCreation?.carousel[0]?.link}')`}>
                              <Text bgGradient={'rgba(255,255,255,0.2) 50%'} fontWeight={500}>See More</Text>
                            </Flex>
                          </HStack>
                        </>
                      ) : (
                        <>
                          {marketingState?.adsCreation?.mediaLink[0]?.type ===
                            'video' ? (
                              <AspectRatio>
                                <iframe
                                  width={'100%'}
                                  src={
                                    marketingState?.adsCreation?.mediaLink[0]?.link
                                  }
                                  allowFullScreen
                                />
                              </AspectRatio>
                            ) : (
                              <AspectRatio ratio={1}>
                                <Image
                                  objectFit={'cover'}
                                  src={
                                    marketingState?.adsCreation?.mediaLink[0]?.link
                                  }
                                  alt="ads Image"
                                />
                              </AspectRatio>
                            )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {marketingState?.adsCreation?.mediaLink?.length === 0 ? (
                        <AspectRatio ratio={4 / 3}>
                          <Image src='https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg' alt='naruto' objectFit='cover' />
                        </AspectRatio>
                      ) : (
                        <>
                          {marketingState?.adsCreation?.mediaLink[0]?.type ===
                            'video' ? (
                              <AspectRatio>
                                <iframe
                                  width={'100%'}
                                  src={
                                    marketingState?.adsCreation?.mediaLink[0]?.link
                                  }
                                  allowFullScreen
                                />
                              </AspectRatio>
                            ) : (
                              <AspectRatio ratio={1}>
                                <Image
                                  objectFit={'cover'}
                                  src={
                                    marketingState?.adsCreation?.mediaLink[0]?.link
                                  }
                                  alt="ads Image"
                                />
                              </AspectRatio>
                            )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
            <Box m={3} align={'center'} >
              <Button size={'sm'} w={'full'}>
                {marketingState?.adsCreation?.cta === ''
                  ? 'Your CTA Button'
                  : marketingState?.adsCreation?.cta}
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </>
  )
}

export default FacebookVideoFeeds

FacebookVideoFeeds.propTypes = {
  marketingState: PropTypes.any,
  data: PropTypes.any,
  param: PropTypes.string,
}