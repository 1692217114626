/* eslint-disable react/no-unescaped-entities */
import { Button, Divider, Grid, HStack, Image, Spacer, Stack, Tag, Text, useToast } from '@chakra-ui/react';
import { arrayUnion, collection, doc, getCountFromServer, query, setDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getCollectionFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { getUserByUid } from '../../Utils/getUser';
import DynamicButton from '../Buttons/DynamicButton';
import _axios from '../../Api/AxiosBarrier';

function ApprovalList() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedDate, setSelectedDate] = useState('');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();

  const currentProject = globalState.currentProject;
  const rolesUid = globalState.uid;
  let accountApproveEnabled = false;

  if (Array.isArray(globalState.projects)) {
    const project = globalState.projects.find((project) => project.id === currentProject);
    if (project) {
      // Check if any of the ids in project.managers matches globalState.uid
      accountApproveEnabled = project.managers.some((managerId) => managerId === rolesUid);
    }
  }

  // const handleDateChange = (e) => {
  //     setSelectedDate(e.target.value);
  // }

  const getDataApproval = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [{ field: 'filesId', operator: '==', value: globalState?.socialData?.id }];
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      conditions.push({ field: 'createdAt', operator: '>=', value: startDate }, { field: 'createdAt', operator: '<=', value: endDate });
    }
    const sortBy = { field: 'createdAt', direction: 'asc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase('social-media-post', conditions, sortBy, limitValue);
      setData(res);

      const collectionRef = collection(db, 'social-media-post');
      const snapshot = await getCountFromServer(query(collectionRef, where('filesId', '==', globalState?.socialData?.id)));

      const assesmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleApproved = async (x) => {
    const updateApproved = {
      approvedAt: new Date(),
      approvedBy: globalState?.uid,
      status: 'approved',
    };

    const collectionName = 'social-media-post';
    const docName = x.id;
    try {
      await updateDocumentFirebase(collectionName, docName, updateApproved);
      toast({
        title: 'Deoapp.com',
        description: 'succes approve this post',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      getDataApproval();
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleRejected = async (x) => {
    const updateApproved = {
      approvedAt: new Date(),
      approvedBy: globalState?.uid,
      status: 'rejected',
    };

    const collectionName = 'social-media-post';
    const docName = x.id;
    try {
      await updateDocumentFirebase(collectionName, docName, updateApproved);
      toast({
        title: 'Deoapp.com',
        description: 'succes approve this post',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      getDataApproval();
    } catch (error) {
      throw new Error(error)
    }
  };

  const handlePost = async (value) => {
    const data = { ...value?.dataPost };

    try {
      setIsLoading(true);
      const res = await _axios.post('ayrshare-post', data);

      if (res.data.status !== 'error') {
        const firebaseDataPromises = value?.dataPost?.platforms.map(async (y) => {
          const firebaseData = {
            startDate: data?.scheduleDate ? new Date(data.scheduleDate) : new Date(),
            endDate: data?.scheduleDate ? new Date(moment(data.scheduleDate).add(1, 'hour')) : new Date(moment().add(1, 'hour')),
            image: globalState?.socialActive?.userImage || data?.mediaUrls[0],
            name: globalState?.socialData?.title,
            post: data.post,
            platform: y,
            status: data.scheduleDate ? 'schedule' : 'active',
          };
          const ref = doc(db, 'schedules', globalState.currentProject);
          await setDoc(
            ref,
            {
              companyId: globalState.currentCompany,
              projectId: globalState.currentProject,
              type: 'social-media',
              filesId: globalState?.socialData?.id,
              data: arrayUnion(firebaseData),
              createdAt: new Date(),
            },
            { merge: true }
          );

          const updateApproved = {
            approvedAt: new Date(),
            approvedBy: globalState?.uid,
            status: 'posted',
          };

          await updateDocumentFirebase('social-media-post', value?.id, updateApproved);

          return `Successfully posted to ${y}.`;
        });

        const results = await Promise.all(firebaseDataPromises);
        results.forEach((message) => {
          toast({
            title: 'Deoapp.com',
            description: message,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        });
      } else {
        // res?.data?.status
        const splitErrorMessage = res?.data?.posts[0].errors[0].message.split('Contact us for assistance. https://docs.ayrshare.com/additional-info/troubleshooting#trouble-posting-to-facebook');
        toast({
          title: 'Failed to post',
          description: splitErrorMessage,
          duration: 8000,
          status: 'error',
        });
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      getDataApproval();
      setIsLoading(false);
    }
  };

  // const handleOpenView = (x) => {
  //     console.log(x, 'ini xx')

  // }

  useEffect(() => {
    getDataApproval();

    return () => {};
  }, [globalState?.currentProject, globalState?.currentCompany, globalState?.socialActive]);

  return (
    <Stack>
      <Stack>
        {data?.length > 0 && (
          <Stack gap={5}>
            {data.map((x, index) => {
              return (
                <Stack
                  key={index}
                  borderRadius='md'
                  shadow={'md'}
                  bgColor={'white'}
                  borderTopWidth={5}
                  borderColor='blue.500'
                  p={5}>
                  <Grid
                    templateColumns={{ base: '1fr', md: '4fr 1fr 0.5fr' }}
                    gap={5}>
                    <Stack
                      bgColor={'white'}
                      p={3}
                      fontSize='sm'>
                      {/* Tidak menampilkan profileKey dan shortenLinks */}
                      {Object.keys(x?.dataPost || {}).map((key, keyIndex) => {
                        if (key !== 'profileKey' && key !== 'shortenLinks' && key !== 'mediaUrls' && key !== 'scheduleDate') {
                          return (
                            <Stack
                              key={keyIndex}
                              bgColor={'white'}
                              p={1}
                              spacing={0}
                              fontSize='sm'>
                              <Text
                                fontWeight='bold'
                                textTransform={'capitalize'}>
                                {key}
                              </Text>
                              <Text>{JSON.stringify(x?.dataPost[key])}</Text>
                            </Stack>
                          );
                        }
                        return null;
                      })}
                      <Stack>
                        <Text fontWeight='bold'>Media Urls</Text>
                        {x?.dataPost?.mediaUrls?.map((mediaUrl, urlIndex) => (
                          <a
                            key={urlIndex}
                            href={mediaUrl}
                            target='_blank'
                            rel='noopener noreferrer'>
                            Link {urlIndex + 1}
                          </a>
                        ))}
                      </Stack>
                    </Stack>

                    {x?.dataPost?.mediaUrls?.length > 0 &&
                      x.dataPost?.mediaUrls?.map((y, index) => {
                        return (
                          <Stack key={index}>
                            {y.endsWith('.MOV') || y.endsWith('.mp4') ? (
                              <video
                                controls
                                width='220'
                                height='140'>
                                <source src={y} />
                                Sorry, your browser doesn't support embedded videos.
                              </video>
                            ) : (
                              <Image
                                borderRadius={'lg'}
                                w={'150px'}
                                shadow={'md'}
                                src={y}
                                alt={y}
                              />
                            )}
                          </Stack>
                        );
                      })}

                    {accountApproveEnabled !== false && (
                      <Stack
                        alignItems={'center'}
                        justifyContent='center'>
                        {x.status === 'pending' ? (
                          <>
                            <DynamicButton
                              size={'sm'}
                              title={'Approved'}
                              variant={'solid'}
                              action={'create'}
                              onClick={() => handleApproved(x)}
                            />
                            <DynamicButton
                              size={'sm'}
                              title={'Rejected'}
                              variant={'solid'}
                              action={'delete'}
                              onClick={() => handleRejected(x)}
                            />
                          </>
                        ) : x.status === 'approved' ? (
                          <Button
                            colorScheme='blue'
                            isLoading={isLoading}
                            size={'sm'}
                            onClick={() => handlePost(x)}>
                            Push Post
                          </Button>
                        ) : null}
                      </Stack>
                    )}
                  </Grid>
                  <Divider />
                  <Stack>
                    <HStack spacing={5}>
                      <Stack
                        alignItems={'center'}
                        justifyContent='flex-start'
                        bgColor={'white'}
                        p={1}
                        borderRadius='md'
                        spacing={0}>
                        <Text
                          fontSize={'sm'}
                          color='gray.600'>
                          Status
                        </Text>
                        <Tag
                          colorScheme={x.status === 'pending' ? 'yellow' : x.status === 'approved' ? 'green' : x.status === 'posted' ? 'blue' : 'red'}
                          fontWeight={500}
                          textTransform={'capitalize'}>
                          {x?.status}
                        </Tag>
                      </Stack>
                      <Stack
                        alignItems={'center'}
                        justifyContent='flex-start'
                        bgColor={'white'}
                        p={1}
                        borderRadius='md'
                        spacing={0}>
                        <Text
                          fontSize={'sm'}
                          color='gray.600'>
                          Requested By
                        </Text>
                        <Tag
                          fontWeight={500}
                          textTransform={'capitalize'}>
                          {getUserByUid(globalState?.users, x?.assignee)?.name || 'none'}
                        </Tag>
                      </Stack>
                      <Stack
                        alignItems={'center'}
                        justifyContent='flex-start'
                        bgColor={'white'}
                        p={1}
                        borderRadius='md'
                        spacing={0}>
                        <Text
                          fontSize={'sm'}
                          color='gray.600'>
                          Approved By
                        </Text>
                        <Tag
                          fontWeight={500}
                          textTransform={'capitalize'}>
                          {getUserByUid(globalState?.users, x?.approvedBy)?.name || 'none'}
                        </Tag>
                      </Stack>
                      <Spacer />
                      <Stack spacing={0}>
                        {x?.dataPost?.scheduleDate && (
                          <Text
                            fontSize={'sm'}
                            fontWeight={500}>
                            On Schedule : {moment(x?.dataPost?.scheduleDate).format('LLLL')}
                          </Text>
                        )}
                        <Text fontSize={'sm'}>{moment(x?.createdAt?.seconds * 1000).format('LLLL')}</Text>
                      </Stack>
                    </HStack>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        )}
      </Stack>
      <Stack
        alignItems={'center'}
        justifyContent='center'>
        {currentPage < totalPages && (
          <Button
            colorScheme={'blue'}
            fontSize='sm'
            onClick={handleLoadMore}>
            Load More
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default ApprovalList;
