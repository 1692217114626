/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box, CircularProgress, CloseButton, HStack, Img, Input, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react';
import { MdOutlinePermMedia } from 'react-icons/md';
import ApiBackend from '../../Api/ApiBackend';

function MediaLargeComponents({ profileKeyData, setImageHeight, setImageWidth, setFiles, files, imageWidth, imageHeight, data }) {

  const [uploading, setUploading] = useState(false);


  const handleFileInputChange = async (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      setUploading(true);

      const newFileArray = [];

      for (let i = 0; i < newFiles.length; i++) {
        const formData = new FormData();
        formData.append('file', newFiles[i]);
        formData.append('profileKey', profileKeyData);


        try {
          const response = await ApiBackend.post('/uploadFile', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });


          const fileData = {
            file: response.data.data,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
            isVideo: newFiles[i].type.startsWith('video/')
          };

          if (!fileData.isVideo) {
            const img = document.createElement('img');
            img.onload = () => {
              setImageHeight(img.height);
              setImageWidth(img.width);
            };
            img.src = response.data.fileLink;
          } else {
            // Handle video logic if needed
          }

          newFileArray.push(fileData);
        } catch (error) {
          throw new Error('Error uploading file:', error)
        }
      }

      setFiles((prevFiles) => [...prevFiles, ...newFileArray]);
      setUploading(false);

    }
  };

  const handleDeleteMedia = (deletedFile) => {
    const updatedFiles = files.filter((file) => file.file !== deletedFile.file);
    setFiles(updatedFiles);
  };
  return (
    <Stack>
      <HStack spacing={2} alignItems="center">
        <Stack>
          <Input
            type="file"
            onChange={handleFileInputChange}
            accept="image/*,video/*" // Menentukan bahwa file yang diterima bisa berupa gambar atau video
            multiple // Mengizinkan memilih beberapa file
            display="none"
            id="fileInput"
          />
          <label htmlFor="fileInput">
            <HStack cursor={'pointer'}>
              <Stack>
                <MdOutlinePermMedia />
              </Stack>
              <Text fontSize={'sm'}>Add Image / Video</Text>
            </HStack>
          </label>
        </Stack>
      </HStack>
      {uploading && <CircularProgress isIndeterminate color="green.300" />}

      <SimpleGrid columns={[1, 2, 3]} gap={3}>
        {files.length > 0 &&
                    files.map((x, index) => (
                      <Stack key={index}>
                        {x.isVideo ? (
                          <video controls width={300}>
                            <source src={x.file} type={x.description} />
                                    Sorry, your browser doesn't support embedded videos.
                          </video>
                        ) : (
                          <>
                            {(imageWidth / imageHeight < 0.56 ||
                                        imageWidth / imageHeight > 0.62) &&
                                        (data.instagramOptions?.reels === true ||
                                            data.instagramOptions?.stories === true) ? (
                                <>
                                  <Text color={'red'} fontSize={'sm'}>
                                                Media file aspect ratio should be 9:16
                                  </Text>
                                </>
                              ) : null}
                            <Box position={'relative'}>
                              <CloseButton
                                onClick={() => handleDeleteMedia(x)}
                                position={'absolute'}
                                right={0}
                                zIndex={999}
                                borderRadius={'full'}
                                size={'sm'}
                                color={'white'}
                                bg={'gray.400'}
                              />
                              <Img
                                src={x.file}
                                borderRadius="xl"
                                alt={x.fileName}
                                shadow="md"
                              />
                            </Box>
                          </>
                        )}
                      </Stack>
                    ))}
      </SimpleGrid>

    </Stack>
  )
}

export default MediaLargeComponents