/* eslint-disable react/react-in-jsx-scope */
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

export const platformArr = [
  {
    name: 'youtube',
    icon: <FaYoutube color="white" size={10} />,
  },
  {
    name: 'twitter',
    icon: <FaTwitter color="white" size={10} />,
  },
  {
    name: 'facebook',
    icon: <FaFacebook color="white" size={10} />,
  },
  {
    name: 'instagram',
    icon: <FaInstagram color="white" size={10} />,
  },
  {
    name: 'tiktok',
    icon: <FaTiktok color="white" size={10} />,
  },
  {
    name: 'linkedin',
    icon: <FaLinkedin color="white" size={10} />,
  },
  {
    name: 'google',
    icon: <FaGoogle color="white" size={10} />,
  },
  {
    name: 'pinterest',
    icon: <FaPinterest color="white" size={10} />,
  },
];
