/* eslint-disable react/prop-types */
import { Divider, Grid, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import useUserStore from '../../../Hooks/Zustand/Store';
import LinkedinAnalyticsPost from './linkedinAnalyticsPost';


function LinkedinAnalyticsCard({ data }) {
  const globalState = useUserStore();

  return (
    <Stack
      borderRadius='lg'
      shadow='md'
      // bgColor={'white'}
      borderTopWidth={5}
      borderColor='blue.500'
      p={5}>
      <Grid
        templateColumns={{ base: '1fr', md: '4fr 1fr' }}
        gap={5}>
        <Stack
          p={3}
          fontSize='sm'>
          <Text>{data?.post}</Text>
        </Stack>
      </Grid>
      <Divider />
      <Stack>
        <Text
          fontSize={'xs'}
          color='gray.600'>
          Share via {globalState?.socialActive?.platform} on {moment(data.created).format('LLLL')}
        </Text>
      </Stack>

      <Stack spacing={5}>
        {Object.entries(data?.analytics).map(([key, value]) => (
          <Stack key={key}>
            <Text
              fontSize={'md'}
              fontWeight='bold'
              textColor={'blue.200'}
              textTransform={'capitalize'}>
              {key}
            </Text>
            <Divider />
            <LinkedinAnalyticsPost analyticsData={value.analytics} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default LinkedinAnalyticsCard;
