/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineAlert } from 'react-icons/ai'

function AlertArrayNull({titleData, action}) {
  return (
    <Stack minH="50vh" alignItems={'center'} justifyContent="center">
      <AiOutlineAlert color="gray" size={50} />
      <Text textAlign="center" color={'gray.500'} fontSize="2xl" fontWeight={'bold'}>You don't have any {titleData || ''} data yet.</Text>
      <Text fontWeight={500} fontSize="lg" textAlign="center" color={'gray.500'}>To create one, click the {action || ''} button.</Text>
    </Stack>
  )
}

export default AlertArrayNull