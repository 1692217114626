import { Box, Center, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaPinterest, FaTelegram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import useUserStore from '../../../Hooks/Zustand/Store';
import { arrayUnion, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import _axios from '../../../Api/AxiosBarrier';
import moment from 'moment';
import CalendarPage from '../Calendar/CalendarPage';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import MediaLargeComponents from '../../../Components/Media/MediaLargeComponents';
import InstagramInput from '../../../Components/Inputs/InstagramInput';
import TiktokInput from '../../../Components/Inputs/TiktokInput';
import FacebookInput from '../../../Components/Inputs/FacebookInput';
import ScheduleInput from '../../../Components/Inputs/ScheduleInput';

function QueueTab() {
  const [modalPublish, setModalPublish] = useState(false);
  const [shortenLinks, setShotenLinks] = useState(false);
  const [schedulePosting, setSchedulePosting] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [files, setFiles] = useState([]);
  const [platformActive, setPlatformActive] = useState([]);
  const [postTypes, setPostTypes] = useState('post');

  const toast = useToast();
  const globalState = useUserStore();

  const platformIcons = {
    twitter: FaTwitter,
    youtube: FaYoutube,
    tiktok: FaTiktok,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    telegram: FaTelegram,
    facebook: FaFacebook,
    google: FaGoogle,
    pinterest: FaPinterest,
  };

  const socialMediaKey = globalState?.socialData?.displayNames;

  const [data, setData] = useState({
    post: '',
    platforms: [''],
    shortenLinks: shortenLinks,
    profileKey: globalState?.socialKey,
  });

  const handleOpenModal = () => {
    setModalPublish(true);
  };

  const handleCloseModal = () => {
    setModalPublish(false);
    setFiles([]);
  };

  const handleAddPlatform = (media) => {
    if (globalState?.socialData?.activeSocialAccounts?.filter((x) => x?.includes(media)).length > 0) {
      setPlatformActive([media]);
      if (!platformActive.includes(media)) {
        setPlatformActive([...platformActive, media]);
        setData({ ...data, platforms: [...platformActive, media] });
      } else {
        setPlatformActive(platformActive.filter((platform) => platform !== media));
        setData({
          ...data,
          platforms: platformActive.filter((platform) => platform !== media),
        });
      }
    } else {
      toast({
        title: 'Deoapp.com',
        description: `${media} need to enabled. please go to Social Accounts page to set up`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  // console.log(globalState, 'global')

  // const uploadApi = async (file, fileName, description) => {
  //     try {
  //         const resImage = await _axios.post("ayrshare-upload", {
  //             file: file,
  //             fileName: fileName,
  //             description: description,
  //         });
  //         return resImage
  //     } catch (error) {
  //         throw error.message
  //     }
  // }

  const handlePublish = async () => {

    if (platformActive?.length === 0) {
      return toast({
        title: 'Deoapp.com',
        description: 'Please choose the platform before click publish button',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }

    if (data.profileKey === '') {
      return toast({
        title: 'Deoapp.com',
        description: 'You need to set up social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }

    globalState.setIsLoading(true);

    try {
      if (files.length > 0) {
        const uploadPromises = files.map(async (x) => {
          return x?.file; // Mengembalikan URL untuk Promise.all
        });
        data.mediaUrls = await Promise.all(uploadPromises);
      }

      const res = await _axios.post('ayrshare-post', data);
      if (res.data.status !== 'error') {
        const firebaseDataPromises = platformActive.map(async (y) => {
          const firebaseData = {
            startDate: data?.scheduleDate ? new Date(data.scheduleDate) : new Date(),
            endDate: data?.scheduleDate ? new Date(moment(data.scheduleDate).add(1, 'hour')) : new Date(moment().add(1, 'hour')),
            image: globalState?.socialActive?.userImage || data?.mediaUrls[0],
            name: globalState?.socialData?.title,
            post: data.post,
            platform: y,
            status: data.scheduleDate ? 'schedule' : 'active',
          };
          const ref = doc(db, 'schedules', globalState.currentProject);
          await setDoc(
            ref,
            {
              companyId: globalState.currentCompany,
              projectId: globalState.currentProject,
              type: 'social-media',
              filesId: globalState?.socialData?.id,
              data: arrayUnion(firebaseData),
              createdAt: new Date(),
            },
            { merge: true }
          );

          return `Successfully posted to ${y}.`;
        });

        const results = await Promise.all(firebaseDataPromises);
        results.forEach((message) => {
          toast({
            title: 'Deoapp.com',
            description: message,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        });
      } else {
        // res?.data?.status
        const splitErrorMessage = res?.data?.posts[0].errors[0].message.split('Contact us for assistance. https://docs.ayrshare.com/additional-info/troubleshooting#trouble-posting-to-facebook');
        toast({
          title: 'Failed to post',
          description: splitErrorMessage,
          duration: 8000,
          status: 'error',
        });
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
      setFiles([]);
      setPlatformActive([]);
      setShotenLinks(false);
      setSchedulePosting('');
      handleCloseModal();
    }
  };

  return (
    <Stack>
      <HStack>
        <Text
          fontWeight={'bold'}
          fontSize='xl'>
          Publish
        </Text>
        <Spacer />
        {socialMediaKey && socialMediaKey.length > 0 ? (
          <DynamicButton
            variant={'solid'}
            action={'create'}
            title='Create Post'
            size={'sm'}
            onClick={handleOpenModal}
          />
        ) : null}
      </HStack>

      {/* <FileUpload profileKeyData={globalState?.socialKey} /> */}
      <CalendarPage />

      <Modal
        size={'2xl'}
        isOpen={modalPublish}
        onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'md'}>Create Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              alignItems='center'
              justifyContent={'center'}
              spacing={5}
              p={5}
              borderRadius={'md'}>
              <Stack>
                <Text
                  color={'gray.500'}
                  fontSize='sm'
                  fontWeight={'semibold'}>
                  Choose Platform To Post
                </Text>
              </Stack>
              <SimpleGrid
                gap={5}
                columns={[3, 6, 9]}>
                {Object.keys(platformIcons).map((platform, index) => (
                  <Center
                    key={index}
                    _hover={{
                      bg: 'gray.100',
                      transform: 'scale(1.02)',
                      transition: '0.3s',
                    }}
                    cursor={'pointer'}
                    border={'1px'}
                    borderColor={'gray.50'}
                    borderRadius={'md'}
                    p={1}
                    boxShadow={'sm'}
                    onClick={() => handleAddPlatform(platform)}>
                    {React.createElement(platformIcons[platform], {
                      size: 25,
                      cursor: 'pointer',
                      color: platformActive.includes(platform) ? 'green' : 'gray',
                    })}
                  </Center>
                ))}
              </SimpleGrid>
            </Stack>
            <Stack>
              {(platformActive.includes('instagram') || platformActive.includes('facebook')) && (
                <Stack>
                  <Text
                    fontSize={'sm'}
                    color='gray.500'>
                    Select Type Post
                  </Text>
                  <Select
                    size={'sm'}
                    placeholder='Select type post'
                    onChange={(e) => {
                      setPostTypes(e.target.value);
                    }}>
                    <option value='post'>Post</option>
                    <option value='reels'>Reels</option>
                    <option value='stories'>Stories</option>
                  </Select>
                </Stack>
              )}
              <Text
                fontSize={'sm'}
                color='gray.500'>
                Type your post
              </Text>
              <Textarea
                placeholder='Here is a sample placeholder'
                fontSize={'sm'}
                onChange={(e) => setData({ ...data, post: e.target.value })}
              />

              <MediaLargeComponents
                profileKeyData={globalState?.socialKey}
                setImageHeight={setImageHeight}
                setImageWidth={setImageWidth}
                setFiles={setFiles}
                files={files}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                data={data}
              />

              {platformActive.includes('instagram') ? (
                <Box py={2}>
                  <InstagramInput
                    data={data}
                    setData={setData}
                    postTypes={postTypes}
                    setPostTypes={setPostTypes}
                  />
                </Box>
              ) : null}

              {platformActive.includes('tiktok') ? (
                <Box py={2}>
                  <TiktokInput
                    data={data}
                    setData={setData}
                  />
                </Box>
              ) : null}

              {platformActive.includes('facebook') ? (
                <Box py={2}>
                  <FacebookInput
                    data={data}
                    setData={setData}
                    postTypes={postTypes}
                    setPostTypes={setPostTypes}
                  />
                </Box>
              ) : null}

              <ScheduleInput
                setSchedulePosting={setSchedulePosting}
                schedulePosting={schedulePosting}
                setData={setData}
                data={data}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <DynamicButton
                action={'delete'}
                variant='solid'
                onClick={handleCloseModal}
                size='sm'
                title='Close'
              />
              <DynamicButton
                action={'create'}
                variant='solid'
                onClick={handlePublish}
                size='sm'
                title={'Publish'}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default QueueTab;
