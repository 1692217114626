import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ErrorToast = ({ title, description }) => {
  const toast = useToast();
  toast({
    title: title,
    description: description,
    status: 'error',
    duration: 5000,
    isClosable: true,
  });
  return null;
};

export default ErrorToast;

ErrorToast.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}