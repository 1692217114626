/* eslint-disable react/prop-types */
import React from 'react';
import { SimpleGrid, Stack, Text } from '@chakra-ui/react';

function TiktokAnalyticsPost({ analyticsData }) {
  const {
    comments = 0,
    likes = 0,
    reach = 0,
    videoViews = 0,
    mediaType = '',
    name = '',
    audienceCountries = [],
    impressionSources = []
  } = analyticsData || {};

  const impressions = audienceCountries?.length > 0 ? audienceCountries?.length : impressionSources?.length;


  const completionRate = ((reach / videoViews) * 100).toFixed(2);

  const analyticsInfo = [
    { label: 'Likes', value: likes },
    { label: 'Comments', value: comments },
    { label: 'Impressions', value: impressions },
    { label: 'Reach', value: reach },
    { label: 'Video Views', value: videoViews },
    { label: 'Completion Rate (%)', value: completionRate },
    { label: 'Post Type', value: mediaType },
    { label: 'Username', value: `@${name}` },
  ];

  return (
    <SimpleGrid columns={[1, 2, 4]} gap={3}>
      {analyticsInfo?.map(({ label, value }, index) => (
        <Stack key={index} align="center">
          <Text fontSize="xs">{label}</Text>
          <Text fontWeight="bold">{value}</Text>
        </Stack>
      ))}
    </SimpleGrid>
  );
}

export default TiktokAnalyticsPost;
