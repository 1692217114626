/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useToast } from '@chakra-ui/react';
import { db } from '../../Config/firebase';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import DynamicTable from '../../Components/Table/DynamicTable';
import moment from 'moment';
import { formatFrice } from '../../Utils/Helper';

function ModalBalanceHistory({ modalBalanceHistory, handleCloseModalHistory, dataMeta }) {

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);

  const tableHeader = ['name', 'type', 'status', 'amount', 'createdAt'];



  const itemsPerPage = 10;
  const toast = useToast()




  const getDataHistory = async () => {
    if (dataMeta.id) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [
        { field: 'filesId', operator: '==', value: dataMeta.id }
      ]

      const sortBy = { field: 'createdAt', direction: 'asc' };
      const limitValue = startIndex + itemsPerPage;
      try {
        const res = await getCollectionFirebase(
          'orders',
          conditions,
          sortBy,
          limitValue,
        );
        setData(res);

        const collectionRef = collection(db, 'orders');
        const snapshot = await getCountFromServer(
          query(
            collectionRef,
            where('filesId', '==', dataMeta.id),
          ));

        const assesmentLength = snapshot.data().count;



        setTotalPages(Math.ceil(assesmentLength / itemsPerPage));

      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }

  }

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const tableData = data?.map((x) => {
    return {
      x,
      'name': x?.orders?.title,
      'type': x?.type,
      'status': x?.paymentStatus,
      'amount': `IDR.${formatFrice(x?.amountTotal)}`,
      'createdAt': moment(x?.createdAt.seconds * 1000).format('DD-MM-YYYY')
    }
  })


  useEffect(() => {
    getDataHistory()

    return () => {
    }
  }, [currentPage, dataMeta.id])


  return (
    <Modal size={'4xl'} isOpen={modalBalanceHistory} onClose={handleCloseModalHistory} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>History Balance</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <Stack>
              <DynamicTable header={tableHeader} data={tableData} />
              <Stack alignItems={'center'} justifyContent="center">

                {currentPage < totalPages && (
                  <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                                        Load More
                  </Button>
                )}
              </Stack>

            </Stack>

          </Stack>
        </ModalBody>

        <ModalFooter>
          <Flex gap={5}>

            <Button leftIcon={<CloseIcon boxSize={3} />} colorScheme="red" onClick={handleCloseModalHistory}>
                            Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalBalanceHistory;
