/* eslint-disable react/prop-types */
import { AspectRatio,Divider,Grid,Heading,HStack,Image,Spacer,Stack,Text } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../Buttons/DynamicButton'

function TwitterPublishCard({ data }) {
  const globalState = useUserStore();

  const dataName = data?.postUrl?.split('/')
  return (
    <Stack
      borderRadius="lg"
      shadow="md"
      // bgColor={'white'}
      borderTopWidth={5}
      borderColor="blue.500"
      p={5}
    >
      <Grid templateColumns={{ base: '1fr',md: '4fr 1fr 0.5fr' }} gap={5}>
        <Stack  p={3} fontSize="sm">
          <Stack>
            <Text fontWeight={500}>Username</Text>
            <Text cursor={'pointer'} onClick={() => window.open(`https://twitter.com/${dataName[3]}`,'_blank')}>{dataName[3]}</Text>
          </Stack>
          <Stack>
            <Text fontWeight={500}>Caption</Text>
            <Text> {data?.post}</Text>
          </Stack>
          <Stack>
            <Text fontWeight={500}>Post Url</Text>
            <Text cursor={'pointer'} onClick={() => window.open(data?.postUrl,'_blank')}>{data?.postUrl}</Text>
          </Stack>

        </Stack>
        <AspectRatio maxW="auto" ratio={1}>
          <Image src={data?.media[0]?.url} />
        </AspectRatio>
        <Stack alignItems={'center'} justifyContent="center">
          <DynamicButton size={'sm'} variant={'solid'} action={'read'} onClick={() => window.open(data?.postUrl,'_blank')} />
        </Stack>
      </Grid>
      <Divider />
      <Stack>
        <HStack spacing={5}>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Impressions</Text>
            <Heading size="md">{data?.publicMetrics.impressionCount}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Retweet</Text>
            <Heading size="md">{data?.publicMetrics.retweetCount}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Like</Text>
            <Heading size="md">{data?.publicMetrics.likeCount}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Reply</Text>
            <Heading size="md">{data?.publicMetrics.replyCount}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Quote</Text>
            <Heading size="md">{data?.publicMetrics.quoteCount}</Heading>
          </Stack>
          <Stack alignItems={'center'} justifyContent="center" w={'70px'}>
            <Text fontSize={'sm'} color="gray.600">Bookmark</Text>
            <Heading size="md">{data?.publicMetrics.bookmarkCount}</Heading>
          </Stack>
          <Spacer />
          <Stack>
            <Text fontSize={'xs'} color="gray.600">Share via {globalState?.socialActive?.platform} on {moment(data?.created).format('LLLL')}</Text>
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  )
}

export default TwitterPublishCard