import loadComponents from './components';
import loadTraits from './traits';

function myPlugin(editor, opts = {}) {
  const options = {};
  for (const name in options) {
    if (!(name in opts)) opts[name] = options[name];
  }

  loadTraits(editor, options);
  loadComponents(editor, opts);
}

export default myPlugin;
