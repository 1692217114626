import { AspectRatio, Box, Grid, HStack, Image, Select, SimpleGrid, Spacer, Stack, Tag, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineComment } from 'react-icons/ai';
import useUserStore from '../../../Hooks/Zustand/Store';
import _axios from '../../../Api/AxiosBarrier';
import CommentEngageTiktok from '../../../Components/Comments/CommentEngageTiktok';

function TiktokTab() {

  const globalState = useUserStore();

  const [data, setData] = useState('')
  const [dataPost, setDataPost] = useState([]);

  const mediaTypeRef = useRef('');
  const availableMediaTypesRef = useRef([]);

  const [postActive, setPostActive] = useState('')


  const getDataPost = async () => {
    globalState.setIsLoading(true)
    const data = {
      profileKey: globalState.socialKey,
      query: `/${globalState?.socialActive?.platform}`
    }
    try {
      const res = await _axios.post('/ayrshare-getAllPosts', data)
      if (res.data.status === 'success') {
        setData(res.data)
        if (res.data.posts.length > 0) {
          let dataFix = [];
          const dataPosting = res.data.posts;
          const platformFilters = {
            tiktok: (x) => x.mediaType === mediaTypeRef.current,
            // Tambahkan platform lain dengan aturan filter yang sesuai jika diperlukan
            // misalnya: twitter: (x) => x.someProperty === someValue
          };
          const activePlatform = globalState?.socialActive?.platform.toLowerCase();

          const filterFunction = platformFilters[activePlatform];
          if (filterFunction) {
            dataFix = dataPosting.filter(filterFunction);
          } else {
            // Jika platform tidak memiliki filter khusus, tampilkan semua data
            dataFix = [...dataPosting];
          }

          setDataPost(dataFix)

        }
      }
    } catch (error) {
      throw new Error(error)
    }
    finally {
      globalState.setIsLoading(false)
    }
  }

  const getDataAvailableFilter = () => {
    if (globalState?.socialActive?.platform === 'tiktok') {
      availableMediaTypesRef.current = ['video']; // Misalnya, opsi Video dan Live untuk TikTok
    }
  };

  useEffect(() => {
    if (availableMediaTypesRef.current.length > 0) {
      mediaTypeRef.current = availableMediaTypesRef.current[0]; // Mengat
    }

    getDataAvailableFilter();
    getDataPost(); // Panggil getDataPost setelah perubahan platform atau mediaType
  }, [globalState.socialKey, globalState?.socialActive?.platform]);



  const handleComment = async (x) => {
    globalState.setIsLoading(true)
    const updateData = x


    const data = {
      profileKey: globalState.socialKey,
      query: `${x.itemId}?searchPlatformId=true&platform=${globalState?.socialActive?.platform}`
    }

    try {
      const res = await _axios.post('ayrshare-getAllComments', data);
      updateData.commentsData = res.data

    } catch (error) {
      throw new Error(error)
    } finally {
      setPostActive(updateData)
      globalState.setIsLoading(false)

    }
  }

  const height = window.innerHeight

  return (
    <Stack >
      <Grid templateColumns={{ base: '1fr', md: '2fr 3fr' }} gap={5} >
        <Stack  h={height / 1.2} overflowY="auto" shadow="md" borderRadius={'md'} p={3}>
          <HStack>
            <Text fontSize={'lg'} fontWeight="bold" color={'gray.600'}>
                            Posting {globalState?.socialActive?.platform}
            </Text>
            <Spacer />
            <Box>
              <Select
                value={mediaTypeRef.current}
                onChange={(e) => {
                  mediaTypeRef.current = e?.target.value;
                  getDataPost(); // Panggil getDataPost setiap kali mediaType berubah
                }}
                placeholder="Select option"
              >
                {availableMediaTypesRef.current.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </Box>
          </HStack>
          {dataPost.length > 0 && (
            <SimpleGrid columns={[1, 2, 3]} gap={2}>
              {dataPost.map((x, index) => {
                return (
                  <Stack key={index} >
                    <Stack position={'relative'} borderWidth={3} borderColor={postActive?.id === x?.id ? 'blue.300' : 'transparent'}>
                      <AspectRatio maxW="auto" ratio={1}>
                        <Image src={x?.thumbnailUrl} />

                      </AspectRatio>

                      <Stack position={'absolute'} bottom={1} left={1}>
                        <Tag cursor="pointer" colorScheme={'red'} size="sm" onClick={() => handleComment(x)}>
                          <HStack>
                            <AiOutlineComment />
                            <Text>
                              {x?.comments}
                            </Text>
                          </HStack>
                        </Tag>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              })}
            </SimpleGrid>
          )}

        </Stack>
        <Stack h={height / 1.2} overflowY="auto" shadow="md" borderRadius={'md'} p={3}>
          {postActive !== '' && (
            <CommentEngageTiktok postActive={postActive} platformData={globalState?.socialActive?.platform} profileKeyData={globalState.socialKey} />
          )}

        </Stack>
      </Grid>
    </Stack>
  )
}

export default TiktokTab