/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React,{ useEffect,useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { MdCancel,MdImportantDevices } from 'react-icons/md';
import BackButtons from '../../../Components/Buttons/BackButtons';
import { languages } from '../../../Utils/getLanguange';
import useMarketingStore from '../../../Hooks/Zustand/MarketingStore';
import axios from 'axios';
import ReactSelect from 'react-select';
import UploadFileToDropbox from '../../../Components/DropBox/UploadFileToDropbox';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';
import { decryptToken } from '../../../Utils/encrypToken';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { feedsPlacement,storiesPlacement } from './FacebookDataSelection';
import FacebookPost from '../../../Components/AdPreviews/FacebookPost';
import InstagramPost from '../../../Components/AdPreviews/InstagramPost';
import FacebookVideoFeeds from '../../../Components/AdPreviews/FacebookVideoFeeds';
import InstagramExplore from '../../../Components/AdPreviews/InstagramExplore';
import MessengerInbox from '../../../Components/AdPreviews/MessengerInbox';
import InstagramtReels from '../../../Components/AdPreviews/InstagramtReels';
import { HiOutlineDeviceMobile } from 'react-icons/hi';
import InstagramtStories from '../../../Components/AdPreviews/InstagramtStories';
import FacebookStories from '../../../Components/AdPreviews/FacebookStories';
import FacebookReels from '../../../Components/AdPreviews/FacebookReels';
import FacebookMarketplace from '../../../Components/AdPreviews/FacebookMarketplace';

function MarketingMetaAddAdPage() {
  const navigate = useNavigate();
  const modalLocation = useDisclosure();
  const globalState = useUserStore();
  const [choosedFeedPlacement,setChoosedFeedPlacement] = useState([]);
  const [choosedReelPlacement,setChoosedReelPlacement] = useState([]);
  const [choosedSimplePlacement,setChoosedSimplePlacement] = useState([]);
  const [selectAll,setSelectAll] = useState(false);
  const [selectAllReels,setSelectAllReels] = useState(false);
  const [dataCarousel,setDataCarousel] = useState({
    webUrl: '',
    link: '',
    headline: '',
    description: '',
    cta: '',
  });
  const marketingState = useMarketingStore();
  const [listProvince,setListProvince] = useState([]);
  const [input,setInput] = useState({});
  const [listCity,setListCity] = useState([]);
  const [progress,setProgress] = useState(0);
  const [isChecked,setIsChecked] = useState(false);
  const [listDisrict,setListDistrict] = useState([]);
  const [data,setData] = useState({});
  const [logo,setLogo] = useState('');
  const [media,setMedia] = useState();
  const toast = useToast();
  const param = useParams();
  const modalCarousel = useDisclosure();
  const [selectedPreview,setSelectedPreview] = useState('');
  const [selectedLocation,setSelectedLocation] = useState({
    province: '',
    city: '',
    district: '',
  });

  const steps = [
    { title: 'Campaign',description: 'Create Campaign' },
    { title: 'Ads',description: 'Create Ads' },
    { title: 'Audience',description: 'Select Audience' },
    {
      title: 'Budget & Strategies',
      description: 'Set your budget & strategies',
    },
    { title: 'Publish',description: 'Review & publish' },
  ];

  const getCampaignData = async () => {
    try {
      const res = await getSingleDocumentFirebase('marketing_meta',param?.id);

      setData(res);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleNavigate = (value) => {
    if (param?.type === 'edit') {
      navigate(`/marketing/meta/ads/create/audience/edit/${value?.id}`);
    } else {
      if ((marketingState?.adsCreation?.format === 'carousel' && marketingState?.adsCreation?.carousel.length > 0) || (marketingState?.adsCreation?.format === 'single' && marketingState?.adsCreation?.mediaLink?.length > 0)) {
        navigate('/marketing/meta/ads/create/audience');
      } else {
        toast({
          status: 'warning',
          title: 'Complete Data',
          duration: 3000,
        });
      }
    }
  };

  const fetchDataProvince = async () => {
    const res = await axios.get('https://ibnux.github.io/data-indonesia/provinsi.json');

    const options = res?.data.map((x) => ({
      label: x?.nama,
      value: x?.id,
    }));

    setListProvince(options);
  };

  const fetchDataKabupaten = async (provinceId) => {
    const resKabupaten = await axios.get(`https://ibnux.github.io/data-indonesia/kabupaten/${provinceId}.json`);

    const options = resKabupaten?.data.map((x) => ({
      label: x?.nama,
      value: x?.id,
    }));

    setListCity(options);
  };

  const fetchDataKecamatan = async (cityId) => {
    const resKecamatan = await axios.get(`https://ibnux.github.io/data-indonesia/kecamatan/${cityId}.json`);

    const options = resKecamatan?.data.map((x) => ({
      label: x?.nama,
      value: x?.id,
    }));

    setListDistrict(options);
  };

  const handleSelectProvince = async (x) => {
    setSelectedLocation({ ...selectedLocation,province: x?.label });

    if (x.value !== '') {
      fetchDataKabupaten(x.value);
    }
  };

  const handleSelectDistrict = async (x) => {
    setSelectedLocation({ ...selectedLocation,district: x?.label });
  };

  const handleSelectCity = async (x) => {
    setSelectedLocation({ ...selectedLocation,city: x?.label });

    if (x.value !== '') {
      fetchDataKecamatan(x.value);
    }
  };

  const handleChangeLogo = async (file) => {
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/facebook-campaign`;

    const token = await getSingleDocumentFirebase('token','dropbox');
    const decryptResult = decryptToken(`${token?.access_token}`);

    const res = await UploadFileToDropbox(file[0],parentPath,decryptResult,setProgress,progress);

    // console.log(res);
    setLogo(res?.link);
    marketingState?.setAdsCreation({ businessLogo: res?.link });
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    // console.log(file);

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/facebook-campaign`;

    try {
      const token = await getSingleDocumentFirebase('token','dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file,parentPath,decryptResult,setProgress,progress);

      // console.log(res);
      setMedia(res?.link);

      if (marketingState?.adsCreation?.format === 'carousel') {
        handleChangeCarousel(res);

        setDataCarousel({ ...dataCarousel,link: res?.link,type: res?.type });
      } else {
        marketingState?.setAdsCreation({
          mediaLink: [res],
        });
      }
    } catch (error) {
      // console.log(error);
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleChangeCarousel = async (value) => {
    let currentMedia = '';

    if (param?.type === 'edit') {
      currentMedia = data?.adsCreation?.mediaLink;
    } else {
      currentMedia = marketingState?.adsCreation?.mediaLink;
    }

    if (Array.isArray(currentMedia)) {
      if (currentMedia?.length > 0) {
        currentMedia?.push(value);

        marketingState?.setAdsCreation({ mediaLink: currentMedia });
      } else {
        marketingState?.setAdsCreation({
          mediaLink: [value],
        });
      }
    } else {
      marketingState?.setAdsCreation({
        mediaLink: [value],
      });
    }
  };

  const handleAddLocation = () => {
    let currentLocation = marketingState?.adsCreation?.location;

    if (param?.type === 'edit') {
      currentLocation = data?.adsCreation?.location;
    } else {
      currentLocation = marketingState?.adsCreation?.location;
    }

    if (selectedLocation.province === '') {
      toast({
        status: 'error',
        title: 'Choose a location',
        duration: 3000,
      });
    } else {
      if (currentLocation?.length > 0) {
        currentLocation?.push(selectedLocation);

        marketingState?.setAdsCreation({
          location: currentLocation,
        });
      } else {
        marketingState?.setAdsCreation({
          location: [selectedLocation],
        });
      }

      // console.log(currentLocation, 'ini location current');
      modalLocation?.onClose();
      setIsChecked(false);
      setSelectedLocation({
        province: '',
        city: '',
        district: '',
      });
    }
  };

  const handleRemoveLocation = (value) => {
    const currentLocation = marketingState?.adsCreation?.location;

    const index = currentLocation.findIndex((x) => x.province === value?.province);
    // console.log(index);

    if (index > 0) {
      currentLocation?.splice(index,1);

      // console.log(currentLocation);

      marketingState?.setAdsCreation({
        location: currentLocation,
      });
    }
  };

  const handleRemove = (value) => {
    let currentCarousel = '';

    if (param?.type === 'edit') {
      currentCarousel = data?.adsCreation?.carousel;
    } else {
      currentCarousel = marketingState?.adsCreation?.carousel;
    }

    const index = currentCarousel.findIndex((x) => x.link === value.link);
    // console.log(index);

    if (index > -1) {
      currentCarousel?.splice(index,1);

      // console.log(currentCarousel);

      marketingState?.setAdsCreation({
        carousel: currentCarousel,
      });
    }
  };

  const handleAddCarousel = () => {
    let currentCarousel = '';

    if (param?.type === 'edit') {
      currentCarousel = data?.adsCreation?.carousel;
    } else {
      currentCarousel = marketingState?.adsCreation?.carousel;
    }

    if (dataCarousel && dataCarousel.link && dataCarousel.type) {
      if (currentCarousel?.length > 0) {
        currentCarousel.push(dataCarousel);
        marketingState?.setAdsCreation({ carousel: currentCarousel });
      } else {
        marketingState?.setAdsCreation({
          carousel: [dataCarousel],
        });
      }

      modalCarousel?.onClose();
    } else {
      toast({
        status: 'warning',
        title: 'Input Carousel',
        duration: 3000,
      });
    }
  };

  const handleRemoveCarousel = (value) => {
    let currentMedia = '';

    if (param?.type === 'edit') {
      currentMedia = data?.adsCreation?.mediaLink;
    } else {
      currentMedia = marketingState?.adsCreation?.mediaLink;
    }

    const index = currentMedia.findIndex((x) => x.link === value.link);
    // console.log(index);

    if (index > -1) {
      currentMedia?.splice(index,1);

      // console.log(currentMedia);

      marketingState?.setAdsCreation({
        mediaLink: currentMedia,
      });
    }
  };

  const handleCheck = () => {
    const currentLocation = marketingState?.adsCreation?.location;

    if (isChecked) {
      setIsChecked(false);

      setSelectedLocation({ ...selectedLocation,province: '' });

      const index = currentLocation.findIndex((x) => x.province === 'Indonesia');

      if (index > 0) {
        currentLocation?.splice(index,1);

        // console.log(currentLocation);

        marketingState?.setAdsCreation({
          location: currentLocation,
        });
      }
    } else {
      setIsChecked(true);

      setSelectedLocation({ ...selectedLocation,province: 'Indonesia' });
    }
  };

  const handleSelectCheckbox = (selected,type) => {
    if (type === 'stories') {
      if (choosedReelPlacement.includes(selected)) {
        setChoosedReelPlacement(choosedReelPlacement?.filter((item) => item !== selected));
        marketingState?.setAdPlacement({ storiesAndReels: choosedReelPlacement?.filter((item) => item !== selected) });
      } else {
        setChoosedReelPlacement([...choosedReelPlacement,selected]);
        marketingState?.setAdPlacement({ storiesAndReels: [...choosedReelPlacement,selected] });
      }
    } else if (type === 'feeds') {
      // console.log('ini masuk feed');
      if (choosedFeedPlacement.includes(selected)) {
        setChoosedFeedPlacement(choosedFeedPlacement?.filter((item) => item !== selected));
        marketingState?.setAdPlacement({ feeds: choosedFeedPlacement?.filter((item) => item !== selected) });
      } else {
        setChoosedFeedPlacement([...choosedFeedPlacement,selected]);
        marketingState?.setAdPlacement({ feeds: [...choosedFeedPlacement,selected] });
      }
    } else {
      if (choosedSimplePlacement?.includes(selected)) {
        setChoosedSimplePlacement(choosedSimplePlacement?.filter((item) => item !== selected));
        marketingState?.setAdPlacement({
          messengerPlacement: choosedSimplePlacement?.filter((item) => item !== selected),
        });
      } else {
        setChoosedSimplePlacement([...choosedSimplePlacement,selected]);
        marketingState?.setAdPlacement({
          messengerPlacement: [...choosedSimplePlacement,selected],
        });
      }
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setChoosedFeedPlacement([]);
      marketingState?.setAdPlacement({ feeds: [] });
    } else {
      setChoosedFeedPlacement([...feedsPlacement]);
      marketingState?.setAdPlacement({ feeds: [...feedsPlacement] });
    }
    setSelectAll(!selectAll);
  };

  const handleSelectAllReels = () => {
    if (selectAllReels) {
      setChoosedReelPlacement([]);
      marketingState?.setAdPlacement({ storiesAndReels: [] });
    } else {
      setChoosedReelPlacement([...storiesPlacement]);
      marketingState?.setAdPlacement({ storiesAndReels: [...storiesPlacement] });
    }

    setSelectAllReels(!selectAllReels);
  };

  useEffect(() => {
    if (param?.type === 'edit') {
      getCampaignData();
    }

    fetchDataProvince();

    return () => { };
  },[]);

  useEffect(() => {
    const fetchData = async () => {
      if (param?.type === 'edit') {
        try {
          const res = await getSingleDocumentFirebase('marketing_meta',param?.id);

          setData(res);
          setInput(res);
        } catch (error) {
          throw new Error(error);
        }
      }
    };

    fetchDataProvince();
    fetchData();
  },[param?.id,param?.type]);

  // console.log(marketingState);

  return (
    <Box mb='5'>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={2}
        bg={'white'}
        p={5}
        borderRadius={'md'}
        shadow={'base'}
        my={5}>
        {steps.map((step,index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Box>
        <Heading textAlign='center'>Ads</Heading>
        <SimpleGrid
          columns={{ base: 1,sm: 1,md: 2 }}
          p={5}
          bg={'white'}
          my={4}>
          <Container
            alignSelf='end'
            p={5}
            mt='5'
            shadow='base'>
            <FormControl>
              <FormLabel>Ad Name</FormLabel>
              <Input
                placeholder={param?.type === 'edit' ? data?.adsCreation?.adName : 'Your Ad Name'}
                defaultValue={marketingState?.adsCreation?.adName}
                onChange={(e) => marketingState?.setAdsCreation({ adName: e.target.value })}
                type='text'
              />
            </FormControl>

            <Box
              mt={4}
              p={2}
              border={'1px'}
              borderRadius={'md'}
              borderColor={'gray.200'}>
              <Text fontWeight={500}>Ad Placements</Text>
              <Text
                fontSize={12}
                py={2}>
                Ads are shown in more places to increase the number of people who can see your ad and to help get more results
              </Text>
              <>
                <Accordion
                  allowMultiple
                  py={2}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as='span'
                          flex='1'
                          textAlign='left'>
                          <Checkbox
                            isChecked={selectAll}
                            // isIndeterminate={isIndeterminate}
                            onChange={() => handleSelectAll('feeds')}>
                            Feeds
                          </Checkbox>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Stack
                        pl={6}
                        mt={1}
                        spacing={1}>
                        {feedsPlacement?.map((x,i) => (
                          <Checkbox
                            key={i}
                            defaultChecked={marketingState?.adPlacement?.feeds?.includes(x)}
                            isChecked={choosedFeedPlacement?.includes(x)}
                            onChange={() => handleSelectCheckbox(x,'feeds')}>
                            {x}
                          </Checkbox>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as='span'
                          flex='1'
                          textAlign='left'>
                          <Checkbox
                            isChecked={selectAllReels}
                            onChange={() => handleSelectAllReels('stories')}>
                            Stories and Reels
                          </Checkbox>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Stack
                        pl={6}
                        mt={1}
                        spacing={1}>
                        {storiesPlacement?.map((x,i) => (
                          <Checkbox
                            key={i}
                            isChecked={choosedReelPlacement.includes(x)}
                            onChange={() => handleSelectCheckbox(x,'stories')}>
                            {x}
                          </Checkbox>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </>
            </Box>

            <FormControl mt={4}>
              <FormLabel>Website URL</FormLabel>
              <Input
                placeholder={param?.type === 'edit' ? data?.adsCreation?.websiteUrl : 'Your Website URL'}
                defaultValue={marketingState?.adsCreation?.websiteUrl}
                onChange={(e) => marketingState?.setAdsCreation({ websiteUrl: e.target.value })}
                type='text'
              />
            </FormControl>

            <FormControl
              my={4}
              border={'1px'}
              borderRadius={'md'}
              p={2}
              borderColor={'gray.300'}>
              <FormLabel>Location</FormLabel>

              {param?.type === 'edit' ? (
                <>
                  {marketingState?.adsCreation?.location?.length > 0 ? (
                    <>
                      <Flex
                        flexDir={'row'}
                        gap={1}
                        flexWrap={'wrap'}>
                        {marketingState?.adsCreation?.location?.map((x,index) => (
                          <HStack
                            key={index}
                            spacing={1}
                            p={1}
                            w={'fit-content'}
                            bg={'gray.200'}
                            borderRadius={'md'}>
                            <MdCancel
                              size={12}
                              color='gray'
                              cursor={'pointer'}
                              onClick={() => handleRemoveLocation(x)}
                            />
                            <Text
                              textTransform={'capitalize'}
                              fontSize={12}>
                              {x?.province}, {x?.city}, {x?.district}
                            </Text>
                          </HStack>
                        ))}
                      </Flex>
                    </>
                  ) : (
                    <Flex
                      flexDir={'row'}
                      gap={1}
                      flexWrap={'wrap'}>
                      {marketingState?.adsCreation?.location?.map((x,index) => (
                        <HStack
                          key={index}
                          spacing={1}
                          p={1}
                          w={'fit-content'}
                          bg={'gray.200'}
                          borderRadius={'md'}>
                          <MdCancel
                            size={12}
                            color='gray'
                            cursor={'pointer'}
                            onClick={() => handleRemoveLocation(x)}
                          />
                          <Text
                            textTransform={'capitalize'}
                            fontSize={12}>
                            {x?.province}, {x?.city}, {x?.district}
                          </Text>
                        </HStack>
                      ))}
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  {marketingState?.adsCreation?.location?.length > 0 && (
                    <>
                      <Flex
                        flexDir={'row'}
                        gap={1}
                        flexWrap={'wrap'}>
                        {marketingState?.adsCreation?.location?.map((x,index) => (
                          <HStack
                            key={index}
                            spacing={1}
                            p={1}
                            w={'fit-content'}
                            bg={'gray.200'}
                            borderRadius={'md'}>
                            <MdCancel
                              size={12}
                              color='gray'
                              cursor={'pointer'}
                              onClick={() => handleRemoveLocation(x)}
                            />
                            <Text
                              textTransform={'capitalize'}
                              fontSize={12}>
                              {x?.province}, {x?.city}, {x?.district}
                            </Text>
                          </HStack>
                        ))}
                      </Flex>
                    </>
                  )}
                </>
              )}

              <Text
                color={'blue.500'}
                cursor={'pointer'}
                onClick={modalLocation.onOpen}>
                + location
              </Text>
            </FormControl>

            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                placeholder={param?.type === 'edit' ? data?.adsCreation?.language : 'Add language for ads'}
                defaultValue={marketingState?.adsCreation?.language}
                onChange={(e) => marketingState?.setAdsCreation({ language: e.target.value })}
                maxW='max-content'>
                {languages.map((x,i) => (
                  <option
                    key={i}
                    value={x.name}>
                    {x.name} - {x.nativeName}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl
              as='fieldset'
              my={4}>
              <FormLabel as='legend'>Format</FormLabel>
              <RadioGroup
                value={marketingState?.adsCreation?.format}
                onChange={(e) => marketingState?.setAdsCreation({ format: e })}
                defaultValue='External Website'>
                <HStack gap='3'>
                  <Radio value='single'>Single Image / Video</Radio>
                  <Radio value='carousel'>Carousel</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>

            <Text
              fontWeight={500}
              mb={3}>
              Ads Display
            </Text>
            <HStack>
              <FormControl>
                <FormLabel>Logo</FormLabel>
                <Input
                  defaultValue={marketingState?.adsCreation?.logo}
                  onChange={(e) => handleChangeLogo(e?.target.files)}
                  type='file'
                />
              </FormControl>
              <FormControl>
                <FormLabel>Business Name</FormLabel>
                <Input
                  placeholder={param?.type === 'edit' ? data?.adsCreation?.businessName : 'Your Business Name'}
                  defaultValue={marketingState?.adsCreation?.businessName}
                  onChange={(e) =>
                    marketingState?.setAdsCreation({
                      businessName: e.target.value,
                    })
                  }
                  type='text'
                />
              </FormControl>
            </HStack>

            <FormControl my={4}>
              <FormLabel>Primary Text</FormLabel>
              <Textarea
                placeholder={param?.type === 'edit' ? data?.adsCreation?.primaryText : 'Add primary text for ads'}
                defaultValue={marketingState?.adsCreation?.primaryText}
                onChange={(e) =>
                  marketingState?.setAdsCreation({
                    primaryText: e.target.value,
                  })
                }
              />
            </FormControl>

            {param?.type === 'edit' ? (
              <>
                {data?.adsCreation?.format === 'carousel' ? (
                  <>
                    {data?.adsCreation?.carousel?.length > 0 ? (
                      <Flex
                        flexWrap={'wrap'}
                        gap={2}
                        my={3}>
                        {data?.adsCreation?.carousel?.map((x,i) => (
                          <Box
                            key={i}
                            position={'relative'}
                            w={'fit-content'}
                            p={1}
                            border={'1px'}
                            borderRadius={'md'}
                            shadow={'base'}
                            borderColor={'gray.100'}>
                            <Text>Carousel {i + 1}</Text>
                            <Box
                              pos={'absolute'}
                              top={-1}
                              right={-2}
                              cursor={'pointer'}
                              onClick={() => handleRemove(x)}>
                              <MdCancel />
                            </Box>
                          </Box>
                        ))}
                      </Flex>
                    ) : null}
                    <Box align={'center'}>
                      <Button
                        variant={'outline'}
                        colorScheme='blue'
                        onClick={modalCarousel.onOpen}>
                        Add Media Carousel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <FormControl>
                      <FormLabel>Media</FormLabel>
                      {data?.adsCreation?.format !== '' && (
                        <Text
                          fontSize={12}
                          fontStyle={'italic'}
                          my={2}>
                          Ads Format: {data?.adsCreation?.format}
                        </Text>
                      )}

                      {data?.adsCreation?.format === 'carousel' ? (
                        <Box>
                          {data?.adsCreation?.mediaLink?.length > 0 && (
                            <Flex
                              gap={2}
                              flexDir={'row'}
                              flexWrap={'wrap'}>
                              {data?.adsCreation?.mediaLink?.map((x, i) => (
                                <Box
                                  key={i}
                                  w={'fit-content'}
                                  position={'relative'}>
                                  <Box
                                    pos={'absolute'}
                                    top={1}
                                    right={1}>
                                    <MdCancel
                                      onClick={() => handleRemoveCarousel(x)}
                                      color='red'
                                      cursor={'pointer'}
                                    />
                                  </Box>
                                  <Image
                                    w={150}
                                    maxH={200}
                                    src={x?.link}
                                    alt='ads Image'
                                  />
                                </Box>
                              ))}
                            </Flex>
                          )}
                          <Input
                            my={2}
                            // value={marketingState?.adsCreation?.mediaLink?.link}
                            onChange={handleChange}
                            type='file'
                          />
                        </Box>
                      ) : (
                        <Input
                          // value={marketingState?.adsCreation?.mediaLink?.link}
                          onChange={handleChange}
                          type='file'
                        />
                      )}
                    </FormControl>

                    <FormControl my={4}>
                      <FormLabel>Headline</FormLabel>
                      <Input
                        placeholder={data?.adsCreation?.headline}
                        defaultValue={marketingState?.adsCreation?.headline}
                        onChange={(e) => marketingState?.setAdsCreation({ headline: e.target.value })}
                        type='text'
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Description</FormLabel>
                      <Input
                        placeholder={data?.adsCreation?.description}
                        defaultValue={marketingState?.adsCreation?.description}
                        onChange={(e) =>
                          marketingState?.setAdsCreation({
                            description: e.target.value,
                          })
                        }
                        type='text'
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Call to Action</FormLabel>
                      <Input
                        placeholder={data?.adsCreation?.cta}
                        defaultValue={marketingState?.adsCreation?.cta}
                        onChange={(e) => marketingState?.setAdsCreation({ cta: e.target.value })}
                        type='text'
                      />
                    </FormControl>
                  </>
                )}
              </>
            ) : (
              <>
                {marketingState?.adsCreation?.format === 'carousel' ? (
                  <>
                    {marketingState?.adsCreation?.carousel?.length > 0 ? (
                      <Flex
                        flexWrap={'wrap'}
                        gap={2}
                        my={3}>
                        {marketingState?.adsCreation?.carousel?.map((x,i) => (
                          <Box
                            key={i}
                            position={'relative'}
                            w={'fit-content'}
                            p={1}
                            border={'1px'}
                            borderRadius={'md'}
                            shadow={'base'}
                            borderColor={'gray.100'}>
                            <Text>Carousel {i + 1}</Text>
                            <Box
                              pos={'absolute'}
                              top={-1}
                              right={-2}
                              cursor={'pointer'}
                              onClick={() => handleRemove(x)}>
                              <MdCancel />
                            </Box>
                          </Box>
                        ))}
                      </Flex>
                    ) : null}
                    <Box align={'center'}>
                      <Button
                        variant={'outline'}
                        colorScheme='blue'
                        onClick={modalCarousel.onOpen}>
                        Add Media Carousel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <FormControl>
                      <FormLabel>Media</FormLabel>
                      {marketingState?.adsCreation?.format !== '' && (
                        <Text
                          fontSize={12}
                          fontStyle={'italic'}
                          my={2}>
                          Ads Format: {marketingState?.adsCreation?.format}
                        </Text>
                      )}

                      {marketingState?.adsCreation?.format === 'carousel' ? (
                        <Box>
                          {marketingState?.adsCreation?.mediaLink?.length > 0 && (
                            <Flex
                              gap={2}
                              flexDir={'row'}
                              flexWrap={'wrap'}>
                              {marketingState?.adsCreation?.mediaLink?.map((x, i) => (
                                <Box
                                  key={i}
                                  w={'fit-content'}
                                  position={'relative'}>
                                  <Box
                                    pos={'absolute'}
                                    top={1}
                                    right={1}>
                                    <MdCancel
                                      onClick={() => handleRemoveCarousel(x)}
                                      color='red'
                                      cursor={'pointer'}
                                    />
                                  </Box>
                                  <Image
                                    w={150}
                                    maxH={200}
                                    src={x?.link}
                                    alt='ads Image'
                                  />
                                </Box>
                              ))}
                            </Flex>
                          )}
                          <Input
                            my={2}
                            // value={marketingState?.adsCreation?.mediaLink?.link}
                            onChange={handleChange}
                            type='file'
                          />
                        </Box>
                      ) : (
                        <Input
                          // value={marketingState?.adsCreation?.mediaLink?.link}
                          onChange={handleChange}
                          type='file'
                        />
                      )}
                    </FormControl>

                    <FormControl my={4}>
                      <FormLabel>Headline</FormLabel>
                      <Input
                        placeholder={param?.type === 'edit' ? data?.adsCreation?.headline : 'Ads headline'}
                        value={marketingState?.adsCreation?.headline}
                        onChange={(e) => marketingState?.setAdsCreation({ headline: e.target.value })}
                        type='text'
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Description</FormLabel>
                      <Input
                        placeholder={param?.type === 'edit' ? data?.adsCreation?.description : 'Description'}
                        defaultValue={marketingState?.adsCreation?.description}
                        onChange={(e) =>
                          marketingState?.setAdsCreation({
                            description: e.target.value,
                          })
                        }
                        type='text'
                      />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Call to Action</FormLabel>
                      <Input
                        placeholder={param?.type === 'edit' ? data?.adsCreation?.headline : 'Number'}
                        defaultValue={marketingState?.adsCreation?.cta}
                        onChange={(e) => marketingState?.setAdsCreation({ cta: e.target.value })}
                        type='text'
                      />
                    </FormControl>
                  </>
                )}
              </>
            )}

            <Button
              mt={5}
              w='full'
              colorScheme='green'
              onClick={() => handleNavigate(data)}>
              Next
            </Button>
          </Container>

          <Box
            border={'1px'}
            borderColor={'gray.50'}
            borderRadius={'md'}
            shadow={'base'}>
            <Center p={5}>
              {selectedPreview === '' ? (
                <Stack align={'center'}>
                  <Box color='gray.400'>
                    <MdImportantDevices size={100} />
                  </Box>
                  <Text fontWeight={500}>Ad Preview will be displayed here</Text>
                  <Text fontSize={14}>Preview displayed based on adplacement that you choose</Text>
                </Stack>
              ) : selectedPreview === 'Facebook Feed' ? (
                <FacebookPost
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Feed' || selectedPreview === 'Instagram profile feed' || selectedPreview === 'Instagram Explore' ? (
                <InstagramPost
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook Marketplace' ? (
                <FacebookMarketplace
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Messenger inbox' ? (
                <MessengerInbox
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook video feeds' ? (
                <FacebookVideoFeeds
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook video feeds' ? (
                <FacebookVideoFeeds
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Reels' ? (
                <InstagramtReels
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Stories' ? (
                <InstagramtStories
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook Stories' ? (
                <FacebookStories
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook Reels' ? (
                <FacebookReels
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Explore home' ? (
                <InstagramExplore
                  marketingState={marketingState}
                  data={data}
                />
              ) : null}
            </Center>

            <Box
              align={'center'}
              py={5}>
              <Divider />
            </Box>

            <Box
              py={3}
              align={'center'}>
              <Text fontWeight={500}>Preview Selections</Text>
              {marketingState?.adPlacement?.feeds?.length > 0 || marketingState?.adPlacement?.storiesAndReels?.length > 0 ? (
                <>
                  <SimpleGrid
                    columns={4}
                    spacing={2}
                    p={3}>
                    {marketingState?.adPlacement?.feeds?.map((x, i) => (
                      <Stack
                        key={i}
                        cursor={'pointer'}
                        p={2}
                        border={'1px'}
                        align={'center'}
                        borderRadius={'md'}
                        shadow={'sm'}
                        borderColor={selectedPreview === x ? 'blue.300' : 'gray.50'}
                        onClick={() => setSelectedPreview(x)}>
                        <HiOutlineDeviceMobile size={20} />
                        <Text align={'center'}>{x}</Text>
                      </Stack>
                    ))}
                    {marketingState?.adPlacement?.storiesAndReels?.map((x, i) => (
                      <Stack
                        key={i}
                        cursor={'pointer'}
                        p={2}
                        border={'1px'}
                        align={'center'}
                        borderRadius={'md'}
                        shadow={'sm'}
                        borderColor={selectedPreview === x ? 'blue.300' : 'gray.50'}
                        onClick={() => setSelectedPreview(x)}>
                        <HiOutlineDeviceMobile size={20} />
                        <Text align={'center'}>{x}</Text>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </>
              ) : (
                <Text>Select placement by check the box in ad placement section</Text>
              )}
            </Box>
          </Box>
        </SimpleGrid>
      </Box>

      <Modal
        isOpen={modalLocation?.isOpen}
        onClose={modalLocation?.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Checkbox
              checked={marketingState?.adsCreation?.location?.find((x) => x.province === 'Indonesia') ? true : false}
              onChange={handleCheck}>
              Indonesia
            </Checkbox>
            <Stack mt={3}>
              <Text fontWeight={500}>Province</Text>
              <ReactSelect
                isDisabled={!isChecked ? false : true}
                className='basic-single'
                classNamePrefix='select'
                isSearchable={true}
                name='color'
                options={listProvince}
                onChange={handleSelectProvince}
              />
            </Stack>
            <Stack>
              <Text fontWeight={500}>City</Text>
              <ReactSelect
                className='basic-single'
                classNamePrefix='select'
                //  defaultValue={colourOptions[0]}
                isDisabled={selectedLocation?.province !== '' && !isChecked ? false : true}
                //  isLoading={isLoading}
                //  isClearable={isClearable}
                //  isRtl={isRtl}
                isSearchable={true}
                onChange={handleSelectCity}
                name='color'
                options={listCity}
              />
            </Stack>
            <Stack>
              <Text fontWeight={500}>District</Text>
              <ReactSelect
                className='basic-single'
                classNamePrefix='select'
                //  defaultValue={colourOptions[0]}
                isDisabled={selectedLocation?.city !== '' ? false : true}
                //  isLoading={isLoading}
                //  isClearable={isClearable}
                //  isRtl={isRtl}
                onChange={handleSelectDistrict}
                isSearchable={true}
                name='color'
                options={listDisrict}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='blue'
              size={'sm'}
              onClick={handleAddLocation}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalCarousel.isOpen}
        onClose={modalCarousel.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Carousel Detail</ModalHeader>
          <ModalBody>
            <Box>
              <FormControl>
                <FormLabel>Media Input</FormLabel>
                <Input
                  my={2}
                  // value={marketingState?.adsCreation?.mediaLink?.link}
                  onChange={handleChange}
                  type='file'
                />
              </FormControl>

              <FormControl my={4}>
                <FormLabel>Headline</FormLabel>
                <Input
                  // value={marketingState?.adsCreation?.headline}
                  onChange={(e) => setDataCarousel({ ...dataCarousel,headline: e.target.value })}
                  type='text'
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input
                  // value={marketingState?.adsCreation?.description}
                  onChange={(e) => setDataCarousel({ ...dataCarousel,description: e.target.value })}
                  type='text'
                />
              </FormControl>

              <FormControl>
                <FormLabel>Web Url</FormLabel>
                <Input
                  // value={marketingState?.adsCreation?.description}
                  onChange={(e) => setDataCarousel({ ...dataCarousel,webUrl: e.target.value })}
                  type='text'
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Call to Action</FormLabel>
                <Input
                  // value={marketingState?.adsCreation?.cta}
                  onChange={(e) => setDataCarousel({ ...dataCarousel,cta: e.target.value })}
                  type='text'
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='blue'
              onClick={handleAddCarousel}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MarketingMetaAddAdPage;
