import { AspectRatio,Box,Button,Container,HStack,Heading,Image,Spacer,Stack,Text } from '@chakra-ui/react'
import React from 'react'
import PropTypes from 'prop-types';

const FacebookMarketplace = ({ marketingState,param }) => {

  return (
    <>
      <Container alignSelf="start" w={'60%'}>
        <Box borderRadius={'md'} shadow={'base'} overflow={'hidden'}>
          <HStack p={2}>
            <Image
              // w={75}
              boxSize={50}
              borderRadius="full"
              src={
                marketingState?.adsCreation?.businessLogo === ''
                  ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                  : marketingState?.adsCreation?.businessLogo
              }
              alt="your brand name"
            />
            <Box>
              <Heading size="md">
                {marketingState?.adsCreation?.businessName === ''
                  ? 'Your Business Name'
                  : marketingState?.adsCreation?.businessName}
              </Heading>
              <Text>Sponsored</Text>
            </Box>
          </HStack>
          <Text mt="5" p={2}>
            {marketingState?.adsCreation?.businessName === ''
              ? 'Your Primary Text'
              : marketingState?.adsCreation?.primaryText}
            {/* 𝟱 𝗝𝗮𝗺 𝗣𝗿𝗮𝗸𝘁𝗲𝗸 𝗶𝗺𝗽𝗼𝗿𝘁 & 𝗥𝗶𝘀𝗲𝘁 𝗣𝗿𝗼𝗱𝘂𝗸 𝗟𝗮𝗿𝗶𝘀 berdasarkan data dari
                    Tokopedia/Shopee dengan Omset 100 Juta per bulan😱 . Kamu akan
                    di bimbing dari 0 hingga mendapatkan 1 Produk potensi CUAN 100
                    Juta Pertama Hanya dari 1 Produk Saja😍 . Yuk Catat tanggal &
                    waktunya */}
          </Text>
          <Box bgColor="F2F2F2" align={'center'}>
            {param?.type === 'edit' ? (
              <>
                {marketingState?.adsCreation?.mediaLink?.length === 0 ? (
                  <AspectRatio maxW={'500px'} ratio={1}>
                    <Image alt='adsImage' src={'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'} />
                  </AspectRatio>
                ) : (
                  <>
                    {marketingState?.adsCreation?.mediaLink[0]?.type ===
                                            'video' ? (
                        <Box align={'center'}>
                          {/* <AspectRatio> */}
                          <iframe
                            width={'100%'}
                            src={
                              marketingState?.adsCreation?.mediaLink[0]?.link
                            }
                            allowFullScreen
                          />
                          {/* </AspectRatio> */}
                        </Box>
                      ) : (
                        <AspectRatio maxW={'500px'} ratio={9 / 16}>
                          <Image
                            w={300}
                            src={
                              marketingState?.adsCreation?.mediaLink[0]?.link
                            }
                            alt="ads Image"
                          />
                        </AspectRatio>
                      )}
                  </>
                )}
              </>
            ) : (
              <>
                {marketingState?.adsCreation?.mediaLink?.length === 0 ? (
                // <AspectRatio maxW={'500px'} ratio={1}>
                //   <iframe title='adsImage' src={'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'} />
                // </AspectRatio>
                  <AspectRatio ratio={4 / 3}>
                    <Image src='https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg' alt='naruto' objectFit='cover' />
                  </AspectRatio>
                ) : (
                  <>
                    {marketingState?.adsCreation?.mediaLink[0]?.type ===
                                            'video' ? (
                    // <Box align={'center'}>
                        <AspectRatio>
                          <iframe
                            width={'100%'}
                            src={
                              marketingState?.adsCreation?.mediaLink[0]?.link
                            }
                            allowFullScreen
                          />
                        </AspectRatio>
                    // </Box>
                      ) : (
                        <AspectRatio ratio={1}>
                          <Image
                            objectFit={'cover'}
                            src={
                              marketingState?.adsCreation?.mediaLink[0]?.link
                            }
                            alt="ads Image"
                          />
                        </AspectRatio>
                      )}
                  </>
                )}
              </>
            )}
          </Box>
          <Stack align={'center'}
            bgColor="white"
            p="2"
            borderTop={'1px'}
            borderColor={'gray.300'}
          >
            <Box>
              <Text>
                {marketingState?.adsCreation?.headline === ''
                  ? 'Your headline'
                  : marketingState?.adsCreation?.headline}
                {/* Dapatkan Tiketnya segera */}
              </Text>
              <Text fontSize="sm">
                {marketingState?.adsCreation?.description === ''
                  ? 'Your description'
                  : marketingState?.adsCreation?.description}
                {/* Pendaftaran Terbatas */}
              </Text>
            </Box>
            <Spacer />
            <Button size={'sm'}>
              {marketingState?.adsCreation?.cta === ''
                ? 'Your CTA Button'
                : marketingState?.adsCreation?.cta}
              {/* Learn More */}
            </Button>
          </Stack>
        </Box>
      </Container>
    </>
  )
}

export default FacebookMarketplace

FacebookMarketplace.propTypes = {
  marketingState: PropTypes.any,
  data: PropTypes.any,
  param: PropTypes.string,
}