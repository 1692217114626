export const textColor = [
  'text-transparent',
  'text-black',
  'text-white',
  'text-gray-50',
  'text-gray-100',
  'text-gray-200',
  'text-gray-300',
  'text-gray-400',
  'text-gray-500',
  'text-gray-600',
  'text-gray-700',
  'text-gray-800',
  'text-gray-900',

  'text-red-50',
  'text-red-100',
  'text-red-200',
  'text-red-300',
  'text-red-400',
  'text-red-500',
  'text-red-600',
  'text-red-700',
  'text-red-800',
  'text-red-900',

  'text-yellow-50',
  'text-yellow-100',
  'text-yellow-200',
  'text-yellow-300',
  'text-yellow-400',
  'text-yellow-500',
  'text-yellow-600',
  'text-yellow-700',
  'text-yellow-800',
  'text-yellow-900',

  'text-green-50',
  'text-green-100',
  'text-green-200',
  'text-green-300',
  'text-green-400',
  'text-green-500',
  'text-green-600',
  'text-green-700',
  'text-green-800',
  'text-green-900',

  'text-blue-50',
  'text-blue-100',
  'text-blue-200',
  'text-blue-300',
  'text-blue-400',
  'text-blue-500',
  'text-blue-600',
  'text-blue-700',
  'text-blue-800',
  'text-blue-900',

  'text-indigo-50',
  'text-indigo-100',
  'text-indigo-200',
  'text-indigo-300',
  'text-indigo-400',
  'text-indigo-500',
  'text-indigo-600',
  'text-indigo-700',
  'text-indigo-800',
  'text-indigo-900',

  'text-purple-50',
  'text-purple-100',
  'text-purple-200',
  'text-purple-300',
  'text-purple-400',
  'text-purple-500',
  'text-purple-600',
  'text-purple-700',
  'text-purple-800',
  'text-purple-900',

  'text-pink-50',
  'text-pink-100',
  'text-pink-200',
  'text-pink-300',
  'text-pink-400',
  'text-pink-500',
  'text-pink-600',
  'text-pink-700',
  'text-pink-800',
  'text-pink-900',
];

export const textAlignment = [
  'text-left',
  'text-center',
  'text-right',
  'text-justify',
];

export const textOpacity = [
  'text-opacity-0',
  'text-opacity-5',
  'text-opacity-10',
  'text-opacity-20',
  'text-opacity-25',
  'text-opacity-30',
  'text-opacity-40',
  'text-opacity-50',
  'text-opacity-60',
  'text-opacity-70',
  'text-opacity-75',
  'text-opacity-80',
  'text-opacity-90',
  'text-opacity-95',
  'text-opacity-100',
];

export const textDecoration = ['underline', 'line-through', 'no-underline'];

export const textTransformation = [
  'uppercase',
  'lowercase',
  'capitalize',
  'normal-case',
];

export const textOverflow = ['truncate', 'overflow-ellipsis', 'overflow-clip'];

export const textFontSize = [
  'text-xs',
  'text-sm',
  'text-base',
  'text-lg',
  'text-xl',
  'text-2xl',
  'text-3xl',
  'text-4xl',
  'text-5xl',
  'text-6xl',
  'text-7xl',
  'text-8xl',
  'text-9xl',
];

export const fontWeight = [
  'font-thin',
  'font-extralight',
  'font-light',
  'font-normal',
  'font-medium',
  'font-semibold',
  'font-bold',
  'font-extrabold',
  'font-black',
];

export const fontVariant = [
  'normal-nums',
  'ordinal',
  'slashed-zero',
  'lining-nums',
  'oldstyle-nums',
  'proportional-nums',
  'tabular-nums',
  'diagonal-fractions',
  'stacked-fractions',
];

export const letterSpacing = [
  'tracking-tighter',
  'tracking-tight',
  'tracking-normal',
  'tracking-wide',
  'tracking-wider',
  'tracking-widest',
];

export const lineHeight = [
  'leading-3',
  'leading-4',
  'leading-5',
  'leading-6',
  'leading-7',
  'leading-8',
  'leading-9',
  'leading-10',
  'leading-none',
  'leading-tight',
  'leading-snug',
  'leading-normal',
  'leading-relaxed',
  'leading-loose',
];

export const verticalAlign = [
  'align-baseline',
  'align-top',
  'align-middle',
  'align-bottom',
  'align-text-top',
  'align-text-bottom',
];

export const wordBreak = ['break-normal', 'break-words', 'break-all'];
export const tableBorder = ['border-collapse', 'border-separate'];
export const tableLayout = ['table-auto', 'table-fixed'];

export const backgroundAttachment = ['bg-fixed', 'bg-local', 'bg-scroll'];
export const backgroundClip = [
  'bg-clip-border',
  'bg-clip-padding',
  'bg-clip-content',
  'bg-clip-text',
];

export const bgColor = [
  'bg-transparent',
  'bg-black',
  'bg-white',
  'bg-gray-400',
  'bg-gray-700',
  'bg-red-400',
  'bg-red-700',
  'bg-yellow-400',
  'bg-yellow-700',
  'bg-green-400',
  'bg-green-700',
  'bg-blue-400',
  'bg-blue-700',
  'bg-indigo-400',
  'bg-indigo-700',
  'bg-purple-400',
  'bg-purple-700',
  'bg-pink-400',
  'bg-pink-700',
];

export const bgRepeat = [
  'bg-repeat',
  'bg-no-repeat',
  'bg-repeat-x',
  'bg-repeat-y',
  'bg-repeat-round',
  'bg-repeat-space',
];

export const bgSize = ['bg-auto', 'bg-cover', 'bg-contain'];

export const borderRadius = [
  'rounded-none',
  'rounded',
  'rounded-lg',
  'rounded-full',
];

export const borderWidth = [
  'border-0',
  'border-4',
  'border',
  'border-t-4',
  'border-r-4',
  'border-l-4',
  'border-b-4',
];

export const borderColor = [
  'border-black',
  'border-gray-500',
  'border-red-500',
  'border-yellow-500',
  'border-green-500',
  'border-blue-500',
  'border-indigo-500',
  'border-purple-500',
  'border-pink-500',
];

export const borderStyle = [
  'border-solid',
  'border-dashed',
  'border-dotted',
  'border-double',
  'border-none',
];

export const margin = [
  'm-0',
  'mx-0',
  'my-0',
  'ms-0',
  'me-0',
  'm-px',
  'mx-px',
  'my-px',
  'ms-px',
  'me-px',
  'm-0.5',
  'mx-0.5',
  'my-0.5',
  'ms-0.5',
  'me-0.5',
  'm-1',
  'mx-1',
  'my-1',
  'ms-1',
  'me-1',
  'm-1.5',
  'mx-1.5',
  'my-1.5',
  'ms-1.5',
  'me-1.5',
  'm-2',
  'mx-2',
  'my-2',
  'ms-2',
  'me-2',
  'm-2.5',
  'mx-2.5',
  'my-2.5',
  'ms-2.5',
  'me-2.5',
  'm-3',
  'mx-3',
  'my-3',
  'ms-3',
  'me-3',
  'm-3.5',
  'mx-3.5',
  'my-3.5',
  'ms-3.5',
  'me-3.5',
  'm-4',
  'mx-4',
  'my-4',
  'ms-4',
  'me-4',
  'm-5',
  'mx-5',
  'my-5',
  'ms-5',
  'me-5',
  'm-6',
  'mx-6',
  'my-6',
  'ms-6',
  'me-6',
  'm-7',
  'mx-7',
  'my-7',
  'ms-7',
  'me-7',
  'm-8',
  'mx-8',
  'my-8',
  'ms-8',
  'me-8',
  'm-9',
  'mx-9',
  'my-9',
  'ms-9',
  'me-9',
  'm-10',
  'mx-10',
  'my-10',
  'ms-10',
  'me-10',
  'm-11',
  'mx-11',
  'my-11',
  'ms-11',
  'me-11',
  'm-12',
  'mx-12',
  'my-12',
  'ms-12',
  'me-12',
  'm-14',
  'mx-14',
  'my-14',
  'ms-14',
  'me-14',
  'm-16',
  'mx-16',
  'my-16',
  'ms-16',
  'me-16',
  'm-20',
  'mx-20',
  'my-20',
  'ms-20',
  'me-20',
  'm-24',
  'mx-24',
  'my-24',
  'ms-24',
  'me-24',
  'm-28',
  'mx-28',
  'my-28',
  'ms-28',
  'me-28',
  'm-32',
  'mx-32',
  'my-32',
  'ms-32',
  'me-32',
  'm-36',
  'mx-36',
  'my-36',
  'ms-36',
  'me-36',
  'm-40',
  'mx-40',
  'my-40',
  'ms-40',
  'me-40',
  'm-44',
  'mx-44',
  'my-44',
  'ms-44',
  'me-44',
  'm-48',
  'mx-48',
  'my-48',
  'ms-48',
  'me-48',
  'm-52',
  'mx-52',
  'my-52',
  'ms-52',
  'me-52',
  'm-56',
  'mx-56',
  'my-56',
  'ms-56',
  'me-56',
  'm-60',
  'mx-60',
  'my-60',
  'ms-60',
  'me-60',
  'm-64',
  'mx-64',
  'my-64',
  'ms-64',
  'me-64',
  'm-72',
  'mx-72',
  'my-72',
  'ms-72',
  'me-72',
  'm-80',
  'mx-80',
  'my-80',
  'ms-80',
  'me-80',
  'm-96',
  'mx-96',
  'my-96',
  'ms-96',
  'me-96',
  'm-auto',
  'mx-auto',
  'my-auto',
  'ms-auto',
  'me-auto',
]

export const padding = [
  'p-0',
  'px-0',
  'py-0',

  'p-px',
  'px-px',
  'py-px',

  'p-0.5',
  'px-0.5',
  'py-0.5',

  'p-1',
  'px-1',
  'py-1',
  'ps-1',
  'pe-1',
  'p-1.5',
  'px-1.5',
  'py-1.5',
  'ps-1.5',
  'pe-1.5',
  'p-2',
  'px-2',
  'py-2',
  'ps-2',
  'pe-2',
  'p-2.5',
  'px-2.5',
  'py-2.5',
  'ps-2.5',
  'pe-2.5',
  'p-3',
  'px-3',
  'py-3',
  'ps-3',
  'pe-3',
  'p-3.5',
  'px-3.5',
  'py-3.5',
  'ps-3.5',
  'pe-3.5',
  'p-4',
  'px-4',
  'py-4',
  'ps-4',
  'pe-4',
  'p-5',
  'px-5',
  'py-5',
  'ps-5',
  'pe-5',
  'p-6',
  'px-6',
  'py-6',
  'ps-6',
  'pe-6',
  'p-7',
  'px-7',
  'py-7',
  'ps-7',
  'pe-7',
  'p-8',
  'px-8',
  'py-8',
  'ps-8',
  'pe-8',
  'p-9',
  'px-9',
  'py-9',
  'ps-9',
  'pe-9',
  'p-10',
  'px-10',
  'py-10',
  'ps-10',
  'pe-10',
  'p-11',
  'px-11',
  'py-11',
  'ps-11',
  'pe-11',
  'p-12',
  'px-12',
  'py-12',
  'ps-12',
  'pe-12',
  'p-14',
  'px-14',
  'py-14',
  'ps-14',
  'pe-14',
  'p-16',
  'px-16',
  'py-16',
  'ps-16',
  'pe-16',
  'p-20',
  'px-20',
  'py-20',
  'ps-20',
  'pe-20',
  'p-24',
  'px-24',
  'py-24',
  'ps-24',
  'pe-24',
  'p-28',
  'px-28',
  'py-28',
  'ps-28',
  'pe-28',
  'p-32',
  'px-32',
  'py-32',
  'ps-32',
  'pe-32',
  'p-36',
  'px-36',
  'py-36',
  'ps-36',
  'pe-36',
  'p-40',
  'px-40',
  'py-40',
  'ps-40',
  'pe-40',
  'p-44',
  'px-44',
  'py-44',
  'ps-44',
  'pe-44',
  'p-48',
  'px-48',
  'py-48',
  'ps-48',
  'pe-48',
  'p-52',
  'px-52',
  'py-52',
  'ps-52',
  'pe-52',
  'p-56',
  'px-56',
  'py-56',
  'p-60',
  'px-60',
  'py-60',
  'p-64',
  'px-64',
  'py-64',
  'p-72',
  'px-72',
  'py-72',
  'p-80',
  'px-80',
  'py-80',
  'p-96',
  'px-96',
  'py-96',
  'p-auto',
  'px-auto',
  'py-auto',
]
