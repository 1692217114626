/* eslint-disable no-undef */
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Container,
  HStack,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useUserStore from '../../../Hooks/Zustand/Store';
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase } from '../../../Api/firebaseApi';
import useMarketingStore from '../../../Hooks/Zustand/MarketingStore';
import { BsTrash } from 'react-icons/bs';
import { objective } from './FacebookDataSelection';
import axios from 'axios';

function MarketingFacebookPageV2() {
  const navigate = useNavigate();
  const globalState = useUserStore();
  const marketingState = useMarketingStore();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const modalEdit = useDisclosure();
  const openModalCreate = useDisclosure();
  const toast = useToast();
  const apiPayment = process.env.REACT_APP_PAYMENT_KEY;
  const apikey = process.env.REACT_APP_META_KEY;

  const handleDelete = async (ads) => {
    try {
      await deleteDocumentFirebase('marketing_meta', ads);
    } catch (error) {
      throw new Error(error)
    } finally {
      handleGetCampaigns();
    }
  };

  const handleGetCampaigns = async () => {
    const currentDate = new Date();

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
      { field: 'type', operator: '==', value: 'ads' },
      { field: 'category', operator: '==', value: 'facebook' },
    ];

    try {
      const res = await getCollectionFirebase('marketing_meta', conditions);

      // console.log(res)

      setCampaignData(res);
      // if (res && res.length > 0) {
      //   // for (const item of res) {
      //   //   if ('campaignId' in item) {
      //   //     const campaignId = item.campaignId;
      //   //     try {
      //   //       const data = {
      //   //         campaignId: campaignId,
      //   //       };

      //   //       const headers = {
      //   //         'Content-Type': 'application/json',
      //   //         Authorization: apiPayment,
      //   //       };

      //   //       const response = await axios.post(apikey, {campaignId: '120203408888120185'}, { headers });

      //   //       item.traffic = response?.data?.data;

      //   //       const durationEnd = new Date(item.budget?.durationEnd);
      //   //       if (durationEnd < currentDate) {
      //   //         item.status = 'inactive';
      //   //       }
      //   //     } catch (error) {
      //   //       throw new Error('Gagal saat mengambil data untuk campaignId:', campaignId, error)
      //   //     }
      //   //   } else {
      //   //     throw new Error('Tidak ada campaignId untuk item:', item)
      //   //   }
      //   // }
      //   // res?.map(async (item) => {
      //   //   if ('campaignId' in item) {
      //   //     const campaignId = item.campaignId;
      //   //     try {
      //   //       const data = {
      //   //         campaignId: campaignId,
      //   //       };

      //   //       const headers = {
      //   //         'Content-Type': 'application/json',
      //   //         Authorization: apiPayment,
      //   //       };

      //   //       const response = await axios.post(apikey, {campaignId: '120203408888120185'}, { headers });

      //   //       item.traffic = response?.data?.data;

      //   //       const durationEnd = new Date(item.budget?.durationEnd);
      //   //       if (durationEnd < currentDate) {
      //   //         item.status = 'inactive';
      //   //       }
      //   //     } catch (error) {
      //   //       throw new Error('Gagal saat mengambil data untuk campaignId:', campaignId, error)
      //   //     }
      //   //   } else {
      //   //     throw new Error('Tidak ada campaignId untuk item:', item)
      //   //   }
      //   // })
      //   throw new Error('Data setelah penambahan field traffic dan status:', res)

      // } else {
      //   throw new Error('Data tidak ditemukan atau res kosong')
      // }

    } catch (error) {
      throw new Error('Gagal saat mengambil data dari Firebase:', error)
    }
  };

  const handleSelectObjective = (value) => {
    marketingState?.setCampaign({ objective: value?.name });
  };

  const handleAddCampaign = async () => {
    setIsLoading(true);
    const data = {
      totalBudget: '',
      campaignName: marketingState?.campaign?.campaignName,
      adAccount: marketingState?.campaign?.adAccount,
      facebookLink: marketingState?.campaign?.facebookLink,
      instagramLink: marketingState?.campaign?.instagramLink,
      pixelId: marketingState?.campaign?.pixelID,
      promotionType: marketingState?.campaign?.promotionType || '',
      tags: marketingState?.campaign?.tags,
      adsCreation: marketingState?.adsCreation,
      budget: marketingState?.budgeting,
      targeting: marketingState?.targeting,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      uid: globalState?.uid,
      status: 'request',
      isDraft: 'draft',
      isPaid: false,
      type: 'ads',
      category: 'facebook',
    };

    try {
      const res = await addDocumentFirebase(
        'marketing_meta',
        data,
        globalState?.currentCompany
      );
      marketingState?.setId(res)
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
      navigate('create/campaign');
    }
  };

  const handleClickAds = (campaign) => {
    if (campaign.isDraft === 'draft') {
      if (campaign.isPaid === false) {
        navigate(`/marketing/meta/ads/create/campaign/edit/${campaign.id}`);
      } else {
        toast({
          status: 'warning',
          duration: 9000,
          title: 'Tidak bisa melakukan edit setelah pembayaran',
          isClosable: true,
        });
      }
    } else if (campaign.status === 'inactive') {
      navigate(`/marketing/meta/ads/create/publish/repaid/${campaign.id}`);
    } else {
      toast({
        status: 'warning',
        duration: 9000,
        title: 'Tidak bisa melakukan edit setelah pembayaran',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    handleGetCampaigns();

    return () => {
      setCampaignData([]);
    };
  }, [globalState?.currentCompany, globalState?.currentProject]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Meta Ads</Heading>
        <Spacer />
        <Button
          colorScheme='green'
          onClick={openModalCreate.onOpen}
          isLoading={isLoading}>
          + Campaign
        </Button>
      </HStack>
      <SimpleGrid
        columns={3}
        spacing={3}
        my={5}>
        {campaignData?.length > 0 ? (
          <>
            {campaignData?.map((campaign, i) => (
              <Box
                cursor={'pointer'}
                _hover={{ transform: 'scale(1.02)', transition: '0.2s' }}
                shadow='base'
                key={i}
                align={'center'}
                bg={'white'}
                p={3}
                borderRadius={'md'}
                onClick={() => handleClickAds(campaign)}>
                <HStack
                  justify={'center'}
                  mb={3}
                  // onClick={() => navigate(`/marketing/meta/ads/create/campaign/edit/${campaign?.id}`)}
                >
                  <Tag
                    textTransform={'capitalize'}
                    borderRadius={'sm'}
                    size={'sm'}
                    variant='solid'
                    colorScheme={campaign?.status === 'process' ? 'yellow' : campaign?.status === 'Draft' ? 'purple' : campaign?.status === 'Running' ? 'blue' : 'green'}>
                    {campaign?.status}
                  </Tag>
                  <Tag
                    borderRadius={'sm'}
                    size={'sm'}
                    variant='solid'
                    colorScheme={'orange'}>
                    {campaign?.adsCreation?.carousel?.length > 0 ? 'Carousel Ads' : 'Single Ads'}
                  </Tag>

                  <Tag
                    borderRadius={'sm'}
                    size={'sm'}
                    variant='solid'
                    colorScheme={campaign?.isDraft === 'draft' ? 'orange' : campaign?.isDraft === 'publish' ? 'purple' : campaign?.isDraft === 'republish' ? 'blue' : 'white'}>
                    {campaign?.isDraft}
                  </Tag>
                </HStack>
                {campaign?.adsCreation?.mediaLink[0]?.type === 'video' ? (
                  <AspectRatio
                    maxW='560px'
                    maxH='150px'
                    ratio={16 / 9}>
                    <iframe
                      width={200}
                      height={200}
                      title='naruto'
                      src={campaign?.adsCreation?.mediaLink[0]?.link}
                      allowFullScreen
                    />
                  </AspectRatio>
                ) : (
                  <AspectRatio
                    maxW='200px'
                    maxH='200px'
                    ratio={3 / 4}>
                    <Image
                      src={campaign?.adsCreation?.mediaLink[0]?.link}
                      alt={campaign?.adsCreation?.adName}
                    />
                  </AspectRatio>
                )}
                <Text
                  fontWeight={500}
                  textAlign='center'
                  my={3}>
                  {campaign?.campaignName}
                </Text>
                <SimpleGrid
                  fontSize={13}
                  spacing={3}
                  columns={3}>
                  <Container
                    border={'1px'}
                    borderColor={'gray.300'}
                    bgColor='white'
                    borderRadius='md'
                    textAlign='center'>
                    <Text fontWeight={500}>CTR</Text>
                    {/* <Text>{x.ctr}%</Text> */}
                    <Text>{campaign?.traffic?.ctr}%</Text>
                  </Container>
                  <Container
                    border={'1px'}
                    borderColor={'gray.300'}
                    bgColor='white'
                    borderRadius='md'
                    textAlign='center'>
                    <Text fontWeight={500}>CPM</Text>
                    {/* <Text>IDR{Rupiah(x.cpm)}</Text> */}
                    <Text>
                      Rp.
                      {campaign?.traffic?.cpm}
                    </Text>
                  </Container>
                  <Container
                    border={'1px'}
                    borderColor={'gray.300'}
                    bgColor='white'
                    borderRadius='md'
                    textAlign='center'>
                    <Text fontWeight={500}>CPR</Text>
                    {/* <Text>{x.cpr}</Text> */}
                    <Text>{campaign?.traffic?.cpr}</Text>
                  </Container>

                  <Container
                    border={'1px'}
                    borderColor={'gray.300'}
                    bgColor='white'
                    borderRadius='md'
                    textAlign='center'>
                    <Text fontWeight={500}>CPC</Text>
                    {/* <Text>{x.cpc}%</Text> */}
                    <Text>{campaign?.traffic?.cpc}</Text>
                  </Container>
                  <Container
                    border={'1px'}
                    borderColor={'gray.300'}
                    bgColor='white'
                    borderRadius='md'
                    textAlign='center'>
                    <Text fontWeight={500}>Reach</Text>
                    {/* <Text>{x.reach}</Text> */}
                    <Text>{campaign?.traffic?.reach}</Text>
                  </Container>
                  <Container
                    border={'1px'}
                    borderColor={'gray.300'}
                    bgColor='white'
                    borderRadius='md'
                    textAlign='center'>
                    <Text fontWeight={500}>Impression</Text>
                    {/* <Text>{x.impression}</Text> */}
                    <Text>{campaign?.traffic?.impressions}</Text>
                  </Container>
                  <BsTrash onClick={() => handleDelete(campaign?.id)} />
                </SimpleGrid>
              </Box>
            ))}
          </>
        ) : (
          <Center>
            <Text
              color={'gray.400'}
              fontWeight={500}>
              No Data
            </Text>
          </Center>
        )}
      </SimpleGrid>

      <Modal
        onClose={modalEdit.onClose}
        isOpen={modalEdit.isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Ads</ModalHeader>
          <ModalBody>This action will affect the ads, are you sure?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                colorScheme='red'
                size={'sm'}>
                No
              </Button>
              <Button
                colorScheme='green'
                size={'sm'}>
                Yes
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={openModalCreate.isOpen}
        onClose={openModalCreate.onClose}
        size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Ads</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text fontWeight={500}>Ojective</Text>
              <Stack
                h={300}
                overflowY={'auto'}
                p={3}
                sx={{
                  '&::-webkit-scrollbar': {
                    w: '2',
                    h: '3',
                  },
                  '&::-webkit-scrollbar-track': {
                    w: '6',
                    h: '5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '10',
                    bg: 'gray.200',
                  },
                }}>
                <SimpleGrid
                  columns={2}
                  spacing={2}>
                  {objective?.map((x, i) => (
                    <Stack
                      key={i}
                      align={'center'}
                      py={2}
                      onClick={() => handleSelectObjective(x)}
                      border={'1px'}
                      borderColor={marketingState?.campaign?.objective === x?.name ? 'blue.400' : 'gray.100'}
                      borderWidth={marketingState?.campaign?.objective === x?.name ? '2px' : '1px'}
                      borderRadius={'md'}
                      p={2}
                      cursor={'pointer'}>
                      <Icon
                        color={'gray.600'}
                        boxSize={7}
                        as={x?.icon}
                      />
                      <Text
                        fontSize={'sm'}
                        fontWeight={'semibold'}>
                        {x?.name}
                      </Text>
                      <Text
                        align={'center'}
                        fontSize={'xs'}>
                        {x?.description}
                      </Text>
                    </Stack>
                  ))}
                </SimpleGrid>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                colorScheme='red'
                onClick={openModalCreate.onClose}>
                Cancel
              </Button>
              <Button
                colorScheme='green'
                onClick={() => handleAddCampaign()}>
                Create
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MarketingFacebookPageV2;
