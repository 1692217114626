export const aboutGrapesJs = () => {
  const casperAbout1 =
  `<section class="relative font-body pt-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="hidden md:block absolute top-0 right-0 h-full w-3/12 lg:w-full lg:max-w-sm xl:max-w-md bg-violet-800">
          <img class="absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2 z-10 lg:w-72 xl:w-auto" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/image-circle.png" alt="" />
          <img class="absolute top-0 right-0 -mt-6 z-10 lg:w-80 xl:w-auto" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/planet.png" alt="" />
        </div>
        <div class="hidden md:block absolute top-1/2 right-0 w-186 h-186 mr-20 -mt-20 transform -translate-y-1/2 bg-gradient-to-t from-blue-500 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        <img class="hidden md:block absolute top-0 right-0 mt-12 -mr-80 lg:-mr-40 xl:-mr-0 h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/double-lines.svg" alt="" />
        <div class="relative container mx-auto px-4 mb-64 md:mb-0 z-10">
          <div class="max-w-28rem xl:max-w-2xl mx-auto md:mx-0">
            <div class="mb-16">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-semibold text-white tracking-tight mb-4">About Us</h2>
              <p class="max-w-32rem text-xl text-gray-500 leading-relaxed tracking-tight mb-6">Casper provide instant support, and boost conversions like never before. Join thousands of satisfied customers who have already elevated their online presence with our AI chat assistant.</p>
              <a class="inline-flex items-center text-sm font-semibold text-violet-500 hover:text-white uppercase" href="#">
                <span class="mr-2">GET TO KNOW US</span>
                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z" fill="currentColor"></path>
                </svg>
              </a>
            </div>
            <div class="max-w-32rem">
              <div class="flex flex-wrap -mx-4 -mb-8">
                <div class="w-full sm:w-1/2 px-4 mb-8">
                  <div class="relative p-6 rounded-3xl overflow-hidden">
                    <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                    <div class="relative">
                      <span class="block text-4xl xl:text-5xl font-semibold text-white">2,000+</span>
                      <span class="block text-xl tracking-tight text-gray-500">It is long established</span>
                    </div>
                  </div>
                </div>
                <div class="w-full sm:w-1/2 px-4 mb-8">
                  <div class="relative p-6 rounded-3xl overflow-hidden">
                    <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                    <div class="relative">
                      <span class="block text-4xl xl:text-5xl font-semibold text-white">2,000+</span>
                      <span class="block text-xl tracking-tight text-gray-500">It is long established</span>
                    </div>
                  </div>
                </div>
                <div class="w-full sm:w-1/2 px-4 mb-8">
                  <div class="relative p-6 rounded-3xl overflow-hidden">
                    <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                    <div class="relative">
                      <span class="block text-4xl xl:text-5xl font-semibold text-white">2,000+</span>
                      <span class="block text-xl tracking-tight text-gray-500">It is long established</span>
                    </div>
                  </div>
                </div>
                <div class="w-full sm:w-1/2 px-4 mb-8">
                  <div class="relative p-6 rounded-3xl overflow-hidden">
                    <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                    <div class="relative">
                      <span class="block text-4xl xl:text-5xl font-semibold text-white">2,000+</span>
                      <span class="block text-xl tracking-tight text-gray-500">It is long established</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="md:hidden relative h-112 bg-violet-800">
            <img class="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10 w-64" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/image-circle.png" alt="" />
            <img class="absolute top-0 right-0 mt-24 z-10 w-64" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/planet.png" alt="" />
            <div class="absolute top-0 right-0 w-128 h-128 mr-20 -mt-40 bg-gradient-to-t from-blue-500 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
            <img class="absolute top-0 right-0 h-128 -mt-20 object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/double-lines.svg" alt="" />
          </div>
        </div>
      </section>`
                
  const casperAbout2 =
      `<section class="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <img class="absolute top-1/2 left-0 transform -translate-y-1/2 w-1/3" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/planet-lg-half.png" alt="">
        <img class="absolute top-0 right-0 h-full z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/double-lines-lg-right.svg" alt="">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 -mt-128 mr-40 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative max-w-4xl mx-auto text-center">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-4">About Us</h2>
            <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-4">Our Chat AI platform is powered by the latest advancements in natural language processing (NLP) and machine learning, making it capable of engaging in natural and human-like conversations. Say goodbye to clunky interactions and hello to seamless communication that feels just like chatting with a real person.</p>
            <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-6">Whether you need a chatbot for customer support, sales, lead generation, or any other use case, our platform is versatile enough to cater to your specific requirements. Our APIs and integration capabilities allow for easy deployment across websites, applications, and messaging platforms, providing a consistent experience for your users.</p>
            <a class="inline-flex items-center text-sm font-medium text-violet-500 hover:text-white uppercase" href="#">
              <span class="mr-2">GET TO KNOW US</span>
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z" fill="currentColor"></path>
              </svg>
            </a>
          </div>
        </div>
      </section>`
                
  const casperAbout3 =
      `<section class="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 right-0 -mt-52 mr-64 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        
        <img class="hidden md:block absolute top-1/2 right-0 transform -translate-y-1/2 z-10 h-full w-1/5 sm:w-2/5 object-contain" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/robot-lg.png" alt="" />
        <div class="relative container mx-auto px-4">
          <div class="relative max-w-32rem lg:max-w-2xl mx-auto md:mx-0">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-4">About Us</h2>
            <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-4">Our Chat AI platform is powered by the latest advancements in natural language processing (NLP) and machine learning, making it capable of engaging in natural and human-like conversations. Say goodbye to clunky interactions and hello to seamless communication that feels just like chatting with a real person.</p>
            <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-6">Whether you need a chatbot for customer support, sales, lead generation, or any other use case, our platform is versatile enough to cater to your specific requirements. Our APIs and integration capabilities allow for easy deployment across websites, applications, and messaging platforms, providing a consistent experience for your users.</p>
            <a class="inline-flex items-center text-sm font-medium text-violet-500 hover:text-white uppercase" href="#">
              <span class="mr-2">GET TO KNOW US</span>
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z" fill="currentColor"></path>
              </svg>
            </a>
            <div class="mt-14">
              <div class="relative flex flex-col sm:flex-row">
                <div class="pb-8 sm:pb-0 sm:pr-8">
                  <span class="block text-5xl lg:text-7xl text-white font-medium">300+</span>
                  <span class="block text-gray-500 tracking-tight">PRODUCTS</span>
                </div>
                <div class="relative py-8 sm:py-0 sm:px-8">
                  <span class="block text-5xl lg:text-7xl text-white font-medium">100+</span>
                  <span class="block text-gray-500 tracking-tight">WEB TEMPLATE</span>
                  <div class="hidden sm:block absolute top-0 left-0 h-full w-px bg-white bg-opacity-20"></div>
                  <div class="hidden sm:block absolute top-0 right-0 h-full w-px bg-white bg-opacity-20"></div>
                  <div class="sm:hidden absolute top-0 left-0 w-full h-px bg-white bg-opacity-20"></div>
                  <div class="sm:hidden absolute bottom-0 left-0 w-full h-px bg-white bg-opacity-20"></div>
                </div>
                <div class="pt-8 sm:pt-0 sm:pl-8">
                  <span class="block text-5xl lg:text-7xl text-white font-medium">215</span>
                  <span class="block text-gray-500 tracking-tight">MOBILE TEMPLATE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperAbout4 =
      `<section class="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <img class="absolute top-0 right-0 -mr-64 lg:-mr-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/double-lines.svg" alt="" />
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 -mt-72 mr-32 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative flex flex-wrap -mx-4">
            <div class="w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
              <div class="max-w-28rem">
                <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-4">About Us Casper</h2>
                <a class="inline-flex mb-20 lg:mb-32 items-center text-sm font-medium text-violet-500 hover:text-white uppercase" href="#">
                  <span class="mr-2">GET TO KNOW US</span>
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z" fill="currentColor"></path>
                  </svg>
                </a>
                <div>
                  <div class="mb-16">
                    <span class="block text-5xl lg:text-7xl text-white font-medium">300+</span>
                    <span class="block text-gray-500 tracking-tight">PRODUCTS</span>
                  </div>
                  <div>
                    <span class="block text-5xl lg:text-7xl text-white font-medium">220+</span>
                    <span class="block text-gray-500 tracking-tight">MOBILE TEMPLATE</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="max-w-32rem">
                <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-4">Our Chat AI platform is powered by the latest advancements in natural language processing (NLP) and machine learning, making it capable of engaging in natural and human-like conversations. Say goodbye to clunky interactions and hello to seamless communication that feels just like chatting with a real person.</p>
                <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-10">Whether you need a chatbot for customer support, sales, lead generation, or any other use case, our platform is versatile enough to cater to your specific requirements. Our APIs and integration capabilities allow for easy deployment across websites, applications, and messaging platforms, providing a consistent experience for your users.</p>
                <img src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/image-fabric.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperAbout5 =
      `<section class="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <img class="absolute top-0 right-0 -mr-64 lg:-mr-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/doublie-line-top-right.svg" alt="" />
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 -mt-80 mr-40 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative">
            <div class="max-w-28rem mb-6">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-4">Casper is AI Platform</h2>
              <a class="inline-flex items-center text-sm font-medium text-violet-500 hover:text-white uppercase" href="#">
                <span class="mr-2">GET TO KNOW US</span>
                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z" fill="currentColor"></path>
                </svg>
              </a>
            </div>
            <div class="max-w-3xl ml-auto mb-16">
              <p class="text-lg text-gray-400 leading-relaxed tracking-tight mb-4">Our Chat AI platform is powered by the latest advancements in natural language processing (NLP) and machine learning, making it capable of engaging in natural and human-like conversations. Say goodbye to clunky interactions and hello to seamless communication that feels just like chatting with a real person.</p>
              <p class="text-lg text-gray-400 leading-relaxed tracking-tight">Whether you need a chatbot for customer support, sales, lead generation, or any other use case, our platform is versatile enough to cater to your specific requirements. Our APIs and integration capabilities allow for easy deployment across websites, applications, and messaging platforms, providing a consistent experience for your users.</p>
            </div>
            <div class="flex flex-wrap -mx-4">
              <div class="w-full md:w-1/2 px-4 mb-8 lg:mb-0">
                <img class="block w-full rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/image-photo-2.png" alt="" />
              </div>
              <div class="w-full md:w-1/2 px-4">
                <img class="block w-full rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/about/image-photo-1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>`

  const arr = [
    { label: 'casperAbout1',content: casperAbout1,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/about1.png' },
    { label: 'casperAbout2',content: casperAbout2,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/about2.png' },
    { label: 'casperAbout3',content: casperAbout3,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/about3.png' },
    { label: 'casperAbout4',content: casperAbout4,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/about4.png' },
    { label: 'casperAbout5',content: casperAbout5,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/about5.png' },
  ]
  return arr
}
