import React from 'react';
import MarketingMetaIntegration from '../Pages/Marketing/MarketingMetaIntegration';
import MarketingPage from '../Pages/Marketing/MarketingPage';
import MarketingDomainPage from '../Pages/Marketing/landingPage/MarketingDomainPage';
import MarketinngMetaAdsPage from '../Pages/Marketing/facebook/MarketinngMetaAdsPage';
import MarketingMetaIntegrationView from '../Pages/Marketing/MarketingMetaIntegrationView';
import MarketingFunnelsPage from '../Pages/Marketing/landingPage/MarketingFunnelsPage';
import MarketingFunnelsViewPage from '../Pages/Marketing/landingPage/MarketingFunnelsViewPage';
import MarketingFunnelPage from '../Pages/Marketing/landingPage/MarketingFunnelPage';
import MarketingFacebookPageV2 from '../Pages/Marketing/facebook/MarketingFacebookPageV2';
import MarketingMetaAddCampaignPage from '../Pages/Marketing/facebook/MarketingMetaAddCampaignPage';
import MarketingMetaAddAdPage from '../Pages/Marketing/facebook/MarketingMetaAddAdPage';
import MarketingMetaAddAudiencePage from '../Pages/Marketing/facebook/MarketingMetaAddAudiencePage';
import MarketingMetaAddBudgetPage from '../Pages/Marketing/facebook/MarketingMetaAddBudgetPage';
import MarketingMetaAddPublishPage from '../Pages/Marketing/facebook/MarketingMetaAddPublishPage';
import PaymentHistory from '../Pages/Marketing/facebook/PaymentHistory';

const MarketingRouter = [
  {
    path: '/marketing',
    element: <MarketingPage />,
  },
  {
    path: '/meta',
    element: <MarketinngMetaAdsPage />,
  },
  {
    path: '/funnels',
    element: <MarketingFunnelPage />,
  },
  {
    path: '/marketing/funnels',
    element: <MarketingFunnelsPage />,
  },
  {
    path: '/marketing/funnels/:id',
    element: <MarketingFunnelsViewPage />,
  },
  {
    path: '/marketing/funnels/domains',
    element: <MarketingDomainPage />,
  },
  {
    path: '/marketing/meta/integration',
    element: <MarketingMetaIntegration />,
  },
  {
    path: '/marketing/meta/payment-history',
    element: <PaymentHistory />,
  },
  {
    path: '/marketing/meta/integration/:id',
    element: <MarketingMetaIntegrationView />,
  },
  {
    path: '/marketing/meta/ads',
    element: <MarketingFacebookPageV2 />,
  },
  {
    path: '/marketing/meta/ads/create/campaign',
    element: <MarketingMetaAddCampaignPage />,
  },
  {
    path: '/marketing/meta/ads/create/campaign/:type/:id',
    element: <MarketingMetaAddCampaignPage />,
  },
  {
    path: '/marketing/meta/ads/create/adset',
    element: <MarketingMetaAddAdPage />,
  },
  {
    path: '/marketing/meta/ads/create/adset/:type/:id',
    element: <MarketingMetaAddAdPage />,
  },
  {
    path: '/marketing/meta/ads/create/audience',
    element: <MarketingMetaAddAudiencePage />,
  },
  {
    path: '/marketing/meta/ads/create/audience/:type/:id',
    element: <MarketingMetaAddAudiencePage />,
  },
  {
    path: '/marketing/meta/ads/create/budget',
    element: <MarketingMetaAddBudgetPage />,
  },
  {
    path: '/marketing/meta/ads/create/budget/:type/:id',
    element: <MarketingMetaAddBudgetPage />,
  },
  {
    path: '/marketing/meta/ads/create/publish',
    element: <MarketingMetaAddPublishPage />,
  },
  {
    path: '/marketing/meta/ads/create/publish/:type/:id',
    element: <MarketingMetaAddPublishPage />,
  },
];

export default MarketingRouter;
