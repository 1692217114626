import React from 'react';
import { SettingAccountPage } from '../Pages/Settings/SettingAccountPage';
import SettingPasswordPage from '../Pages/Settings/SettingPasswordPage';
// import NotificationPage from '../Pages/Notification/NotificationPage';

const SettingRouter = [
  {
    path: '/change-password',
    element: <SettingPasswordPage />,
  },
  // {
  //   path: '/notification',
  //   element: <NotificationPage />,
  // },
  {
    path: '/settings',
    element: <SettingAccountPage />,
  },
];

export default SettingRouter;
