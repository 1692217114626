/* eslint-disable no-undef */
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../../../Api/firebaseApi';
import { commandNameCustomCode, keyCustomCode } from './utils';
import { clientTypessense } from '../../../../Api/Typesense';
import { warehouseDataArray, productVariantArray, formList } from './block';
const currentCompany = localStorage.getItem('currentCompany')
const currentProject = localStorage.getItem('currentProject')

function findObjectsById(arrayOfArrays, id) {
  const foundObjects = [];
  for (let i = 0; i < arrayOfArrays.length; i++) {
    const innerArray = arrayOfArrays[i];
    for (let j = 0; j < innerArray.length; j++) {
      const obj = innerArray[j];
      if (obj.productId === id) {
        foundObjects.push(obj);
      }
    }
  }
  // Return the array of found objects
  return foundObjects;
}

export default (editor, opts = {}) => {
  const { modalTitle, codeViewOptions, commandCustomCode } = opts;

  const appendToContent = (target, content) => {
    
    if (content) {
      target.appendChild(content);
      target.insertAdjacentHTML('beforeend', content);
    }
  }

  // Add the custom code command
  editor.Commands.add(commandNameCustomCode, {
    keyCustomCode,
    target: null,
    codeViewer: null,
    // formList: [],
    productData: null,
    warehouseData: null,
    // warehouseDataArray: null,
    productVariant: [],
    // productVariantArray: [],

    run(editor, s, opts = {}) {
      const target = opts.target || editor.getSelected();
      this.target = target;

      if (target?.get('editable')) {
        this.showCustomCode(target, opts);
      }
    },

    stop(editor) {
      editor.Modal.close();
    },

    /**
     * Method which tells how to show the custom code
     */
    showCustomCode(target, options) {
      const title = options.title || modalTitle;
      const code = target.get(keyCustomCode) || '';
      const content = this.getContent();
      editor.Modal
        .open({ title, content })
        .onceClose(() => editor.stopCommand(commandNameCustomCode))
      this.getCodeViewer().setContent(code);
    },

    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {},

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {},

    /**
     * Get all the content for the custom code
     */
    getContent() {
      const codeViewer = this.getCodeViewer();
      const content = document.createElement('div');
      const pfx = editor.getConfig('stylePrefix');
      content.className = `${pfx}custom-code`;
      appendToContent(content, this.getPreContent());
      // content.appendChild(codeViewer.getElement());
      content.appendChild(this.getListProduct())
      appendToContent(content, this.getPostContent());
      // appendToContent(content, this.getContentActions());
      codeViewer.refresh();
      setTimeout(() => codeViewer.focus(), 0);

      return content;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     */
    getContentActions() {
      const btn = document.createElement('button');
      btn.setAttribute('type', 'button');
      const pfx = editor.getConfig('stylePrefix');
      btn.innerHTML = opts.buttonLabel;
      btn.className = `${pfx}btn-prim ${pfx}btn-import__custom-code`;
      btn.onclick = () => this.handleSave();

      return btn;
    },

    /**
     * Handle the main save task
     */
    handleSave() {
      const { target } = this;
      const code = this.getCodeViewer().getContent();
      target?.set(keyCustomCode, code);
      editor.Modal.close();
    },

    /**
     * Return the code viewer instance
     */
    getCodeViewer() {
      if (!this.codeViewer) {
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: 'htmlmixed',
          theme: 'hopscotch',
          readOnly: 0,
          ...codeViewOptions,
        });
      }
      return this.codeViewer;
    },

    getListProduct() {
      // Create a div element to hold the list
      const mainContainer = document.createElement('div');
      mainContainer.classList.add('gjs-mdl-content');

      const innerContainer = document.createElement('div');
      innerContainer.setAttribute('id', 'gjs-mdl-c');

      const customCodeContainer = document.createElement('div');
      customCodeContainer.classList.add('gjs-custom-code');

      // Create search bar input
      const searchBarContainer = document.createElement('div');
      searchBarContainer.classList.add('flex', 'gap-2', 'mb-3', 'text-white');

      const searchBarInput = document.createElement('input');
      searchBarInput.setAttribute('id', 'search-bar');
      searchBarInput.setAttribute('placeholder', 'Search Product');
      searchBarInput.classList.add('px-2', 'py-1', 'bg-zinc-500', 'rounded', 'w-full');

      const searchButton = document.createElement('div');
      searchButton.classList.add('px-2', 'py-1', 'bg-zinc-600', 'rounded');
      searchButton.textContent = 'Search';

      searchBarContainer.appendChild(searchBarInput);
      searchBarContainer.appendChild(searchButton);

      customCodeContainer.appendChild(searchBarContainer);
      customCodeContainer.appendChild(document.createElement('hr'));

      // Create the product list container
      const listContainer = document.createElement('div');
      listContainer.setAttribute('id', 'product-list');
      listContainer.classList.add('flex', 'flex-wrap', 'gap-3', 'my-4', 'justify-start', 'items-start', 'text-white');

      // Iterate over the dataList array using map
      if (formList) {
        formList.map((item) => {
          const listItem = document.createElement('div');
          listItem.classList.add('border', 'p-3', 'rounded-md', 'duration-100', 'ease-out', 'hover:scale-105', 'hover:ease-in', 'pointer');

          listItem.textContent = item.name;

          listItem.addEventListener('click', () => {
            this.productData = item;
            this.handleClickList(); // Display the corresponding list data in the console
          });
    
          // Append the list item to the list container
          listContainer.appendChild(listItem);
        });
      }

      customCodeContainer.appendChild(listContainer);
      innerContainer.appendChild(customCodeContainer);
      mainContainer.appendChild(innerContainer);

      // document.body.appendChild(mainContainer);

      const script = document.createElement('script');

      if(formList.length > 0){
        const formListJSON = JSON.stringify(formList);
        // Now, add the script tag
        script.textContent = `
          const container = document.getElementById('product-list')

          const htmlElements =  ${formListJSON}.map((product) => {
            return
            '<div class="border p-3 rounded-md duration-100 ease-out hover:scale-105 hover:ease-in pointer">' + product.name + '</div>'
          })

          // Function to filter and display nearest name
          function searchAndUpdate(input) {
            // Filter the arrayList based on the input
            const filteredProducts = ${formListJSON}.filter((item) => {
              return item.name.toLowerCase().includes(input.toLowerCase());
            });

            // Clear previous results
            container.innerHTML = '';

            // Display the nearest name for each filtered product
            filteredProducts.forEach(product => {
              const nearestName = product.name;
              container.innerHTML += "<div class='border p-3 rounded-md duration-100 ease-out hover:scale-105 hover:ease-in pointer'>" + nearestName + "</div>";;
            });
          }

          document.getElementById('search-bar').addEventListener('input', e => {
            const input = e.target.value.trim();
            if (input !== '') {
              searchAndUpdate(input);
            } else {
              // If input is empty, clear the product list
              container.innerHTML = htmlElements.join('')
            }
          });
        `;
      }

      // Append the script to the document body
      mainContainer.appendChild(script);
      
      console.log(mainContainer)

      // Return the main container
      return mainContainer;
    },

    handleClickList() {
      const { target } = this;
      let addressInput = '';
      let shipmentCode = '';
      let variantsCode = '';
      let priceCode = '';

      const imageReplace = this.productData.images[0].replace(/\s/g, '%20')

      const variantData = findObjectsById(productVariantArray, this.productData.id)

      if(this.productData.warehouseId !== ''){
        const findWarehouse = warehouseDataArray.find((warehouse) => warehouse?.id === this.productData.warehouseId )
        this.warehouseData = findWarehouse
      }


      //for variant
      if (variantData?.length > 0) {
        console.log(this.productData.discount_amount);

        variantsCode = `
    <div>Pilih Variant</div>
    <div id="variant-list" class="flex flex-wrap justify-center gap-3 my-3">
      ${variantData.map((variant) => {
    // Set discount data based on conditions
    let discountData = this.productData.discount_amount;
    let discountTypeData = this.productData.discount_type;

    if (this.productData.discount_amount === '' || this.productData.discount_type === '') {
      discountData = variant.discount_amount;
      discountTypeData = variant.discount_type;
    }

    console.log(discountData, discountTypeData)

    return `
          <div class="flex items-center rounded border border-gray-200 px-4 dark:border-gray-700">
            <input id="${variant.name}" type="radio" name="variant" data-weight="${variant.weight}" data-height="${variant.height}" data-width="${variant.width}" data-price="${variant.price}" data-discount="${discountData}" data-discount-type="${discountTypeData}" value="${variant.id}" class="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600" />
            <label for="${variant.name}" class="ms-2 w-full py-4 text-sm font-medium text-gray-900 dark:text-gray-300">${variant.name}</label>
          </div>
        `;
  }).join('')}
    </div>
    `;
      }

      // const code = this.getCodeViewer().getContent();

      //for address
      if(this.productData.type === 'physical'){
        addressInput = `
        <div class="mt-3">
          <h2 class="text mb-2 text-center font-semibold">Cari nama kota anda disini</h2>
          <div class="flex gap-2">
            <input id="cityInput" type="text" name="address" class="w-full rounded border px-3 py-1" placeholder="Masukkan nama kota" />
            <button id="search-btn" class="hover: rounded bg-green-500 p-2 text-sm font-semibold text-white hover:bg-green-400">Cari</button>
            <div id="spinner" class="hidden">
                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
            </div>
          </div>
          <div class="my-3">
            <select id="kecamatan" class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-200 dark:focus:border-blue-500 dark:focus:ring-blue-500">
              <option selected>Select District</option>
            </select>
            <p id="" class="mt-2 text-center text-sm text-gray-700">Ketik keyword dan tekan cari untuk mencari kelurahan</p>
            <input class="my-3 w-full rounded border px-3 py-1" type="text" name="jalan" placeholder="Jalan" />
            <input class="w-full rounded border px-3 py-1" type="text" name="kota" placeholder="Kota" />
            <input class="my-3 w-full rounded border px-3 py-1" type="text" name="provinsi" placeholder="Provinsi" />
            <input class="w-full rounded border px-3 py-1" type="text" name="postalCode" placeholder="Kode Pos" />
            <textarea rows="4" name="alamat" id="alamat" placeholder="Alamat lengkap" class="my-3 block w-full rounded-md border-0 px-3 py-1 text-gray-900 shadow-sm ring-1 ring-inset sm:leading-6"></textarea>
          </div>
        </div>`
      }

      //forPrice
      if(variantData?.length > 0){
        priceCode = `
        <div class="flex justify-between">
              <p>Price
              </p>
              <p id="price-display">0
              </p>
            </div>
        `
      } else {
        priceCode = `
        <div id="totals" class="flex justify-between font-semibold" data-price="${this.productData.price}" data-discount="${this.productData.discount_amount}" data-discount-type="${this.productData.discount_type}">
              <p>Price
              </p>
              <p id="price-display">${this.productData.price}
              </p>
            </div>
        `
      }

      //for shipment
      if(this.productData.type === 'physical'){
        const arr = ['oExpress', 'jne', 'rajaOngkir']

        const serviceArr = []

        arr.forEach((x) => {
          if (x in this.warehouseData && this.warehouseData[x] !== '') {
            serviceArr.push(x);
          }
        });

        

        shipmentCode = `
        <div class="my-3">
          <p class="mb-2 text-center font-semibold">Metode Pengiriman</p>
          <select id="shipment-services" name="service" data-warehouse-oexpress="${this.warehouseData.oExpress}" data-warehouse-jne="${this.warehouseData.jne}" data-warehouse-rajaOngkir="${this.warehouseData.rajaOngkir}" class="block mb-3 w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-200 dark:focus:border-blue-500 dark:focus:ring-blue-500">
              <option selected="">Available Service</option>
              ${serviceArr.map((x) => `
              <option class="uppercase" name="service-list" value="${x}">${x}</option>
              `).join('')}
            </select>
        </div>

        <div id="service-package">
                
        </div>
        `
      }

      const code = `
      <div class="mx-auto max-w-4xl p-10">
        <form id="checkout-form" action="https://marketing.deoapp.com/form-payment" method="get">
          <div class="my-10 flex flex-col md:flex-row gap-5">
            <div class="md:w-1/2 w-full px-[5%]">
              <div class="mb-5 p-10 bg-white">
                <h2 class="text mb-2 text-center font-semibold">Data Penerima</h2>
                  <input class="rounded border px-3 py-1 w-full" type="text" name="name" placeholder="Nama Anda" />
                  <input class="rounded border px-3 py-1 w-full my-3" type="number" name="phone" placeholder="Nomor Whatsapp" />
                  <input class="rounded border px-3 py-1 w-full" type="email" name="email" placeholder="Email Anda" />
                  <input class="rounded border px-3 py-1 w-full mt-3" type="text" name="company" placeholder="Company Anda" />
                  <input class="hidden" type="text" name="productId" value="${this.productData.id}"/>
                  <input class="hidden" type="text" name="productType" value="${this.productData.type}"/>
                  <input class="hidden" type="text" name="companyId" value="${currentCompany}"/>
                  <input class="hidden" type="text" name="projectId" value="${currentProject}"/>

                  ${addressInput}

                  ${shipmentCode}

                  <h2 class="mb-2 text-center font-semibold">Metode Pembayaran</h2>
                  <div class="grid grid-flow-col grid-rows-4 gap-4 border p-4 align-middle">
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="qr" name="bank" value="qr" onChange="{}" />
                      <label for="qr"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://seeklogo.com/images/Q/quick-response-code-indonesia-standard-qris-logo-F300D5EB32-seeklogo.com.png" /> 
                        </div>
                      </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="gopay" name="bank" value="gopay" />
                      <label for="gopay"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://antinomi.org/wp-content/uploads/2022/03/logo-gopay-vector.png" /> 
                        </div>
                        </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="bni" name="bank" value="bni" />
                      <label for="bni"> 
                        <div class="border m-1 p-3"> 
                          <image class="w-[4rem]" src="https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png" />
                        </div> 
                      </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="bca" name="bank" value="bca" />
                      <label for="bca"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png" /> 
                        </div> 
                      </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="mandiri" name="bank" value="mandiri" />
                      <label for="mandiri"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png" />
                        </div>
                      </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="permata" name="bank" value="permata" />
                      <label for="permata"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png" /> 
                        </div>
                      </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="bri" name="bank" value="bri" />
                      <label for="bri"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png" />
                        </div>
                      </label>
                    </div>
                    <div class="flex gap-2 align-middle">
                      <input type="radio" id="cimb" name="bank" value="cimb" />
                      <label for="cimb"> 
                        <div class="border m-1 p-3">
                          <image class="w-[4rem]" src="https://career.amikom.ac.id/images/company/cover/1637497490.jpeg" /> 
                        </div>
                      </label>
                    </div>
                  </div>
                  
              </div>
            </div>
            <div class="md:w-1/2 w-full border bg-white p-3 text-center">
              <img class="mx-auto max-w-80" src="${imageReplace}" />
              <h2 class="m-2 text-center font-semibold">${this.productData.name}</h2>
              <hr class="m-2" />
              <div>Product Description</div>
              <hr class="m-2" />

              
              ${variantsCode}

              <div class="flex justify-center gap-2">
                <label class="font-semibold"> Quantity </label>
                <input id="qty-input" class="w-10 border" name="quantity" placeholder="1" value="1" />
              </div>
              
              <div class="container max-w-4xl bg-white p-5">
            
            ${priceCode}
            <div id="shipping-cost-container" class="hidden my-2 flex justify-between">
              <p>Shipping</p>
              <p id="shipping-cost-display">0</p>
            </div>
            <div class="my-2 flex justify-between">
              <p>Discount
              </p>
              <p id="discount-display">0
              </p>
            </div>
            <div class="flex justify-between font-semibold">
              <p>Total
              </p>
              <p id="total-display">0
              </p>
            </div>
            
          </div>
          <input class="hidden" type="text" value="0" name="totalPrice"></input>
          <input class="hidden" type="text" value="0" name="originId"></input>
          <input class="hidden" type="text" value="0" name="destinationId"></input>
          <input class="hidden" type="text" value="0" name="serviceName"></input>
          <input class="hidden" type="text" value="0" name="servicePackage"></input>
          
          <input type="submit" value="Buat Pesanan" class="mt-4 bg-black text-white p-2 w-full hover:bg-slate-900" />
            </div>
          </div>
        </form>
      </div>
        `;
      target?.set(keyCustomCode, code);
      editor.Modal.close();
    },

    ...commandCustomCode,
  });
};