import React from 'react';
import UsersPage from '../Pages/Configuration/UsersPage';
import ProjectPage from '../Pages/Configuration/ProjectPage';
import ProjectNewPage from '../Pages/Configuration/ProjectNewPage';
import ConfigurationPage from '../Pages/Configuration/ConfigurationPage';

const ConfigurationRouter = [
  {
    path: '/team',
    element: <ConfigurationPage />,
  },
  {
    path: '/team/project',
    element: <ProjectPage />,
  },
  {
    path: '/team/project/:id',
    element: <ProjectNewPage />,
  },
  {
    path: '/team/users',
    element: <UsersPage />,
  },
];

export default ConfigurationRouter;
