export const showcaseGrapesJs = () => {
  const casperShowcase1 =
  `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <img class="hidden md:block absolute top-0 left-0 -mt-10 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
          <div class="absolute top-0 left-0 mt-96 -ml-52 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="absolute top-0 right-0 w-186 h-186 mt-10 -mr-64 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative text-center mb-16">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-8">Projects Launched</h2>
            <p class="max-w-[36rem] mx-auto text-xl text-gray-500 leading-relaxed tracking-tight mb-12">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
            <div class="inline-block max-w-max mx-auto p-2 md:p-1.5 bg-white bg-opacity-10 rounded-3xl md:rounded-full">
              <div class="flex flex-col md:flex-row flex-nowrap items-center"><a class="group inline-flex w-full sm:w-auto py-4 px-5 mb-2 md:mb-0 md:mr-2 items-center justify-center text-base font-medium text-white hover:text-black hover:bg-violet-500 transition duration-150 rounded-full" href="#">All Categories</a><a class="group inline-flex w-full sm:w-auto py-4 px-5 mb-2 md:mb-0 md:mr-2 items-center justify-center text-base font-medium text-white hover:text-black hover:bg-violet-500 transition duration-150 rounded-full" href="#">Branding</a><a class="group inline-flex w-full sm:w-auto py-4 px-5 mb-2 md:mb-0 md:mr-2 items-center justify-center text-base font-medium text-white hover:text-black hover:bg-violet-500 transition duration-150 rounded-full" href="#">UI Design</a><a class="group inline-flex w-full sm:w-auto py-4 px-5 mb-2 md:mb-0 md:mr-2 items-center justify-center text-base font-medium text-white hover:text-black hover:bg-violet-500 transition duration-150 rounded-full" href="#">Illustration</a><a class="group inline-flex w-full sm:w-auto py-4 px-5 items-center justify-center text-base font-medium text-white hover:text-black hover:bg-violet-500 transition duration-150 rounded-full" href="#">Development</a></div>
            </div>
          </div>
          <div class="relative flex flex-wrap -mx-4">
            <div class="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
              <div class="relative flex items-end h-128 p-6 mb-8 rounded-3xl overflow-hidden">
                <img class="absolute top-0 left-0 w-full h-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/projects-box-vertical-1.png" alt="">
                <a class="relative group block w-full p-6 rounded-3xl overflow-hidden" href="#">
                  <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/bg-gradient-square.png" alt="">
                  <div class="relative">
                    <div class="flex mb-20 items-center justify-between">
                      <img src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon.svg" alt="">
                      <span class="text-black group-hover:text-white transform group-hover:translate-x-1 group-hover:-translate-y-1 transition duration-100">
                        <svg width="33" height="32" viewbox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M24.3934 8.82667C24.2581 8.50087 23.9992 8.24197 23.6734 8.10667C23.5131 8.03835 23.3409 8.00211 23.1667 8H9.83337C9.47975 8 9.14061 8.14048 8.89057 8.39052C8.64052 8.64057 8.50004 8.97971 8.50004 9.33333C8.50004 9.68696 8.64052 10.0261 8.89057 10.2761C9.14061 10.5262 9.47975 10.6667 9.83337 10.6667H19.9534L8.88671 21.72C8.76174 21.844 8.66254 21.9914 8.59485 22.1539C8.52716 22.3164 8.49231 22.4907 8.49231 22.6667C8.49231 22.8427 8.52716 23.017 8.59485 23.1794C8.66254 23.3419 8.76174 23.4894 8.88671 23.6133C9.01066 23.7383 9.15813 23.8375 9.3206 23.9052C9.48308 23.9729 9.65736 24.0077 9.83337 24.0077C10.0094 24.0077 10.1837 23.9729 10.3461 23.9052C10.5086 23.8375 10.6561 23.7383 10.78 23.6133L21.8334 12.5467V22.6667C21.8334 23.0203 21.9738 23.3594 22.2239 23.6095C22.4739 23.8595 22.8131 24 23.1667 24C23.5203 24 23.8595 23.8595 24.1095 23.6095C24.3596 23.3594 24.5 23.0203 24.5 22.6667V9.33333C24.4979 9.1591 24.4617 8.98696 24.3934 8.82667Z" fill="currentColor"></path>
                        </svg>
                      </span>
                    </div>
                    <div class="max-w-[20rem] pr-12">
                      <h4 class="text-3xl font-medium mb-3">Jiggle Branding</h4>
                      <p class="text-sm">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                    </div>
                  </div>
                </a>
              </div>
              <img class="block w-full rounded-3xl h-80" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/projects-box-3.png" alt="">
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
              <img class="block w-full mb-8 rounded-3xl h-80 object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/projects-box-1.png" alt="">
              <img class="block w-full rounded-3xl h-128 object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/projects-box-vertical-3.png" alt="">
            </div>
            <div class="w-full lg:w-1/3 px-4">
              <img class="block w-full mb-8 rounded-3xl h-128 object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/projects-box-vertical-2.png" alt="">
              <img class="block w-full rounded-3xl h-80 object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/projects-box-2.png" alt="">
            </div>
          </div>
        </div>
      </section>`
                
  const casperShowcase2 =
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <img class="hidden md:block absolute top-0 right-0 lg:-mt-20 mr-20 lg:mr-40 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
          <div class="relative max-w-[32rem] lg:max-w-[76rem] mx-auto">
            <div class="absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
            <div class="absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
            <div class="relative flex flex-wrap items-end -mx-4 mb-12">
              <div class="w-full lg:w-3/5 xl:w-auto px-4 mb-8 lg:mb-0">
                <div class="max-w-[42rem]">
                  <span class="block font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-transparent bg-clip-text bg-gradient-to-br from-violet-500 via-blueGray-800 to-sky-900 tracking-tight">300+</span>
                  <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">Projects Launched</h2>
                  <p class="max-w-[36rem] text-xl text-gray-500 leading-relaxed tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
                </div>
              </div>
              <div class="w-full lg:w-2/5 xl:w-auto ml-auto px-4 lg:text-right">
                <a class="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full" href="#">
                  <span class="mr-2">SEE MORE PROJECTS</span>
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="relative flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-6 lg:mb-0">
                <a class="group block mb-6 rounded-3xl overflow-hidden" href="#">
                  <img class="block h-96 w-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/portfolio-post1.png" alt="">
                  <div class="p-8 bg-gray-800 bg-opacity-20 group-hover:bg-violet-600 transition duration-200">
                    <div class="sm:flex mb-6 items-center justify-between">
                      <span class="text-xl font-medium text-gray-500 group-hover:text-black tracking-tight">jiggle.co</span>
                      <img class="block mt-4 sm:mt-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon.svg" alt="">
                    </div>
                    <p class="text-2xl font-medium text-white group-hover:text-black transition duration-200">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  </div>
                </a>
                <a class="group block rounded-3xl overflow-hidden" href="#">
                  <img class="block h-96 w-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/portfolio-post3.png" alt="">
                  <div class="p-8 bg-gray-800 bg-opacity-20 group-hover:bg-violet-600 transition duration-200">
                    <div class="sm:flex mb-6 items-center justify-between">
                      <span class="text-xl font-medium text-gray-500 group-hover:text-black tracking-tight">welytics.co</span>
                      <img class="block mt-4 sm:mt-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon-3.svg" alt="">
                    </div>
                    <p class="text-2xl font-medium text-white group-hover:text-black transition duration-200">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-1/2 px-4 lg:pt-20">
                <a class="group block mb-6 rounded-3xl overflow-hidden" href="#">
                  <img class="block h-96 w-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/portfolio-post2.png" alt="">
                  <div class="p-8 bg-gray-800 bg-opacity-20 group-hover:bg-violet-600 transition duration-200">
                    <div class="sm:flex mb-6 items-center justify-between">
                      <span class="text-xl font-medium text-gray-500 group-hover:text-black tracking-tight">symtric.co.id</span>
                      <img class="block mt-4 sm:mt-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon-2.svg" alt="">
                    </div>
                    <p class="text-2xl font-medium text-white group-hover:text-black transition duration-200">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  </div>
                </a>
                <a class="group block rounded-3xl overflow-hidden" href="#">
                  <img class="block h-96 w-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/portfolio-post4.png" alt="">
                  <div class="p-8 bg-gray-800 bg-opacity-20 group-hover:bg-violet-600 transition duration-200">
                    <div class="sm:flex mb-6 items-center justify-between">
                      <span class="text-xl font-medium text-gray-500 group-hover:text-black tracking-tight">casper.agency</span>
                      <img class="block mt-4 sm:mt-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon-4.svg" alt="">
                    </div>
                    <p class="text-2xl font-medium text-white group-hover:text-black transition duration-200">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperShowcase3 =
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 left-0 -mt-128 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        <img class="absolute top-0 right-0 mt-16" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/double-lines.svg" alt="">
        <img class="hidden md:block absolute top-0 right-0 mt-8 mr-20 lg:mr-40 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
        <div class="relative container mx-auto px-4">
          <div class="relative max-w-[42rem] mx-auto mb-12 text-center">
            <span class="block font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-transparent bg-clip-text bg-gradient-to-br from-violet-500 via-blueGray-800 to-sky-900 tracking-tight">300+</span>
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">Projects Launched</h2>
            <p class="max-w-[36rem] mx-auto text-xl text-gray-500 leading-relaxed tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
          </div>
        </div>
        <div class="relative mb-16 overflow-hidden">
          <div class="flex items-center px-4 lg:px-0 mb-8">
            <img class="hidden lg:block flex-shrink-0 -ml-20 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-3.png" alt="">
            <div class="relative sm:flex-shrink-0 flex items-end p-6 sm:mr-8 w-full sm:w-128 h-80 rounded-3xl overflow-hidden">
              <img class="absolute top-0 left-0 w-full h-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-2.png" alt="">
              <a class="relative group block w-full p-6 rounded-3xl overflow-hidden" href="#">
                <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 group-hover:bg-opacity-50 rounded-3xl backdrop-filter backdrop-blur-md transition duration-75"></div>
                <div class="relative flex items-center justify-between">
                  <div class="max-w-[20rem]">
                    <h4 class="text-3xl text-white font-medium mb-2">coca.co.id</h4>
                    <p class="text-sm text-white">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  </div>
                  <div>
                    <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.8933 8.82667C23.758 8.50087 23.4991 8.24197 23.1733 8.10667C23.013 8.03835 22.8409 8.00211 22.6667 8H9.33333C8.97971 8 8.64057 8.14048 8.39052 8.39052C8.14047 8.64057 8 8.97971 8 9.33333C8 9.68696 8.14047 10.0261 8.39052 10.2761C8.64057 10.5262 8.97971 10.6667 9.33333 10.6667H19.4533L8.38667 21.72C8.26169 21.844 8.1625 21.9914 8.09481 22.1539C8.02712 22.3164 7.99227 22.4907 7.99227 22.6667C7.99227 22.8427 8.02712 23.017 8.09481 23.1794C8.1625 23.3419 8.26169 23.4894 8.38667 23.6133C8.51062 23.7383 8.65808 23.8375 8.82056 23.9052C8.98304 23.9729 9.15732 24.0077 9.33333 24.0077C9.50935 24.0077 9.68362 23.9729 9.8461 23.9052C10.0086 23.8375 10.156 23.7383 10.28 23.6133L21.3333 12.5467V22.6667C21.3333 23.0203 21.4738 23.3594 21.7239 23.6095C21.9739 23.8595 22.313 24 22.6667 24C23.0203 24 23.3594 23.8595 23.6095 23.6095C23.8595 23.3594 24 23.0203 24 22.6667V9.33333C23.9979 9.1591 23.9617 8.98696 23.8933 8.82667Z" fill="white"></path>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
            <img class="hidden sm:block flex-shrink-0 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-1.png" alt="">
            <img class="hidden lg:block flex-shrink-0 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-3.png" alt="">
          </div>
          <div class="flex items-center">
            <img class="block flex-shrink-0 -ml-64 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-1.png" alt="">
            <img class="block flex-shrink-0 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-2.png" alt="">
            <img class="block flex-shrink-0 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-3.png" alt="">
            <img class="block flex-shrink-0 mr-8 w-128 h-80 rounded-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/box-horizontal-md-1.png" alt="">
          </div>
        </div>
        <div class="relative container mx-auto px-4 text-center">
          <a class="group inline-flex w-full sm:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full" href="#">
            <span class="mr-2">SEE MORE PROJECTS</span>
            <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
            </svg>
          </a>
        </div>
      </section>`
                
  const casperShowcase4 =
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 right-0 -mr-40 mt-128 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        <img class="hidden md:block absolute top-0 right-0 mt-52 mr-20 lg:mr-40 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
        <div class="relative container mx-auto px-4">
          <div class="relative max-w-[42rem] mb-16">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">The work we do</h2>
            <p class="max-w-[36rem] text-xl text-gray-500 leading-relaxed tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full md:w-1/2 px-4 mb-12 md:mb-20">
              <div class="max-w-[28rem] mx-auto md:mx-0">
                <img class="block w-full rounded-3xl mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/image-portfolio-1.png" alt="">
                <h3 class="font-heading text-3xl md:text-4xl font-medium text-white">Symtric - Branding &amp; Web Development</h3>
              </div>
            </div>
            <div class="w-full md:w-1/2 px-4 mb-12 md:mb-20">
              <div class="max-w-[28rem] mx-auto md:mx-0">
                <img class="block w-full rounded-3xl mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/image-portfolio-2.png" alt="">
                <h3 class="font-heading text-3xl md:text-4xl font-medium text-white">Jiggle - Mobile Application for Finance</h3>
              </div>
            </div>
            <div class="w-full md:w-3/4 px-4 mb-12 md:mb-20">
              <div class="max-w-[56rem]">
                <img class="block w-full rounded-3xl mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/image-portfolio-3.png" alt="">
                <h3 class="font-heading text-3xl md:text-4xl font-medium text-white">Welytics - Branding, Illustration &amp; Web Application for health agency</h3>
              </div>
            </div>
            <div class="w-full md:w-1/2 px-4 mb-12 md:mb-20">
              <div class="max-w-[28rem] mx-auto md:mx-0">
                <img class="block w-full rounded-3xl mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/image-portfolio-4.png" alt="">
                <h3 class="font-heading text-3xl md:text-4xl font-medium text-white">Symtric Branding &amp; Web Development</h3>
              </div>
            </div>
            <div class="w-full md:w-1/2 px-4 mb-12 md:mb-20">
              <div class="max-w-[28rem] mx-auto md:mx-0">
                <img class="block w-full rounded-3xl mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/image-portfolio-5.png" alt="">
                <h3 class="font-heading text-3xl md:text-4xl font-medium text-white">Symtric Branding &amp; Web Development</h3>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperShowcase5 =
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 left-0 -mt-128 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        <img class="absolute top-0 right-0 mt-16" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/double-lines.svg" alt="">
        <img class="hidden md:block absolute top-0 right-0 mt-8 mr-20 lg:mr-40 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
        <div class="relative container mx-auto px-4">
          <div class="relative max-w-[42rem] mx-auto mb-20 text-center">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">Our Projects</h2>
            <p class="max-w-[36rem] mx-auto text-xl text-gray-500 leading-relaxed tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
          </div>
          <div class="max-w-[28rem] lg:max-w-8xl mx-auto">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                <a class="relative block group p-8 rounded-3xl overflow-hidden" href="#">
                  <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-800 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                  <div class="relative flex flex-col">
                    <div class="mb-52">
                      <span class="block mb-4 text-xl text-gray-500 group-hover:text-gray-900">01</span>
                      <img src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon.svg" alt="">
                    </div>
                    <div class="mb-3">
                      <h4 class="text-4xl text-white group-hover:text-black font-medium mb-2">Jiggle Branding</h4>
                      <p class="text-sm text-white group-hover:text-black">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                    </div>
                    <div class="flex flex-wrap -mb-2">
                      <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">BRANDING</span>
                      <span class="inline-block px-5 py-3 mb-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">ILLUSTRATION</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                <a class="relative block group p-8 rounded-3xl overflow-hidden" href="#">
                  <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-800 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                  <div class="relative flex flex-col">
                    <div class="mb-52">
                      <span class="block mb-4 text-xl text-gray-500 group-hover:text-gray-900">02</span>
                      <img src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon-3.svg" alt="">
                    </div>
                    <div class="mb-3">
                      <h4 class="text-4xl text-white group-hover:text-black font-medium mb-2">Welytics Company Profile</h4>
                      <p class="text-sm text-white group-hover:text-black">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                    </div>
                    <div class="flex flex-wrap -mb-2">
                      <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">BRANDING</span>
                      <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">ILLUSTRATION</span>
                      <span class="inline-block px-5 py-3 mb-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">LANDING PAGE</span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <a class="relative block group p-8 rounded-3xl overflow-hidden" href="#">
                  <div class="absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-800 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150"></div>
                  <div class="relative flex flex-col">
                    <div class="mb-52">
                      <span class="block mb-4 text-xl text-gray-500 group-hover:text-gray-900">01</span>
                      <img src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/logo-icon.svg" alt="">
                    </div>
                    <div class="mb-3">
                      <h4 class="text-4xl text-white group-hover:text-black font-medium mb-2">Jiggle Branding</h4>
                      <p class="text-sm text-white group-hover:text-black">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                    </div>
                    <div class="flex flex-wrap -mb-2">
                      <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">BRANDING</span>
                      <span class="inline-block px-5 py-3 mb-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">ILLUSTRATION</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const casperShowcase6 = 
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        <img class="hidden md:block absolute top-0 right-0 mt-20 mr-20 xl:mr-64 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
        <div class="relative container mx-auto px-4">
          <div class="relative max-w-[42rem] mx-auto mb-24 text-center">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">Our Projects</h2>
            <p class="max-w-[36rem] mx-auto text-xl text-gray-500 leading-relaxed tracking-tight mb-8">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
            <a class="group inline-flex w-full sm:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full" href="#">
              <span class="mr-2">SEE MORE PROJECTS</span>
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
              </svg>
            </a>
          </div>
          <div class="max-w-[28rem] lg:max-w-3xl xl:max-w-6xl mx-auto">
            <div class="flex flex-wrap -mx-4 mb-16">
              <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                <div class="flex flex-col max-w-[20rem] lg:max-w-xs xl:max-w-sm h-full items-start">
                  <h4 class="text-4xl text-white font-medium mb-6">Symtric Web Developmentg</h4>
                  <p class="text-sm text-white leading-snug mb-6">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  <div class="flex flex-wrap -mb-2">
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">WEBSITE</span>
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">WEBFLOW</span>
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">UI DESIGN</span>
                    <span class="inline-block px-5 py-3 mb-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">DEVELOPMENT</span>
                  </div>
                  <div class="pt-4 mt-auto">
                    <a class="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-white hover:text-violet-800 hover:bg-white border-2 border-gray-300 hover:border-white transition duration-200 rounded-full" href="#">
                      <span class="mr-2">SEE DETAILS</span>
                      <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <img class="block object-cover rounded-3xl" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/portfolio-image-square-3.png" alt="">
              </div>
            </div>
            <div class="flex flex-wrap -mx-4 mb-16">
              <div class="w-full lg:w-1/2 px-4 order-last lg:order-first">
                <img class="block object-cover rounded-3xl" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/portfolio/portfolio-image-square-2.png" alt="">
              </div>
              <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                <div class="flex flex-col max-w-[24rem] lg:max-w-xs xl:max-w-sm lg:ml-auto h-full items-start">
                  <h4 class="text-4xl text-white font-medium mb-6">Weltics Company Profile</h4>
                  <p class="text-sm text-white leading-snug mb-6">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  <div class="flex flex-wrap -mb-2">
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">WEBSITE</span>
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">WEBFLOW</span>
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">UI DESIGN</span>
                    <span class="inline-block px-5 py-3 mb-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">DEVELOPMENT</span>
                  </div>
                  <div class="pt-4 mt-auto">
                    <a class="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-white hover:text-violet-800 hover:bg-white border-2 border-gray-300 hover:border-white transition duration-200 rounded-full" href="#">
                      <span class="mr-2">SEE DETAILS</span>
                      <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-4 mb-16">
              <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                <div class="flex flex-col max-w-[24rem] lg:max-w-xs xl:max-w-sm h-full items-start">
                  <h4 class="text-4xl text-white font-medium mb-6">Sans Design Branding</h4>
                  <p class="text-sm text-white leading-snug mb-6">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                  <div class="flex flex-wrap -mb-2">
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">WEBSITE</span>
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">WEBFLOW</span>
                    <span class="inline-block px-5 py-3 mb-2 mr-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">UI DESIGN</span>
                    <span class="inline-block px-5 py-3 mb-2 text-sm font-medium text-white bg-white bg-opacity-20 uppercase rounded-full">DEVELOPMENT</span>
                  </div>
                  <div class="pt-4 mt-auto">
                    <a class="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-white hover:text-violet-800 hover:bg-white border-2 border-gray-300 hover:border-white transition duration-200 rounded-full" href="#">
                      <span class="mr-2">SEE DETAILS</span>
                      <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <img class="block object-cover rounded-3xl" src="casper-assets/portfolio/portfolio-image-square-1.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>`

  const arr = [
    { label: 'casperShowcase1',content: casperShowcase1,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/product11.png' },
    { label: 'casperShowcase2',content: casperShowcase2,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/product22.png' },
    { label: 'casperShowcase3',content: casperShowcase3,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/product33.png' },
    { label: 'casperShowcase4',content: casperShowcase4,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/product4.png' },
    { label: 'casperShowcase5',content: casperShowcase5,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/product5.png' },
    { label: 'casperShowcase6',content: casperShowcase6,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/product6.png' },
  ]
  return arr
}
