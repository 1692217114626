import {
  Avatar,
  AvatarBadge,
  Button,
  Flex,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';


import { AiOutlineGlobal } from 'react-icons/ai';
import { IoAnalyticsSharp } from 'react-icons/io5';
import moment from 'moment';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getCollectionFirebase } from '../../../Api/firebaseApi';
import _axios from '../../../Api/AxiosBarrier';
import BackButtons from '../../../Components/Buttons/BackButtons';
import AnalyticsData from '../Analytics/AnalyticsData';


function ReportsPage() {
  const globalState = useUserStore();
  // const [barStatus, setBarStatus] = useState(false);
  // const contentWidth = barStatus ? '85%' : '95%';

  const height = window.innerHeight;

  const [userData, setUserData] = useState('');
  // const [platformActive, setPlatformActive] = useState('');
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [dataAnalytics, setDataAnalytics] = useState({});
  const [loadingView, setLoadingView] = useState(false);

  const profileKey = globalState.socialKey;
  // const projectTitle = globalState.projectTitle;

  // const currentUser = auth.currentUser

  const toast = useToast();

  const getListSocial = async () => {

    const conditions = [

      {
        field: 'users',
        operator: 'array-contains',
        value: globalState?.uid,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
      {
        field: 'type',
        operator: '==',
        value: 'social-media',
      },
    ];

    try {
      // const res = await getCollectionFirebase("social_media", conditions);
      // console.log(res, "xx");

      const socialMediaKey = await getCollectionFirebase('files', conditions);
      if (socialMediaKey.length > 0) {
        setUserData(socialMediaKey[0])
        setSocialAccounts(socialMediaKey[0].displayNames);
        handleAnalytics(socialMediaKey[0]?.activeSocialAccounts);
      } else {
        setSocialAccounts([])
      }

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }


    // // getDataProject();

    // try {
    //   const docRef = doc(db, "users", globalState.uid);
    //   const docSnap = await getDoc(docRef);
    //   if (docSnap.exists()) {
    //     const filterArr = docSnap
    //       ?.data()
    //       ?.social_accounts?.filter((x) => x.title === projectTitle);
    //     setSocialMediaList(filterArr);
    //     console.log(socialMediaList);

    //     handleAnalytics(filterArr[0]?.activeSocialAccounts);
    //   } else {
    //     console.log("No such document!");
    //   }
    //   ;
    // } catch (error) {
    //   console.log(error);
    //   ;
    // }
  };

  // useEffect(() => {
  //   getDataProject();
  // }, [profileKey]);

  useEffect(() => {
    getListSocial();
    return () => {
      setDataAnalytics({});
    };
  }, [globalState?.currentProject]);

  const handleAnalytics = async (platforms) => {
    setLoadingView(true);
    // setPlatformActive(platforms);

    try {
      const res = await _axios.post('ayrshare-analyticsSocial', {
        platforms,
        profileKey,
      });
      if (res.data.status === 'success') {
        const obj = res.data;
        const { ...analyticsData } = obj;
        const filteredData = {};
        for (const platform in analyticsData) {
          if (obj[platform]?.status !== 'error') {
            filteredData[platform] = obj[platform];
          } else {
            toast({
              title: 'Deoapp.com',
              description: obj[platform]?.message,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          }
        }
        setDataAnalytics(filteredData);
      } else {
        toast({
          title: 'Deoapp.com',
          description: res.data[platforms]?.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      setLoadingView(false);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      setLoadingView(false);
    }
    setLoadingView(false);
  };

  // const getAnalytics = async () => {

  //     if(profileKey){
  //         try {
  //             const res = await ApiBackend.post('/analyticslinks', {
  //                 lastDays : 1,
  //                 profileKey
  //             })
  //             console.log(res, 'report')
  //         } catch (error) {
  //             console.log(error)
  //         }
  //     }
  // }

  return (
    <>
      <Flex bgColor={'gray.100'} flex={1} flexDirection="row" spacing={3}>
        <Stack w={'100%'} transition={'0.2s ease-in-out'} minH={height}>
          <Stack p={10} spacing={5}>
            <HStack>
              <BackButtons/>
              <Text fontSize={'xl'} fontWeight="bold" color={'gray.600'}>
                Report page
              </Text>
            </HStack>

            <SimpleGrid columns={[1]} gap={2}>
             
              <Stack
                shadow={'md'}
                borderRadius="lg"
                p={5}
                spacing={3}
              >
                <HStack>
                  <Text fontSize={'xs'} color="gray.600">
                          Name
                  </Text>
                  <Spacer />
                  <Text
                    fontSize={'xs'}
                    color="gray.900"
                    fontWeight={'bold'}
                  >
                    {userData?.title}
                  </Text>
                </HStack>

                <HStack>
                  <Text fontSize={'xs'} color="gray.600">
                          Updated
                  </Text>
                  <Spacer />
                  <Text
                    fontSize={'xs'}
                    color="gray.900"
                    fontWeight={'bold'}
                  >
                    {moment(userData?.lastUpdated?.seconds * 1000).toLocaleString()}
                  </Text>
                </HStack>

                <Stack>
                  <SimpleGrid columns={[1, 2, 4]} gap={3}>
                    {socialAccounts?.length > 0 &&
                            socialAccounts?.map((z, index) => {
                              const PlatformArr = [
                                {
                                  name: 'youtube',
                                  icon: <FaYoutube color="white" size={10} />,
                                },
                                {
                                  name: 'twitter',
                                  icon: <FaTwitter color="white" size={10} />,
                                },
                                {
                                  name: 'facebook',
                                  icon: <FaFacebook color="white" size={10} />,
                                },
                                {
                                  name: 'instagram',
                                  icon: <FaInstagram color="white" size={10} />,
                                },
                                {
                                  name: 'tiktok',
                                  icon: <FaTiktok color="white" size={10} />,
                                },
                                {
                                  name: 'linkedin',
                                  icon: <FaLinkedin color="white" size={10} />,
                                },
                                {
                                  name: 'google',
                                  icon: <FaGoogle color="white" size={10} />,
                                },
                                {
                                  name: 'pinterest',
                                  icon: <FaPinterest color="white" size={10} />,
                                },
                              ];

                              const filterError = PlatformArr.filter((y) =>
                                y.name.includes(z.platform)
                              );
                              const resIcon = filterError[0]?.icon;

                              return (
                                <Stack
                                  shadow={'md'}
                                  alignItems={'center'}
                                  _hover={{
                                    transform: 'scale(1.1)',
                                    shadow: 'xl',
                                  }}
                                  transition={'0.2s ease-in-out'}
                                  justifyContent="center"
                                  borderRadius="lg"
                                  key={index}
                                  borderTopWidth={5}
                                  borderColor="blue.500"
                                  p={5}
                                >
                                  <Stack>
                                    <Avatar
                                      src={z.userImage}
                                      alt={z.displayName}
                                    >
                                      <AvatarBadge
                                        boxSize="1.25em"
                                        bg="green.500"
                                      >
                                        {' '}
                                        {resIcon}
                                      </AvatarBadge>
                                    </Avatar>
                                  </Stack>
                                  <Spacer />
                                  <Stack>
                                    <Text
                                      textAlign={'center'}
                                      fontSize="xs"
                                      color={'gray.600'}
                                    >
                                      {z.displayName}
                                    </Text>
                                  </Stack>

                                  <Stack>
                                    <SimpleGrid columns={[2]} gap={2}>
                                      <Stack
                                        alignItems={'center'}
                                        justifyContent="center"
                                      >
                                        <Button
                                          size={'xs'}
                                          colorScheme="twitter"
                                          onClick={() =>
                                            handleAnalytics(z.platform)
                                          }
                                        >
                                          <IoAnalyticsSharp />
                                        </Button>
                                      </Stack>
                                      <Stack
                                        alignItems={'center'}
                                        justifyContent="center"
                                      >
                                        <a
                                          href={z.profileUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Button
                                            size={'xs'}
                                            colorScheme="twitter"
                                          >
                                            <AiOutlineGlobal />
                                          </Button>
                                        </a>
                                      </Stack>
                                    </SimpleGrid>
                                  </Stack>
                                </Stack>
                              );
                            })}
                  </SimpleGrid>
                </Stack>
              </Stack>
            </SimpleGrid>
            {dataAnalytics &&
              (loadingView ? (
                <Stack
                  w={'100%'}
                  h={height / 2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner size={'md'} colorScheme="twitter" />
                </Stack>
              ) : (
                Object.entries(dataAnalytics).map(([key, value]) => (
                  <Stack key={key}>
                    <AnalyticsData data={value.analytics} platform={key} />
                  </Stack>
                ))
              ))}
          </Stack>
        </Stack>
      </Flex>
    </>
  );
}

export default ReportsPage;
