import { AspectRatio,Box,Container,HStack,Heading,Image,Spacer,Text } from '@chakra-ui/react'
import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { SlBubble,SlHeart,SlPaperPlane } from 'react-icons/sl'
import instagramLogo from '../../assets/instagram.png'
import { CiBookmark } from 'react-icons/ci'
import Slider from 'react-slick'
import PropTypes from 'prop-types';

const InstagramPost = ({ marketingState }) => {
  const options = {
    dots: true,
    infinite: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 5000
  };

  return (
    <>
      <Container alignSelf="start" w={'60%'}>
        <Box borderRadius={'md'} shadow={'base'} overflow={'hidden'}>
          <Box align={'center'} my={4}>
            <Image src={instagramLogo} width={100} />
          </Box>
          <HStack p={2} borderTop={'1px'} borderColor={'gray.200'}>
            <Image
              // w={75}
              boxSize={50}
              borderRadius="full"
              src={
                marketingState?.adsCreation?.businessLogo === ''
                  ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                  : marketingState?.adsCreation?.businessLogo
              }
              alt="your brand name"
            />
            <Box>
              <Heading size="sm">
                {marketingState?.adsCreation?.businessName === ''
                  ? 'Your Business Name'
                  : marketingState?.adsCreation?.businessName}
              </Heading>
              <Text fontSize={14}>Sponsored</Text>
            </Box>
          </HStack>
          <Box bgColor="F2F2F2" align={'center'}>
            {marketingState?.adsCreation?.format === 'carousel' ? (
              <>
                {marketingState?.adsCreation?.carousel?.length === 0 ? (
                  <Image
                    w={300}
                    src={
                      'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                    }
                    alt="ads Image"
                  />
                ) : (
                  <Slider {...options}>
                    {/* <Image
                              w={300}
                              src={marketingState?.adsCreation?.mediaLink[0]?.link}
                              alt="ads Image"
                            /> */}
                    {marketingState?.adsCreation?.carousel?.map((x, i) => (
                      <AspectRatio ratio={1} key={i}>

                        {x?.type === 'video' ? (
                          <iframe
                            // objectFit={'cover'}
                            src={
                              x?.link
                            }
                            alt="ads Image"
                          />
                        ) : (
                          <Image
                            objectFit={'cover'}
                            src={
                              x?.link
                            }
                            alt="ads Image"
                          />

                        )}

                      </AspectRatio>
                    ))}
                  </Slider>
                )}
              </>
            ) : (
              <>
                {marketingState?.adsCreation.mediaLink?.length === 0 ? (
                  <AspectRatio>
                    <Image
                      src={
                        'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                      }
                      alt="ads Image"
                    />
                    {/* <Image src='https://bit.ly/naruto-sage' alt='naruto' objectFit='cover' /> */}
                  </AspectRatio>
                ) : (
                  <>
                    {marketingState?.adsCreation?.mediaLink[0]?.type ===
                                            'video' ? (
                        <Box align={'center'}>
                          <iframe
                            width={'100%'}
                            src={
                              marketingState?.adsCreation?.mediaLink[0]?.link
                            }
                            allowFullScreen
                          />
                        </Box>
                      ) : (
                        <AspectRatio ratio={16 / 9}>
                          <Image

                            src={
                              marketingState?.adsCreation?.mediaLink[0]?.link
                            }
                            alt="ads Image"
                          />
                        </AspectRatio>
                      )}
                  </>
                )}
              </>
            )}
          </Box>
          <HStack borderBottom={'1px'} borderColor={'gray.300'}>
            <Text p={3} fontWeight={500} fontSize={14}>
              {marketingState?.adsCreation?.cta === ''
                ? 'Your CTA'
                : marketingState?.adsCreation?.cta}
            </Text>
            <Spacer />
            <Box p={3} >
              <FaAngleRight />
            </Box>
          </HStack>
          <HStack p={2} color={'gray.700'} spacing={5}>
            <SlHeart size={20} />
            <SlBubble size={20} />
            <SlPaperPlane size={20} />
            <Spacer />
            <CiBookmark size={23} />
          </HStack>
          <Text my="2" p={2}>
            <Text fontWeight={'semibold'} as={'span'} pr={1}>{marketingState?.adsCreation?.businessName}</Text>
            {marketingState?.adsCreation?.primaryText === ''
              ? 'Your Primary Text'
              : marketingState?.adsCreation?.primaryText}
          </Text>
        </Box>
      </Container>
    </>
  )
}

export default InstagramPost

InstagramPost.propTypes = {
  marketingState: PropTypes.any,
}