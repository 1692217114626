import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcIphone, FcPhotoReel,FcStart, FcVideoCall } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../../Hooks/Zustand/Store';
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase } from '../../../Api/firebaseApi';
import { auth } from '../../../Config/firebase';
import BackButtons from '../../../Components/Buttons/BackButtons';
import CardAvatar from '../../../Components/Card/CardAvatar';
import AlertArrayNull from '../../../Components/AlertDialog/AlertArrayNull';
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2';

function SocialMediaCreationPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const globalState = useUserStore();
  const [type,setType] = useState();
  const [ratio,setRatio] = useState();
  const [title,setTitle] = useState();
  const modalDeleteFolder = useDisclosure();
  const modalCreateContent = useDisclosure();
  const [contents,setContents] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [bannerImage,setBannerImage] = useState([]);
  const [selectedFolder,setSelectedFolder] = useState('');

  const getContents = async () => {
    const conditions = [
      { field: 'companyId',operator: '==',value: globalState?.currentCompany },
      { field: 'projectId',operator: '==',value: globalState?.currentProject },
      { field: 'user',operator: 'array-contains',value: globalState?.uid },
    ];

    try {
      const res = await getCollectionFirebase('files',conditions);
      // console.log(res)
      setContents(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getBannerData = async () => {
    // const urlHost = window.location.host;
    // const urlHostName = window.location.hostname
    const urlPath = window.location.pathname;
    const urlResult = urlPath.substring(1);


    const conditions = [
      { field: 'companyId',operator: '==',value: '0BZDboI1UP5SoXFV1Z4n' },
      { field: 'isActive',operator: '==',value: 'active' },
      { field: 'path',operator: '==',value: urlResult },
      // { field: "baseUrl", operator: "==", value: `${urlHost}/` },
    ];

    try {
      const res = await getCollectionFirebase('banner',conditions);
      setBannerImage(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleCreate = async () => {
    // globalState.setIsLoading(true);
    setIsLoading(true);
    try {
      const res = await addDocumentFirebase(
        'files',
        {
          companyId: globalState?.currentCompany,
          projectId: globalState?.currentProject,
          title: title,
          type: `${type}-content`,
          limit: 30,
          owner: [globalState?.uid],
          user: [globalState?.uid],
          userDisplay: [
            {
              id: auth?.currentUser?.uid,
              image: auth.currentUser?.photoURL,
              name: auth?.currentUser?.displayName,
              email: auth?.currentUser?.email,
            },
          ],
          ratio: ratio,
        },
        globalState?.currentCompany
      );

      // const addSubcollection = await addDocumentFirebase(`social_media_contents/${res}/`, res, {user: [globalState?.uid]})

      navigate(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      // globalState.setIsLoading(false);
    }
    // add new docs
    // {companyId:companyId,projectId:projectId,title:type-ratio,type:type,ratio:ratio}
    // once you get the ID from firebase, send user to /content/ID
  };

  const handleModalDelete = (value) => {
    setSelectedFolder(value);
    modalDeleteFolder.onOpen();
  };
  const handleDelete = async () => {
    try {
      await deleteDocumentFirebase('files',selectedFolder?.id);

      toast({
        title: 'Content folder deleted',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      getContents();
      modalDeleteFolder?.onClose();
    }
  };

  const handleOpen = (type, ratio) => {
    setType(type);
    setRatio(ratio);

    modalCreateContent.onOpen();
  };

  const handleNavigate = (x) => {
    navigate(x?.id);
  };

  useEffect(() => {
    getContents();
    getBannerData();

    return () => {
      setContents([]);
    };
  },[globalState?.currentProject,globalState?.currentCompany]);

  return (
    <Box>
      {/* <Image
        src="https://t3.ftcdn.net/jpg/03/26/26/46/360_F_326264640_Rx6nxYBAE7RVrAFhsrXuk0c53XwzF5RX.jpg"
        alt="banner"
      /> */}

      {bannerImage?.map((x) => (
        <a
          key={x?.id}
          href={x?.image[0]?.link}
          target='_blank' rel="noreferrer">
          <Image
            src={x?.image[0]?.url}
            alt='banner image'
          />
        </a>
      ))}

      <HStack my={5}>
        <BackButtons />
        <Heading ml={3}>Content Creation</Heading>
        <Spacer />
        <Input
          type='text'
          placeholder='Search Content'
          maxW='sm'
        />
        <Menu>
          <MenuButton
            as={Button}
            colorScheme='green'
            rightIcon={<ChevronDownIcon />}>
            Create
          </MenuButton>
          <MenuList>
            {/* <Link to='videos/1-1'> */}
            <MenuItem
              minH='48px'
              onClick={() => handleOpen('video','1-1')}>
              <Icon
                as={FcStart}
                boxSize='2rem'
              />
              1:1 Videos
            </MenuItem>
            {/* </Link> */}
            <MenuItem
              minH='48px'
              onClick={() => handleOpen('video','9-16')}>
              <Icon
                as={FcIphone}
                boxSize='2rem'
              />
              9:16 Videos
            </MenuItem>
            <MenuItem
              minH='48px'
              onClick={() => handleOpen('video','16-9')}>
              <Icon
                as={FcVideoCall}
                boxSize='2rem'
              />
              16:9 Videos
            </MenuItem>
            {/* <MenuItem minH='40px'>
                  <Icon as={FcAddImage} boxSize='2rem' />
                  <span>1:1 Images</span>
                </MenuItem>
                <MenuItem minH='48px'>
                    <Icon as={FcImageFile} boxSize='2rem' />
                  <span>9:16 Images</span>
                </MenuItem>
                <MenuItem minH='48px'>
                    <Icon as={FcPicture} boxSize='2rem' />
                  <span>16:9 Images</span>
                </MenuItem> */}
          </MenuList>
        </Menu>
      </HStack>

      {contents?.length > 0 ? (
        <SimpleGrid
          columns='4'
          spacing={3}
          borderRadius={'md'}
          p={3}>
          {contents?.map((x,i) => (
            <Box key={i}>
              {/* {x?.type === 'video' ? (
              <AspectRatio maxW="560px" ratio={1}>
                <iframe
                  title="naruto"
                  src="https://www.youtube.com/embed/QhBnZ6NPOY0"
                  allowFullScreen
                />
              </AspectRatio>
            ) : (
              <AspectRatio maxW="560px" ratio={1}>
                <Image
                  src="https://bit.ly/naruto-sage"
                  alt="naruto"
                  objectFit="cover"
                />
              </AspectRatio>
            )} */}
              {/* <HStack p="2">
              <Link to={x?.id}>
                {x?.type === 'video' ? (
                  <Icon as={FcVideoCall} />
                ) : (
                  <Icon as={FcCamera} />
                )}
                <Heading fontSize="md">{x?.title}</Heading>
              </Link>
              <Spacer />
              {x?.user?.length > 0 ? (
            <AvatarGroup size="sm" max={2}>
              {x?.user?.map( (userId) => {
                const user = getUserByUid(globalState?.users, userId);
                return (
                  <Avatar
                    key={user?.id} // Make sure to provide a unique key for each element in the array
                    name={user?.name}
                    src={user?.image}
                  />
                );
              })}
            </AvatarGroup>
          ):(
            <Text>No User</Text>
          )} */}
              {/* <AvatarGroup size="sm" max={2}>
                <Avatar
                  name="Ryan Florence"
                  src="https://bit.ly/ryan-florence"
                />
                <Avatar
                  name="Segun Adebayo"
                  src="https://bit.ly/sage-adebayo"
                />
                <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                <Avatar
                  name="Prosper Otemuyiwa"
                  src="https://bit.ly/prosper-baba"
                />
                <Avatar
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                />
              </AvatarGroup> */}

              {/* </HStack> */}
              <CardAvatar
                data={x}
                title={x.title}
                Icon={FcPhotoReel}
                globalState={globalState}
                timeCreated={x?.lastUpdated?.seconds}
                owner={x?.owner}
                onNavigate={() => handleNavigate(x)}
                userArray={x?.userDisplay}
                onDelete={() => handleModalDelete(x)}
              />
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <AlertArrayNull
          titleData={'Social Media Content'}
          action='Add Content'
        />
      )}

      <Modal
        isOpen={modalCreateContent.isOpen}
        onClose={modalCreateContent.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Content Group Name</ModalHeader>
          <ModalBody>
            <Input
              onChange={(e) => setTitle(e.target.value)}
              placeholder='Enter name'
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme='green'
              onClick={() => handleCreate()}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isOpen={modalDeleteFolder?.isOpen}
        onClose={modalDeleteFolder?.onClose}
        title={`${selectedFolder?.title} will be deleted`}
        isLoading={isLoading}
        onAction={handleDelete}
      />
    </Box>
  );
}

export default SocialMediaCreationPage;
