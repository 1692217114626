/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useMarketingStore from '../../../Hooks/Zustand/MarketingStore';
import { addDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, updateDocumentFirebase } from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import axios from 'axios';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import moment from 'moment';
import { formatFrice } from '../../../Utils/Helper';
import { MdImportantDevices } from 'react-icons/md';
import FacebookPost from '../../../Components/AdPreviews/FacebookPost';
import InstagramPost from '../../../Components/AdPreviews/InstagramPost';
import FacebookMarketplace from '../../../Components/AdPreviews/FacebookMarketplace';
import MessengerInbox from '../../../Components/AdPreviews/MessengerInbox';
import FacebookVideoFeeds from '../../../Components/AdPreviews/FacebookVideoFeeds';
import InstagramtReels from '../../../Components/AdPreviews/InstagramtReels';
import InstagramtStories from '../../../Components/AdPreviews/InstagramtStories';
import FacebookStories from '../../../Components/AdPreviews/FacebookStories';
import FacebookReels from '../../../Components/AdPreviews/FacebookReels';
import InstagramExplore from '../../../Components/AdPreviews/InstagramExplore';
import { HiOutlineDeviceMobile } from 'react-icons/hi';

function MarketingMetaAddPublishPage() {
  const toast = useToast();
  const [value, setValue] = useState('credit card');
  const globalState = useUserStore();
  const marketingState = useMarketingStore();
  const [payData, setPayData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeDays, setActiveDays] = useState();
  const [totalEstimateBudget, setTotalEstimateBudget] = useState();
  const [sliderValue, setSliderValue] = useState();
  const param = useParams();
  const [data, setData] = useState();
  const [adsId, setAdsId] = useState('');
  const [selectedPreview, setSelectedPreview] = useState('');
  const [selectedPaymentMethodImage, setSelectedPaymentMethodImage] = useState('');

  const modalPayment = useDisclosure();

  const handleCancelPay = () => {
    setPayData({});
  };

  const steps = [
    { title: 'Campaign', description: 'Create Campaign' },
    { title: 'Ads', description: 'Create Ads' },
    { title: 'Audience', description: 'Select Audience' },
    {
      title: 'Budget & Strategies',
      description: 'Set your budget & strategies',
    },
    { title: 'Publish', description: 'Review & publish' },
  ];

  const navigate = useNavigate();

  const banks = [
    {
      name: 'BNI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png',
    },
    {
      name: 'MANDIRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png',
    },
    {
      name: 'PERMATA',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png',
    },
    {
      name: 'BRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png',
    },
  ];

  const apikey = process.env.REACT_APP_PAYMENT_KEY;

  const handlePay = async () => {
    setIsLoad(true);
    const dataOrder = {
      campaignId: marketingState?.id,
      amount: totalEstimateBudget,
      projectId: globalState?.currentProject,
      companyId: globalState?.currentCompany,
      dataBudget: marketingState?.budgeting,
      bankCode: value,
      name: globalState?.name,
      status: 'PENDING',
      module: 'ads_budget',
    };

    try {
      const res = await addDocumentFirebase('orders', dataOrder, globalState?.currentCompany);
      setOrderId(res);

      if (res) {
        const paramPay = {
          expected_amount: totalEstimateBudget,
          bank_code: value,
          name: globalState?.name,
          company_id: globalState?.currentCompany,
          project_id: globalState?.currentProject,
          outlet_id: marketingState?.campaign?.campaignName,
          feeRule: false,
          is_production: false,
          userId: globalState?.uid,
          for_user_id: '',
          type: 'onetime',
          module: 'marketing',
          callback_url: 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/xendit-paymentCallback',
        };

        const url = process.env.REACT_APP_MARKETERS_HOST;

        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: apikey,
          },
        };

        const response = await axios.post(`${url}/xendit-payment`, paramPay, options);
        setIsLoad(false);

        if (response.data.status) {
          const draftResult = await handleDraft();

          if (draftResult.status) {
            setPayData(response?.data?.data);
          }
        } else {
          toast({
            status: 'warning',
            duration: 9000,
            title: response.data.message,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      setIsLoad(false);
      toast({
        status: 'warning',
        duration: 9000,
        title: error.message,
        isClosable: true,
      });
    }
  };

  const handleDonePayment = async () => {
    setIsLoad(true);
    try {
      const res = await getSingleDocumentFirebase('payments', payData?.external_id);

      if (res?.status === 'PAID') {
        setIsPaid(true);
        modalPayment.onClose();

        await updateAdsDataStatus(adsId);

        navigate('/marketing/meta/ads');
      } else {
        toast({
          status: 'warning',
          title: 'Payment is still pending',
          duration: 3000,
        });
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoad(false);
    }
  };

  const updateAdsDataStatus = async (id, param) => {
    try {
      const existingAdsData = await getSingleDocumentFirebase('marketing_meta', id);

      if (existingAdsData) {
        if (param?.type === 'repaid') {
          await updateDocumentFirebase('marketing_meta', id, {
            isDraft: 'republish',
            isPaid: true,
            status: 'request',
          });
        } else {
          await updateDocumentFirebase('marketing_meta', id, {
            isDraft: 'publish',
            isPaid: true,
            status: 'request',
          });
        }
      }
    } catch (error) {
      throw new Error('Error mengupdate status data iklan:', error)
    }
  };

  const handleDraft = async () => {
    setLoading(true);
    const checkCampaignName = await checkDataCampaignName(marketingState?.campaign?.campaignName);

    if (checkCampaignName?.length >= 0) {
      try {
        if (param?.type === 'edit') {
          const existingData = {
            campaignName: marketingState?.campaign?.campaignName || data?.campaign?.campaignName || '',
            pixelId: marketingState?.campaign?.pixelID || data?.campaign?.pixelID || '',
            facebookLink: marketingState?.campaign?.facebookLink || data?.campaign?.facebookLink || '',
            instagramLink: marketingState?.campaign?.instagramLink || data?.campaign?.instagramLink || '',
            tags: marketingState?.campaign?.tags || data?.campaign?.tags || '',
            adsCreation: marketingState?.adsCreation || data?.adsCreation || '',
            budget: data?.budgeting || '0',
            targeting: data?.targeting || '0',
            adPlacement: globalState?.adPlacement || '',
            companyId: globalState?.currentCompany || '',
            projectId: globalState?.currentProject || '',
            uid: globalState?.uid || '',
            status: 'request',
            type: 'ads',
            category: 'facebook',
            isDraft: 'draft',
            isPaid: false,
          };

          await updateDocumentFirebase('marketing_meta', param.id, existingData);
          toast({
            title: 'success',
            description: 'Success update campaign, preparing to publish',
            duration: 3000,
            status: 'success',
          });
        } else if (param?.type === 'repaid') {
          const existingData = {
            campaignName: marketingState?.campaign?.campaignName || data?.campaign?.campaignName || '',
            pixelId: marketingState?.campaign?.pixelID || data?.campaign?.pixelID || '',
            facebookLink: marketingState?.campaign?.facebookLink || data?.campaign?.facebookLink || '',
            instagramLink: marketingState?.campaign?.instagramLink || data?.campaign?.instagramLink || '',
            tags: marketingState?.campaign?.tags || data?.campaign?.tags || '',
            adsCreation: marketingState?.adsCreation || data?.adsCreation || '',
            budget: marketingState?.budgeting || '0',
            targeting: data?.targeting || '0',
            adPlacement: globalState?.adPlacement || '',
            companyId: globalState?.currentCompany || '',
            projectId: globalState?.currentProject || '',
            uid: globalState?.uid || '',
            status: 'request',
            type: 'ads',
            category: 'facebook',
            isDraft: 'republish',
            isPaid: false,
          };

          await updateDocumentFirebase('marketing_meta', param.id, existingData);
          toast({
            title: 'success',
            description: 'Success update campaign, preparing to publish',
            duration: 3000,
            status: 'success',
          });
        } else {
          const newAdsData = {
            totalBudget: totalEstimateBudget,
            campaignName: marketingState?.campaign?.campaignName,
            objective: marketingState?.campaign?.objective,
            adAccount: marketingState?.campaign?.adAccount,
            facebookLink: marketingState?.campaign?.facebookLink,
            instagramLink: marketingState?.campaign?.instagramLink,
            pixelId: marketingState?.campaign?.pixelID,
            tags: marketingState?.campaign?.tags,
            adsCreation: marketingState?.adsCreation,
            budget: marketingState?.budgeting || '0',
            targeting: marketingState?.targeting || '0',
            adPlacement: marketingState?.adPlacement || '',
            companyId: globalState?.currentCompany || '',
            projectId: globalState?.currentProject || '',
            uid: globalState?.uid || '',
            status: 'request',
            isDraft: 'draft',
            isPaid: false,
            type: 'ads',
            category: 'facebook',
          };

          const res = await addDocumentFirebase('marketing_meta', newAdsData, globalState?.currentCompany);
          setAdsId(res);

          toast({
            title: 'success',
            description: 'Success save campaign, preparing to publish',
            duration: 3000,
            status: 'success',
          });
        }

        setLoading(false);
        // navigate('/marketing/meta/ads');

        return { status: true };
      } catch (error) {
        toast({
          title: 'Error',
          description: `An error occurred: ${error}`,
          duration: 3000,
          status: 'error',
        });
      } finally {
        setIsLoad(false);
      }
    } else {
      toast({
        title: 'Payment Error, Campaign Name is already exist',
        description: 'Please change',
        duration: 3000,
        status: 'error',
      });

      return { status: false };
    }
  };

  const handleDraftButton = async () => {
    setLoading(true);
    let adsData = {};
    adsData = {
      totalBudget: totalEstimateBudget,
      campaignName: marketingState?.campaign?.campaignName,
      objective: marketingState?.campaign?.objective,
      adAccount: marketingState?.campaign?.adAccount,
      facebookLink: marketingState?.campaign?.facebookLink,
      instagramLink: marketingState?.campaign?.instagramLink,
      pixelId: marketingState?.campaign?.pixelID,
      tags: marketingState?.campaign?.tags,
      adsCreation: marketingState?.adsCreation,
      budget: marketingState?.budgeting,
      targeting: marketingState?.targeting || '0',
      adPlacement: marketingState?.adPlacement || '',
      companyId: globalState?.currentCompany || '',
      projectId: globalState?.currentProject || '',
      uid: globalState?.uid || '',
      status: 'request',
      isDraft: 'draft',
      isPaid: false,
      type: 'ads',
      category: 'facebook',
    };

    try {
      const res = await addDocumentFirebase('marketing_meta', adsData, globalState?.currentCompany);
      setAdsId(res);

      toast({
        title: 'success',
        description: 'Success save campaign, preparing to publish',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: `An error occurred: ${error}`,
        duration: 3000,
        status: 'error',
      });
    }
    marketingState?.setCampaign({
      campaignName: '',
      facebookLink: '',
      adAccount: '',
      instagramLink: '',
      pixelID: '',
      tags: '',
    });
    marketingState?.setAdsCreation({
      adName: '',
      websiteUrl: '',
      location: [],
      language: '',
      format: '',
      businessName: '',
      businessLogo: '',
      primaryText: '',
      mediaLink: [],
      headline: '',
      description: '',
      cta: '',
      carousel: [],
    });
    marketingState?.setBudgeting({
      dailyBudget: '',
      durationStart: '',
      durationEnd: '',
    });
    marketingState?.setAdPlacement({
      manualPlacement: false,
      audienceNetworkPlacement: [],
      messengerPlacement: [],
      feeds: [],
      storiesAndReels: [],
    });
    marketingState?.setTargeting({
      customAudience: '',
      ageStart: '',
      ageEnd: '',
      sex: '',
      detailedTargeting: '',
      devices: '',
    });

    setLoading(false);
    navigate('/marketing/meta/ads');
  };

  const checkDataCampaignName = async (campaignName) => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState?.currentCompany },
      { field: 'projectId', operator: '==', value: globalState?.currentProject },
      { field: 'campaignName', operator: '==', value: campaignName },
    ];

    const res = await getCollectionFirebase('marketing_meta', conditions);

    return res;
  };

  const handleDays = () => {
    const start = moment(marketingState?.budgeting?.durationStart);
    const end = moment(marketingState?.budgeting?.durationEnd);

    const dateCount = moment.duration(end?.diff(start));
    const daysCount = dateCount.asDays();

    setActiveDays(daysCount);
    setSliderValue(parseInt(daysCount));

    const totalBudget = parseInt(marketingState?.budgeting?.dailyBudget) * parseInt(daysCount) + parseInt(marketingState?.budgeting?.dailyBudget) * parseInt(daysCount) * 0.07;
    setTotalEstimateBudget(totalBudget);
  };

  // const handleSlider = (e) => {
  //   setSliderValue(e);

  //   const changeEndDate = moment(marketingState?.budgeting?.durationStart).clone().add(e,'days').format('YYYY-MM-DDTHH:mm');

  //   marketingState?.setBudgeting({ durationEnd: changeEndDate });
  // };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Announce',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };

  const handlePaymentMethodSelect = (method) => {
    setValue(method);
    const imageFind = banks.find((x) => x.name === method);
    setSelectedPaymentMethodImage(imageFind?.uri || 'https://sandbox.ipaymu.com/asset/images/logo_cc.png');
  };

  const getCampaignData = async () => {
    try {
      const res = await getSingleDocumentFirebase('marketing_meta', param?.id);

      setData(res);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (param?.type === 'edit') {
      getCampaignData();
    }

    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (param?.type === 'repaid') {
        try {
          const res = await getSingleDocumentFirebase('marketing_meta', param?.id);
          // console.log(res);

          setData(res);
          // setInput(res); // Initialize input state with existing data

          marketingState?.setCampaign({
            campaignName: `${res?.campaignName} repaid`,
            facebookLink: res?.facebookLink,
            adAccount: res?.adAccount,
            instagramLink: res?.instagramLink,
            pixelID: res?.pixelId,
            tags: res?.tags,
            objective: res?.objective,
          });
          marketingState?.setAdsCreation({
            adName: res?.adsCreation?.adName,
            websiteUrl: res?.adsCreation?.websiteUrl,
            location: res?.adsCreation?.location,
            language: res?.adsCreation?.language,
            format: res?.adsCreation?.format,
            businessName: res?.adsCreation?.businessName,
            businessLogo: res?.adsCreation?.businessLogo,
            primaryText: res?.adsCreation?.primaryText,
            mediaLink: res?.adsCreation?.mediaLink,
            headline: res?.adsCreation?.headline,
            description: res?.adsCreation?.description,
            cta: res?.adsCreation?.cta,
            carousel: res?.adsCreation?.carousel,
            mediaRatio: res?.adsCreation?.mediaRatio,
          });
          marketingState?.setBudgeting({
            dailyBudget: res?.budget?.dailyBudget,
            durationStart: res?.budget?.durationStart,
            durationEnd: res?.budget?.durationEnd,
          });
          marketingState?.setAdPlacement({
            manualPlacement: res?.adPlacement?.manualPlacement,
            audienceNetworkPlacement: res?.adPlacement?.audienceNetworkPlacement,
            messengerPlacement: res?.adPlacement?.messengerPlacement,
            feeds: res?.adPlacement?.feeds,
            storiesAndReels: res?.adPlacement?.storiesAndReels,
          });
          marketingState?.setTargeting({
            customAudience: res?.targeting?.customAudience,
            ageStart: res?.targeting?.ageStart,
            ageEnd: res?.targeting?.ageEnd,
            sex: res?.targeting?.sex,
            detailedTargeting: res?.targeting?.detailedTargeting,
            devices: res?.targeting?.devices,
          });
        } catch (error) {
          throw new Error(error);
        }
      }
    };

    fetchData();
  }, [param?.id, param?.type]);

  useEffect(() => {
    if (marketingState?.budgeting?.durationEnd !== '' || marketingState?.budgeting?.durationStart !== '') {
      handleDays();
    }

    return () => {};
  }, [marketingState?.budgeting?.durationStart, marketingState?.budgeting?.durationEnd, marketingState?.budgeting?.dailyBudget]);

  return (
    <Box mb='5'>
      <HStack>
        <BackButtons />
      </HStack>
      {param?.type !== 'repaid' && (
        <Stepper
          index={5}
          bg={'white'}
          p={5}
          borderRadius={'md'}
          shadow={'base'}
          my={5}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink='0'>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      )}

      <Box>
        {param?.type !== 'repaid' && <Heading textAlign='center'>Publish</Heading>}
        {param?.type === 'repaid' && <Heading textAlign='center'>Re Paid</Heading>}
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2 }}
          my={5}
          bg={'white'}
          p={5}>
          <Container
            alignSelf='start'
            mt='5'
            shadow='base'
            bg={'white'}
            p={5}
            borderRadius={'md'}>
            <Text
              align={'center'}
              fontWeight={500}>
              Campaign Final Input
            </Text>
            <Stack
              border={'1px'}
              borderColor={'gray.50'}
              shadow={'sm'}
              my={4}
              p={3}>
              <FormControl>
                <FormLabel>Campaign Name</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.campaign?.campaignName}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Ad Name</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.adsCreation?.adName}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Facebook Link</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.campaign?.facebookLink}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Instagram Link</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.campaign?.instagramLink}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Pixel ID</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.campaign?.pixelID}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Objective</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.campaign?.objective}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Language</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.adsCreation?.language}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Locations</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.adsCreation?.location?.map((x) => `${x.province}`)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Age</FormLabel>
                <HStack>
                  <Input
                    size={'sm'}
                    isDisabled
                    value={marketingState?.targeting?.ageStart}
                  />
                  <Text>-</Text>
                  <Input
                    size={'sm'}
                    isDisabled
                    value={marketingState?.targeting?.ageEnd}
                  />
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>Sex</FormLabel>
                <Input
                  size={'sm'}
                  isDisabled
                  value={marketingState?.targeting?.sex}
                />
              </FormControl>
              {param?.type !== 'repaid' && (
                <FormControl>
                  <FormLabel>Budget</FormLabel>
                  <Input
                    size={'sm'}
                    isDisabled
                    value={totalEstimateBudget}
                  />
                </FormControl>
              )}
            </Stack>

            {param?.type === 'edit' ? (
              <>
                {!isPaid ? (
                  <>
                    <Box align={'center'}>
                      <DynamicButton
                        isLoading={loading}
                        action={'create'}
                        title='Paid'
                        variant={'solid'}
                        // onClick={() => handlePublish()}
                        onClick={modalPayment.onOpen}
                      />
                    </Box>
                    <Box
                      align={'center'}
                      mt={5}>
                      <DynamicButton
                        isLoading={loading}
                        action={'create'}
                        title='Save Draft'
                        variant={'solid'}
                        onClick={() => handleDraft()}
                        // onClick={modalPayment.onOpen}
                      />
                    </Box>
                  </>
                ) : (
                  <Box align={'center'}>
                    <DynamicButton
                      isLoading={loading}
                      action={'create'}
                      title='Publish'
                      variant={'solid'}
                      onClick={() => handleDraft()}
                    />
                  </Box>
                )}
              </>
            ) : (
              <>
                {!isPaid ? (
                  <>
                    <Button
                      mt={5}
                      w='full'
                      // isLoading={loading}
                      colorScheme='green'
                      // onClick={() => handlePublish()}
                      onClick={modalPayment.onOpen}>
                      Proceed to payment
                    </Button>

                    {param?.type !== 'repaid' && (
                      <Button
                        mt={5}
                        w='full'
                        // isLoading={loading}
                        colorScheme='green'
                        onClick={() => handleDraftButton()}
                        // onClick={modalPayment.onOpen}
                      >
                        Save To Draft
                      </Button>
                    )}
                  </>
                ) : (
                  <Box align={'center'}>
                    <DynamicButton
                      isLoading={loading}
                      action={'create'}
                      title='Publish'
                      variant={'solid'}
                      onClick={() => handleDraft()}
                    />
                  </Box>
                )}
              </>
            )}
          </Container>

          <Box
            border={'1px'}
            borderColor={'gray.50'}
            borderRadius={'md'}
            shadow={'base'}>
            <Center p={5}>
              {selectedPreview === '' ? (
                <Stack align={'center'}>
                  <Box color='gray.400'>
                    <MdImportantDevices size={100} />
                  </Box>
                  <Text fontWeight={500}>Ad Preview will be displayed here</Text>
                  <Text fontSize={14}>Preview displayed based on adplacement that you choose</Text>
                </Stack>
              ) : selectedPreview === 'Facebook Feed' ? (
                <FacebookPost
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Feed' || selectedPreview === 'Instagram profile feed' || selectedPreview === 'Instagram Explore' ? (
                <InstagramPost
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook Marketplace' ? (
                <FacebookMarketplace
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Messenger inbox' ? (
                <MessengerInbox
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook video feeds' ? (
                <FacebookVideoFeeds
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook video feeds' ? (
                <FacebookVideoFeeds
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Reels' ? (
                <InstagramtReels
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Stories' ? (
                <InstagramtStories
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook Stories' ? (
                <FacebookStories
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Facebook Reels' ? (
                <FacebookReels
                  marketingState={marketingState}
                  data={data}
                />
              ) : selectedPreview === 'Instagram Explore home' ? (
                <InstagramExplore
                  marketingState={marketingState}
                  data={data}
                />
              ) : null}
            </Center>

            <Box
              align={'center'}
              py={5}>
              <Divider />
            </Box>

            <Box
              py={3}
              align={'center'}>
              <Text fontWeight={500}>Preview Selections</Text>
              {marketingState?.adPlacement?.feeds?.length > 0 || marketingState?.adPlacement?.storiesAndReels?.length > 0 ? (
                <>
                  <SimpleGrid
                    columns={4}
                    spacing={2}
                    p={3}>
                    {marketingState?.adPlacement?.feeds?.map((x, i) => (
                      <Stack
                        key={i}
                        cursor={'pointer'}
                        p={2}
                        border={'1px'}
                        align={'center'}
                        borderRadius={'md'}
                        shadow={'sm'}
                        borderColor={selectedPreview === x ? 'blue.300' : 'gray.50'}
                        onClick={() => setSelectedPreview(x)}>
                        <HiOutlineDeviceMobile size={20} />
                        <Text align={'center'}>{x}</Text>
                      </Stack>
                    ))}
                    {marketingState?.adPlacement?.storiesAndReels?.map((x, i) => (
                      <Stack
                        key={i}
                        cursor={'pointer'}
                        p={2}
                        border={'1px'}
                        align={'center'}
                        borderRadius={'md'}
                        shadow={'sm'}
                        borderColor={selectedPreview === x ? 'blue.300' : 'gray.50'}
                        onClick={() => setSelectedPreview(x)}>
                        <HiOutlineDeviceMobile size={20} />
                        <Text align={'center'}>{x}</Text>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </>
              ) : (
                <Text>Select placement by check the box in ad placement section</Text>
              )}
            </Box>

            {param?.type === 'repaid' && (
              <Box
                pt='5'
                mt='5'
                shadow='base'
                borderColor='gray'
                bg={'whitesmoke'}
                borderRadius={'md'}>
                <Text
                  textAlign='center'
                  fontWeight={700}>
                  Set Date And Budget{' '}
                </Text>

                <Box>
                  <Container
                    p='5'
                    mt='5'
                    shadow='base'
                    bg={'white'}
                    borderRadius={'md'}>
                    <FormControl>
                      <FormLabel>Daily Budget</FormLabel>
                      <HStack w={'100%'}>
                        <Input
                          w={'80%'}
                          placeholder={'input Your Budget'}
                          value={marketingState?.budgeting?.dailyBudget}
                          onChange={(e) =>
                            marketingState?.setBudgeting({
                              dailyBudget: e.target.value,
                            })
                          }
                          onWheel={(e) => e.target.blur()}
                          type='number'
                        />
                        <Text w={'20%'}>/ day</Text>
                      </HStack>
                    </FormControl>
                    <FormControl my={3}>
                      <Stack spacing={1}>
                        <FormLabel>Duration</FormLabel>
                        <HStack>
                          <Box>
                            <Text>Start Date</Text>
                            <Input
                              placeholder={param?.type === 'repaid' ? data?.budgeting?.durationStart : null}
                              Value={marketingState?.budgeting?.durationStart}
                              onChange={(e) =>
                                marketingState?.setBudgeting({
                                  durationStart: e.target.value,
                                })
                              }
                              type='datetime-local'
                            />
                          </Box>
                          <Box>
                            <Text>End Date</Text>
                            <Input
                              placeholder={param?.type === 'repaid' ? data?.budgeting?.durationEnd : null}
                              Value={marketingState?.budgeting?.durationEnd}
                              onChange={(e) =>
                                marketingState?.setBudgeting({
                                  durationEnd: e.target.value,
                                })
                              }
                              type='datetime-local'
                            />
                          </Box>
                        </HStack>
                      </Stack>
                    </FormControl>
                  </Container>
                </Box>
              </Box>
            )}
          </Box>
        </SimpleGrid>
      </Box>

      <Modal
        isOpen={modalPayment.isOpen}
        onClose={modalPayment.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {payData.external_id === undefined ? (
              <Stack>
                <Box align={'center'}>
                  <Text>Budget Estimation within {parseInt(activeDays)} active days</Text>
                  <Text fontSize={12}>*include 7% fee</Text>
                  <HStack
                    justifyContent={'center'}
                    my={5}>
                    <Heading size={'sm'}>Rp</Heading>
                    <Heading>{isNaN(totalEstimateBudget) || totalEstimateBudget < 0 ? 0 : formatFrice(totalEstimateBudget)}</Heading>
                  </HStack>
                  <Text fontSize={12}>
                    {moment(marketingState?.budgeting?.durationStart).format('LL')} to {moment(marketingState?.budgeting?.durationEnd).format('LL')}
                  </Text>
                </Box>

                <>
                  <RadioGroup
                    value={value}
                    onChange={handlePaymentMethodSelect}
                    m='2'>
                    <Stack
                      spacing={4}
                      my={4}>
                      <Stack spacing={2}>
                        <Text
                          fontWeight={500}
                          fontSize='sm'>
                          Choose Virtual Account :
                        </Text>
                        <SimpleGrid
                          columns={[2, null, 4]}
                          spacing={3}
                          align='start'>
                          {banks?.map((x, index) => (
                            <Radio
                              key={index}
                              value={x.name}>
                              <Image
                                src={x?.uri}
                                w='80px'
                              />
                            </Radio>
                          ))}
                        </SimpleGrid>
                      </Stack>

                      {/* <Stack spacing={2}>
                        <Text fontWeight={500} fontSize="sm">
                          Choose Credit Card :
                        </Text>
                        <Radio value={'CREDIT_CARD'}>
                          <Image
                            src={
                              'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
                            }
                            w="90px"
                          />
                        </Radio>
                      </Stack> */}
                    </Stack>
                  </RadioGroup>

                  {isLoad ? (
                    <Button
                      my={5}
                      w='full'
                      colorScheme='blue'
                      isLoading>
                      Pay
                    </Button>
                  ) : (
                    <Button
                      my={5}
                      w='full'
                      colorScheme='blue'
                      onClick={() => handlePay()}>
                      Pay
                    </Button>
                  )}
                </>
              </Stack>
            ) : (
              <>
                <Stack>
                  <HStack
                    w='full'
                    px={5}>
                    <Image
                      src={selectedPaymentMethodImage}
                      alt={payData?.bank_code}
                      w='80px'
                      borderRadius='xl'
                    />

                    <Spacer />

                    <Text
                      fontSize='sm'
                      fontWeight={'bold'}
                      color={payData?.status === 'pending' ? 'red.600' : 'green.600'}
                      textTransform='uppercase'>
                      {payData?.status}
                    </Text>
                  </HStack>

                  <Box
                    bg='white'
                    px={5}>
                    <Text>No. Virtual Account : </Text>
                    <Divider my={2} />
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'>
                      <Text
                        fontSize={20}
                        color='gray.500'>
                        {payData ? payData?.account_number : 'none'}
                      </Text>
                      <Text
                        color='blue.600'
                        cursor='pointer'
                        onClick={() => handleCopy(payData?.account_number)}>
                        SALIN
                      </Text>
                    </Box>
                    <Divider my={2} />
                    <HStack py={1}>
                      {/* <Text fontSize="sm" textTransform={'uppercase'}>{store}</Text> */}
                      <Spacer />
                      <Text fontSize='sm'>Rp. {payData?.expected_amount}</Text>
                    </HStack>
                    <Divider my={2} />

                    <Text
                      fontSize={10}
                      color='gray.600'>
                      Proses verifikasi otomatis kurang dari 10 menit setelah pembayaran berhasil
                    </Text>
                    <Spacer />
                    <Text
                      fontSize={10}
                      color='gray.600'>
                      Bayar ke Virtual Account di atas sebelum membuat donasi baru dengan Virtual account agar nomor tetap sama.
                    </Text>
                  </Box>
                  <Box
                    bg='white'
                    p={5}>
                    <Text
                      fontSize={10}
                      color='gray.600'>
                      Petunjuk Transfer mBanking :
                    </Text>
                    <Divider />
                    <Text
                      fontSize={10}
                      color='gray.600'>
                      1. Login ke mBanking-mu, pilih Bayar, kemudian cari Xendit / {payData.merchant_code} Virtual Account
                    </Text>
                    <Text
                      fontSize={10}
                      color='gray.600'>
                      2. Masukkan nomor Virtual Account
                    </Text>
                    <Text
                      fontSize={10}
                      color='gray.600'>
                      3. Pastikan nama dan nominal bayar benar
                    </Text>
                    <Text
                      fontSize={10}
                      fontWeight={500}
                      color='red.500'>
                      4. Jika kamu sudah melakukan pembayaran, klik tombol done
                    </Text>
                  </Box>

                  <HStack
                    alignItems='center'
                    justifyContent='center'>
                    <Box>
                      <DynamicButton
                        action={'delete'}
                        title='Cancel'
                        variant={'solid'}
                        onClick={() => handleCancelPay()}
                      />
                    </Box>

                    <Box>
                      <DynamicButton
                        action={'create'}
                        title='Done'
                        variant={'solid'}
                        onClick={() => handleDonePayment()}
                      />
                    </Box>
                  </HStack>
                </Stack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* <ModalBudgetPayment modalPayment={modalPayment} activeDays={activeDays} totalEstimateBudget={totalEstimateBudget} marketingState={marketingState} sliderValue={sliderValue} globalState={globalState} handleSlider={handleSlider} /> */}
    </Box>
  );
}

export default MarketingMetaAddPublishPage;
