import { create } from 'zustand';

const useMarketingStore = create((set, get) => ({
  //initial state
  id: '',
  campaign: {
    campaignName: '',
    facebookLink: '',
    adAccount: '',
    instagramLink: '',
    pixelID: '',
    tags: '',
    objective: '',
  },
  adsCreation: {
    adName: '',
    websiteUrl: '',
    location: [],
    language: '',
    format: '',
    businessName: '',
    businessLogo: '',
    primaryText: '',
    mediaLink: [],
    headline: '',
    description: '',
    cta: '',
    carousel: [],
    mediaRatio: 1 / 1,
  },

  adPlacement: {
    manualPlacement: false,
    audienceNetworkPlacement: [],
    messengerPlacement: [],
    feeds: [],
    storiesAndReels: [],
  },

  targeting: {
    customAudience: [],
    ageStart: '',
    ageEnd: '',
    sex: '',
    detailedTargeting: '',
    devices: '',
  },

  budgeting: {
    dailyBudget: '',
    durationStart: '',
    durationEnd: '',
  },

  //function dispatch reducer
  setId: (data) => {
    set({
      id: data,
    });
  },

  setCampaign: (data) => {
    set({
      campaign: {
        ...get().campaign,
        ...data,
      },
    });
  },

  setAdsCreation: (data) => {
    set({
      adsCreation: {
        ...get().adsCreation,
        ...data,
      },
    });
  },

  setAdPlacement: (data) => {
    set({
      adPlacement: {
        ...get().adPlacement,
        ...data,
      },
    });
  },

  setTargeting: (data) => {
    set({
      targeting: {
        ...get().targeting,
        ...data,
      },
    });
  },

  setBudgeting: (data) => {
    set({
      budgeting: {
        ...get().budgeting,
        ...data,
      },
    });
  },

  //   setFunctionAddData: (func) => {
  //     set({
  //       setData: func,
  //     });
  //   },
  //   setTaskData: (data) => {
  //     set({
  //       taskData: {
  //         ...get().taskData,
  //         ...data,
  //       },
  //     });
  //   },
  //   setNewCardId: (str) => {
  //     set({
  //       newCardId: str,
  //     });
  //   },
  //   resetTaskData: () => {
  //     set({
  //       taskData: {},
  //     });
  //   },
  //   setFilterData: (data) => {
  //     set({
  //       filterData: {
  //         ...get().filterData,
  //         ...data,
  //       },
  //     });
  //   },
  //   resetFilterData: () => {
  //     set({
  //       filterData: {
  //         asignee: "",
  //         label: "",
  //         category: "",
  //       },
  //     });
  //   },
}));

export default useMarketingStore;
