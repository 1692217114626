export const statisticsGrapesJs = () => {
  const simple=`<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-600">Transactions every 24 hours</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">44 million</dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-600">Assets under holding</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">$119 trillion</dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-600">New users annually</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">46,000</dd>
        </div>
      </dl>
    </div>
  </div>
  `;
  const simpleOnDark=`<div class="bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-400">Transactions every 24 hours</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">44 million</dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-400">Assets under holding</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">$119 trillion</dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt class="text-base leading-7 text-gray-400">New users annually</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">46,000</dd>
        </div>
      </dl>
    </div>
  </div>
  `;
  const simpleGrid=`<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="text-center">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Trusted by creators worldwide</h2>
          <p class="mt-4 text-lg leading-8 text-gray-600">Lorem ipsum dolor sit amet consect adipisicing possimus.</p>
        </div>
        <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Creators on the platform</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">8,000+</dd>
          </div>
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Flat platform fee</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">3%</dd>
          </div>
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Uptime guarantee</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">99.9%</dd>
          </div>
          <div class="flex flex-col bg-gray-400/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-600">Paid out to creators</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">$70M</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  `;
  const simpleGridOnDark=`<div class="bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="text-center">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Trusted by creators worldwide</h2>
          <p class="mt-4 text-lg leading-8 text-gray-300">Lorem ipsum dolor sit amet consect adipisicing possimus.</p>
        </div>
        <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300">Creators on the platform</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">8,000+</dd>
          </div>
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300">Flat platform fee</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">3%</dd>
          </div>
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300">Uptime guarantee</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">99.9%</dd>
          </div>
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300">Paid out to creators</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">$70M</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  `;
  const withBackgroundImage=`<div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
    <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80&blend=111827&blend-mode=multiply&sat=-100&exp=15" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
    <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
      <div class="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10" aria-hidden="true">
        <div class="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
        <h2 class="text-base font-semibold leading-8 text-indigo-400">Our track record</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Trusted by thousands of creators&nbsp;worldwide</p>
        <p class="mt-6 text-lg leading-8 text-gray-300">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p>
      </div>
      <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
        <div class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-6">Creators on the platform</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight">8,000+</dd>
        </div>
        <div class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-6">Flat platform fee</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight">3%</dd>
        </div>
        <div class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-6">Uptime guarantee</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight">99.9%</dd>
        </div>
        <div class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-6">Paid out to creators</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight">$70M</dd>
        </div>
      </dl>
    </div>
  </div>
  `;
  const splitWithImage=`<div class="relative bg-white">
    <img class="h-56 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2" src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80" alt="">
    <div class="mx-auto grid max-w-7xl lg:grid-cols-2">
      <div class="px-6 pb-24 pt-16 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32">
        <div class="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
          <h2 class="text-base font-semibold leading-8 text-indigo-600">Our track record</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Trusted by thousands of creators&nbsp;worldwide</p>
          <p class="mt-6 text-lg leading-8 text-gray-600">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p>
          <dl class="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
            <div class="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
              <dt class="text-sm leading-6 text-gray-600">Creators on the platform</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">8,000+</dd>
            </div>
            <div class="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
              <dt class="text-sm leading-6 text-gray-600">Flat platform fee</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">3%</dd>
            </div>
            <div class="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
              <dt class="text-sm leading-6 text-gray-600">Uptime guarantee</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">99.9%</dd>
            </div>
            <div class="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
              <dt class="text-sm leading-6 text-gray-600">Paid out to creators</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">$70M</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
  `;
  const timeline=`<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
        <div>
          <time datetime="2021-08" class="flex items-center text-sm font-semibold leading-6 text-indigo-600">
            <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
              <circle cx="2" cy="2" r="2" fill="currentColor" />
            </svg>
            Aug 2021
            <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
          </time>
          <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Founded company</p>
          <p class="mt-1 text-base leading-7 text-gray-600">Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.</p>
        </div>
        <div>
          <time datetime="2021-12" class="flex items-center text-sm font-semibold leading-6 text-indigo-600">
            <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
              <circle cx="2" cy="2" r="2" fill="currentColor" />
            </svg>
            Dec 2021
            <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
          </time>
          <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Secured $65m in funding</p>
          <p class="mt-1 text-base leading-7 text-gray-600">Provident quia ut esse. Vero vel eos repudiandae aspernatur. Cumque minima impedit sapiente a architecto nihil.</p>
        </div>
        <div>
          <time datetime="2022-02" class="flex items-center text-sm font-semibold leading-6 text-indigo-600">
            <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
              <circle cx="2" cy="2" r="2" fill="currentColor" />
            </svg>
            Feb 2022
            <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
          </time>
          <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Released beta</p>
          <p class="mt-1 text-base leading-7 text-gray-600">Sunt perspiciatis incidunt. Non necessitatibus aliquid. Consequatur ut officiis earum eum quia facilis. Hic deleniti dolorem quia et.</p>
        </div>
        <div>
          <time datetime="2022-12" class="flex items-center text-sm font-semibold leading-6 text-indigo-600">
            <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
              <circle cx="2" cy="2" r="2" fill="currentColor" />
            </svg>
            Dec 2022
            <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
          </time>
          <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Global launch of product</p>
          <p class="mt-1 text-base leading-7 text-gray-600">Ut ipsa sint distinctio quod itaque nam qui. Possimus aut unde id architecto voluptatem hic aut pariatur velit.</p>
        </div>
      </div>
    </div>
  </div>
  `;
  const stepped=`<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">We approach the workplace as something that adds to our lives and adds value to world.</h2>
        <p class="mt-6 text-base leading-7 text-gray-600">Diam nunc lacus lacus aliquam turpis enim. Eget hac velit est euismod lacus. Est non placerat nam arcu. Cras purus nibh cursus sit eu in id. Integer vel nibh.</p>
      </div>
      <div class="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
        <div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
          <p class="flex-none text-3xl font-bold tracking-tight text-gray-900">250k</p>
          <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p class="text-lg font-semibold tracking-tight text-gray-900">Users on the platform</p>
            <p class="mt-2 text-base leading-7 text-gray-600">Vel labore deleniti veniam consequuntur sunt nobis.</p>
          </div>
        </div>
        <div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
          <p class="flex-none text-3xl font-bold tracking-tight text-white">$8.9 billion</p>
          <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p class="text-lg font-semibold tracking-tight text-white">We’re proud that our customers have made over $8 billion in total revenue.</p>
            <p class="mt-2 text-base leading-7 text-gray-400">Eu duis porta aliquam ornare. Elementum eget magna egestas.</p>
          </div>
        </div>
        <div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
          <p class="flex-none text-3xl font-bold tracking-tight text-white">401,093</p>
          <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p class="text-lg font-semibold tracking-tight text-white">Transactions this year</p>
            <p class="mt-2 text-base leading-7 text-indigo-200">Eu duis porta aliquam ornare. Elementum eget magna egestas. Eu duis porta aliquam ornare.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
  const twoColumnDescriptionOnDark=`<div class="bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <p class="text-base font-semibold leading-7 text-indigo-400">Deploy faster</p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">A better workflow</h1>
        <div class="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
          <div>
            <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
            <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas.</p>
          </div>
          <div>
            <p>Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim eget. Est augue maecenas risus nulla ultrices congue nunc tortor. Enim et nesciunt doloremque nesciunt voluptate.</p>
            <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
          </div>
        </div>
        <dl class="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
          <div class="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
            <dt class="text-base leading-7 text-gray-300">Founded</dt>
            <dd class="text-3xl font-semibold tracking-tight text-white">2021</dd>
          </div>
          <div class="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
            <dt class="text-base leading-7 text-gray-300">Employees</dt>
            <dd class="text-3xl font-semibold tracking-tight text-white">37</dd>
          </div>
          <div class="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
            <dt class="text-base leading-7 text-gray-300">Countries</dt>
            <dd class="text-3xl font-semibold tracking-tight text-white">12</dd>
          </div>
          <div class="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6">
            <dt class="text-base leading-7 text-gray-300">Raised</dt>
            <dd class="text-3xl font-semibold tracking-tight text-white">$25M</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  `;
  const withDescription=`<div class="bg-white py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our mission</h2>
        <div class="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div class="lg:w-full lg:max-w-2xl lg:flex-auto">
            <p class="text-xl leading-8 text-gray-600">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
            <p class="mt-10 max-w-xl text-base leading-7 text-gray-700">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
          </div>
          <div class="lg:flex lg:flex-auto lg:justify-center">
            <dl class="w-64 space-y-8 xl:w-80">
              <div class="flex flex-col-reverse gap-y-4">
                <dt class="text-base leading-7 text-gray-600">Transactions every 24 hours</dt>
                <dd class="text-5xl font-semibold tracking-tight text-gray-900">44 million</dd>
              </div>
              <div class="flex flex-col-reverse gap-y-4">
                <dt class="text-base leading-7 text-gray-600">Assets under holding</dt>
                <dd class="text-5xl font-semibold tracking-tight text-gray-900">$119 trillion</dd>
              </div>
              <div class="flex flex-col-reverse gap-y-4">
                <dt class="text-base leading-7 text-gray-600">New users annually</dt>
                <dd class="text-5xl font-semibold tracking-tight text-gray-900">46,000</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;

  const casperStats1 = 
  `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 -mt-72 w-148 h-148 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="absolute top-0 right-0 mr-112 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative">
            <div class="max-w-4xl mx-auto text-center mb-16">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-7xl font-medium text-white tracking-tight mb-3">Achievements So far</h2>
              <p class="max-w-md mx-auto text-lg text-gray-500">Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus..</p>
            </div>
            <div class="max-w-sm lg:max-w-none mx-auto">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full lg:w-1/3 px-4 mb-10 pb-10 lg:mb-0 lg:pb-0 border-b lg:border-b-0 lg:border-r border-white border-opacity-20">
                  <div class="flex items-center lg:justify-center">
                    <img class="block w-16 xl:w-20 h-16 xl:h-20" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-3.png" alt="">
                    <div class="ml-5 xl:ml-8">
                      <span class="block text-4xl xl:text-6xl font-semibold text-gray-100">120</span>
                      <span class="sm:text-lg tracking-tight text-gray-500">Partners Joined</span>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-4 mb-10 pb-10 lg:mb-0 lg:pb-0 border-b lg:border-b-0 lg:border-r border-white border-opacity-20">
                  <div class="flex items-center lg:justify-center">
                    <img class="block w-16 xl:w-20 h-16 xl:h-20" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-2.png" alt="">
                    <div class="ml-5 xl:ml-8">
                      <span class="block text-4xl xl:text-6xl font-semibold text-gray-100">300+</span>
                      <span class="sm:text-lg tracking-tight text-gray-500">Projects Done</span>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-4">
                  <div class="flex items-center lg:justify-center">
                    <img class="block w-16 xl:w-20 h-16 xl:h-20" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-1.png" alt="">
                    <div class="ml-5 xl:ml-8">
                      <span class="block text-4xl xl:text-6xl font-semibold text-gray-100">10+</span>
                      <span class="sm:text-lg tracking-tight text-gray-500">Years of Experience</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const casperStats2 =           
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center -mx-4">
            <div class="relative z-10 w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
              <div class="max-w-md xl:max-w-xl mx-auto">
                <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-3">Stunning UI Library for Start Up</h2>
                <p class="max-w-sm xl:max-w-md text-lg text-gray-500 mb-16">Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus.</p>
                <a class="inline-flex h-14 py-2 px-7 mb-5 items-center justify-center text-base font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full" href="#">
                  <span class="mr-2">LOGIN NOW</span>
                  <svg width="25" height="24" viewbox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.4199 6.62C18.3185 6.37565 18.1243 6.18147 17.8799 6.08C17.7597 6.02876 17.6306 6.00158 17.4999 6H7.49994C7.23472 6 6.98037 6.10536 6.79283 6.29289C6.6053 6.48043 6.49994 6.73478 6.49994 7C6.49994 7.26522 6.6053 7.51957 6.79283 7.70711C6.98037 7.89464 7.23472 8 7.49994 8H15.0899L6.78994 16.29C6.69621 16.383 6.62182 16.4936 6.57105 16.6154C6.52028 16.7373 6.49414 16.868 6.49414 17C6.49414 17.132 6.52028 17.2627 6.57105 17.3846C6.62182 17.5064 6.69621 17.617 6.78994 17.71C6.8829 17.8037 6.9935 17.8781 7.11536 17.9289C7.23722 17.9797 7.36793 18.0058 7.49994 18.0058C7.63195 18.0058 7.76266 17.9797 7.88452 17.9289C8.00638 17.8781 8.11698 17.8037 8.20994 17.71L16.4999 9.41V17C16.4999 17.2652 16.6053 17.5196 16.7928 17.7071C16.9804 17.8946 17.2347 18 17.4999 18C17.7652 18 18.0195 17.8946 18.207 17.7071C18.3946 17.5196 18.4999 17.2652 18.4999 17V7C18.4984 6.86932 18.4712 6.74022 18.4199 6.62Z" fill="currentColor"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="relative w-full lg:w-1/2 px-4">
              <div class="absolute top-0 right-0 -mt-40 -mr-20 w-148 h-148 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl"></div>
              <div class="absolute top-0 right-0 -mt-40 mr-60 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl"></div>
              <div class="relative max-w-md lg:max-w-lg xl:max-w-lg mx-auto lg:mr-0">
                <div class="flex flex-wrap -mx-4 -mb-20">
                  <div class="w-full sm:w-1/2 px-4 mb-20">
                    <div>
                      <span class="block text-gray-500 tracking-tight">Purchase</span>
                      <span class="block text-4xl sm:text-5xl font-semibold text-white">$150M</span>
                      <div class="flex items-center text-sm">
                        <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.2824 9.5325L9.5324 13.2825C9.46107 13.3508 9.37696 13.4043 9.2849 13.44C9.1023 13.515 8.89749 13.515 8.7149 13.44C8.62283 13.4043 8.53872 13.3508 8.4674 13.2825L4.71739 9.5325C4.64747 9.46257 4.592 9.37956 4.55415 9.28819C4.51631 9.19682 4.49683 9.0989 4.49683 9C4.49683 8.80027 4.57617 8.60873 4.71739 8.4675C4.85862 8.32627 5.05017 8.24693 5.2499 8.24693C5.44962 8.24693 5.64117 8.32627 5.7824 8.4675L8.2499 10.9425V5.25C8.2499 5.05109 8.32891 4.86032 8.46957 4.71967C8.61022 4.57902 8.80098 4.5 8.9999 4.5C9.19881 4.5 9.38957 4.57902 9.53023 4.71967C9.67088 4.86032 9.7499 5.05109 9.7499 5.25V10.9425L12.2174 8.4675C12.2871 8.3972 12.3701 8.34141 12.4615 8.30333C12.5529 8.26526 12.6509 8.24565 12.7499 8.24565C12.8489 8.24565 12.9469 8.26526 13.0383 8.30333C13.1297 8.34141 13.2127 8.3972 13.2824 8.4675C13.3527 8.53722 13.4085 8.62017 13.4466 8.71157C13.4846 8.80296 13.5042 8.90099 13.5042 9C13.5042 9.09901 13.4846 9.19704 13.4466 9.28843C13.4085 9.37983 13.3527 9.46278 13.2824 9.5325Z" fill="#E10808"></path>
                        </svg>
                        <span class="font-medium text-red-800">10.5%</span>
                        <span class="ml-2 text-white">from last year</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-20">
                    <div>
                      <span class="block text-gray-500 tracking-tight">Return</span>
                      <span class="block text-4xl sm:text-5xl font-semibold text-white">$19,110</span>
                      <div class="flex items-center text-sm">
                        <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.2824 8.4675L9.5324 4.7175C9.46107 4.64922 9.37696 4.59569 9.2849 4.56C9.1023 4.48499 8.89749 4.48499 8.7149 4.56C8.62283 4.59569 8.53872 4.64922 8.4674 4.7175L4.71739 8.4675C4.64747 8.53743 4.592 8.62044 4.55415 8.71181C4.51631 8.80318 4.49683 8.9011 4.49683 9C4.49683 9.19973 4.57617 9.39127 4.71739 9.5325C4.85862 9.67373 5.05017 9.75307 5.2499 9.75307C5.44962 9.75307 5.64117 9.67373 5.7824 9.5325L8.2499 7.0575V12.75C8.2499 12.9489 8.32891 13.1397 8.46957 13.2803C8.61022 13.421 8.80098 13.5 8.9999 13.5C9.19881 13.5 9.38957 13.421 9.53023 13.2803C9.67088 13.1397 9.7499 12.9489 9.7499 12.75V7.0575L12.2174 9.5325C12.2871 9.6028 12.3701 9.65859 12.4615 9.69667C12.5529 9.73474 12.6509 9.75435 12.7499 9.75435C12.8489 9.75435 12.9469 9.73474 13.0383 9.69667C13.1297 9.65859 13.2127 9.6028 13.2824 9.5325C13.3527 9.46278 13.4085 9.37983 13.4466 9.28843C13.4846 9.19704 13.5042 9.09901 13.5042 9C13.5042 8.90099 13.4846 8.80296 13.4466 8.71157C13.4085 8.62017 13.3527 8.53722 13.2824 8.4675Z" fill="#0BB678"></path>
                        </svg>
                        <span class="font-medium text-green-800">2.5%</span>
                        <span class="ml-2 text-white">from last month</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-20">
                    <div>
                      <span class="block text-gray-500 tracking-tight">Sales</span>
                      <span class="block text-4xl sm:text-5xl font-semibold text-white">$22,901</span>
                      <div class="flex items-center text-sm">
                        <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.2824 8.4675L9.5324 4.7175C9.46107 4.64922 9.37696 4.5957 9.2849 4.56C9.1023 4.48499 8.89749 4.48499 8.7149 4.56C8.62283 4.5957 8.53872 4.64922 8.4674 4.7175L4.71739 8.4675C4.64747 8.53743 4.592 8.62045 4.55415 8.71181C4.51631 8.80318 4.49683 8.90111 4.49683 9C4.49683 9.19973 4.57617 9.39127 4.71739 9.5325C4.85862 9.67373 5.05017 9.75307 5.2499 9.75307C5.44962 9.75307 5.64117 9.67373 5.7824 9.5325L8.2499 7.0575V12.75C8.2499 12.9489 8.32891 13.1397 8.46957 13.2803C8.61022 13.421 8.80098 13.5 8.9999 13.5C9.19881 13.5 9.38957 13.421 9.53023 13.2803C9.67088 13.1397 9.7499 12.9489 9.7499 12.75V7.0575L12.2174 9.5325C12.2871 9.6028 12.3701 9.65859 12.4615 9.69667C12.5529 9.73475 12.6509 9.75435 12.7499 9.75435C12.8489 9.75435 12.9469 9.73475 13.0383 9.69667C13.1297 9.65859 13.2127 9.6028 13.2824 9.5325C13.3527 9.46278 13.4085 9.37983 13.4466 9.28843C13.4846 9.19704 13.5042 9.09901 13.5042 9C13.5042 8.90099 13.4846 8.80296 13.4466 8.71157C13.4085 8.62017 13.3527 8.53722 13.2824 8.4675Z" fill="#0BB678"></path>
                        </svg>
                        <span class="font-medium text-green-800">12.8%</span>
                        <span class="ml-2 text-white">from last month</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2 px-4 mb-20">
                    <div>
                      <span class="block text-gray-500 tracking-tight">Marketing</span>
                      <span class="block text-4xl sm:text-5xl font-semibold text-white">$10,451</span>
                      <div class="flex items-center text-sm">
                        <svg width="18" height="18" viewbox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.2824 8.4675L9.5324 4.7175C9.46107 4.64922 9.37696 4.5957 9.2849 4.56C9.1023 4.48499 8.89749 4.48499 8.7149 4.56C8.62283 4.5957 8.53872 4.64922 8.4674 4.7175L4.71739 8.4675C4.64747 8.53743 4.592 8.62045 4.55415 8.71181C4.51631 8.80318 4.49683 8.90111 4.49683 9C4.49683 9.19973 4.57617 9.39127 4.71739 9.5325C4.85862 9.67373 5.05017 9.75307 5.2499 9.75307C5.44962 9.75307 5.64117 9.67373 5.7824 9.5325L8.2499 7.0575V12.75C8.2499 12.9489 8.32891 13.1397 8.46957 13.2803C8.61022 13.421 8.80098 13.5 8.9999 13.5C9.19881 13.5 9.38957 13.421 9.53023 13.2803C9.67088 13.1397 9.7499 12.9489 9.7499 12.75V7.0575L12.2174 9.5325C12.2871 9.6028 12.3701 9.65859 12.4615 9.69667C12.5529 9.73475 12.6509 9.75435 12.7499 9.75435C12.8489 9.75435 12.9469 9.73475 13.0383 9.69667C13.1297 9.65859 13.2127 9.6028 13.2824 9.5325C13.3527 9.46278 13.4085 9.37983 13.4466 9.28843C13.4846 9.19704 13.5042 9.09901 13.5042 9C13.5042 8.90099 13.4846 8.80296 13.4466 8.71157C13.4085 8.62017 13.3527 8.53722 13.2824 8.4675Z" fill="#0BB678"></path>
                        </svg>
                        <span class="font-medium text-green-800">20.5%</span>
                        <span class="ml-2 text-white">from last month</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const casperStats3 =           
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center -mx-4">
            <div class="relative z-10 w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
              <div class="max-w-md lg:max-w-xl mx-auto">
                <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-3">Stunning UI Library for Start Up</h2>
                <p class="max-w-md text-lg text-gray-500 mb-24">Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus.</p>
                <div class="flex flex-wrap">
                  <div class="w-full lg:w-1/2 px-4 mb-10 pb-10 lg:mb-0 lg:pb-0 border-b lg:border-b-0 lg:border-r border-white border-opacity-20">
                    <div class="flex items-center lg:justify-center">
                      <img class="block w-16 h-16" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-3.png" alt="">
                      <div class="ml-6">
                        <span class="block text-4xl font-medium text-gray-100">99%</span>
                        <span class="sm:text-lg tracking-tight text-gray-500">Partners Joined</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:w-1/2 px-4">
                    <div class="flex items-center lg:justify-center">
                      <img class="block w-16 h-16" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-2.png" alt="">
                      <div class="ml-6">
                        <span class="block text-4xl font-medium text-gray-100">300+</span>
                        <span class="sm:text-lg tracking-tight text-gray-500">Projects Done</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative w-full lg:w-1/2 px-4">
              <div class="absolute top-0 right-0 -mt-72 w-148 h-148 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl"></div>
              <div class="absolute top-0 right-0 mr-64 -mt-64 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl"></div>
              <img class="relative block w-full max-w-md xl:max-w-lg mx-auto lg:mr-0" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/dashboard.png" alt="">
            </div>
          </div>
        </div>
      </section>`

  const casperStats4 =           
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 right-0 -mt-40 -mr-20 w-148 h-148 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl"></div>
        <div class="absolute top-0 right-0 mr-60 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl"></div>
        <div class="container mx-auto px-4">
          <div class="relative max-w-lg lg:max-w-8xl mx-auto px-8 py-20 sm:px-20 rounded-3xl overflow-hidden">
            <img class="absolute top-0 left-0 w-full h-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/bg-gradient.png" alt="">
            <div class="flex flex-wrap items-center -mx-4">
              <div class="relative z-10 w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
                <div class="max-w-md xl:max-w-xl mx-auto">
                  <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-black tracking-tight mb-3">Our Targets</h2>
                  <p class="max-w-sm xl:max-w-md text-lg text-gray-900 mb-16">Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus.</p>
                  <a class="inline-flex h-14 py-2 px-7 mb-5 items-center justify-center text-base font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full" href="#">
                    <span class="mr-2">LOGIN NOW</span>
                    <svg width="25" height="24" viewbox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.4199 6.62C18.3185 6.37565 18.1243 6.18147 17.8799 6.08C17.7597 6.02876 17.6306 6.00158 17.4999 6H7.49994C7.23472 6 6.98037 6.10536 6.79283 6.29289C6.6053 6.48043 6.49994 6.73478 6.49994 7C6.49994 7.26522 6.6053 7.51957 6.79283 7.70711C6.98037 7.89464 7.23472 8 7.49994 8H15.0899L6.78994 16.29C6.69621 16.383 6.62182 16.4936 6.57105 16.6154C6.52028 16.7373 6.49414 16.868 6.49414 17C6.49414 17.132 6.52028 17.2627 6.57105 17.3846C6.62182 17.5064 6.69621 17.617 6.78994 17.71C6.8829 17.8037 6.9935 17.8781 7.11536 17.9289C7.23722 17.9797 7.36793 18.0058 7.49994 18.0058C7.63195 18.0058 7.76266 17.9797 7.88452 17.9289C8.00638 17.8781 8.11698 17.8037 8.20994 17.71L16.4999 9.41V17C16.4999 17.2652 16.6053 17.5196 16.7928 17.7071C16.9804 17.8946 17.2347 18 17.4999 18C17.7652 18 18.0195 17.8946 18.207 17.7071C18.3946 17.5196 18.4999 17.2652 18.4999 17V7C18.4984 6.86932 18.4712 6.74022 18.4199 6.62Z" fill="currentColor"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="relative w-full lg:w-1/2 px-4">
                <div class="max-w-xs mx-auto lg:mr-0">
                  <img src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/dashboard-bg-none.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const casperStats5 =           
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 -mt-72 w-148 h-148 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="absolute top-0 right-0 mr-112 w-148 h-148 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative">
            <div class="max-w-4xl mx-auto text-center mb-16">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-7xl font-medium text-white tracking-tight mb-3">Achievements So far</h2>
              <p class="max-w-md mx-auto text-lg text-gray-500">Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus..</p>
            </div>
            <div class="max-w-sm lg:max-w-5xl mx-auto">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div class="h-full p-8 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10">
                    <img class="block mb-32" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-3.png" alt="">
                    <h3 class="text-5xl sm:text-6xl font-semibold text-white">99%</h3>
                    <span class="text-xl font-medium text-white tracking-tight">Partners Joined</span>
                    <p class="max-w-xs text-sm text-white">Powered by state-of-the-art artificial intelligence, ensuring natural and meaningful</p>
                  </div>
                </div>
                <div class="w-full lg:w-1/2 px-4">
                  <div class="h-full p-8 rounded-3xl shadow-box-orange overflow-hidden bg-white bg-opacity-10">
                    <img class="block mb-32" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/stats/avatar-1.png" alt="">
                    <h3 class="text-5xl sm:text-6xl font-semibold text-white">$ 300M</h3>
                    <span class="text-xl font-medium text-white tracking-tight">Capital Raised</span>
                    <p class="max-w-xs text-sm text-white">Powered by state-of-the-art artificial intelligence, ensuring natural and meaningful</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const arr = [
    {label:'simple', content:simple,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-simple.png'},
    {label:'simpleOnDark', content:simpleOnDark,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-simpleOnDark.png'},
    {label:'simpleGrid', content:simpleGrid,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-simpleGrid.png'},
    {label:'simpleGridOnDark', content:simpleGridOnDark,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-simpleGridOnDark.png'},
    {label:'withBackgroundImage', content:withBackgroundImage,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-withBackgroundImage.png'},
    {label:'splitWithImage', content:splitWithImage,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-splitWithImage.png'},
    {label:'timeline', content:timeline,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-timeline.png'},
    {label:'stepped', content:stepped,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-stepped.png'},
    {label:'twoColumnDescriptionOnDark', content:twoColumnDescriptionOnDark,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-twoColumnDescriptionOnDark.png'},
    {label:'withDescription', content:withDescription,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/stats-withDescription.png'},
    {label:'casperStats1', content:casperStats1,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/stats1.png'},
    {label:'casperStats2', content:casperStats2,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/stats2.png'},
    {label:'casperStats3', content:casperStats3,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/stats3.png'},
    {label:'casperStats4', content:casperStats4,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/stats4.png'},
    {label:'casperStats5', content:casperStats5,image:'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/stats5.png'},
  ]
  return arr
}
  