/* eslint-disable react/prop-types */
import { AspectRatio, Divider, Grid, Image, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react'
import useUserStore from '../../Hooks/Zustand/Store';
import InstagramAnalyticsPost from '../../Pages/SocialMedia/Analytics/InstagramAnalyticsPost';


function InstagramAnalyticsCard({ data }) {
  const globalState = useUserStore();

  return (
    <Stack
      borderRadius="lg"
      shadow="md"
      bgColor={'white'}
      borderTopWidth={5}
      borderColor="blue.500"
      p={5}
    >
      <Grid templateColumns={{ base: '1fr', md: '4fr 1fr' }} gap={5}>
        <Stack bgColor={'white'} p={3} fontSize="sm">
          <Text>{data?.post}</Text>
        </Stack>
        {data?.mediaType === 'VIDEO' ? (
          <AspectRatio maxW="auto" ratio={1}>
            <iframe
              title='halo'
              src={data?.mediaUrl}
              allowFullScreen
            />
          </AspectRatio>
        ) : (
          <AspectRatio maxW="auto" ratio={1}>
            <Image src={data?.mediaUrl} />
          </AspectRatio>
        )}
      </Grid>
      <Divider />
      <Stack>
        <Text fontSize={'xs'} color="gray.600">Share via {globalState?.socialActive?.platform} on {moment(data.created).format('LLLL')}</Text>
      </Stack>

      <Stack spacing={5}>
        {Object.entries(data?.analytics).map(([key, value]) => (
          <Stack key={key}>
            <Text
              fontSize={'sm'}
              fontWeight="bold"
              textTransform={'capitalize'}
            >
              {key}
            </Text>
            <Divider />
            <InstagramAnalyticsPost analyticsData={value.analytics} />
          </Stack>
        ))}

      </Stack>

    </Stack>
  )
}

export default InstagramAnalyticsCard