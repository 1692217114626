/* eslint-disable no-unused-vars */
import React,{ useEffect,useState } from 'react';
import '../../../index.css'
import CryptoJS from 'crypto-js';
import 'grapesjs/dist/css/grapes.min.css';
import './grapesJs.css';
import {
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useLocation,useNavigate,useParams } from 'react-router-dom';
import grapesjs from 'grapesjs';
import presetPlugin from 'grapesjs-preset-webpage';
import pluginGrapesjsBlocksBasic from 'grapesjs-blocks-basic';
import gjsForms from 'grapesjs-plugin-forms';
import tailwindPlugin from 'grapesjs-tailwind';
import formSelectPlugin from '../landingPage/CustomFormPlugin'
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../../Api/firebaseApi';
import {
  getFolderAws,
  uploadAws,
  uploadImageAws,
} from '../../../Api/AwsApi.js';
import useUserStore from '../../../Hooks/Zustand/Store';
import { heroGrapesJs } from './SectionComponent/hero';
import { pricingGrapesJs } from './SectionComponent/pricing';
import { blogsGrapesJs } from './SectionComponent/blog';
import { contactGrapesJs } from './SectionComponent/contact';
import { headerGrapesJs } from './SectionComponent/header';
import { contentGrapesJs } from './SectionComponent/content';
import { ctaGrapesJs } from './SectionComponent/cta';
import { commercesGrapesJs } from './SectionComponent/commerce/commerce';
import { footerGrapesJs } from './SectionComponent/footer';
import { teamGrapesJs } from './SectionComponent/team';
import { testimonialsGrapesJs } from './SectionComponent/testimonials';
import { featuresGrapesJs } from './SectionComponent/features';
import { statisticsGrapesJs } from './SectionComponent/statistics';
import myPlugin from './TailwindPlugin';
import customCodePlugin from './EmbedCodePlugin';
import { aboutGrapesJs } from './SectionComponent/about';
import { servicesGrapesJs } from './SectionComponent/services';
import { showcaseGrapesJs } from './SectionComponent/productShowcase';
import { stepsGrapesJs } from './SectionComponent/steps';
import { casper } from './TailwindPlugin/tailwindConfig.js';
import buttonPlugin from './ButtonPlugin';
// import { templateConditions } from './Templates/templateConditions';

function MarketingLandingPage() {
  const globalState = useUserStore();
  const toast = useToast();
  const navigate = useNavigate()
  const [mediaAssets,setMediaAsset] = useState();
  // const [templateConfig,setTemplateConfig] = useState(null)
  const { state } = useLocation();
  const param = useParams();

  const getDataStorage = async (editor) => {
    globalState?.setIsLoading(true)
    try {
      const res = await getSingleDocumentFirebase('websites',param?.pageId);

      // eslint-disable-next-line no-prototype-builtins
      if ('html' in res) {
        editor.setComponents(res?.html);
        editor.setStyle(res?.css);
      }

      // const configs = templateConditions(res?.templateName)
      // setTemplateConfig(configs)

      globalState?.setIsLoading(false)
      if(res){
        globalState?.setIsLoading(false)
      }

    } catch (error) {
      // console.log(error,'Error retrieving data from storage');
      // toast({
      //   title: 'Error',
      //   description: 'Failed to retrieve data from storage',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    } finally {
      globalState?.setIsLoading(false)
    }
  };

  const getFileAssets = async () => {
    try {
      const pageDetail = await getSingleDocumentFirebase(
        'websites',
        param?.pageId
      );

      const { data } = await getFolderAws();

      const fileFind = data?.data?.data?.filter(
        (x) =>
          x?.deoapp_folder?.path ===
          `${pageDetail?.domainName}/${pageDetail?.domainPath}`
      );

      const imageArr = fileFind?.map((x) => x?.link);

      setMediaAsset(imageArr);
    } catch (error) {
      throw new Error(error);
    }
  };

  const getAssetManager = async (editor) => {
    try {
      const pageDetail = await getSingleDocumentFirebase(
        'websites',
        param?.pageId
      );

      const { data } = await getFolderAws();

      const fileFind = data?.data?.data?.filter(
        (x) =>
          x?.deoapp_folder?.path ===
          `${pageDetail?.domainName}/${pageDetail?.domainPath}`
      );

      const filteredArray = fileFind.filter((obj) => obj.link.includes('jpeg' | 'jpg' | 'png' | 'svg' | 'gif' | 'mp4'));

      const imageArr = filteredArray?.map((x) => x?.link);
      editor.AssetManager.add(imageArr);
      editor.AssetManager.open();
      setMediaAsset(imageArr);
    } catch (error) {
      throw new Error(error);
    }
  };

  function getAllPanels(editor) {
    editor.on('load',() => {
      editor.Panels.addPanel({
        id: 'basic-actions',
        el: '.panel__basic-actions',
        buttons: [
          {
            id: 'back-button',
            className: 'btn-back',
            active: false,
            label: 'Back',
            command(editor) {
              navigate(-1)
            }
          },
          {
            id: 'assets-button',
            className: 'btn-get-assets',
            active: false,
            label: 'Assets',
            command(editor) {
              getAssetManager(editor);
            },
          },
          {
            id: 'live-preview-button',
            className: 'btn-live-preview',
            active: false,
            label: 'Live',
            async command(editor) {
              globalState?.setIsLoading(true)
              try {
                const pageDetail = await getSingleDocumentFirebase('websites',param?.pageId);
                
                console.log(pageDetail)

                if(pageDetail && 'html' in pageDetail){
                  window.open(`https://${pageDetail.domainName}/${pageDetail.domainPath}`, '_blank')
                } else {
                  toast({
                    title: 'Error',
                    description: 'Cannot display data, Save first!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                }
              } catch (error) {
                toast({
                  title: 'Error',
                  description: 'Failed to save data',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                throw new Error(error);
              } finally {
                globalState?.setIsLoading(false);
              }
            }
          },
          {
            id: 'save-button',
            className: 'btn-save',
            active: false,
            label: '<button>Save</button>',
            async command(editor) {
              globalState?.setIsLoading(true)

              try {
                const pageDetail = await getSingleDocumentFirebase('websites',param?.pageId);
                const titleContent = pageDetail?.landingpageTitle;
                const htmlContent = editor.getHtml();
                const cssContent = editor.getCss();
                const gtmContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',${pageDetail?.gtmCode})`;
                const pixelIdContent = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${pageDetail?.pixelId}');fbq('track', 'PageView');`
                // const configTailwind = JSON.stringify(templateConfig);
                const md5Digest = CryptoJS.MD5(htmlContent).toString();
                const md5DigestCss = CryptoJS.MD5(cssContent).toString();
                const md5DigestHead = CryptoJS.MD5(gtmContent).toString();
                const md5DigestPixelId = CryptoJS.MD5(pixelIdContent).toString();
                const md5DigestTitle = CryptoJS.MD5(titleContent).toString();
                // const md5DigestConfig = CryptoJS.MD5(JSON.stringify(configTailwind));

                const dataUploads = [
                  { content: htmlContent,md5Digest: md5Digest,fileName: 'index.html' },
                  { content: cssContent,md5Digest: md5DigestCss,fileName: 'style.css' },
                  { content: gtmContent,md5Digest: md5DigestHead,fileName: 'gtm.js' },
                  { content: pixelIdContent,md5Digest: md5DigestPixelId,fileName: 'pixel.js' },
                  { content: titleContent,md5Digest: md5DigestTitle,fileName: 'title.txt' },
                  // { content: configTailwind,md5Digest: md5DigestConfig,fileName: 'config.js' }
                ];

                const uploadPromises = dataUploads.map(async (data) => {
                  const blob = new Blob([data.content],{ type: 'text/plain' });
                  const file = new File([blob],data.fileName,{ type: 'text/plain' });
                  const dataUpload = { file: file,folder: `${pageDetail?.domainName}/${pageDetail?.domainPath}` };
                  return uploadAws(dataUpload,data.md5Digest);
                });

                const [res,resCss,resHead,resConfig] = await Promise.all(uploadPromises);

                if (res?.status && resCss?.status && resHead?.status && resConfig?.status) {
                  const updateData = {
                    html: htmlContent,
                    css: cssContent,
                    gtm: gtmContent,
                    pixelId: pixelIdContent,
                    projectId: globalState?.currentProject,
                    companyId: globalState?.currentCompany
                  };
                  await (pageDetail?.html === '' && pageDetail?.css === ''
                    ? updateDocumentFirebase('websites',param?.pageId,updateData)
                    : setDocumentFirebase('websites',param?.pageId,updateData,globalState.currentCompany));
                  toast({
                    title: 'Success',
                    description: 'Data successfully saved',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                }

              } catch (error) {
                toast({
                  title: 'Error',
                  description: 'Failed to save data',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                throw new Error(error);
              } finally {
                globalState?.setIsLoading(false);
              }
            }
          },
        ],
      });
    });
  }

  const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi,'-');

  useEffect(() => {
    getFileAssets();

    const editor = grapesjs.init({
      assetManager: {
        assets: mediaAssets,
        uploadFile: async (ev) => {
          const files = ev.dataTransfer
            ? ev.dataTransfer.files
            : ev.target.files;

          if (files[0]?.size > 5 * 1024 * 1024) {
            toast({
              title: 'Upload Stopped',
              description: 'Image file size is too large, File should be less than 5mb',
              duration: 3000,
              status: 'warning',
              position: 'top'
            })
          } else {
            const pageDetail = await getSingleDocumentFirebase(
              'websites',
              param?.pageId
            );

            const dataUpload = {
              file: files[0],
              folder: `${pageDetail?.domainName}/${pageDetail?.domainPath}`,
            };

            const resUpload = await uploadImageAws(dataUpload);

            editor.AssetManager.add(resUpload?.data?.data);
          }

        },
        embedAsBase64: false
      },
      container: '#gjs',
      allowScripts: 1,
      selectorManager: { escapeName },
      canvas: {
        scripts: []
      },
      plugins: [
        myPlugin,
        // customCodePlugin,
        presetPlugin,
        pluginGrapesjsBlocksBasic,
        gjsForms,
        buttonPlugin,
        formSelectPlugin,
        tailwindPlugin,
      ],
      pluginsOpts: {
        [myPlugin]: {},
        [buttonPlugin]: {},
        // [customCodePlugin]: {},
        [formSelectPlugin]: {},
        [presetPlugin]: {},
        [pluginGrapesjsBlocksBasic]: {},
        [gjsForms]: {},
        [tailwindPlugin]: {
          config: casper
        },
      }
    });

    getDataStorage(editor)

    const css = editor.Css
    css.addRules(`
    @font-face {
      font-family: 'Inter';
      src:  url("http://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf") format('truetype');
    }
    @font-face {
      font-family: 'Open Sauce Sans';
      src:  url("../../../assets/Fonts/OpenSauceSans-Regular.ttf") format('truetype');
    }
    @font-face {
      font-family: 'Clash Display';
      src: url('../../../assets/Fonts/ClashDisplay-Regular.tff') format('truetype');
           font-weight: 400;
           font-display: swap;
           font-style: normal;
    }`);

    // Update component button
    const typeEditor = editor.DomComponents.getType('button')

    const eventScript = function ({ props }) {
      // eslint-disable-next-line react/prop-types
      const urlAddress = props.urlValue
      window.open(`${urlAddress}`,'_blank')
    }

    editor.DomComponents.addType('button',{
      model: {
        defaults: {
          eventScript,
          urlValue: 'https://google.com',
          traits: [
            ...typeEditor.model.prototype.defaults.traits,
            {
              type: 'button-href',
              name: 'href',
              label: 'Link Direction',
              changeProp: true
            },
          ],
          'script-props': ['urlValue'],
        }
      }
    });

    editor.TraitManager.addType('button-href',{
      // Expects as return a simple HTML string or an HTML element
      createInput({ trait }) {

        // Create a new element container and add some content
        const el = document.createElement('div');

        el.innerHTML = `
          <div class="button-title__title-inputs">
            <input class="button-href__href" placeholder="eg: https://google.com"/>
          </div>
        `;

        const inputsTitle = el.querySelector('.button-href__href');
        inputsTitle.addEventListener('input',(ev) => {
          trait.set('value',ev.target.value);
        });

        return el;
      },

      onEvent({ elInput,component,event }) {
        const idComponent = component.getId()
        const classComponent = component.getClasses()
        const listClasses = classComponent.join(' ')
        const componentContent = component.__innerHTML()
        const inputsHref = elInput.querySelector('.button-href__href');

        let url = inputsHref.value

        if (inputsHref.value === '') {
          url = 'https://google.com'
        } else if (!inputsHref.value.includes('https://')) {
          url = 'https://' + inputsHref.value
        }

        component.replaceWith(`<button id=${idComponent} class="${listClasses}">${componentContent}</button>
        <script>
          function openLinkInNewTab() {
            var defaultUrl = 'https://www.google.com'
            var url = '${url}'

            window.open(url, '_blank');
          }
          document.getElementById("${idComponent}").addEventListener("click", openLinkInNewTab);
        </script>`)
      },
    });

    

    getAllPanels(editor)
    // getTitle(editor)

    editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
    });

    editor.runCommand('get-tailwindCss')
    const blockManager = editor.Blocks;

    blockManager.render();

    blockManager.getCategories();
    heroGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Hero',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    pricingGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Pricing',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    blogsGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Blog',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    headerGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Header',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    contactGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Contact',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    contentGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Content',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    ctaGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'CTA',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    commercesGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Commerce',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    footerGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Footer',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    teamGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Team',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    testimonialsGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Testimonials',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    featuresGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Features',
        attributes: {
          title: 'Insert h1 block'
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    statisticsGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Statistics',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    aboutGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'About Us',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    servicesGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Services',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    showcaseGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Product Showcase',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )
    stepsGrapesJs.call().map((x) =>
      blockManager.add(x.label,{
        label: `<img src="${x.image}" alt="${x.label}" >`,
        content: x.content,
        category: 'Steps',
        attributes: {
          title: 'Insert Image block',
          src: x.image,
          alt: x.label
        },
        onClick: (test) => {
          editor.addComponents(test.getContent());
          toast({
            title: 'Added block to canvas',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })}
      })
    )

    blockManager.render()

    return () => {
      editor.destroy();
    };
  },[]);


  return (
    <Stack width="full" height="full">
      <div>
        <div className="panel__top">
          <div className="panel__basic-actions"></div>
          <div className="">{`${state.title} - ${state.title_page}`}</div>
        </div>
        <div id="gjs"></div>
      </div>
    </Stack>
  );
}

export default MarketingLandingPage;
