
import { Badge, FormControl, FormLabel, Heading, HStack, Input, SimpleGrid, Spacer, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleDocumentFirebase, updateDocumentFirebase } from '../../Api/firebaseApi'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import ModalBalanceHistory from '../../Components/Modals/ModalBalanceHistory'
import ModalBalanceMeta from '../../Components/Modals/ModalBalanceMeta'
import { formatFrice } from '../../Utils/Helper';


function MarketingMetaIntegrationView() {

  const param = useParams()
  const [data, setData] = useState({})
  const [modalBalance, setModalBalance] = useState(false)
  const [modalBalanceHistory, setModalBalanceHistory] = useState(false)
  const navigate = useNavigate();
  const toast = useToast()



  const getData = async () => {
    try {
      const result = await getSingleDocumentFirebase('integrations', param.id)
      setData(result)
    } catch (error) {
      throw new Error(error)
    }
  }

  const handleSave = async () => {
    const collectionName = 'integrations';
    const docName = param?.id;



    try {
      await updateDocumentFirebase(collectionName, docName, data);

      toast({
        status: 'success',
        duration: 3000,
        title: 'Successfully Save data',
        isClosable: true,
      });
      navigate(-1)
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    getData()

    return () => {
      setData({})
    }
  }, [])

  const handleOpenModal = () => {
    setModalBalance(true)
  }

  const handleCloseModal = () => {
    setModalBalance(false)
  }

  const handleOpenModalHistory = () => {
    setModalBalanceHistory(true)
  }

  const handleCloseModalHistory = () => {
    setModalBalanceHistory(false)
  }



  return (
    <Stack p={[1, 1, 5]} spacing={5}>

      <HStack>
        <BackButtons />
        <Heading size="md">{data?.title}</Heading>
        <Spacer />
        <DynamicButton action={'create'} title='Top up Balance' variant={'solid'} onClick={handleOpenModal} />
      </HStack>



      <Heading textAlign="center">Ads Account</Heading>

      <Stack p={[1, 1, 5]} shadow="base" spacing={10} bg={'white'} borderRadius={'md'}>
        <HStack >
          <HStack fontWeight={'bold'} spacing={2} fontSize='xl' >
            <Text>Balance : </Text>
            <Text>IDR {formatFrice(data?.balance)}</Text>
            <DynamicButton action={'read'} size='xs' variant={'solid'} onClick={handleOpenModalHistory} />
          </HStack>
          <Spacer />
          <Badge px={2} py={1} fontSize={'lg'} fontWeight={'bold'} textTransform='capitalize' colorScheme={data.status === 'pending' ? 'yellow' : 'green'}>{data?.status}</Badge>
        </HStack>

        <SimpleGrid columns={[1, 1, 2]} gap={5}>
          <Stack>
            <FormControl>
              <FormLabel>Ads account ID</FormLabel>
              <Input
                defaultValue={data?.adsAccountId}
                isDisabled={true}
                type="text"
              />
            </FormControl>


            <FormControl>
              <FormLabel>Ads account name</FormLabel>
              <Input
                defaultValue={data?.title}
                type="text"
                onChange={(e) =>
                  setData({ ...data, title: e.target.value || data?.title })
                }
              />
            </FormControl>




            <FormControl >
              <FormLabel>Business Manager ID (BM ID)</FormLabel>
              <Input
                defaultValue={data?.bmId}
                onChange={(e) =>
                  setData({ ...data, bmId: e.target.value || data?.bmId })
                }
                type="text"
              />
            </FormControl>

          </Stack>
          <Stack>

            <FormControl>
              <FormLabel>Your Company</FormLabel>
              <Input
                isDisabled={true}
                defaultValue={data?.companyId}

                type="text"
              />
            </FormControl>

            <FormControl >
              <FormLabel>Name</FormLabel>
              <Input
                value={data?.userData?.name}
                isDisabled={true}
                type="text"
              />
            </FormControl>

            <FormControl >
              <FormLabel>Email</FormLabel>
              <Input
                value={data?.userData?.email}
                isDisabled={true}
                type="text"
              />
            </FormControl>

            <FormControl >
              <FormLabel>Phone Number</FormLabel>
              <Input
                value={data?.userData?.phoneNumber}
                isDisabled={true}
                type="text"
              />
            </FormControl>
          </Stack>
        </SimpleGrid>

        <DynamicButton action={'create'} title='Save' variant={'solid'} onClick={handleSave} />


      </Stack>

      <ModalBalanceMeta modalBalance={modalBalance} handleCloseModal={handleCloseModal} dataMeta={data}/>
      <ModalBalanceHistory modalBalanceHistory={modalBalanceHistory} handleCloseModalHistory={handleCloseModalHistory} dataMeta={data}/>
    </Stack>

  )
}

export default MarketingMetaIntegrationView