/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import React,{ useEffect,useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useMarketingStore from '../../../Hooks/Zustand/MarketingStore';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import { decryptToken } from '../../../Utils/encrypToken';
import UploadFileToDropbox from '../../../Components/DropBox/UploadFileToDropbox';
import { BsFiletypeCsv } from 'react-icons/bs';
import targetingList from '../../../assets/targetingList.json';
import ReactSelect from 'react-select';

function MarketingMetaAddAudiencePage() {
  const param = useParams();
  const navigate = useNavigate();
  const [data,setData] = useState();
  const [customAudienceSource,setCustomAudienceSource] = useState('');
  const marketingState = useMarketingStore();
  const toast = useToast();
  const globalState = useUserStore();
  const [progress,setProgress] = useState();
  const [listTargeting,setListTargeting] = useState([]);
  const [inputCustom,setInputCustom] = useState({
    accountName: '',
    events: '',
    retentionDays: 1,
    audienceName: '',
    description: '',
    category: 'include',
  });

  const [inputCustomList,setInputCustomList] = useState({
    link: '',
    audienceName: '',
    description: '',
    category: 'include',
  });

  const steps = [
    { title: 'Campaign',description: 'Create Campaign' },
    { title: 'Ads',description: 'Create Ads' },
    { title: 'Audience',description: 'Select Audience' },
    {
      title: 'Budget & Strategies',
      description: 'Set your budget & strategies',
    },
    { title: 'Publish',description: 'Review & publish' },
  ];

  const getCampaignData = async () => {
    try {
      const res = await getSingleDocumentFirebase('marketing_meta',param?.id);

      setData(res);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (param?.type === 'edit') {
      getCampaignData();
    }

    return () => { };
  },[]);

  // const handleNavigate = (value) => {
  //   if (param?.type === 'edit') {
  //     navigate(`/marketing/meta/ads/create/budget/edit/${value?.id}`);
  //   } else {
  //     navigate('/marketing/meta/ads/create/budget');
  //   }
  //   console.log(value);
  // };

  const handleNavigate = (value) => {
    if (param?.type === 'edit') {
      navigate(`/marketing/meta/ads/create/budget/edit/${value?.id}`);
    } else {
      const ageStart = parseInt(marketingState?.targeting?.ageStart,10);

      if (ageStart >= 21) {
        navigate('/marketing/meta/ads/create/budget');
      } else {
        toast({
          status: 'warning',
          title: 'Minimum 21 Years Old',
          duration: 3000,
        });
      }
    }
  };

  const handleAddCustomAudience = (value,field,type) => {
    if (field === 'category') {
      setInputCustom({ ...inputCustom,[field]: value });
    }

    if (type === 'customerList') {
      setInputCustomList({ ...inputCustomList,[field]: value,type: type });
    } else {
      setInputCustom({ ...inputCustom,[field]: value,type: type });
    }
  };

  const handleSaveAudience = (type) => {
    const currentCustomAudience = marketingState?.targeting?.customAudience;

    if (type === 'customerList') {
      currentCustomAudience?.push(inputCustomList);
      marketingState?.setTargeting({ customAudience: currentCustomAudience });
    } else {
      currentCustomAudience?.push(inputCustom);
      marketingState?.setTargeting({ customAudience: currentCustomAudience });
    }

  };

  const handleRemoveCustomAudience = (value) => {
    const currentCustomAudience = marketingState?.targeting?.customAudience;

    const customAudienceIndex = currentCustomAudience?.findIndex((x) => x?.audienceName === value?.audienceName);

    if (customAudienceIndex > -1) {
      currentCustomAudience?.splice(customAudienceIndex,1);

      marketingState?.setTargeting({ customAudience: currentCustomAudience });
    }
  };

  const handleUploadFile = async (e,field,type) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/ads-customer-list`;

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token','dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file,parentPath,decryptResult,setProgress,progress);

      // setMedia(res?.link);

      setInputCustomList({ ...inputCustomList,[field]: res?.link,type: type });
    } catch (error) {
      throw new Error(error)
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleSelectTargeting = async (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    marketingState?.setTargeting({ detailedTargeting: selectedValues });
  };
  const getDataTargeting = () => {
    const targetMapList = targetingList?.interest || [];

    const options = targetMapList.flatMap((category) =>
      category.fields.map((field) => ({
        label: field.name,
        value: field.name,
      }))
    );

    setListTargeting(options);
  };

  useEffect(() => {
    getDataTargeting();
  },[]);
  return (
    <Box>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={3}
        bg={'white'}
        p={5}
        borderRadius={'md'}
        shadow={'base'}
        my={5}>
        {steps.map((step,index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Heading textAlign='center'>Audience </Heading>

      <Box>
        <Container
          p='5'
          mt='5'
          shadow='base'
          bg={'white'}
          borderRadius={'md'}>
          <Tabs>
            <TabList w={'100%'}>
              <Tab w={'50%'}>Create New</Tab>
              <Tab w={'50%'}>Custom Audience</Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={3}>
                <HStack my={3}>
                  <Box>
                    <FormLabel>Age</FormLabel>
                    <HStack>
                      {/* <Select defaultValue="include" maxW="max-content">
                        <option>include</option>
                        <option>exclude</option>
                      </Select> */}
                      <Input
                        placeholder={param?.type === 'edit' ? data?.targeting?.ageStart : 'Minimum 21 Years Old'}
                        defaultValue={marketingState?.targeting?.ageStart}
                        onChange={(e) => {
                          const enteredValue = parseInt(e.target.value,10);
                          marketingState?.setTargeting({ ageStart: enteredValue.toString() });
                        }}
                        onWheel={(e) => e.target.blur()}
                        type='number'
                      />
                      <Text>-</Text>
                      {/* <Select defaultValue="include" maxW="max-content">
                        <option>include</option>
                        <option>exclude</option>
                      </Select> */}
                      <Input
                        placeholder={param?.type === 'edit' ? data?.targeting?.ageEnd : null}
                        defaultValue={marketingState?.targeting?.ageEnd}
                        onChange={(e) => marketingState?.setTargeting({ ageEnd: e?.target.value })}
                        onWheel={(e) => e.target.blur()}
                        type='number'
                      />
                    </HStack>
                  </Box>
                  <Box>
                    <FormLabel>Sex</FormLabel>
                    <HStack>
                      <Select
                        placeholder={param?.type === 'edit' ? data?.targeting?.sex : 'Choose'}
                        defaultValue={marketingState?.targeting?.sex}
                        onChange={(e) =>
                          marketingState?.setTargeting({
                            sex: e.target.value,
                          })
                        }
                        // defaultValue="include"
                        maxW='max-content'>
                        <option value={'male'}>Male</option>
                        <option value={'female'}>Female</option>
                        <option value={'both'}>Both</option>
                      </Select>
                    </HStack>
                  </Box>
                </HStack>

                <FormControl>
                  <FormLabel>Detailed Targetting</FormLabel>
                  <ReactSelect
                    isMulti={true}
                    className='basic-single'
                    defaultValue={marketingState?.targeting?.detailedTargeting}
                    classNamePrefix='select'
                    isSearchable={true}
                    name='targeting'
                    options={listTargeting}
                    onChange={handleSelectTargeting}
                  />
                  {/* <Input
                    placeholder={param?.type === 'edit' ? data?.targeting?.detailedTargeting : null}
                    defaultValue={marketingState?.targeting?.detailedTargeting}
                    onChange={(e) =>
                      marketingState?.setTargeting({
                        detailedTargeting: e.target.value,
                      })
                    }
                    type='text'
                  /> */}
                  {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
                </FormControl>

                <FormControl my={3}>
                  <FormLabel>Devices</FormLabel>
                  <Select
                    placeholder={param?.type === 'edit' ? data?.targeting?.devices : 'Choose Device'}
                    defaultValue={marketingState?.targeting?.devices}
                    onChange={(e) =>
                      marketingState?.setTargeting({
                        devices: e.target.value,
                      })
                    }
                  // defaultValue="include"
                  // maxW="max-content"
                  >
                    <option value={'desktop'}>Desktop</option>
                    <option value={'tablet'}>Tablet</option>
                    <option value={'iOs'}>Mobile iOS</option>
                    <option value={'android'}>Mobile Android</option>
                    <option value={'both'}>Both</option>
                  </Select>
                </FormControl>
              </TabPanel>
              <TabPanel align={'center'}>
                <RadioGroup
                  size={'sm'}
                  onChange={setCustomAudienceSource}
                // value={value}
                >
                  <HStack
                    direction='row'
                    justify={'space-evenly'}>
                    <Radio value='customer list'>Customer List</Radio>
                    <Radio value='facebook fanpage'>Facebook Fanpage</Radio>
                    <Radio value='instagram account'>Instagram Account</Radio>
                  </HStack>
                </RadioGroup>

                {Array.isArray(marketingState?.targeting?.customAudience) && marketingState.targeting.customAudience.length > 0 ? (
                  <SimpleGrid
                    p={2}
                    columns={3}
                    spacing={2}>
                    {marketingState.targeting.customAudience.map((x,i) => (
                      <Stack
                        key={i} // Add a unique key to each element when using map
                        shadow={'base'}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}
                        p={2}
                        pos={'relative'}>
                        <Box
                          color='gray'
                          pos={'absolute'}
                          right={0}
                          top={0}
                          onClick={() => handleRemoveCustomAudience(x)}>
                          <IoCloseCircleSharp />
                        </Box>
                        <Text>{x?.audienceName}</Text>
                      </Stack>
                    ))}
                  </SimpleGrid>
                ) : null}

                <Box m={3}>
                  {customAudienceSource === 'customer list' && (
                    <>
                      <Stack
                        p={3}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}>
                        {inputCustomList?.link !== '' ? (
                          <Box
                            color={'gray.400'}
                            py={3}>
                            <BsFiletypeCsv size={50} />
                          </Box>
                        ) : (
                          <Text fontSize={14}>Upload your customer list files</Text>
                        )}
                        <Input
                          onChange={(e) => handleUploadFile(e,'link','customerList')}
                          type={'file'}
                          size={'sm'}
                        />
                      </Stack>

                      <Box
                        p={3}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}>
                        <Text
                          fontSize={14}
                          fontWeight={'semibold'}>
                          Audience Information
                        </Text>
                        <FormControl>
                          <FormLabel>Audience Name</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'audienceName','customerList')}
                            size={'sm'}
                            placeholder={'Your fanpage name'}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Description</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'description','customerList')}
                            size={'sm'}
                            placeholder={'Your fanpage name'}
                          />
                        </FormControl>
                      </Box>
                      {customAudienceSource !== '' && (
                        <Button
                          my={2}
                          size={'sm'}
                          onClick={() => handleSaveAudience('customerList')}>
                          Save Custom Audience
                        </Button>
                      )}
                    </>
                  )}
                  {customAudienceSource === 'facebook fanpage' && (
                    <>
                      <Box
                        mt={2}
                        p={3}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}>
                        <Text
                          fontSize={14}
                          fontWeight={'semibold'}>
                          Fanpage Information
                        </Text>
                        <RadioGroup
                          py={2}
                          size={'sm'}
                          onChange={(e) => handleAddCustomAudience(e,'category')}
                        // value={value}
                        >
                          <HStack
                            direction='row'
                            justify={'space-evenly'}>
                            <Radio value='include'>Include</Radio>
                            <Radio value='exclude'>Exclude</Radio>
                          </HStack>
                        </RadioGroup>
                        <FormControl>
                          <FormLabel>Page</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'accountName','facebook')}
                            size={'sm'}
                            placeholder={'Your fanpage name'}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Events</FormLabel>
                          <Select
                            size={'sm'}
                            onChange={(e) => handleAddCustomAudience(e.target.value,'events','facebook')}>
                            <option value={'Accounts Center: accounts who started following the account'}>Accounts Center: accounts who started following the account</option>
                            <option value={'Everyone who engaged with the account'}>Everyone who engaged with the account</option>
                            <option value={'Anyone who visited the account profile'}>Anyone who visited the account's profile</option>
                            <option value={'Accounts Center: accounts who engaged with any post or ad'}>Accounts Center: accounts who engaged with any post or ad</option>
                            <option value={'People who sent a message to the account'}>People who sent a message to the account</option>
                            <option value={'Accounts Center: accounts who saved any post or ad'}>Accounts Center: accounts who saved any post or ad</option>
                          </Select>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Retention Days</FormLabel>
                          <Flex>
                            <Input
                              onChange={(e) => handleAddCustomAudience(e.target.value,'retentionDays','facebook')}
                              w={'50%'}
                              size={'sm'}
                              type={'number'}
                              max={365}
                              placeholder={'Enter retention days'}
                            />
                            <Text ml={2}> / days</Text>
                          </Flex>
                        </FormControl>
                      </Box>

                      <Box
                        p={3}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}>
                        <Text
                          fontSize={14}
                          fontWeight={'semibold'}>
                          Audience Information
                        </Text>
                        <FormControl>
                          <FormLabel>Audience Name</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'audienceName','facebook')}
                            size={'sm'}
                            placeholder={'Enter audience name'}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Description</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'description','facebook')}
                            size={'sm'}
                            placeholder={'Enter audience descripiton'}
                          />
                        </FormControl>
                      </Box>
                      {customAudienceSource !== '' && (
                        <Button
                          my={2}
                          size={'sm'}
                          onClick={() => handleSaveAudience('facebook')}>
                          Save Custom Audience
                        </Button>
                      )}
                    </>
                  )}
                  {customAudienceSource === 'instagram account' && (
                    <>
                      <Box
                        p={3}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}>
                        <Text
                          fontSize={14}
                          fontWeight={'semibold'}>
                          Source Information
                        </Text>
                        <RadioGroup
                          py={2}
                          size={'sm'}
                          onChange={(e) => handleAddCustomAudience(e,'category')}
                        // value={value}
                        >
                          <HStack
                            direction='row'
                            justify={'space-evenly'}>
                            <Radio value='include'>Include</Radio>
                            <Radio value='exclude'>Exclude</Radio>
                          </HStack>
                        </RadioGroup>
                        <FormControl>
                          <FormLabel>Instagram Account</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'accountName','instagram')}
                            size={'sm'}
                            placeholder={'Your fanpage name'}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Events</FormLabel>
                          <Select
                            size={'sm'}
                            onChange={(e) => handleAddCustomAudience(e.target.value,'events','instagram')}>
                            <option value={'Accounts Center: accounts who started following the account'}>Accounts Center: accounts who started following the account</option>
                            <option value={'Everyone who engaged with the account'}>Everyone who engaged with the account</option>
                            <option value={'Anyone who visited the account profile'}>Anyone who visited the account's profile</option>
                            <option value={'Accounts Center: accounts who engaged with any post or ad'}>Accounts Center: accounts who engaged with any post or ad</option>
                            <option value={'People who sent a message to the account'}>People who sent a message to the account</option>
                            <option value={'Accounts Center: accounts who saved any post or ad'}>Accounts Center: accounts who saved any post or ad</option>
                          </Select>
                        </FormControl>
                        <FormControl>
                          <FormLabel>Retention Days</FormLabel>
                          <Flex>
                            <Input
                              onChange={(e) => handleAddCustomAudience(e.target.value,'retentionDays','instagram')}
                              w={'50%'}
                              size={'sm'}
                              type={'number'}
                              placeholder={'Your fanpage name'}
                            />
                            <Text ml={2}> / days</Text>
                          </Flex>
                        </FormControl>
                      </Box>

                      <Box
                        p={3}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}>
                        <Text
                          fontSize={14}
                          fontWeight={'semibold'}>
                          Audience Information
                        </Text>

                        <FormControl>
                          <FormLabel>Audience Name</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'audienceName','instagram')}
                            size={'sm'}
                            placeholder={'Your fanpage name'}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Description</FormLabel>
                          <Input
                            onChange={(e) => handleAddCustomAudience(e.target.value,'description','instagram')}
                            size={'sm'}
                            placeholder={'Your fanpage name'}
                          />
                        </FormControl>
                      </Box>
                      {customAudienceSource !== '' && (
                        <Button
                          my={2}
                          size={'sm'}
                          onClick={() => handleSaveAudience('instagram')}>
                          Save Custom Audience
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/* <FormControl>
            <FormLabel>Custom Audience</FormLabel>
            <Select
              placeholder={param?.type === 'edit' ? data?.targeting?.customAudience : 'include'}
              Value={marketingState?.targeting?.customAudience === '' ? 'include' : marketingState?.targeting?.customAudience}
              onChange={(e) =>
                marketingState?.setTargeting({
                  customAudience: e.target.value,
                })
              }
              defaultValue='include'
              maxW='max-content'>
              <option>include</option>
              <option>exclude</option>
            </Select>
          </FormControl> */}

          <Button
            my={3}
            w='full'
            colorScheme='green'
            onClick={() => handleNavigate(data)}>
            Next
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default MarketingMetaAddAudiencePage;
