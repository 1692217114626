/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack,
  VStack,
  HStack,
  Text,
  AvatarGroup,
  Avatar,
  Spacer,
} from '@chakra-ui/react';
import moment from 'moment';
import DynamicButton from '../Buttons/DynamicButton';

function CardAvatar({
  Icon,
  data,
  globalState,
  onNavigate,
  userArray,
  title,
  timeCreated,
  modalTeam,
  owner,
  onDelete,
  onEdit,
}) {
  const findProject = globalState?.projects?.find(
    (x) => x?.id === data?.projectId
  );

  return (
    <Stack shadow="base" bgColor="white">
      <VStack align="flex-start" w="full" h="full" justify="space-between">
        <HStack spacing={3} px={4} pt={4} w="100%">
          <Stack cursor="pointer" onClick={onNavigate}>
            {Icon && <Icon size={50} />}
          </Stack>
          <Stack>
            <Text
              textTransform="capitalize"
              color="blackAlpha.800"
              cursor="pointer"
              noOfLines={2}
              fontSize="sm"
              fontWeight="medium"
              onClick={onNavigate}
            >
              {title}
            </Text>
            {userArray?.length > 0 && (
              <AvatarGroup
                onClick={modalTeam}
                cursor="pointer"
                size="sm"
                max={5}
              >
                {userArray?.map((user,i) => (
                  <Avatar key={i} name={user.name} />
                ))}
              </AvatarGroup>
            )}
          </Stack>
        </HStack>

        <HStack
          alignItems="center"
          justifyContent="space-around"
          w="full"
          p={3}
          mt={3}
        >
          <Stack spacing={0}>
            <Text spacing={3} fontSize="2xs">
              Project: {findProject?.name}
            </Text>
            <Text spacing={3} fontSize="2xs">
              Last updated {`${moment.unix(timeCreated).fromNow()}`}
            </Text>
          </Stack>
          <Spacer />
          {owner?.includes(globalState?.uid) && (
            <HStack spacing={2}>
              {onEdit && (
                <DynamicButton action={'update'} size="sm" onClick={onEdit} />
              )}

              {onDelete && (
                <DynamicButton action={'delete'} size="sm" onClick={onDelete} />
              )}
            </HStack>
          )}
        </HStack>
      </VStack>
    </Stack>
  );
}

export default CardAvatar;
