export const contentGrapesJs = () => {
  const stickyProductScreenshot = `<div class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
    <div class="absolute inset-0 -z-10 overflow-hidden">
      <svg class="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
          <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
      </svg>
    </div>
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="lg:max-w-lg">
            <p class="text-base font-semibold leading-7 text-indigo-600">Deploy faster</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</h1>
            <p class="mt-6 text-xl leading-8 text-gray-700">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas.</p>
          </div>
        </div>
      </div>
      <div class="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
        <img class="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png" alt="">
      </div>
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
            <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
            <ul role="list" class="mt-8 space-y-8 text-gray-600">
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                  <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</span>
              </li>
            </ul>
            <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
            <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>
            <p class="mt-6">Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis ipsum eu a sed convallis diam.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
  const withTestimonial = `<div class="relative isolate overflow-hidden bg-white py-24 sm:py-32">
    <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
      <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)"></div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <p class="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Deploy faster</p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</h1>
        <p class="mt-6 text-xl leading-8 text-gray-700">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
        <div class="relative lg:order-last lg:col-span-5">
          <svg class="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]" aria-hidden="true">
            <defs>
              <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" width="200" height="200" patternUnits="userSpaceOnUse">
                <path d="M0.5 0V200M200 0.5L0 0.499983" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
          </svg>
          <figure class="border-l border-indigo-600 pl-8">
            <blockquote class="text-xl font-semibold leading-8 tracking-tight text-gray-900">
              <p>“Vel ultricies morbi odio facilisi ultrices accumsan donec lacus purus. Lectus nibh ullamcorper ac dictum justo in euismod. Risus aenean ut elit massa. In amet aliquet eget cras. Sem volutpat enim tristique.”</p>
            </blockquote>
            <figcaption class="mt-8 flex gap-x-4">
              <img src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" class="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50">
              <div class="text-sm leading-6">
                <div class="font-semibold text-gray-900">Brenna Goyette</div>
                <div class="text-gray-600">@brenna</div>
              </div>
            </figcaption>
          </figure>
        </div>
        <div class="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
          <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
          <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</span>
            </li>
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.</span>
            </li>
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</span>
            </li>
          </ul>
          <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
          <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>
          <p class="mt-6">Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis ipsum eu a sed convallis diam.</p>
        </div>
      </div>
    </div>
  </div>
  `;
  const twoColumnsWithScreenshot = `<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <p class="text-base font-semibold leading-7 text-indigo-600">Deploy faster</p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</h1>
        <div class="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
          <div>
            <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
            <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas.</p>
          </div>
          <div>
            <p>Erat pellentesque dictumst ligula porttitor risus eget et eget. Ultricies tellus felis id dignissim eget. Est augue maecenas risus nulla ultrices congue nunc tortor.</p>
            <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
          </div>
        </div>
        <div class="mt-10 flex">
          <a href="#" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
        </div>
      </div>
    </div>
    <div class="relative overflow-hidden pt-16 lg:pt-20">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <img class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" src="https://tailwindui.com/img/component-images/project-app-screenshot.png" alt="">
        <div class="relative" aria-hidden="true">
          <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
        </div>
      </div>
    </div>
  </div>
  `;
  const withTestimoialandStats = `<div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-4">
          <div class="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
            <img class="absolute inset-0 h-full w-full brightness-125 saturate-0" src="https://images.unsplash.com/photo-1630569267625-157f8f9d1a7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2669&q=80" alt="">
            <div class="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl" aria-hidden="true">
              <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-40" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
            </div>
            <figure class="relative isolate">
              <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute -left-2 -top-4 -z-10 h-32 stroke-white/20">
                <path id="0ef284b8-28c2-426e-9442-8655d393522e" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
                <use href="#0ef284b8-28c2-426e-9442-8655d393522e" x="86" />
              </svg>
              <img src="https://tailwindui.com/img/logos/workcation-logo-white.svg" alt="" class="h-12 w-auto">
              <blockquote class="mt-6 text-xl font-semibold leading-8 text-white">
                <p>“Amet amet eget scelerisque tellus sit neque faucibus non eleifend. Integer eu praesent at a. Ornare arcu gravida natoque erat et cursus tortor.”</p>
              </blockquote>
              <figcaption class="mt-6 text-sm leading-6 text-gray-300"><strong class="font-semibold text-white">Judith Rogers,</strong> CEO at Workcation</figcaption>
            </figure>
          </div>
        </div>
        <div>
          <div class="text-base leading-7 text-gray-700 lg:max-w-lg">
            <p class="text-base font-semibold leading-7 text-indigo-600">Company values</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">On a mission to empower remote teams</h1>
            <div class="max-w-xl">
              <p class="mt-6">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
              <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
              <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
            </div>
          </div>
          <dl class="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Founded</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">2021</dd>
            </div>
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Employees</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">37</dd>
            </div>
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Countries</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">12</dd>
            </div>
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Raised</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">$25M</dd>
            </div>
          </dl>
          <div class="mt-10 flex">
            <a href="#" class="text-base font-semibold leading-7 text-indigo-600">Learn more about our company <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
  const splitWithImage = `<div class="relative bg-white">
    <div class="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
      <div class="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
        <div class="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
          <img class="absolute inset-0 h-full w-full bg-gray-50" src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80" alt="">
        </div>
      </div>
      <div class="px-6 lg:contents">
        <div class="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
          <p class="text-base font-semibold leading-7 text-indigo-600">Deploy faster</p>
          <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</h1>
          <p class="mt-6 text-xl leading-8 text-gray-700">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
          <div class="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
            <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
            <ul role="list" class="mt-8 space-y-8 text-gray-600">
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">Push to deploy.</strong> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">SSL certificates.</strong> Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                  <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</span>
              </li>
            </ul>
            <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
            <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>
            <p class="mt-6">Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis ipsum eu a sed convallis diam.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
  const centered = `<div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
      <p class="text-base font-semibold leading-7 text-indigo-600">Introducing</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">JavaScript for Beginners</h1>
      <p class="mt-6 text-xl leading-8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
      <div class="mt-10 max-w-2xl">
        <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.</p>
        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
          <li class="flex gap-x-3">
            <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
            <span><strong class="font-semibold text-gray-900">Data types.</strong> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</span>
          </li>
          <li class="flex gap-x-3">
            <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
            <span><strong class="font-semibold text-gray-900">Loops.</strong> Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.</span>
          </li>
          <li class="flex gap-x-3">
            <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
            <span><strong class="font-semibold text-gray-900">Events.</strong> Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</span>
          </li>
        </ul>
        <p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p>
        <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">From beginner to expert in 3 hours</h2>
        <p class="mt-6">Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh. Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis ipsum eu a sed convallis diam.</p>
        <figure class="mt-10 border-l border-indigo-600 pl-9">
          <blockquote class="font-semibold text-gray-900">
            <p>“Vel ultricies morbi odio facilisi ultrices accumsan donec lacus purus. Lectus nibh ullamcorper ac dictum justo in euismod. Risus aenean ut elit massa. In amet aliquet eget cras. Sem volutpat enim tristique.”</p>
          </blockquote>
          <figcaption class="mt-6 flex gap-x-4">
            <img class="h-6 w-6 flex-none rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
            <div class="text-sm leading-6"><strong class="font-semibold text-gray-900">Maria Hill</strong> – Marketing Manager</div>
          </figcaption>
        </figure>
        <p class="mt-10">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.</p>
      </div>
      <figure class="mt-16">
        <img class="aspect-video rounded-xl bg-gray-50" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3" alt="">
        <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
          <svg class="mt-0.5 h-5 w-5 flex-none text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
          </svg>
          Faucibus commodo massa rhoncus, volutpat.
        </figcaption>
      </figure>
      <div class="mt-16 max-w-2xl">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900">Everything you need to get up and running</h2>
        <p class="mt-6">Purus morbi dignissim senectus mattis adipiscing. Amet, massa quam varius orci dapibus volutpat cras. In amet eu ridiculus leo sodales cursus tristique. Tincidunt sed tempus ut viverra ridiculus non molestie. Gravida quis fringilla amet eget dui tempor dignissim. Facilisis auctor venenatis varius nunc, congue erat ac. Cras fermentum convallis quam.</p>
        <p class="mt-8">Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.</p>
      </div>
    </div>
  </div>
  `;

  const casperContent1 =
  `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 left-0 -ml-32 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <img class="hidden md:block absolute top-0 right-0 animate-spinStar" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/blink-star.png" alt="" />
          <div class="relative max-w-4xl mx-auto">
            <span class="block text-sm font-medium text-sky-900 mb-4">BLOG CATEGORY</span>
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-10">Gorgeous UI Library</h2>
            <img class="block w-full mb-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/image-lg.png" alt="" />
            <h3 class="text-2xl text-gray-50 font-medium mb-8">Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus morbi.</h3>
            <p class="text-lg tracking-tight text-gray-200 mb-4">Pellentesque ipsum nulla in eget interdum a. Neque pellentesque pulvinar mauris pulvinar diam. Turpis aliquet pellentesque velit vitae nisi sed morbi ultrices sed. Egestas interdum elit integer nec pharetra eget blandit dolor purus. A sed nisi congue morbi fermentum blandit. Turpis pretium dignissim risus ultrices purus et amet netus nibh.</p>
            <p class="text-lg tracking-tight text-gray-200 mb-10">Vestibulum est ante in congue a fusce nunc. At tristique donec nisi viverra vulputate blandit orci at lectus. Morbi cras urna congue ornare. Mi magna vestibulum tortor id nec tortor non. Enim orci lorem egestas sed morbi dui mauris etiam nulla. Euismod cursus viverra ut ac eu sit quam amet tempor. Id in suspendisse nam sit vitae ullamcorper mollis et ut.</p>
          </div>
        </div>
      </section>`

  const casperContent2 =    
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 left-0 -ml-60 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative flex flex-wrap -mx-4">
            <div class="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
              <div class="max-w-2xl mx-auto lg:mx-0 lg:max-w-md xl:max-w-lg">
                <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-6">
                  <span class="block">Casper</span>
                  <span>is an awesome UI Library</span>
                </h2>
                <p class="text-3xl font-medium text-gray-600 mb-4">Arcu ultrices sit non magna enim id semper quam venenatis. Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus morbi.</p>
                <p class="text-3xl font-medium text-gray-600">Augue justo at convallis vitae nunc maecenas est. Viverra duis feugiat posuere vitae tincidunt.</p>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="max-w-2xl mx-auto lg:mx-0 lg:max-w-md xl:max-w-xl">
                <h3 class="text-2xl text-gray-50 font-medium mb-8">Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus morbi.</h3>
                <p class="text-lg tracking-tight text-gray-200 mb-4">Pellentesque ipsum nulla in eget interdum a. Neque pellentesque pulvinar mauris pulvinar diam. Turpis aliquet pellentesque velit vitae nisi sed morbi ultrices sed. Egestas interdum elit integer nec pharetra eget blandit dolor purus. A sed nisi congue morbi fermentum blandit. Turpis pretium dignissim risus ultrices purus et amet netus nibh.</p>
                <p class="text-lg tracking-tight text-gray-200 mb-10">Vestibulum est ante in congue a fusce nunc. At tristique donec nisi viverra vulputate blandit orci at lectus. Morbi cras urna congue ornare. Mi magna vestibulum tortor id nec tortor non. Enim orci lorem egestas sed morbi dui mauris etiam nulla. Euismod cursus viverra ut ac eu sit quam amet tempor. Id in suspendisse nam sit vitae ullamcorper mollis et ut.</p>
                <img class="block w-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/image-robot.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperContent3 = 
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="absolute top-0 left-0 -ml-60 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 left-0 -ml-60 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative">
            <div class="max-w-[28rem] mb-12">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-2">Blog Content</h2>
              <p class="text-lg tracking-tight text-gray-600">Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus morbi.</p>
            </div>
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
                <div class="max-w-[24rem]">
                  <ul>
                    <li>
                      <a class="group flex py-6 items-center border-b border-dashed border-gray-800" href="#">
                        <span class="hidden group-hover:block mr-2">
                          <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 5L19 12L12 19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </span>
                        <span class="text-lg text-white group-hover:text-sky-800 font-medium tracking-tight">Category One</span>
                      </a>
                    </li>
                    <li>
                      <a class="group flex py-6 items-center border-b border-dashed border-gray-800" href="#">
                        <span class="hidden group-hover:block mr-2">
                          <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 5L19 12L12 19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </span>
                        <span class="text-lg text-white group-hover:text-sky-800 font-medium tracking-tight">Category Two</span>
                      </a>
                    </li>
                    <li>
                      <a class="group flex py-6 items-center border-b border-dashed border-gray-800" href="#">
                        <span class="hidden group-hover:block mr-2">
                          <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 5L19 12L12 19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </span>
                        <span class="text-lg text-white group-hover:text-sky-800 font-medium tracking-tight">Category Three</span>
                      </a>
                    </li>
                    <li>
                      <a class="group flex py-6 items-center border-b border-dashed border-gray-800" href="#">
                        <span class="hidden group-hover:block mr-2">
                          <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 5L19 12L12 19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </span>
                        <span class="text-lg text-white group-hover:text-sky-800 font-medium tracking-tight">Category Four</span>
                      </a>
                    </li>
                    <li>
                      <a class="group flex py-6 items-center border-b border-dashed border-gray-800" href="#">
                        <span class="hidden group-hover:block mr-2">
                          <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 5L19 12L12 19" stroke="#00BBE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </span>
                        <span class="text-lg text-white group-hover:text-sky-800 font-medium tracking-tight">Category Five</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="w-full lg:w-2/3 px-4">
                <div>
                  <img class="hidden sm:block absolute top-0 right-0 animate-spinStar" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/blink-star.png" alt="" />
                  <div class="relative max-w-4xl mx-auto">
                    <img class="block w-full mb-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/image-lg.png" alt="" />
                    <h3 class="text-2xl text-gray-50 font-medium mb-8">Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus morbi.</h3>
                    <p class="text-lg tracking-tight text-gray-200 mb-4">Pellentesque ipsum nulla in eget interdum a. Neque pellentesque pulvinar mauris pulvinar diam. Turpis aliquet pellentesque velit vitae nisi sed morbi ultrices sed. Egestas interdum elit integer nec pharetra eget blandit dolor purus. A sed nisi congue morbi fermentum blandit. Turpis pretium dignissim risus ultrices purus et amet netus nibh.</p>
                    <p class="text-lg tracking-tight text-gray-200 mb-10">Vestibulum est ante in congue a fusce nunc. At tristique donec nisi viverra vulputate blandit orci at lectus. Morbi cras urna congue ornare. Mi magna vestibulum tortor id nec tortor non. Enim orci lorem egestas sed morbi dui mauris etiam nulla. Euismod cursus viverra ut ac eu sit quam amet tempor. Id in suspendisse nam sit vitae ullamcorper mollis et ut.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const casperContent4 =          
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <img class="hidden md:block absolute top-0 left-0 z-10 animate-spinStar" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/blink-star.png" alt="" />
          <div class="relative max-w-4xl mx-auto mb-12">
            <div class="text-center mb-12">
              <span class="block text-sm font-medium text-sky-900 mb-4">BLOG CATEGORY</span>
              <h2 class="font-heading text-4xl xs:text-5xl font-medium text-white tracking-tight">Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus</h2>
            </div>
            <div class="sm:flex items-center justify-between">
              <div class="flex items-center mb-10 sm:mb-0">
                <img class="mr-5" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/avatar.png" alt="" />
                <div>
                  <span class="block text-lg text-white font-medium">Rakabuming Suhu</span>
                  <span class="text-gray-600">Editor</span>
                </div>
              </div>
              <div class="flex items-center">
                <span class="mr-5 font-medium text-white tracking-tight">Share</span>
                <a class="flex items-center justify-center w-12 h-12 mr-3 rounded-full bg-sky-900 hover:bg-violet-600 transition duration-150" href="#">
                  <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.6668 2.49999C18.8688 3.06289 17.9853 3.49341 17.0502 3.77499C16.5483 3.19791 15.8813 2.7889 15.1394 2.60326C14.3974 2.41762 13.6164 2.46432 12.9019 2.73703C12.1874 3.00975 11.5739 3.49532 11.1443 4.12808C10.7148 4.76085 10.4899 5.51027 10.5002 6.27499V7.10832C9.03569 7.14629 7.58456 6.8215 6.276 6.16286C4.96745 5.50422 3.84209 4.53218 3.00016 3.33332C3.00016 3.33332 -0.333171 10.8333 7.16683 14.1667C5.4506 15.3316 3.40613 15.9158 1.3335 15.8333C8.8335 20 18.0002 15.8333 18.0002 6.24999C17.9994 6.01787 17.9771 5.78632 17.9335 5.55832C18.784 4.71957 19.3842 3.66058 19.6668 2.49999Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </a>
                <a class="flex items-center justify-center w-12 h-12 rounded-full bg-blue-500 hover:bg-violet-600 transition duration-150" href="#">
                  <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5002 1.66666H13.0002C11.8951 1.66666 10.8353 2.10564 10.0539 2.88704C9.27248 3.66845 8.8335 4.72825 8.8335 5.83332V8.33332H6.3335V11.6667H8.8335V18.3333H12.1668V11.6667H14.6668L15.5002 8.33332H12.1668V5.83332C12.1668 5.61231 12.2546 5.40035 12.4109 5.24407C12.5672 5.08779 12.7791 4.99999 13.0002 4.99999H15.5002V1.66666Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <img class="block w-full h-112 object-cover rounded-3xl mb-12" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/image-lg.png" alt="" />
          <div class="max-w-4xl mx-auto">
            <h3 class="text-2xl text-gray-50 font-medium mb-8">Heading goes here</h3>
            <p class="text-lg tracking-tight text-gray-200 mb-4">Pellentesque ipsum nulla in eget interdum a. Neque pellentesque pulvinar mauris pulvinar diam. Turpis aliquet pellentesque velit vitae nisi sed morbi ultrices sed. Egestas interdum elit integer nec pharetra eget blandit dolor purus. A sed nisi congue morbi fermentum blandit. Turpis pretium dignissim risus ultrices purus et amet netus nibh.</p>
            <p class="text-lg tracking-tight text-gray-200">Vestibulum est ante in congue a fusce nunc. At tristique donec nisi viverra vulputate blandit orci at lectus. Morbi cras urna congue ornare. Mi magna vestibulum tortor id nec tortor non. Enim orci lorem egestas sed morbi dui mauris etiam nulla. Euismod cursus viverra ut ac eu sit quam amet tempor. Id in suspendisse nam sit vitae ullamcorper mollis et ut.</p>
          </div>
        </div>
      </section>`

  const casperContent5 =          
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 left-0 -ml-60 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative">
            <span class="block text-sm font-medium text-sky-900 mb-4">BLOG CATEGORY</span>
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-10">Gorgeous UI Library</h2>
            <img class="hidden md:block absolute top-0 right-0 animate-spinStar" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/blink-star.png" alt="" />
            <div class="relative flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
                <div class="max-w-2xl mx-auto lg:mx-0 lg:max-w-md xl:max-w-xl">
                  <img class="block w-full mb-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/content/image-robot.png" alt="" />
                  <div class="-mb-2"><a class="inline-flex mb-2 mr-2 h-10 px-5 items-center justify-center text-sm text-white rounded-full bg-white bg-opacity-20" href="#">BRANDING</a><a class="inline-flex mb-2 mr-2 h-10 px-5 items-center justify-center text-sm text-white rounded-full bg-white bg-opacity-20" href="#">ILLUSTRATION</a><a class="inline-flex mb-2 h-10 px-5 items-center justify-center text-sm text-white rounded-full bg-white bg-opacity-20" href="#">LANDING PAGE</a></div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="max-w-2xl mx-auto lg:mx-0 lg:max-w-md xl:max-w-xl">
                  <h3 class="text-2xl text-gray-50 font-medium mb-8">Mi quisque ultrices hendrerit nec aliquet integer mollis faucibus morbi.</h3>
                  <p class="text-lg tracking-tight text-gray-200 mb-4">Pellentesque ipsum nulla in eget interdum a. Neque pellentesque pulvinar mauris pulvinar diam. Turpis aliquet pellentesque velit vitae nisi sed morbi ultrices sed. Egestas interdum elit integer nec pharetra eget blandit dolor purus. A sed nisi congue morbi fermentum blandit. Turpis pretium dignissim risus ultrices purus et amet netus nibh.</p>
                  <p class="text-lg tracking-tight text-gray-200 mb-10">Vestibulum est ante in congue a fusce nunc. At tristique donec nisi viverra vulputate blandit orci at lectus. Morbi cras urna congue ornare. Mi magna vestibulum tortor id nec tortor non. Enim orci lorem egestas sed morbi dui mauris etiam nulla. Euismod cursus viverra ut ac eu sit quam amet tempor. Id in suspendisse nam sit vitae ullamcorper mollis et ut.</p>
                  <p class="text-lg tracking-tight text-gray-200 mb-4">Pellentesque ipsum nulla in eget interdum a. Neque pellentesque pulvinar mauris pulvinar diam. Turpis aliquet pellentesque velit vitae nisi sed morbi ultrices sed. Egestas interdum elit integer nec pharetra eget blandit dolor purus. A sed nisi congue morbi fermentum blandit. Turpis pretium dignissim risus ultrices purus et amet netus nibh.</p>
                  <p class="text-lg tracking-tight text-gray-200">Vestibulum est ante in congue a fusce nunc. At tristique donec nisi viverra vulputate blandit orci at lectus. Morbi cras urna congue ornare. Mi magna vestibulum tortor id nec tortor non. Enim orci lorem egestas sed morbi dui mauris etiam nulla. Euismod cursus viverra ut ac eu sit quam amet tempor. Id in suspendisse nam sit vitae ullamcorper mollis et ut.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const arr = [
    { label: 'stickyProductScreenshot',content: stickyProductScreenshot,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/content-stickyProductScreenshot.png' },
    { label: 'withTestimonial',content: withTestimonial,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/content-withTestimonial.png' },
    { label: 'twoColumnsWithScreenshot',content: twoColumnsWithScreenshot,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/content-twoColumnsWithScreenshot.png' },
    { label: 'withTestimoialandStats',content: withTestimoialandStats,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/content-withTestimoialandStats.png' },
    { label: 'splitWithImage',content: splitWithImage,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/content-splitWithImage.png' },
    { label: 'centered',content: centered,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/content-centered.png' },
    { label: 'casperContent1',content: casperContent1,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/content1.png' },
    { label: 'casperContent2',content: casperContent2,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/content2.png' },
    { label: 'casperContent3',content: casperContent3,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/content3.png' },
    { label: 'casperContent4',content: casperContent4,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/content4.png' },
    { label: 'casperContent5',content: casperContent5,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/content5.png' },
  ]
  return arr
}
