import axios from 'axios';

const baseUrl = 'https://new-apiv2.importir.com/'

export const uploadAws = async (data, md5check) => {
  const configTest = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-MD5': md5check
    },
  }
  try {
    // Make the HTTP request to upload the file to AWS
    const response = await axios.post(`${baseUrl}api/upload-aws/image`, 
      data, configTest
    );
    
    return response
  } catch (error) {
    throw new Error('Error uploading file to AWS', error)
  }
}

export const getFolderAws = async () => {
  const configTest = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await axios.get(`${baseUrl}api/upload-aws/folder-list`, configTest
    );

    return response
  } catch (error) {
    throw new Error('Error get file from AWS', error)
  }
}

export const uploadDigitalOcean = async (data, md5check) => {
  const configTest = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-MD5': md5check
    },
  }
  try {
    // Make the HTTP request to upload the file to digital ocean
    const response = await axios.post(`${baseUrl}api/upload-do/upload`, 
      data, configTest
    );
    
    return response
  } catch (error) {
    throw new Error('Error uploading file to AWS', error)
  }
}

export const uploadImageAws = async (data) => {
  const configTest = {
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Content-MD5': md5check
    },
  }
  try {
    // Make the HTTP request to upload the file to digital ocean
    const response = await axios.post(`${baseUrl}api/upload-aws/image`, 
      data, configTest
    );

    return response
  } catch (error) {
    throw new Error('Error uploading file to AWS', error);
  }
}