import loadComponents from './component';
import loadTraits from './traits';

function buttonPlugin(editor, opts = {}) {
  const options = {};
  for (const name in options) {
    if (!(name in opts)) opts[name] = options[name];
  }

  loadTraits(editor, options);
  loadComponents(editor, opts);
}

export default buttonPlugin;
