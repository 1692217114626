import { Badge, Box, Flex, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TbReload } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { addDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import ImageComponent from '../../Components/Image/ImageComponent';
import useUserStore from '../../Hooks/Zustand/Store';

function ProjectPage() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [modalNew, setModalNew] = useState(false);

  const navigate = useNavigate();
  const getData = async () => {
    try {
      if (globalState?.currentCompany) {
        const conditions = [
          {
            field: 'companyId',
            operator: '==',
            value: globalState?.currentCompany,
          },
          {
            field: 'users',
            operator: 'array-contains',
            value: globalState?.uid,
          },
        ];
        const data = await getCollectionFirebase('projects', conditions);
        setData(data);
      }
    } catch (error) {
      throw new Error(`Err code IP-GD: ${error}`)
    }
  };

  const handleNewProject = () => {
    setModalNew(true);
  };

  const createNewProject = () => {
    const data = {
      name: title,
      companyId: globalState.currentCompany,
      lastUpdated: new Date(),
      type: 'marketing',
      owner: [globalState.uid],
      users: [globalState.uid],
    };
    addDocumentFirebase('projects', { ...data, type: 'marketing' }, globalState.currentCompany).then((x) => {
      navigate(`/team/project/${x}`);
    });
  };

  useEffect(() => {
    getData();
    return () => {
      setData();
    };
  }, [globalState?.currentCompany]);

  return (
    <Stack
      p={[1, 1, 5]}
      spacing={5}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'}>Projects</Heading>
        <Spacer />

        <DynamicButton
          action={'create'}
          title='Project'
          onClick={handleNewProject}
        />
        <DynamicButton
          action={'custom'}
          icon={TbReload}
          variant='solid'
          title='Configuration'
          color='blue'
          onClick={() => window.location.reload()}
        />
      </HStack>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
        p={{ base: '2', md: '4' }}
        spacing={{ base: 4, md: 6 }}
        my={5}>
        {data?.length > 0 &&
          data.map((x, i) => (
            <Stack
              key={i}
              p='5'
              shadow='base'
              minH='450px'
              borderRadius='md'
              onClick={() => navigate(`${x.id}`)}
              cursor='pointer'>
              <Stack
                height='100%'
                width='98%'>
                <Stack minH='50%'>
                  <ImageComponent
                    image={x.image}
                    name={x.name}
                  />
                </Stack>
                <Spacer />
                <Stack>
                  <Text fontWeight={'bold'}>{x.name}</Text>
                  <Text fontSize='3xs'>ID: {x.id}</Text>
                </Stack>
                <SimpleGrid
                  columns={{ base: 2, lg: 3 }}
                  textAlign='center'>
                  <Box
                    shadow='base'
                    borderRadius='md'
                    m='1'
                    p='1'>
                    <Text fontSize='xs'>Manager</Text>
                    <Text fontWeight={500}>{x?.managers?.length ? x.managers.length : 0}</Text>
                  </Box>
                  <Box
                    shadow='base'
                    borderRadius='md'
                    m='1'
                    p='1'>
                    <Text fontSize='xs'>Users</Text>
                    <Text fontWeight={500}>{x?.users?.length ? x.users.length : 0}</Text>
                  </Box>
                  <Box
                    shadow='base'
                    borderRadius='md'
                    m='1'
                    p='1'>
                    <Text fontSize='xs'>Modules</Text>
                    <Text fontWeight={500}>{x?.modules?.length ? x.modules.length : 0}</Text>
                  </Box>
                </SimpleGrid>
                <Flex
                  overflowY='auto'
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '1px',
                      height: '1px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'blue',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'transparent',
                    },
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'blue transparent',
                  }}>
                  {x.modules?.map((z, index) => (
                    <Badge
                      key={index}
                      colorScheme='green'
                      m='1'
                      borderRadius={'md'}>
                      {z}
                    </Badge>
                  ))}
                </Flex>

                <Text
                  fontSize='2xs'
                  textAlign={'end'}>
                  Created: {moment(x?.createdAt?.seconds * 1000).format('LLL')}
                </Text>
              </Stack>
            </Stack>
          ))}
      </SimpleGrid>

      <Modal
        isOpen={modalNew}
        onClose={() => setModalNew(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input
              type='text'
              onChange={(e) => setTitle(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewProject()}
              title='Save'
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ProjectPage;
