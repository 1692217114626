/* eslint-disable react/prop-types */
import React from 'react'
import { Checkbox, Box,  Stack, Text } from '@chakra-ui/react';


function TiktokInput({data, setData}) {
  return (
    <Stack>
      <Text
        fontSize={'sm'}
        color="gray.500"
        fontWeight={'semibold'}
      >
                    Details Post for Tiktok
      </Text>
      <Stack px={2}>
        <Checkbox
          colorScheme="blue"
          onChange={(e) =>
            setData({
              ...data,
              tikTokOptions: {
                ...data.tikTokOptions,
                disableComments: e.target.checked,
              },
            })
          }
        >
          <Text fontSize={'sm'}>Disable Comments</Text>
        </Checkbox>
        <Checkbox
          colorScheme="blue"
          onChange={(e) =>
            setData({
              ...data,
              tikTokOptions: {
                ...data.tikTokOptions,
                disableDuet: e.target.checked,
              },
            })
          }
        >
          <Text fontSize={'sm'}>Disable Duet</Text>
        </Checkbox>
        <Checkbox
          colorScheme="blue"
          onChange={(e) =>
            setData({
              ...data,
              tikTokOptions: {
                ...data.tikTokOptions,
                disableStitch: e.target.checked,
              },
            })
          }
        >
          <Text fontSize={'sm'}>Disable Stitch</Text>
        </Checkbox>
      </Stack>
    </Stack>
  )
}

export default TiktokInput