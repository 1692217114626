export const stepsGrapesJs = () => {
  const casperSteps1 =
  `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative max-w-8xl md:max-w-8xl mx-auto">
            <div class="text-center mb-14">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">How Does Casper Work</h2>
              <p class="text-xl text-gray-400">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
            </div>
            <div class="flex flex-wrap items-center -mx-4 mb-16">
              <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                <div class="relative flex max-w-28rem mx-auto pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                  <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt="" />
                  <img class="relative w-87 h-87 rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-1.png" alt="" />
                </div>
              </div>
              <div class="w-full md:w-1/2 px-4">
                <div class="max-w-lg mx-auto">
                  <h3 class="font-heading text-4xl lg:text-5xl font-semibold text-white">01. Upload</h3>
                  <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                  <p class="text-xl text-white tracking-tight">Our user-friendly interface allows for quick setup, and our dedicated support team is ready to assist you every step of the way.</p>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap items-center -mx-4 mb-16">
              <div class="w-full md:w-1/2 px-4 order-last md:order-first">
                <div class="max-w-lg mx-auto">
                  <h3 class="font-heading text-4xl lg:text-5xl font-semibold text-white">02. Auto Generation</h3>
                  <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                  <p class="text-xl text-white tracking-tight">From answering questions to providing recommendations, our AI assistant will engage your visitors effortlessly.</p>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                <div class="relative flex max-w-28rem mx-auto pt-12 lg:pt-20 pl-12 lg:pl-20 md:ml-auto items-end justify-end rounded-3xl overflow-hidden">
                  <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt=""/>
                  <img class="relative w-87 h-87 rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-2.png" alt=""/>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap items-center -mx-4">
              <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                <div class="relative flex max-w-28rem mx-auto pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                  <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt="" />
                  <img class="relative w-87 h-87 rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-3.png" alt=""/>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-4">
                <div class="max-w-lg mx-auto">
                  <h3 class="font-heading text-4xl lg:text-5xl font-semibold text-white">03. Select &amp; Send</h3>
                  <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                  <p class="text-xl text-white tracking-tight">Our user-friendly interface allows for quick setup, and our dedicated support team is ready to assist you every step of the way.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperSteps2 =
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden border">
        <div class="relative container mx-auto px-4 border">
          <div class="absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative max-w-24rem md:max-w-8xl mx-auto border">
            <div class="max-w-xl mb-16">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">How it works</h2>
              <p class="text-xl text-gray-400">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
            </div>
            <div class="flex flex-wrap -mx-4">
              <div class="w-full md:w-1/3 px-4 mb-8 md:mb-0">
                <div>
                  <h3 class="text-5xl font-semibold text-gray-700">01</h3>
                  <div class="my-6 h-1 w-full bg-gray-500 bg-opacity-20"></div>
                  <p class="pr-3 text-base lg:text-xl text-gray-50 tracking-tight">From answering questions to providing recommendations, our AI assistant will engage your visitors effortlessly.</p>
                </div>
              </div>
              <div class="w-full md:w-1/3 px-4 mb-8 md:mb-0">
                <div>
                  <h3 class="text-5xl font-semibold text-gray-700">02</h3>
                  <div class="my-6 h-1 w-full bg-gray-500 bg-opacity-20"></div>
                  <p class="pr-3 text-base lg:text-xl text-gray-50 tracking-tight">By understanding your visitors' needs, our chat assistant creates a unique experience</p>
                </div>
              </div>
              <div class="w-full md:w-1/3 px-4">
                <div>
                  <h3 class="text-5xl font-semibold text-gray-700">03</h3>
                  <div class="my-6 h-1 w-full bg-gray-500 bg-opacity-20"></div>
                  <p class="pr-3 text-base lg:text-xl text-gray-50 tracking-tight">Say goodbye to static web pages and hello to an interactive, conversational platform</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperSteps3 =
      `<section class="relative font-body py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative max-w-28rem md:max-w-8xl mx-auto">
            <div class="text-center mb-20 md:mb-40">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">How Does Casper Work</h2>
              <p class="text-xl text-gray-400">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
            </div>
            <div class="flex flex-wrap -mx-4 -mb-24">
              <div class="w-full md:w-1/2 px-4 mb-24">
                <div class="max-w-28rem xl:max-w-lg">
                  <div class="mb-8">
                    <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-white">01. Upload</h3>
                    <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                    <p class="text-xl text-gray-100 tracking-tight">Our user-friendly interface allows for quick setup, and our dedicated support team is ready to assist you every step of the way.</p>
                  </div>
                  <div class="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                    <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt="" />
                    <img class="relative rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-2.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-4 mb-24">
                <div class="max-w-28rem xl:max-w-lg md:ml-auto">
                  <div class="mb-8">
                    <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-white">03. Select &amp; Send</h3>
                    <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                    <p class="text-xl text-gray-100 tracking-tight">Gain valuable insights into your users' preferences, behavior, and frequently asked questions with Casper advanced analytics dashboard</p>
                  </div>
                  <div class="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                    <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt="" />
                    <img class="relative rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-3.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-4 mb-24">
                <div class="max-w-28rem xl:max-w-lg">
                  <div class="mb-8">
                    <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-white">02. Auto Generation</h3>
                    <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                    <p class="text-xl text-gray-100 tracking-tight">From answering questions to providing recommendations, our AI assistant will engage your visitors effortlessly.</p>
                  </div>
                  <div class="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                    <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt="" />
                    <img class="relative rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-1.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 px-4 mb-24">
                <div class="max-w-28rem xl:max-w-lg md:ml-auto">
                  <div class="mb-8">
                    <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-white">04. Results</h3>
                    <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                    <p class="text-xl text-gray-100 tracking-tight">Gain valuable insights into your users' preferences, behavior, and frequently asked questions with Casper advanced analytics dashboard</p>
                  </div>
                  <div class="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                    <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-square-gradient.png" alt="" />
                    <img class="relative rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/image-square-sm-3.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperSteps4 =
      `<section class="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
        <div class="absolute top-0 right-0 mr-40 -mt-20 w-160 h-128 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl"></div>
        <div class="absolute top-0 right-0 mt-64 mr-128 w-96 h-96 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl"></div>
        <div class="relative container mx-auto px-4">
          <div class="max-w-36rem lg:max-w-none mx-auto">
            <div class="mb-20">
              <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">How it works</h2>
              <p class="text-xl text-gray-400">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
            </div>
            <div class="relative flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
                <div class="max-w-sm pr-4 mb-8">
                  <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-gray-700">01</h3>
                  <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                  <p class="text-xl text-gray-100 tracking-tight">From answering questions to providing recommendations, our AI assistant will engage your visitors effortlessly.</p>
                </div>
                <div class="max-w-sm pr-4 mb-8">
                  <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-gray-700">02</h3>
                  <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                  <p class="text-xl text-gray-100 tracking-tight">By understanding your visitors' needs, our chat assistant creates a unique experience</p>
                </div>
                <div class="max-w-sm pr-4">
                  <h3 class="font-heading text-4xl xl:text-5xl font-semibold text-gray-700">03</h3>
                  <div class="my-6 h-1 w-full bg-white bg-opacity-20"></div>
                  <p class="text-xl text-gray-100 tracking-tight">Say goodbye to static web pages and hello to an interactive, conversational platform</p>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="relative flex max-w-36rem mx-auto lg:mr-0 pt-16 sm:pt-24 pl-16 sm:pl-24 items-end justify-end rounded-3xl overflow-hidden">
                  <img class="absolute top-0 left-0 w-full h-full" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/bg-gradient-lg.png" alt="" />
                  <img class="relative w-full h-full rounded-tl-3xl object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/how-it-works/box-fabric-lg.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const arr = [
    { label: 'casperSteps1',content: casperSteps1,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/steps1.png' },
    { label: 'casperSteps2',content: casperSteps2,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/steps2.png' },
    { label: 'casperSteps3',content: casperSteps3,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/steps3.png' },
    { label: 'casperSteps4',content: casperSteps4,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/steps4.png' },
  ]
  return arr
}
