/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from '@chakra-ui/react';
import React,{ useEffect,useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useMarketingStore from '../../../Hooks/Zustand/MarketingStore';
import moment from 'moment';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';

function MarketingMetaAddBudgetPage() {
  const marketingState = useMarketingStore();
  const [totalEstimateBudget,setTotalEstimateBudget] = useState();
  const [activeDays,setActiveDays] = useState();
  const param = useParams();
  const [data,setData] = useState();

  const steps = [
    { title: 'Campaign',description: 'Create Campaign' },
    { title: 'Ads',description: 'Create Ads' },
    { title: 'Audience',description: 'Select Audience' },
    {
      title: 'Budget & Strategies',
      description: 'Set your budget & strategies',
    },
    { title: 'Publish',description: 'Review & publish' },
  ];

  const navigate = useNavigate();

  const handleDays = () => {
    const start = moment(marketingState?.budgeting?.durationStart);
    const end = moment(marketingState?.budgeting?.durationEnd);

    const dateCount = moment.duration(end?.diff(start));
    const daysCount = dateCount.asDays();

    setActiveDays(daysCount);

    const totalBudget = parseInt(marketingState?.budgeting?.dailyBudget) * parseInt(daysCount);
    setTotalEstimateBudget(totalBudget);
  };

  const getCampaignData = async () => {
    try {
      const res = await getSingleDocumentFirebase('marketing_meta',param?.id);

      setData(res);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleNavigate = (value) => {
    if (param?.type === 'edit') {
      navigate(`/marketing/meta/ads/create/publish/edit/${value?.id}`);
    } else {
      navigate('/marketing/meta/ads/create/publish');
    }
  };

  useEffect(() => {
    if (param?.type === 'edit') {
      getCampaignData();
    }

    return () => { };
  },[]);

  useEffect(() => {
    if (marketingState?.budgeting?.durationEnd !== '' || marketingState?.budgeting?.durationStart !== '') {
      handleDays();
    }

    return () => { };
  },[marketingState?.budgeting?.durationStart,marketingState?.budgeting?.durationEnd,marketingState?.budgeting?.dailyBudget]);

  return (
    <Box>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={4}
        bg={'white'}
        p={5}
        borderRadius={'md'}
        shadow={'base'}
        my={5}>
        {steps.map((step,index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Heading textAlign='center'>Budget & Strategies </Heading>

      <Box>
        <Container
          p='5'
          mt='5'
          shadow='base'
          bg={'white'}
          borderRadius={'md'}>
          <FormControl>
            <FormLabel>Daily Budget</FormLabel>
            <HStack w={'100%'}>
              <Input
                w={'80%'}
                placeholder={param?.type === 'edit' ? data?.budgeting?.dailyBudget : 'Ads budget'}
                defaultValue={marketingState?.budgeting?.dailyBudget}
                onChange={(e) =>
                  marketingState?.setBudgeting({
                    dailyBudget: e.target.value,
                  })
                }
                onWheel={(e) => e.target.blur()}
                type='number'
              />
              <Text w={'20%'}>/ day</Text>
            </HStack>
          </FormControl>
          <FormControl my={3}>
            <Stack spacing={1}>
              <FormLabel>Duration</FormLabel>
              <HStack>
                <Box>
                  <Text>Start Date</Text>
                  <Input
                    placeholder={param?.type === 'edit' ? data?.budgeting?.durationStart : null}
                    defaultValue={marketingState?.budgeting?.durationStart}
                    onChange={(e) =>
                      marketingState?.setBudgeting({
                        durationStart: e.target.value,
                      })
                    }
                    type='datetime-local'
                  />
                </Box>
                <Box>
                  <Text>End Date</Text>
                  <Input
                    placeholder={param?.type === 'edit' ? data?.budgeting?.durationEnd : null}
                    value={marketingState?.budgeting?.durationEnd}
                    onChange={(e) =>
                      marketingState?.setBudgeting({
                        durationEnd: e.target.value,
                      })
                    }
                    type='datetime-local'
                  />
                </Box>
              </HStack>
            </Stack>
          </FormControl>

          {/* <Box align={'center'}>
            Budget Estimation within active days
            <Heading>
              {isNaN(totalEstimateBudget) || totalEstimateBudget < 0
                ? 0
                : formatFrice(totalEstimateBudget)}
            </Heading>
          </Box> */}

          {/* <FormControl as='fieldset'>
            <FormLabel as='legend'>Strategies</FormLabel>
            <RadioGroup
              gap={3}
              value={marketingState?.budgeting?.strategies}
              onChange={(e) =>
                marketingState?.setBudgeting({
                  strategies: e,
                })
              }
              defaultValue='External Website'>
              {strategies.map((x, i) => (
                <HStack
                  key={i}
                  spacing='24px'
                  m='2'
                  shadow='base'
                  p='2'>
                  <Radio value={x.name}>
                    <Box>
                      <Heading
                        mb={2}
                        fontSize='md'>
                        {x.name}
                      </Heading>
                      <Flex gap={2}>
                        <Image
                          width='100px'
                          src={x.image}
                          alt={x.name}
                        />
                        <Text fontSize={13}>{x.description}</Text>
                      </Flex>
                      <Text>Steps</Text>
                      {x.strategy.map((y, a) => (
                        <Text
                          pl='3'
                          fontSize={13}>
                          {a + 1}. {y}
                        </Text>
                      ))}
                    </Box>
                  </Radio>
                </HStack>
              ))}
            </RadioGroup>
            <FormHelperText>Select only if you're a fan.</FormHelperText>
          </FormControl> */}

          <Button
            w='full'
            colorScheme='green'
            onClick={() => handleNavigate(data)}>
            Next
          </Button>
        </Container>
      </Box>
    </Box>
  );
}

export default MarketingMetaAddBudgetPage;
