import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../../../Hooks/Zustand/Store';

const FormV2 = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const globalState = useUserStore()

  const handleQueryParams = () => {
    const url = window.location.href;
    const params = new URLSearchParams(url.split('?')[1]);

    const queryParams = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    console.log(queryParams);
    return queryParams
  }

  const handleCreateOrder = async (data) => {
    globalState?.setIsLoading(true)
    const items = [
      {
        'variantId': data?.variant, // warehouse denpasar (warehouse id = lQzFqM4JYgDMh879EklR)
        'productId': data?.productId, // cari warehouse where projectId === productId
        'quantity': data?.quantity,
        'message': 'halo world',
        'shipping_provider': { // if variant type == physical, shipping_provider required!!!
          'provider': data?.serviceName, // oExpress || jne ======= required!!!
          'service': data?.servicePackage, // HORE,SERBU,KISS,SATSET,SULTAN,GOCAP (oExpress) || REG, OKE, JTR, YES, ======= required!!!
          'description': '-',
          'note': '',
          'from': data?.originId, //required!!!
          'to': data?.destinationId // example: 4569 (oExpress),  CLG10000, PDG10000 (jne) required!!!
        }
      },
    ];

    if (data?.type === 'digital') delete items[0].shipping_provider;


    const payload = {
      'userId': '',
      'userType': 'customer', // customer || user
      'companyId': data?.companyId,
      'projectId': data?.projectId,
      'paymentGateway': 'midtrans', // xendit || midtrans || ipaymu || finpay || stripe
      'paymentService': data?.bank === 'qr' || data?.bank === 'gopay' ? 'gopay' : 'bank_transfer', // va || qr || gopay 
      'bank': data?.bank === 'qr' ? '' : data?.bank, // bca || bni || bri || permata || 
      'module': 'commerce', // commerce || management || business || ...
      'is_production': false, // true or false for xendit create payment
      'items': items,
      'contact_information': {
        'email': data?.email,
        'name': data?.name,
        'phone_number': data?.phone,
        'address': {
          'street': data?.jalan,
          'city': data?.kota,
          'state': data?.provinsi,
          'postal_code': data?.postalCode,
          'country': 'indonesia'
        },
      },
      'utm_medium': '',
      'utm_campaign': '',
      'utm_content': '',
    };
    // return console.log(payload)
    const config = {
      url: 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/order-createOrder',
      method: 'post',
      data: payload,
    }
    console.log(payload, 'payload');
    try {
      const res = await axios(config);
      console.log(res.data, 'res.data')
      if (res.data?.status === true && Object.keys(res.data?.data?.payment_information)?.length > 0) {
        navigate('/payment', { state: res.data });
      } else if (res.data?.status === false) {
        toast({
          title: 'Oops!',
          description: res?.data.error?.message || 'Order tidak berhasil',
          status: 'warning',
          isClosable: true,
          duration: 9000
        })
        // navigate(-1)
      } else {
        toast({
          title: 'Oops!',
          description: res?.data?.message || 'Order tidak berhasil',
          status: 'warning',
          isClosable: true,
          duration: 9000
        })
        // navigate(-1)
      }
    } catch (error) {
      console.log(error.message, 'error order');
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        isClosable: true,
        duration: 9000
      })
      // navigate(-1)
    } finally {
      globalState?.setIsLoading(false)
    }
  };

  useEffect(() => {
    const res = handleQueryParams()

    if(res){
      handleCreateOrder(res)
    }

    return () => {
      
    }
  }, [])
  
  return (
    <div className=''>
      <h2 className='text-center my-5'>Redirecting ....</h2>
    </div>
  )
}

export default FormV2