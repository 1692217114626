import { Box, Center, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaPinterest, FaTelegram, FaTiktok, FaTwitter, FaYoutube } from 'react-icons/fa';
import useUserStore from '../../../Hooks/Zustand/Store';
import { addDocumentFirebase } from '../../../Api/firebaseApi';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import MediaLargeComponents from '../../../Components/Media/MediaLargeComponents';
import InstagramInput from '../../../Components/Inputs/InstagramInput';
import TiktokInput from '../../../Components/Inputs/TiktokInput';
import ScheduleInput from '../../../Components/Inputs/ScheduleInput';
import ApprovalList from '../../../Components/List/ApprovalList';


function ApprovedTab() {
  const [modalPublish, setModalPublish] = useState(false);
  const [shortenLinks, setShotenLinks] = useState(false);
  const [schedulePosting, setSchedulePosting] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [files, setFiles] = useState([]);
  const [platformActive, setPlatformActive] = useState([]);
  const [postTypes, setPostTypes] = useState('post');
  const toast = useToast();
  const globalState = useUserStore();

  const platformIcons = {
    twitter: FaTwitter,
    youtube: FaYoutube,
    tiktok: FaTiktok,
    instagram: FaInstagram,
    linkedin: FaLinkedin,
    telegram: FaTelegram,
    facebook: FaFacebook,
    google: FaGoogle,
    pinterest: FaPinterest,
  };

  const [data, setData] = useState({
    post: '',
    platforms: [''],
    shortenLinks: shortenLinks,
    profileKey: globalState?.socialKey,
  });

  const handleOpenModal = () => {
    setModalPublish(true);
  };

  const handleCloseModal = () => {
    setModalPublish(false);
    setFiles([]);
  };

  const handleAddPlatform = (media) => {
    if (globalState?.socialData?.activeSocialAccounts?.filter((x) => x?.includes(media)).length > 0) {
      setPlatformActive([media]);
      if (!platformActive.includes(media)) {
        setPlatformActive([...platformActive, media]);
        setData({ ...data, platforms: [...platformActive, media] });
      } else {
        setPlatformActive(platformActive.filter((platform) => platform !== media));
        setData({
          ...data,
          platforms: platformActive.filter((platform) => platform !== media),
        });
      }
    } else {
      toast({
        title: 'Deoapp.com',
        description: `${media} need to enabled. please go to Social Accounts page to set up`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  // const uploadApi = async (file, fileName, description) => {
  //   try {
  //     const resImage = await _axios.post('ayrshare-upload', {
  //       file: file,
  //       fileName: fileName,
  //       description: description,
  //     });
  //     return resImage;
  //   } catch (error) {
  //     throw error.message;
  //   }
  // };

  const handlePublish = async () => {
    if (data.profileKey === '') {
      return toast({
        title: 'Deoapp.com',
        description: 'You need to set up social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }

    globalState.setIsLoading(true);

    try {
      if (files.length > 0) {
        const uploadPromises = files.map(async (x) => {
          return x?.file; // Mengembalikan URL untuk Promise.all
        });
        data.mediaUrls = await Promise.all(uploadPromises);
      }

      const dataSend = {
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        dataPost: data,
        status: 'pending',
        filesId: globalState?.socialData?.id,
        assignee: globalState?.uid,
        approvedBy: '',
        approvedAt: '',
      };

      const collectionName = 'social-media-post';

      try {
        await addDocumentFirebase(collectionName, dataSend, globalState?.currentCompany);
        toast({
          title: 'Deoapp.com',
          description: 'success add new document',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: 'error 1',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'error 2',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
      setFiles([]);
      setPlatformActive([]);
      setShotenLinks(false);
      setSchedulePosting('');
      handleCloseModal();
    }
  };

  return (
    <Stack>
      <HStack alignItems={'center'}>
        <Text
          fontWeight={'bold'}
          fontSize='xl'>
          Approvals
        </Text>
        <Spacer />
        <DynamicButton
          variant={'solid'}
          action={'create'}
          title='Create Post'
          size={'sm'}
          onClick={handleOpenModal}
        />
      </HStack>

      <Stack>
        <ApprovalList />
      </Stack>

      <Modal
        size={'2xl'}
        isOpen={modalPublish}
        onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'md'}>Create Post</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              alignItems='center'
              justifyContent={'center'}
              spacing={5}
              p={5}
              borderRadius={'md'}>
              <Stack>
                <Text
                  color={'gray.500'}
                  fontSize='sm'
                  fontWeight={'semibold'}>
                  Choose Platform To Post
                </Text>
              </Stack>
              <SimpleGrid
                gap={5}
                columns={[3, 6, 9]}>
                {Object.keys(platformIcons).map((platform, index) => (
                  <Center
                    key={index}
                    _hover={{
                      bg: 'gray.100',
                      transform: 'scale(1.02)',
                      transition: '0.3s',
                    }}
                    cursor={'pointer'}
                    border={'1px'}
                    borderColor={'gray.50'}
                    borderRadius={'md'}
                    p={1}
                    boxShadow={'sm'}
                    onClick={() => handleAddPlatform(platform)}>
                    {React.createElement(platformIcons[platform], {
                      size: 25,
                      cursor: 'pointer',
                      color: platformActive.includes(platform) ? 'green' : 'gray',
                    })}
                  </Center>
                ))}
              </SimpleGrid>
            </Stack>
            <Stack>
              {platformActive.includes('instagram') && (
                <Stack>
                  <Text
                    fontSize={'sm'}
                    color='gray.500'>
                    Select Type Post
                  </Text>
                  <Select
                    size={'sm'}
                    placeholder='Select type post'
                    onChange={(e) => {
                      setPostTypes(e.target.value);
                    }}>
                    <option value='post'>Post</option>
                    <option value='reels'>Reels</option>
                    <option value='stories'>Stories</option>
                  </Select>
                </Stack>
              )}
              <Text
                fontSize={'sm'}
                color='gray.500'>
                Type your post
              </Text>
              <Textarea
                placeholder='Here is a sample placeholder'
                fontSize={'sm'}
                onChange={(e) => setData({ ...data, post: e.target.value })}
              />

              <MediaLargeComponents
                profileKeyData={globalState?.socialKey}
                setImageHeight={setImageHeight}
                setImageWidth={setImageWidth}
                setFiles={setFiles}
                files={files}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                data={data}
              />

              {platformActive.includes('instagram') ? (
                <Box py={2}>
                  <InstagramInput
                    data={data}
                    setData={setData}
                    postTypes={postTypes}
                    setPostTypes={setPostTypes}
                  />
                </Box>
              ) : null}

              {platformActive.includes('tiktok') ? (
                <Box py={2}>
                  <TiktokInput
                    data={data}
                    setData={setData}
                  />
                </Box>
              ) : null}

              <ScheduleInput
                setSchedulePosting={setSchedulePosting}
                schedulePosting={schedulePosting}
                setData={setData}
                data={data}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <DynamicButton
                action={'delete'}
                variant='solid'
                onClick={handleCloseModal}
                size='sm'
                title='Close'
              />
              <DynamicButton
                action={'create'}
                variant='solid'
                onClick={handlePublish}
                size='sm'
                title={'Publish'}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default ApprovedTab;
