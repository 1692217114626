// import useUserStore from '../Hooks/Zustand/Store'

//Get Users by UID
// export const getUserByUid = async (uid) => {
//     try {
//         const res = await getSingleDocumentFirebase('users', uid )
    
//         return {name: res?.name ?? '',
//         email: res?.email ?? '',
//         phone: res?.phone ?? '',
//         image: res?.image ?? '',}
//     } catch (error) {
//         console.log(error)
//     }
// }

export const getUserByUid = (dataUsers, uid) => {
  try {
    const res = dataUsers?.find((x) => x.id === uid)

    return res
  } catch (error) {
    throw new Error(error)
  }
}