import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
} from '@chakra-ui/react';
import React,{ useEffect,useState } from 'react';
import { FcFilledFilter,FcPlus } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useUserStore from '../../../Hooks/Zustand/Store';
import { AddIcon,CloseIcon } from '@chakra-ui/icons';
import { addDocumentFirebase,deleteDocumentFirebase,getCollectionFirebase } from '../../../Api/firebaseApi';
import CardAvatar from '../../../Components/Card/CardAvatar';

function MarketingFunnelsPage() {
  const navigate = useNavigate();
  const [title,setTitle] = useState('');
  const globalState = useUserStore();
  const deleteFunnelModal = useDisclosure();
  const toast = useToast();
  const [detailFunnel,setDetailFunnel] = useState({});
  const [modalNewPage,setModalNewPage] = useState(false);
  const [funnels,setFunnels] = useState([]);
  const [currentPage,setCurrentPage] = useState(0);
  const itemsPerPage = 6;

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + itemsPerPage);
  };

  const getFunnels = async () => {
    if (!globalState?.currentCompany) {
      return toast({
        title: 'Error',
        description: 'Please check your select company',
        status: 'error',
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: 'Error',
        description: 'Please check your select project',
        status: 'error',
      });
    }
    //coba coba

    const conditions = [
      { field: 'companyId',operator: '==',value: globalState.currentCompany },
      { field: 'projectId',operator: '==',value: globalState.currentProject },
    ];
    // const limitValue = currentPage + itemsPerPage;
    const sortBy = { field: 'createdAt',direction: 'desc' };
    const limitValue = currentPage + itemsPerPage;

    try {
      const res = await getCollectionFirebase('funnels',conditions,sortBy,limitValue);
      setFunnels(res);
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleOpenModal = (dataFunnel) => {
    deleteFunnelModal.onOpen();
    setDetailFunnel(dataFunnel);
  };

  const handleNewPage = async () => {
    if (!globalState?.currentCompany) {
      return toast({
        title: 'Error',
        description: 'Please check your select company',
        status: 'error',
      });
    }
    if (!globalState?.currentProject) {
      return toast({
        title: 'Error',
        description: 'Please check your select project',
        status: 'error',
      });
    }

    const dataPage = {
      title: title,
      funnels: [],
      type: 'website',
      projectId: globalState.currentProject,
    };

    const collectionName = 'funnels';
    const data = dataPage;

    try {
      const docID = await addDocumentFirebase(collectionName,data,globalState.currentCompany);

      setModalNewPage(false);
      getFunnels();
      
      await addDocumentFirebase(
        'logs',
        {
          activity: 'add funnel ai landing page',
          uid: globalState.uid,
          projectId: globalState.currentProject,
          details: {
            ...dataPage,
            id: docID,
          },
        },
        globalState.currentCompany
      );
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleDeleteFunnel = async () => {
    try {
      await deleteDocumentFirebase('funnels',detailFunnel?.id);

      toast({
        status: 'success',
        title: 'Template Deleted',
        duration: 1000,
      });

      deleteFunnelModal.onClose();
    } catch (error) {
      throw new Error('Terjadi kesalahan:', error)

    }
  };

  useEffect(() => {
    getFunnels();

    return () => { };
  },[globalState?.currentProject,globalState?.currentCompany,currentPage]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Landing Page</Heading>
        <Spacer />
        <Button
          colorScheme='green'
          variant='outline'
          onClick={() => setModalNewPage(true)}>
          <HStack spacing={2}>
            <FcPlus />
            <Text
              textTransform='uppercase'
              fontSize='sm'
              fontWeight={500}>
              New Funnel
            </Text>
          </HStack>
        </Button>
      </HStack>

      <SimpleGrid
        my={5}
        columns={[1,2,3]}
        gap='4'>
        <>
          {funnels?.length > 0 ? (
            <>
              {funnels?.map((x,i) => (
                <CardAvatar
                  key={i}
                  data={x}
                  title={x.title || 'Unnamed Funnel'}
                  Icon={FcFilledFilter}
                  globalState={globalState}
                  timeCreated={x?.lastUpdated?.seconds}
                  // owner={x?.owner}
                  onNavigate={() => navigate(`${x?.id}`)}
                // userArray={x?.userDisplay}
                // onDelete={() => handleModalDelete(x)}
                />
              ))}
            </>
          ) : (
            <Box>
              <Text
                textAlign='center'
                fontWeight='semibold'>
                No Data Funnel, Please Create One
              </Text>
            </Box>
          )}
        </>
      </SimpleGrid>
      <Box align={'center'}>
        <Button
          onClick={handleLoadMore}
          colorScheme='blue'
          size='sm'>
          Load More
        </Button>
      </Box>

      <Modal
        isOpen={modalNewPage}
        onClose={() => setModalNewPage(false)}
        isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Funnels</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type='text'
              placeholder='Funnels Name'
              onChange={(e) => setTitle(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme='green'
                onClick={() => handleNewPage()}>
                Add New
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme='red'
                onClick={() => {
                  setModalNewPage(false);
                }}>
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={deleteFunnelModal.isOpen}
        onClose={deleteFunnelModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{detailFunnel.title} Funnel</ModalHeader>
          <ModalBody>Funnel will be deleted, are you sure?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                size={'sm'}
                colorScheme='green'
                onClick={handleDeleteFunnel}>
                Yes
              </Button>
              <Button
                size={'sm'}
                colorScheme='red'
                onClick={deleteFunnelModal.onClose}>
                No
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MarketingFunnelsPage;
