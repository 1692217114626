/* eslint-disable no-unused-vars */
export default (editor, config = {}) => {
  const tm = editor.TraitManager;

  tm.addType('button-href',{
    createInput({ trait }) {
      const el = document.createElement('div');

      el.innerHTML = `
          <div class="button-title__title-inputs">
            <input class="button-href__href" placeholder="eg: https://google.com"/>
          </div>
        `;

      const inputsTitle = el.querySelector('.button-href__href');
      inputsTitle.addEventListener('input',(ev) => {
        trait.set('value',ev.target.value);
      });

      return el;
    },

    onEvent({ elInput,component,event }) {
      const idComponent = component.getId()
      const classComponent = component.getClasses()
      const listClasses = classComponent.join(' ')
      const componentContent = component.__innerHTML()
      const inputsHref = elInput.querySelector('.button-href__href');

      let url = inputsHref.value

      if (inputsHref.value === '') {
        url = 'https://google.com'
      } else if (!inputsHref.value.includes('https://')) {
        url = 'https://' + inputsHref.value
      }

      component.replaceWith(`<button id=${idComponent} class="${listClasses}">${componentContent}</button>

        <script>
          function openLinkInNewTab() {
            var defaultUrl = 'https://www.google.com'
            var url = '${url}'

            window.open(url, '_blank');
          }
          document.getElementById("${idComponent}").addEventListener("click", openLinkInNewTab);
        </script>`)
    },
  });
};
