import { AspectRatio,Box,Button,Container,HStack,Heading,Image,Spacer,Stack,Text } from '@chakra-ui/react'
import React,{ useState } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types';

const MessengerInbox = ({ marketingState }) => {
  const [isExpanded,setIsExpanded] = useState(false)

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: '10px'
  };

  return (
    <Container w={'60%'}>
      <Box>
        <HStack p={2} justify={'center'}>
          <Button size={'xs'} colorScheme={'facebook'} onClick={() => setIsExpanded(false)}>Ad view</Button>
          <Button size={'xs'} onClick={() => setIsExpanded(true)}>Expanded view</Button>
        </HStack>

        {!isExpanded ? (
          <HStack bg={'white'} p={2}>
            <Image
              // w={75}
              boxSize={50}
              borderRadius="full"
              src={
                marketingState?.adsCreation?.businessLogo === ''
                  ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                  : marketingState?.adsCreation?.businessLogo
              }
              alt="your brand name"
            />
            <Stack spacing={0}>
              <HStack>
                <Text fontWeight={500}>{marketingState?.adsCreation?.businessName === ''
                  ? 'Business Name'
                  : marketingState?.adsCreation?.businessName}</Text>
                <Text bg={'gray.50'} borderRadius={'md'} px={1} fontSize={12}>Ad</Text>
              </HStack>
              <Text color={'blue'} fontSize={10}>View more</Text>
            </Stack>
            <Spacer />
            <Image
              // w={75}
              boxSize={50}
              borderRadius="sm"
              src={
                marketingState?.adsCreation?.carousel?.length === 0
                  ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                  : marketingState?.adsCreation?.carousel[0]?.link
              }
              alt="your brand name"
            />
          </HStack>
        ) : (
          <Box borderRadius={'md'} shadow={'base'} overflow={'hidden'}>
            <HStack p={2}>
              <Image
                // w={75}
                boxSize={70}
                borderRadius="full"
                src={
                  marketingState?.adsCreation?.businessLogo === ''
                    ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                    : marketingState?.adsCreation?.businessLogo
                }
                alt="your brand name"
              />
              <Box>
                <Heading size="md">
                  {marketingState?.adsCreation?.businessName === ''
                    ? 'Your Business Name'
                    : marketingState?.adsCreation?.businessName}
                </Heading>
                <Text>Sponsored</Text>
              </Box>
            </HStack>
            <Text mt="5" p={2}>
              {marketingState?.adsCreation?.businessName === ''
                ? 'Your Primary Text'
                : marketingState?.adsCreation?.primaryText}
              {/* 𝟱 𝗝𝗮𝗺 𝗣𝗿𝗮𝗸𝘁𝗲𝗸 𝗶𝗺𝗽𝗼𝗿𝘁 & 𝗥𝗶𝘀𝗲𝘁 𝗣𝗿𝗼𝗱𝘂𝗸 𝗟𝗮𝗿𝗶𝘀 berdasarkan data dari
                    Tokopedia/Shopee dengan Omset 100 Juta per bulan😱 . Kamu akan
                    di bimbing dari 0 hingga mendapatkan 1 Produk potensi CUAN 100
                    Juta Pertama Hanya dari 1 Produk Saja😍 . Yuk Catat tanggal &
                    waktunya */}
            </Text>
            <Box bgColor="F2F2F2" align={'center'}>
              <>
                {marketingState?.adsCreation?.carousel?.length <= 1 ? (
                  <Text align={'center'} p={5} fontSize={12}>Add More Media to see carousel display</Text>
                ) : (
                  <Slider {...settings}>
                    {marketingState?.adsCreation?.carousel?.map((x,i) => (
                      <Box borderRadius={'md'} p={2} key={i}>
                        <AspectRatio ratio={1}>
                          {x?.type === 'video' ? (
                            <iframe
                              src={
                                x?.link
                              }
                              alt="ads Image"
                            />
                          ) : (
                            <Image
                              objectFit={'cover'}
                              src={
                                x?.link
                              }
                              alt="ads Image"
                            />
                          )}
                        </AspectRatio>
                        <Box
                          bgColor="gray.100"
                          p="2"
                          borderTop={'1px'}
                          borderColor={'gray.300'}
                        >
                          <Button size={'sm'} colorScheme='facebook'>
                                                        Send message
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  </Slider>
                )}
              </>
            </Box>

          </Box>
        )}
      </Box>
    </Container>
  )
}

export default MessengerInbox

MessengerInbox.propTypes = {
  marketingState: PropTypes.any,
  data: PropTypes.any,
  param: PropTypes.string,
}