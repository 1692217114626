/* eslint-disable react/prop-types */
import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';

function InstagramAnalyticsPost({ analyticsData }) {
  const {
    commentsCount,
    engagementCount,
    impressionsCount,
    username,
    mediaProductType,
    igReelsVideoViewTotalTimeCount,
    likeCount,
    reachCount,
    // created,
    // igReelsAvgWatchTimeCount,
    // mediaType,
    // playsCount,
    // savedCount,
    // sharesCount,
    // totalInteractionsCount,
    
  } = analyticsData;

  const engagementRate =
        impressionsCount !== 0 && !isNaN(engagementCount)
          ? ((engagementCount || 0) / impressionsCount * 100).toFixed(2)
          : 0;

  let completionRate = 0;
  if (igReelsVideoViewTotalTimeCount !== 0 && !isNaN(reachCount)) {
    completionRate = ((reachCount || 0) / igReelsVideoViewTotalTimeCount * 100).toFixed(2);
  }

  const analyticsInfo = [
    { label: 'Likes', value: likeCount },
    { label: 'Comments', value: commentsCount },
    { label: 'Impressions', value: impressionsCount },
    { label: 'Reach', value: reachCount },
    { label: 'Engagement Rate (%)', value: engagementRate },
    { label: 'Completion Rate (%)', value: completionRate },
    { label: 'Post Type', value: mediaProductType },
    { label: 'username', value: `@${username}` },
  ];

  return (
    <SimpleGrid columns={[1, 2, 4]} gap={3}>
      {analyticsInfo.map(({ label, value }, index) => (
        <Stack key={index} align="center">
          <Text fontSize={'xs'}>{label}</Text>
          <Text fontWeight={'bold'}>{value}</Text>
        </Stack>
      ))}
    </SimpleGrid>
  );
}

export default InstagramAnalyticsPost;
