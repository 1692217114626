/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import {
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  Box,
  useToast,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import _axios from '../../Api/AxiosBarrier';

function UsersViewPage(props) {
  const toast = useToast();
  const { t } = useTranslation();
  const globalState = useUserStore();

  const [data, setData] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getData = () => {
    getSingleDocumentFirebase('users', props.uid)
      .then((x) => setData(x))
      .catch((err) => err.message);
  };

  const handleAddUser = async () => {
    if (
      globalState?.currentCompany === undefined ||
      globalState?.currentCompany === null
    ) {
      toast({
        status: 'warning',
        duration: 9000,
        title: 'you must set up your company',
        isClosable: true,
      });
      return;
    }

    if (
      data === undefined ||
      data?.name === '' ||
      data?.name === undefined ||
      data?.email === '' ||
      data.email === undefined
    ) {
      toast({
        status: 'warning',
        title: 'Name or Email or Phone number should not be empty,',
        duration: 3000,
        isClosable: true,
      });
    } else {
      try {
        if (globalState.roleCompany !== 'owner') {
          return displayAccessError();
        }
        setLoading(true);

        const existingUser = await findExistingUser();

        if (existingUser.length > 0) {
          await addExistingUserToCompany(existingUser[0].id);
        } else {
          await createUserAndHandleRoles();
        }

        props.getData('', null, 1);
        toast({
          title: t('toast.success'),
          description: 'success add new users',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        props.setModal(false);
      } catch (error) {
        toast({
          title: t('toast.alert'),
          description: error.message,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const editUser = async () => {
    try {
      setLoading(true);
      await updateUserInDatabase();
      props.getData('', null, 1);
    } catch (error) {
      toast({
        title: t('toast.alert'),
        description: error.message,
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      props.setModal(false);
    }
  };

  const displayAccessError = () => {
    return toast({
      title: t('toast.alert'),
      description: t('toast.noAccess'),
      status: 'warning',
      duration: 3000,
      isClosable: true,
    });
  };

  const findExistingUser = async () => {
    const conditions = [{ field: 'email', operator: '==', value: data?.email }];
    const sortBy = null;
    const limitValue = 1;
    return await getCollectionFirebase('users', conditions, sortBy, limitValue);
  };

  const addExistingUserToCompany = async (userId) => {
    const collectionName = 'companies';
    const docName = globalState.currentCompany;
    const field = 'users';
    const values = [userId];

    return await _axios.post('/company-addArrayField', {
      collection: collectionName,
      doc: docName,
      field,
      values,
    });
  };

  const createUserAndHandleRoles = async () => {
    setLoading(true);

    const dataUser = {
      ...data,
      email: data?.email,
      name: data?.name,
      companyId: globalState?.currentCompany,
      phoneNumber: data?.phoneNumber,
      country: 'indonesia',
    };

    try {
      const res = await _axios.post('/user-create', dataUser);

      if (res.status === true) {
        props.setModal(false);
      }
      return res;
    } catch (error) {
      setLoading(false);
      return error;
    } finally {
      setLoading(false);
    }
  };

  const updateUserInDatabase = async () => {
    await setDocumentFirebase(
      'users',
      props.uid,
      data,
      globalState.currentCompany
    );
  };

  const deleteUser = async () => {
    if (globalState.roleCompany !== 'owner') {
      return toast({
        title: t('toast.alert'),
        description: 'You don\'t have access to delete user',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }

    setLoadingDelete(true);

    const data = {
      uid: props.uid,
    };

    try {
      const res = await _axios.post('/user-delete', data);
      if (res.status === true) {
        await deleteUserFromCompany();
        await deleteFromProject();

        await deleteDocumentFirebase('users', props.uid);
      }

      setLoadingDelete(false);

      toast({
        title: 'Deoapp',
        status: 'success',
        description: 'Account deleted',
        duration: 3000,
      });
    } catch (error) {
      setLoadingDelete(false);
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      setLoadingDelete(false);
      props.getData('', null, 1);
    }
  };

  const deleteFromFirestore = async (collection, doc, field, value) => {
    const dataObj = {
      collection: collection,
      doc: doc,
      field: field,
      values: value,
    };

    await _axios.post('/company-removeFromArrayField', dataObj);
  };

  const deleteUserFromCompany = async () => {
    const dataConditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: props.uid,
      },
    ];

    const deleteUserFromCompany = await getCollectionFirebase(
      'companies',
      dataConditions
    );

    const updateCompanyPromises = deleteUserFromCompany.map(async (company) => {
      await deleteFromFirestore('companies', company?.id, 'owners', [
        props?.uid,
      ]);
      await deleteFromFirestore('companies', company?.id, 'users', [
        props?.uid,
      ]);
    });

    await Promise.all(updateCompanyPromises);
  };

  const deleteFromProject = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      {
        field: 'users',
        operator: 'array-contains',
        value: props?.uid,
      },
    ];

    const CompanyProject = await getCollectionFirebase('projects', conditions);

    const updateProjectPromises = CompanyProject.map(async (project) => {
      await deleteFromFirestore('projects', project?.id, 'owners', [
        props?.uid,
      ]);
      await deleteFromFirestore('projects', project?.id, 'users', [props?.uid]);

      const getSubcol = await getCollectionFirebase(
        `projects/${project.Id}/users`
      );

      if (getSubcol && getSubcol.length > 0) {
        await deleteDocumentFirebase(
          `projects/${project.id}/users`,
          props?.uid
        );
      }
    });

    await Promise.all(updateProjectPromises);
  };

  useEffect(() => {
    if (props.uid) getData(props.uid);

    return () => {
      setData();
    };
  }, []);

  return (
    <Box p="5">
      <HStack my={3}>
        <Stack w={'50%'}>
          <FormControl isRequired>
            <FormLabel fontSize={'sm'}>{t('name')}</FormLabel>
            <Input
              placeholder={data?.name ? data.name : 'Name'}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </FormControl>
        </Stack>

        <Stack w={'50%'}>
          <FormControl isRequired>
            <FormLabel fontSize={'sm'}>Email</FormLabel>
            <Input
              placeholder={data?.email ? data.email : 'email'}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </FormControl>
        </Stack>
      </HStack>
      <HStack my={3}>
        <Stack w={'50%'}>
          <Text fontSize={'sm'}>{t('phoneNumber')}</Text>
          <HStack>
            <Text>+62</Text>
            <Input
              placeholder={
                data?.phoneNumber ? data.phoneNumber : 'Phone ex: 81xxxxxxxxx'
              }
              onChange={(e) =>
                setData({ ...data, phoneNumber: e.target.value })
              }
            />
          </HStack>
        </Stack>
        <Stack w={'50%'}>
          <Text fontSize={'sm'}>{t('company')}</Text>
          <Input
            placeholder=""
            value={
              globalState.companies.find(
                (x) => x.id === globalState.currentCompany
              ).name
            }
            disabled
          />
        </Stack>
      </HStack>

      <Text fontWeight={500}>Bank</Text>
      <HStack my={3}>
        <Stack w={'50%'}>
          <Text fontSize={'sm'}>{t('bankAccount')}</Text>
          <Input
            placeholder={data?.bankAccount ? data.bankAccount : 'Ex: BCA'}
            onChange={(e) => setData({ ...data, bankAccount: e.target.value })}
          />
        </Stack>
        <Stack w={'50%'}>
          <Text fontSize={'sm'}>{t('bankAccountName')}</Text>
          <Input
            placeholder={
              data?.bankAccountName
                ? data.bankAccountName
                : 'Ex: Bank Account Name'
            }
            onChange={(e) =>
              setData({ ...data, bankAccountName: e.target.value })
            }
          />
        </Stack>
      </HStack>
      <HStack my={3}>
        <Stack w={'50%'}>
          <Text fontSize={'sm'}>{t('bankAccountNumber')}</Text>
          <Input
            placeholder={
              data?.bankAccountNumber
                ? data.bankAccountNumber
                : 'Ex: Bank Account Number'
            }
            onChange={(e) =>
              setData({ ...data, bankAccountNumber: e.target.value })
            }
          />
        </Stack>
        <Stack w={'50%'}>
          <Text fontSize={'sm'}>{t('joinDate')}</Text>
          <Input
            type="date"
            placeholder={data?.date ? data.date : 'Date'}
            onChange={(e) => setData({ ...data, joinDate: e.target.value })}
          />
        </Stack>
      </HStack>
      <HStack>
        <Spacer />
        {props.uid ? (
          <HStack>
            <DynamicButton
              isLoading={loadingDelete}
              action={'delete'}
              title={t('deletePermanent')}
              onClick={() => deleteUser()}
            />
            <DynamicButton
              variant={'solid'}
              isLoading={loading}
              action={'update'}
              title={t('updateUser')}
              onClick={() => editUser()}
            />
          </HStack>
        ) : (
          <DynamicButton
            variant={'solid'}
            isLoading={loading}
            action={'create'}
            title={t('submit')}
            onClick={() => handleAddUser()}
          />
        )}
      </HStack>
    </Box>
  );
}

export default UsersViewPage;
