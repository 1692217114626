import React from 'react';
import SocialBuildPage from '../Pages/SocialMedia/Posts/SocialBuildPage';
import SocialPublishPage from '../Pages/SocialMedia/Posts/SocialPublishPage';
import SocialAnalyticsPage from '../Pages/SocialMedia/Posts/SocialAnalyticsPage';
import CommentsPage from '../Pages/SocialMedia/Comments/CommentsPage';
import SocialEngagementPage from '../Pages/SocialMedia/Posts/SocialEngagementPage';
import ReportsPage from '../Pages/SocialMedia/Reports/ReportsPage';
import CalendarPage from '../Pages/SocialMedia/Calendar/CalendarPage';
import SocialAccountPage from '../Pages/SocialMedia/SocialAccounts/SocialAccountPage';
import SocialMediaPage from '../Pages/SocialMedia/SocialMediaPage';
import SocialMediaCreationPage from '../Pages/SocialMedia/ContentCreation/SocialMediaCreationPage';
import SocialMediaCreateionViewPage from '../Pages/SocialMedia/ContentCreation/SocialMediaCreateionViewPage';
import SocialMediaCreationVideoCreatePage from '../Pages/SocialMedia/ContentCreation/SocialMediaCreationVideoCreatePage';
import SocialMediaCreationEditPage from '../Pages/SocialMedia/ContentCreation/SocialMediaCreationVideoEditPage';


const SocialmediaRouter = [
  {
    path: '/social-media',
    element: <SocialMediaPage />,
  },
  {
    path: '/social-media/content',
    element: <SocialMediaCreationPage />,
  },
  {
    path: '/social-media/content/:contentId',
    element: <SocialMediaCreateionViewPage />,
  },
  {
    path: '/social-media/content/:contentId/edit/:fileId/:templateId',
    element: <SocialMediaCreationVideoCreatePage />,
  },
  {
    path: '/social-media/content/:contentId/editing/:fileId/:templateId',
    element: <SocialMediaCreationEditPage />,
  },
  {
    path: '/social-media/post',
    element: <SocialBuildPage />,
  },
  {
    path: '/social-media/publish',
    element: <SocialPublishPage />,
  },

  {
    path: '/social-media/analytics',
    element: <SocialAnalyticsPage />,
  },
  {
    path: '/social-media/comments',
    element: <CommentsPage />,
  },

  {
    path: '/social-media/engagement',
    element: <SocialEngagementPage />,
  },

  {
    path: '/social-media/reports',
    element: <ReportsPage />,
  },

  {
    path: '/social-media/schedule',
    element: <CalendarPage />,
  },
  {
    path: '/social-media/account',
    element: <SocialAccountPage />,
  },
];

export default SocialmediaRouter;
