import React,{ useEffect,useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Link,useNavigate } from 'react-router-dom';
import { AddIcon,CloseIcon } from '@chakra-ui/icons';
import { FaFacebookSquare } from 'react-icons/fa';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebase,getCollectionFirebase,getSingleDocumentFirebase } from '../../Api/firebaseApi';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';

function MarketingMetaIntegration() {
  const [modalNewPage,setModalNewPage] = useState(false);
  const [title,setTitle] = useState('');
  const [data,setData] = useState([]);
  const globalState = useUserStore();
  const navigate = useNavigate();
  const toast = useToast();
  const [dataUsers,setDataUsers] = useState({
    name: '',
    email: '',
    id: '',
    phoneNumber: '',
  });

  const handleNew = async () => {
    const collectionName = 'integrations';
    const newData = {
      title: title,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      balance: 0,
      bmId: '',
      type: 'ads',
      category: 'meta',
      status: 'pending',
      userId: globalState?.uid,
      userData: dataUsers,
    };

    try {
      const docID = await addDocumentFirebase(collectionName,newData,globalState?.currentCompany);
      navigate(`/marketing/meta/integration/${docID}`);

      toast({
        status: 'success',
        duration: 3000,
        title: 'Successfully added data',
        isClosable: true,
      });
      setModalNewPage(false);
      setTitle('');
    } catch (error) {
      toast({
        status: 'error',
        duration: 3000,
        title: error.message,
        isClosable: true,
      });
    }
  };

  const getDataIntegration = async () => {
    const conditions = [
      { field: 'type',operator: '==',value: 'ads' },
      { field: 'projectId',operator: '==',value: globalState?.currentProject },
    ];
    const sortBy = { field: 'createdAt',direction: 'desc' };
    try {
      const res = await getCollectionFirebase('integrations',conditions,sortBy);
      setData(res);
    } catch (error) {
      toast({
        status: 'error',
        duration: 3000,
        title: error.message,
        isClosable: true,
      });
    }
  };

  const getDataUser = async (uid) => {
    try {
      const result = await getSingleDocumentFirebase('users',uid);
      const dataUsersResult = {
        name: result?.name || '',
        email: result?.email || '',
        phoneNumber: result?.phoneNumber || '',
        id: uid || ''
      };
      setDataUsers({ ...dataUsers,...dataUsersResult });
    } catch (error) {
      throw new Error(error)
    }
  };

  useEffect(() => {
    getDataIntegration();

    return () => {
      setData([]);
    };
  },[globalState?.currentProject]);

  const handleOpenModal = () => {
    setModalNewPage(true);
    getDataUser(globalState.uid);
  };

  return (
    <Stack spacing={5} p={[1,1,5]}>
      <HStack>
        <BackButtons />
        <Heading size="lg">Integrations</Heading>
        <Spacer />
        <Input bg={'whitesmoke'} type="text" placeholder="Search Integration" maxW="sm" />
        <DynamicButton action="create" title="Integration" variant="solid" onClick={handleOpenModal} />
      </HStack>

      {data?.length > 0 ? (
        <SimpleGrid columns={[1,2,4]} bg={'white'} p={5} borderRadius={'md'} shadow={'base'}>
          {data.map((x,i) => (
            <Box
              cursor="pointer"
              key={i}
              shadow="base"
              p="2"
              m="2"
              borderRadius="md"
              onClick={() => navigate(`/marketing/meta/integration/${x.id}`)}
            >
              {/* <AspectRatio maxW="560px" ratio={1}>
                <Image src="https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg" alt="naruto" objectFit="cover" />
              </AspectRatio> */}

              <Badge colorScheme='green' size={'md'}>Connected</Badge>
              <HStack p="2">
                <Link to="id-here">
                  <Icon as={FaFacebookSquare} />
                  <Heading fontSize="md">{x.title}</Heading>
                </Link>
                <Spacer />
                <AvatarGroup size="sm" max={2}>
                  <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                  <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                  <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                  <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
                  <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                </AvatarGroup>
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <AlertArrayNull titleData={'Integration'} action={'Add Integration'} />
      )}

      <Modal isOpen={modalNewPage} onClose={() => setModalNewPage(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Input type="text" placeholder="new ads account" onChange={(e) => setTitle(e.target.value)} />
              <Input type="text" placeholder="Your name" defaultValue={dataUsers?.name} onChange={(e) => setDataUsers({ ...dataUsers,name: e.target.value })} />
              <Input type="text" placeholder="Your Email" defaultValue={dataUsers?.email} onChange={(e) => setDataUsers({ ...dataUsers,email: e.target.value })} />
              <Input type="text" placeholder="Your phone" defaultValue={dataUsers?.phoneNumber} onChange={(e) => setDataUsers({ ...dataUsers,phoneNumber: e.target.value })} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex gap={5}>
              <Button leftIcon={<AddIcon boxSize={3} />} colorScheme="green" onClick={handleNew}>
                Add New
              </Button>
              <Button leftIcon={<CloseIcon boxSize={3} />} colorScheme="red" onClick={() => setModalNewPage(false)}>
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default MarketingMetaIntegration;
