import React from 'react';
import NoAccessPage from '../Pages/Auth/NoAccessPage';
import RedirectElement from '../Pages/Auth/RedirectElement';
import FormV2 from '../Pages/Marketing/landingPage/SectionComponent/formV2';
import CheckoutFrontPayment from '../Pages/Purchase/CheckoutFrontPayment';

const AuthenticationMainRouter = [
  {
    path: '/login',
    element: <RedirectElement />,
  },

  {
    path: '/no-access',
    element: <NoAccessPage />,
  },
  {
    path: '/payment',
    element: <CheckoutFrontPayment />,
  },
  {
    path: '/form-payment',
    element: <FormV2 />,
  },




];

export default AuthenticationMainRouter;
