/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React,{ useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box,Button,Divider,Flex,HStack,Image,Input,Modal,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalHeader,ModalOverlay,Radio,RadioGroup,SimpleGrid,Spacer,Stack,Text,useToast } from '@chakra-ui/react';
import { formatFrice } from '../../Utils/Helper';
import DynamicButton from '../Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';
import _axios from '../../Api/AxiosBarrier';

function ModalBalanceMeta({ modalBalance,handleCloseModal,dataMeta }) {
  const globalState = useUserStore()
  const [isLoading,setIsLoading] = useState(false)
  const [paymentMethod,setPaymentMethod] = useState('MANDIRI')
  const [buttonPaidClicked,setButtonPaidClicked] = useState(false)
  const [dataPayment,setDataPayment] = useState()
  const [value,setValue] = useState()
  const [selectedPaymentMethodImage,setSelectedPaymentMethodImage] =
        useState('');
  const [dataTopup,setDataTopup] = useState({
    amount: 0,
    amount_total: 0,
    amount_fee: 0,
    payment_type: '',
  });

  const banks = [
    {
      name: 'BNI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png',
    },
    {
      name: 'MANDIRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png',
    },
    {
      name: 'PERMATA',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png',
    },
    {
      name: 'BRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png',
    },
  ];

  const toast = useToast()

  const handleInputChange = (e) => {
    const amount = parseFloat(e.target.value);
    const fee = amount * 0.07; // 7% fee calculation
    const totalAmount = amount + fee;

    setDataTopup({
      ...dataTopup,
      amount: amount,
      amount_fee: fee,
      amount_total: totalAmount,
    });
  };

  const handleCheckboxChange = (e) => {
    setPaymentMethod(e);

    setDataTopup({
      ...dataTopup,
      payment_type: e,
    });
  };

  const handleSave = async () => {
    const dataOrders = {
      orders: dataMeta,
      filesId: dataMeta.id,
      fileName: 'integrations',
      paymentStatus: 'open',
      userId: dataMeta?.userId || '',
      companyId: dataMeta?.companyId || '',
      projectId: dataMeta?.projectId || '',
      paymentMethod: dataTopup?.payment_type,
      bankCode: dataTopup?.payment_type,
      module: 'marketer',
      type: 'topup_balance',
      category: dataMeta?.category || '',
      tax: 0,
      discount: 0,
      amount: dataTopup?.amount,
      amountFee: dataTopup?.amount_fee,
      amountTotal: dataTopup?.amount_total,
    }

    const collectionName = 'orders';

    // try {
    //     const docID = await addDocumentFirebase(collectionName, dataOrders, dataMeta.companyId);
    //     console.log('ID Dokumen Baru:', docID);
    //     toast({
    //         title: "Deoapp.com",
    //         description: `success add new order id:${docID}`,
    //         status: "success",
    //         position: "top-right",
    //         isClosable: true,
    //       });
    //     handleCloseModal()
    // } catch (error) {
    //     toast({
    //         title: "Deoapp.com",
    //         description: error.message,
    //         status: "error",
    //         position: "top-right",
    //         isClosable: true,
    //       });

    // }
  }

  const handlePayment = async () => {
    setIsLoading(true)
    const dataPayment = {
      expected_amount: dataTopup?.amount_total,
      bank_code: value, //MANDIRI, CREDIT_CARD, BNI DLL
      name: globalState?.name,
      company_id: globalState?.currentCompany,
      project_id: globalState?.currentProject,
      outlet_id: 'xxxx',
      feeRule: true, //true and false
      is_production: true,
      userId: globalState?.uid,
      for_user_id: '',
      type: 'top-up', //onetime, subcribtion, top up
      module: 'marketing', //management, marketing, chat, hris
      callback_url: 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/xendit-billingCallback'
    }

    if (dataTopup?.amount_total === 0) {
      toast({
        title: 'Failed to pay',
        description: 'Please fill the topup amount',
        status: 'warning',
        duration: 3000,
        position: 'top'
      })
    }

    try {
      const res = await _axios.post('/xendit-billing',dataPayment)

      if (res?.status) {
        setButtonPaidClicked(true)
        setDataPayment(res?.data)
      } else {
        toast({
          title: 'Failed to pay',
          description: `An error occured ${res?.message}`,
          status: 'warning',
          duration: 3000,
          position: 'top'
        })
      }

    } catch (error) {
      throw new Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePaymentMethodSelect = (method) => {
    setValue(method);
    const imageFind = banks.find((x) => x.name === method);
    setSelectedPaymentMethodImage(
      imageFind?.uri || 'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
    );
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Announce',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };

  const handleCancelPay = () => {
    setDataPayment({});
    setDataTopup({
      amount: 0,
      amount_total: 0,
      amount_fee: 0,
      payment_type: '',
    })
    setButtonPaidClicked(false)
  };


  return (
    <Modal size={'2xl'} isOpen={modalBalance} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Balance</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!buttonPaidClicked ? (
            <Stack spacing={3}>
              <Stack>
                <Text fontWeight={'bold'} fontSize='lg'>Top up</Text>
                <Divider />
                <Input type={'number'} onChange={handleInputChange} />
                <Stack>
                  <HStack>
                    <Text color={'gray.700'}>Amount</Text>
                    <Spacer />
                    <Text fontWeight={500}>IDR.{formatFrice(dataTopup?.amount)}</Text>
                  </HStack>

                  <HStack>
                    <Text color={'gray.700'}>Fee</Text>
                    <Spacer />
                    <Text fontWeight={500}>IDR.{formatFrice(dataTopup?.amount_fee)}</Text>
                  </HStack>

                  <HStack>
                    <Text color={'gray.700'}>Total Amount</Text>
                    <Spacer />
                    <Text fontWeight={500}>IDR.{formatFrice(dataTopup?.amount_total)}</Text>
                  </HStack>
                </Stack>
              </Stack>
              <Divider />
              <Stack>
                <Text fontWeight={'bold'} fontSize='lg'>Payment With</Text>
                <Divider />
                <RadioGroup
                  value={value}
                  onChange={handlePaymentMethodSelect}
                  m="2"
                >
                  <Stack spacing={4} my={4}>
                    <Stack spacing={2}>
                      <Text fontWeight={500} fontSize="sm">
                                                Choose Virtual Account :
                      </Text>
                      <SimpleGrid
                        columns={[2,null,4]}
                        spacing={3}
                        align="start"
                      >
                        {banks?.map((x,index) => (
                          <Radio key={index} value={x.name}>
                            <Image src={x?.uri} w="80px" />
                          </Radio>
                        ))}
                      </SimpleGrid>
                    </Stack>

                    {/* <Stack spacing={2}>
                        <Text fontWeight={500} fontSize="sm">
                          Choose Credit Card :
                        </Text>
                        <Radio value={'CREDIT_CARD'}>
                          <Image
                            src={
                              'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
                            }
                            w="90px"
                          />
                        </Radio>
                      </Stack> */}
                  </Stack>
                </RadioGroup>
                {/* <HStack>
                                    <Checkbox isChecked={dataTopup?.payment_type === "mandiri"} value="mandiri" onChange={handleCheckboxChange} />
                                    <Text fontWeight={500}>Mandiri</Text>
                                </HStack>
                                <HStack>
                                    <Checkbox isChecked={dataTopup?.payment_type === "credit_card"} value="credit_card" onChange={handleCheckboxChange} />
                                    <Text fontWeight={500}>Credit Card</Text>
                                </HStack> */}
              </Stack>
            </Stack>
          ) : (
            <Stack>
              <HStack w="full" px={5}>
                <Image
                  src={selectedPaymentMethodImage}
                  alt={dataPayment?.bank_code}
                  w="80px"
                  borderRadius="xl"
                />

                <Spacer />

                <Text
                  fontSize="sm"
                  fontWeight={'bold'}
                  color={
                    dataPayment?.status === 'pending' ? 'red.600' : 'green.600'
                  }
                  textTransform="uppercase"
                >
                  {dataPayment?.status}
                </Text>
              </HStack>

              <Box bg="white" px={5}>
                <Text>No. Virtual Account : </Text>
                <Divider my={2} />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text fontSize={20} color="gray.500">
                    {dataPayment ? dataPayment?.account_number : 'none'}
                  </Text>
                  <Text
                    color="blue.600"
                    cursor="pointer"
                    onClick={() => handleCopy(dataPayment?.account_number)}
                  >
                                        SALIN
                  </Text>
                </Box>
                <Divider my={2} />
                <HStack py={1}>
                  {/* <Text fontSize="sm" textTransform={'uppercase'}>{store}</Text> */}
                  <Spacer />
                  <Text fontSize="sm">
                                        Rp. {dataPayment?.expected_amount}
                  </Text>
                </HStack>
                <Divider my={2} />

                <Text fontSize={10} color="gray.600">
                                    Proses verifikasi otomatis kurang dari 10 menit setelah
                                    pembayaran berhasil
                </Text>
                <Spacer />
                <Text fontSize={10} color="gray.600">
                                    Bayar ke Virtual Account di atas sebelum membuat donasi
                                    baru dengan Virtual account agar nomor tetap sama.
                </Text>
              </Box>
              <Box bg="white" p={5}>
                <Text fontSize={10} color="gray.600">
                                    Petunjuk Transfer mBanking :
                </Text>
                <Divider />
                <Text fontSize={10} color="gray.600">
                                    1. Login ke mBanking-mu, pilih Bayar, kemudian cari Xendit
                                    / {dataPayment?.bank_code} Virtual Account
                </Text>
                <Text fontSize={10} color="gray.600">
                                    2. Masukkan nomor Virtual Account
                </Text>
                <Text fontSize={10} color="gray.600">
                                    3. Pastikan nama dan nominal bayar benar
                </Text>
                <Text fontSize={10} fontWeight={500} color="red.500">
                                    4. Jika kamu sudah melakukan pembayaran, klik tombol done
                </Text>
              </Box>


            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <Flex gap={5}>
            {buttonPaidClicked ? (
              <HStack alignItems="center" justifyContent="center">
                <Box>
                  <DynamicButton
                    action={'delete'}
                    title="Cancel"
                    variant={'solid'}
                    onClick={() => handleCancelPay()}
                  />
                </Box>

                <Box>
                  <DynamicButton
                    action={'create'}
                    title="Done"
                    variant={'solid'}
                    // onClick={() => handleDonePayment()}
                  />
                </Box>
              </HStack>
            ) : (
              <>
                {/* <DynamicButton onClick={handleSave} variant='solid' action={'create'} title='Pay now' /> */}
                <DynamicButton onClick={handlePayment} isLoading={isLoading} variant='solid' action={'create'} title='Pay now' />

                <Button leftIcon={<CloseIcon boxSize={3} />} colorScheme="red" onClick={handleCloseModal}>
                                    Cancel
                </Button>
              </>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalBalanceMeta;
