/* eslint-disable */

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getSingleDocumentFirebase } from "../../Api/firebaseApi"
import { AspectRatio, Box, Heading, Icon, SimpleGrid, Skeleton, Stack, Text } from "@chakra-ui/react"
import BackButtons from "../Buttons/BackButtons"
import { data } from "../Sidebar/DataMenu"


function MainMenuComponent(props) {
  if (!props) return
  const navigate = useNavigate()

  const [videoLoaded, setVideoLoaded] = useState(false)
  const [dataConfig, setDataConfig] = useState({})

  let menuName = ''
  if (props.menuName) {
    menuName = props.menuName
  }



  const getDataMenu = async () => {

    try {
      const result = await getSingleDocumentFirebase('config', 'videoFront')

      const dataFind = result?.data.find((x) => x.title === ('management').toLowerCase())
      setDataConfig(dataFind)

    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } 
  }

  useEffect(() => {
    getDataMenu()

    return () => {
      setDataConfig({})

    }
  }, [])





  return (
    <Box p={5}>
      <BackButtons />
      <Stack align={'center'} spacing={3}>
      <SimpleGrid columns={[1, 1, 2]} gap={5} p={[1, 1, 5]}>
          <Stack>
            {dataConfig?.video ? (
              <Stack
                justify={'center'}
                textAlign={'center'}
                bgRepeat={'no-repeat'}
                bgSize={'cover'}
                opacity={videoLoaded ? 1 : 0} // Atur opacity untuk efek transisi
                transition={'opacity 0.3s ease-in-out'} // Transisi opasitas
              >
                <AspectRatio ratio={16 / 9}>
                  <Box
                    as="iframe"
                    title="YouTube video player"
                    src={dataConfig?.video}
                    allowFullScreen
                    frameBorder="0"
                    onLoad={() => setVideoLoaded(true)} // Mengatur video sebagai loaded saat selesai dimuat
                    visibility={videoLoaded ? 'visible' : 'hidden'} // Atur visibility untuk efek transisi
                    transition={'visibility 0.3s ease-in-out'} // Transisi visibility
                  ></Box>
                </AspectRatio>
              </Stack>
            ) : (
              <Skeleton width="450px" height="300px" />
            )}
          </Stack>
          <Stack p={3} spacing={5}>
            <Heading>{menuName}</Heading>

            <Text align={['center', 'start', 'start']} display={['none', 'flex', 'flex']} color={'gray.500'}>
              {dataConfig?.description || ''}
            </Text>
          </Stack>
        </SimpleGrid>
      </Stack>

      <Box my={7} p={4} shadow={'md'}>
        <Text color={'gray.500'} fontWeight={500} mb={5}>
          SOP Menu
        </Text>
        <SimpleGrid columns={4} spacing={5}>
          {data
            .find((menu) => menu.name === props.menuName)
            ?.submenu?.map((x, i) => (
              <Stack
                p={3}
                key={i}
                border={'1px'}
                shadow={'base'}
                align={'center'}
                cursor={'pointer'}
                borderRadius={'md'}
                borderColor={'gray.300'}
                onClick={() => navigate(`${x.link}`)}
                _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}
              >
                <Icon as={x.icon} boxSize={12} />
                <Text fontWeight={500}>{x.name}</Text>
              </Stack>
            ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default MainMenuComponent
