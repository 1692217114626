import { Box,Container,Divider,Flex,HStack,Stack,Text } from '@chakra-ui/react'
import React,{ useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import SocialAccountComponents from '../SocialAccountComponents'
import OverviewTab from '../AnalyticsTab/OverviewTab'
import PostsTab from '../AnalyticsTab/PostsTab'
import StoriesTab from '../AnalyticsTab/StoriesTab'
import AudienceTab from '../AnalyticsTab/AudienceTab'
import AnswerTab from '../AnalyticsTab/AnswerTab'
import HistoryAnalyticsTab from '../AnalyticsTab/HistoryAnalyticsTab'


function SocialAnalyticsPage() {

  const [navigateState,setNavigateState] = useState('overview')


  const handleNavigate = (param) => {
    setNavigateState(param)
  }

  const height = window.innerHeight

  return (
    <Flex direction={['column', 'row', 'row']}
      gap={3}>
      <Stack
        w='300px'
        height='auto'
        overflowY='auto'
        borderWidth='1px'
        position='sticky'
        top={0}
        borderRadius='md'
        shadow={'md'}
        h={height}
        p={[1, 1, 5]}>
        <SocialAccountComponents />
      </Stack>

      <Container maxW='5xl' >
        <Stack
          overflowY='auto'
          spacing={5}
          w='full'>

          <BackButtons />
          <HStack spacing={5} >
            <Box cursor={'pointer'} borderBottomWidth={1} borderColor={navigateState === 'overview' ? 'blue.400' : 'transparent'} onClick={() => handleNavigate('overview')}>
              <Text fontSize={'sm'} fontWeight="bold" color={navigateState === 'overview' ? 'blue.400' : 'gray.600'}>Overview</Text>
            </Box>
            <Box cursor={'pointer'} borderBottomWidth={1} borderColor={navigateState === 'posts' ? 'blue.400' : 'transparent'} onClick={() => handleNavigate('posts')}>
              <Text fontSize={'sm'} fontWeight="bold" color={navigateState === 'posts' ? 'blue.400' : 'gray.600'}>Posts</Text>
            </Box>
            <Box cursor={'pointer'} borderBottomWidth={1} borderColor={navigateState === 'stories' ? 'blue.400' : 'transparent'} onClick={() => handleNavigate('stories')}>
              <Text fontSize={'sm'} fontWeight="bold" color={navigateState === 'stories' ? 'blue.400' : 'gray.600'}>Stories</Text>
            </Box>
            {/* <Box cursor={"pointer"} borderBottomWidth={1} borderColor={navigateState === "audience" ? "blue.400" : "transparent"} onClick={() => handleNavigate("audience")}>
                            <Text fontSize={"sm"} fontWeight="bold" color={navigateState === "audience" ? "blue.400" : "gray.600"}>Audience</Text>
                        </Box>

                        {/* <Box cursor={"pointer"} borderBottomWidth={1} borderColor={navigateState === "answer" ? "blue.400" : "transparent"} onClick={() => handleNavigate("answer")}>
                            <Text fontSize={"sm"} fontWeight="bold" color={navigateState === "answer" ? "blue.400" : "gray.600"}>Answer</Text>
                        </Box> */}

            <Box cursor={'pointer'} borderBottomWidth={1} borderColor={navigateState === 'history' ? 'blue.400' : 'transparent'} onClick={() => handleNavigate('history')}>
              <Text fontSize={'sm'} fontWeight="bold" color={navigateState === 'history' ? 'blue.400' : 'gray.600'}>History</Text>
            </Box>

          </HStack>

          <Divider />
          <Stack>
            {navigateState === 'overview' ? (
              <OverviewTab />
            ) : navigateState === 'posts' ? (
              <PostsTab />
            ) : navigateState === 'stories' ? (
              <StoriesTab />
            ) : navigateState === 'audience' ? <AudienceTab />
              :
              navigateState === 'answer' ? <AnswerTab /> :
                navigateState === 'history' ? <HistoryAnalyticsTab /> : null
            }

          </Stack>
        </Stack>


      </Container>
    </Flex>
  )
}

export default SocialAnalyticsPage