import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import useUserStore from '../../../Hooks/Zustand/Store';
import useMarketingStore from '../../../Hooks/Zustand/MarketingStore';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';

function MarketingMetaAddCampaignPage() {
  const param = useParams();
  const globalState = useUserStore();
  const marketingState = useMarketingStore();
  const [data, setData] = useState({});
  const [input, setInput] = useState({});
  const [formChanged, setFormChanged] = useState(false);

  const steps = [
    { title: 'Campaign', description: 'Create Campaign' },
    { title: 'Ads', description: 'Create Ads' },
    { title: 'Audience', description: 'Select Audience' },
    {
      title: 'Budget & Strategies',
      description: 'Set your budget & strategies',
    },
    { title: 'Publish', description: 'Review & publish' },
  ];
  const navigate = useNavigate();

  const getCampaignData = async () => {
    try {
      const res = await getSingleDocumentFirebase('marketing_meta', param?.id);

      setData(res);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (param?.type === 'edit') {
        try {
          const res = await getSingleDocumentFirebase('marketing_meta', param?.id);

          setData(res);

          marketingState?.setCampaign({
            campaignName: res?.campaignName,
            facebookLink: res?.facebookLink,
            adAccount: res?.adAccount,
            instagramLink: res?.instagramLink,
            pixelID: res?.pixelId,
            tags: res?.tags,
            promotionType: res?.promotionType || '',
            objective: res?.objective,
          });
          marketingState?.setAdsCreation({
            adName: res?.adsCreation?.adName,
            websiteUrl: res?.adsCreation?.websiteUrl,
            location: res?.adsCreation?.location,
            language: res?.adsCreation?.language,
            format: res?.adsCreation?.format,
            businessName: res?.adsCreation?.businessName,
            businessLogo: res?.adsCreation?.businessLogo,
            primaryText: res?.adsCreation?.primaryText,
            mediaLink: res?.adsCreation?.mediaLink,
            headline: res?.adsCreation?.headline,
            description: res?.adsCreation?.description,
            cta: res?.adsCreation?.cta,
            carousel: res?.adsCreation?.carousel,
            mediaRatio: res?.adsCreation?.mediaRatio,
          });
          marketingState?.setBudgeting({
            dailyBudget: res?.budget?.dailyBudget,
            durationStart: res?.budget?.durationStart,
            durationEnd: res?.budget?.durationEnd,
          });
          marketingState?.setAdPlacement({
            manualPlacement: res?.adPlacement?.manualPlacement,
            audienceNetworkPlacement: res?.adPlacement?.audienceNetworkPlacement,
            messengerPlacement: res?.adPlacement?.messengerPlacement,
            feeds: res?.adPlacement?.feeds,
            storiesAndReels: res?.adPlacement?.storiesAndReels,
          });
          marketingState?.setTargeting({
            customAudience: res?.targeting?.customAudience,
            ageStart: res?.targeting?.ageStart,
            ageEnd: res?.targeting?.ageEnd,
            sex: res?.targeting?.sex,
            detailedTargeting: res?.targeting?.detailedTargeting,
            devices: res?.targeting?.devices,
            durationStart: res?.targeting?.durationStart,
            durationEnd: res?.targeting?.durationEnd,
          });
        } catch (error) {
          throw new Error(error);
        }
      }
    };

    fetchData();
  }, [param?.id, param?.type]);

  // console.log(input, 'xxx');

  const handleNavigate = (value) => {
    if (param?.type === 'edit') {
      navigate(`/marketing/meta/ads/create/adset/edit/${value?.id}`);
    } else {
      navigate('/marketing/meta/ads/create/adSet');
    }
  };

  useEffect(() => {
    if (param?.id) {
      getCampaignData();
    }

    return () => {
      setData({});
      setInput({});
      setFormChanged(false);
    };
  }, []);

  return (
    <Box>
      <HStack>
        <BackButtons />
      </HStack>

      <Stepper
        index={1}
        bg={'white'}
        p={5}
        borderRadius={'md'}
        shadow={'base'}
        my={5}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription fontSize={12}>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Heading textAlign='center'>Campaign </Heading>

      <Container
        my='5'
        shadow='base'
        bg={'white'}
        p={5}
        borderRadius={'md'}>
        <FormControl>
          <FormLabel>Campaign Name</FormLabel>
          <Input
            placeholder={param?.type === 'edit' ? data?.campaignName : null}
            defaultValue={marketingState?.campaign?.campaignName}
            onChange={(e) => marketingState?.setCampaign({ campaignName: e.target.value })}
            type='text'
          />
        </FormControl>

        <FormControl my={3}>
          <FormLabel>Ad Account</FormLabel>
          <HStack>
            <Select
              onChange={(e) => marketingState?.setCampaign({ adAccount: e.target.value })}
              placeholder={param?.type === 'edit' ? data?.adAccount : 'Select ad account'}>
              <option value={globalState.currentCompany}>{globalState.currentCompany}</option>
            </Select>
            <Button>+</Button>
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel>Link to your Facebook fanpage</FormLabel>
          <Input
            placeholder={param?.type === 'edit' ? data?.facebookLink : 'Facebook Link'}
            value={marketingState?.campaign?.facebookLink}
            onChange={(e) => marketingState?.setCampaign({ facebookLink: e.target.value })}
            type='text'
          />
        </FormControl>

        <FormControl py={2}>
          <FormLabel>Link to your Instagram account</FormLabel>
          <Input
            placeholder={param?.type === 'edit' ? data?.instagramLink : 'Instagram Link'}
            value={marketingState?.campaign?.instagramLink}
            onChange={(e) => marketingState?.setCampaign({ instagramLink: e.target.value })}
            type='text'
          />
        </FormControl>

        <FormControl>
          <FormLabel>Pixel ID for this campaign</FormLabel>
          <Input
            placeholder={param?.type === 'edit' ? data?.pixelID : 'Pixel ID'}
            value={marketingState?.campaign?.pixelID}
            onChange={(e) => marketingState?.setCampaign({ pixelID: e.target.value })}
            type='text'
          />
        </FormControl>

        <FormControl my={3}>
          <FormLabel>Tags</FormLabel>
          <Input
            placeholder={param?.type === 'edit' ? data?.tags : 'Tags'}
            value={marketingState?.campaign?.tags}
            onChange={(e) => marketingState?.setCampaign({ tags: e.target.value })}
            type='text'
          />
          {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
        </FormControl>

        <Button
          my={3}
          w='full'
          colorScheme='green'
          onClick={() => handleNavigate(formChanged ? input : data)}>
          Next
        </Button>
      </Container>
    </Box>
  );
}

export default MarketingMetaAddCampaignPage;
