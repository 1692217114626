import React from 'react';
import { Route,Routes } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import Layout from '../Layouts';
import ConfigurationRouter from './ConfigurationRouter';
import MarketingRouter from './MarketingRouter';
import SettingRouter from './SettingRouter';
import MarketingLandingPage from '../Pages/Marketing/landingPage/MarketingLandingPage';
import SocialmediaRouter from './SocialMediaRouter';
import CheckoutFrontViewPage from '../Pages/Marketing/landingPage/SectionComponent/form';
import FormV2 from '../Pages/Marketing/landingPage/SectionComponent/formV2';

function MainRouter() {

  const allRouter = [
    ...AuthenticationMainRouter,
    ...HomeRouter,
    ...ConfigurationRouter,
    ...MarketingRouter,
    ...SettingRouter,
    ...SocialmediaRouter
  ];

  return (
    <Routes>
      <Route
        path='/marketing/funnel/create/:funnelId/lp-builder/:pageId'
        element={<MarketingLandingPage />}
      />
      {allRouter.map((item,index) => {
        return (
          <Route
            key={index}
            path={item.path}
            element={<Layout>{item.element}</Layout>}
          />
        );
      })}
    </Routes>
  );
}

export default MainRouter;
