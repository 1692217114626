import React from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const SocialMediaPage = () => {
  return (
    <MainMenuComponent 
      menuName='Social Media' 
    />
  );
};

export default SocialMediaPage;
