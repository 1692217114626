/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Avatar, AvatarBadge, Box, HStack, Stack, Text, useToast } from '@chakra-ui/react';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getPlatformIcon } from '../../../Utils/PlatformIcon';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'


const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date()); // State untuk menyimpan nilai bulan saat ini
  const toast = useToast();
  const height = window.innerHeight;
  const globalState = useUserStore();

  const socialMediaKey = globalState?.socialData?.displayNames;



  const fetchEvents = async () => {
    try {
      const result = await getSingleDocumentFirebase('schedules', globalState.currentProject);
      const data = result.data;
      setEvents(
        data.map((item) => ({
          title: item.name,
          start: moment(item.startDate.seconds * 1000).toDate(),
          end: moment(item.endDate.seconds * 1000).toDate(),
          imageUrl: item.image,
          platform: item.platform,
          post: item.post,
          status: item.status,
        }))
      );
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  // console.log(globalState);

  const handleMonthChange = (date) => {
    setCurrentDate(date); 
  };

  useEffect(() => {
    if (socialMediaKey && socialMediaKey.length > 0) {
      fetchEvents();
    }
  }, [socialMediaKey, globalState?.currentProject, globalState?.currentCompany]);

  // const eventStyleGetter = (event, start, end, isSelected) => {
  const eventStyleGetter = () => {
    const backgroundColor = '#3174ad';
    const style = {
      backgroundColor,
      borderRadius: '20px',
      opacity: 0.8,
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  const Event = ({ event }) => {
    const resIcon = getPlatformIcon(event.platform);

    return (
      <HStack
        alignItems={'center'}
        p={1}
        spacing={3}>
        <Avatar
          cursor={'pointer'}
          size='xs'
          src={event?.imageUrl}
          alt={event?.imageUrl}>
          <AvatarBadge
            boxSize='1.5em'
            bg='red.400'>
            {resIcon && resIcon}
          </AvatarBadge>
        </Avatar>

        <Stack spacing={0}>
          <HStack>
            <Text
              noOfLines={1}
              fontSize={'xx-small'}>
              {event?.title}
            </Text>
            {event?.status && (
              <Text
                noOfLines={1}
                fontSize={'xx-small'}
                color='gray.300'>
                ( {event?.status} )
              </Text>
            )}
          </HStack>
          <Text
            noOfLines={1}
            fontSize={'xx-small'}>
            {event?.post}
          </Text>
        </Stack>
      </HStack>
    );
  };

  return (
    <Stack>
      <Stack
        w={'100%'}
        transition={'0.2s ease-in-out'}
        minH={height}>
        <HStack
          justifyContent='center'
          alignItems='center'
          spacing={5}>
          <Box
            onClick={() => handleMonthChange(moment(currentDate).subtract(1, 'months'))}
            cursor='pointer'>
            <MdNavigateBefore />
          </Box>
          <Text
            fontSize={'lg'}
            fontWeight={500}>
            {moment(currentDate).format('MMMM YYYY')}
          </Text>
          <Box
            onClick={() => handleMonthChange(moment(currentDate).add(1, 'months'))}
            cursor='pointer'>
            <MdNavigateNext />
          </Box>
        </HStack>

        {/* Tambahkan kalender dengan tanggal yang diatur */}
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor='start'
          endAccessor='end'
          style={{
            height: '100vh',
            padding: 10,
            borderRadius: '10px',
          }}
          eventPropGetter={eventStyleGetter}
          components={{
            event: Event,
          }}
          date={currentDate} // Gunakan state currentDate sebagai tanggal yang ingin ditampilkan
        />
      </Stack>
    </Stack>
  );
};

export default CalendarPage;
