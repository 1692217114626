import { Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../../Hooks/Zustand/Store';
import _axios from '../../../Api/AxiosBarrier';
import { FaLock } from 'react-icons/fa';
import AnalyticsData from '../Analytics/AnalyticsData';

function OverviewTab() {
  const globalState = useUserStore();
  const toast = useToast();

  const [dataAnalytics, setDataAnalytics] = useState({});

  const handleAnalytics = async (platforms) => {
    // globalState.setIsLoading(true);
    const profileKey = globalState?.socialKey;
    if (profileKey) {
      globalState.setIsLoading(true);
      try {
        const res = await _axios.post('ayrshare-analyticsSocial', {
          platforms,
          profileKey,
        });
        if (res.data.status === 'success') {
          const obj = res.data;
          const { ...analyticsData } = obj;
          const filteredData = {};
          for (const platform in analyticsData) {
            if (obj[platform]?.status !== 'error') {
              filteredData[platform] = obj[platform];
            } else {
              toast({
                title: 'Deoapp.com',
                description: obj[platform]?.message,
                status: 'error',
                position: 'top-right',
                isClosable: true,
              });
            }
          }
         
          setDataAnalytics(filteredData);
        } else {
          toast({
            title: 'Deoapp.com',
            description: res.data[platforms]?.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        globalState.setIsLoading(false);
      }
    } else {
      globalState.setIsLoading(false);
      return toast({
        title: 'Deoapp.com',
        description: 'Please set up your social account',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (globalState?.socialData) {
      handleAnalytics(globalState?.socialData?.activeSocialAccounts);
    }

    return () => {};
  }, [globalState?.socialData]);

  return (
    <Stack spacing={5}>
      {Object.keys(dataAnalytics).length === 0 ? (
        <Stack
          p={1}
          w='65vw'
          h='700px'
          position={'absolute'}
          zIndex={100}
          opacity={0.5}
          alignItems='center'
          justifyContent={'center'}
          bgColor='gray.300'>
          <FaLock size={50} />
          <Text
            fontSize={'xl'}
            fontWeight={500}>
            No Account
          </Text>
        </Stack>
      ) : (
        <>
          <Stack>
            {dataAnalytics &&
              Object.entries(dataAnalytics).map(([key, value]) => (
                <Stack key={key}>
                  <AnalyticsData
                    data={value.analytics}
                    platform={key}
                  />
                </Stack>
              ))}
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default OverviewTab;
