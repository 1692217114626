export const eventScript = function (props) {
  const urlAddress = props.urlValue
  window.open(`${urlAddress}`,'_blank')
}

// eslint-disable-next-line no-unused-vars
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;

  dc.getTypes().forEach((type) => {
    if(type.id === 'button') {
      editor.DomComponents.addType('button',{
        model: {
          defaults: {
            eventScript,
            urlValue: 'https://google.com',
            traits: [
              ...type.model.prototype.defaults.traits,
              {
                type: 'button-href',
                name: 'href',
                label: 'Link Direction',
                changeProp: true
              },
            ],
            'script-props': ['urlValue'],
          }
        }
      });
    }
  });
};