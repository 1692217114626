import React,{ useEffect,useState } from 'react';
import { Table,Thead,Tbody,Tr,Th,Td,TableContainer,Box,HStack,Heading,Center,Text } from '@chakra-ui/react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import moment from 'moment';

import useUserStore from '../../../Hooks/Zustand/Store';
import { getCollectionFirebase } from '../../../Api/firebaseApi';

const PaymentHistory = () => {
  const [historyPayment,setHistoryPayment] = useState([]);
  const globalState = useUserStore();

  const handleGetPayment = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
      { field: 'module',operator: '==',value: 'marketing' },
    ];

    try {
      const paymentsResponse = await getCollectionFirebase('payments',conditions);

      const paymentsWithDates = paymentsResponse.map((payment) => ({
        ...payment,
        createdAt: payment.createdAt instanceof Date ? payment.createdAt : payment.createdAt.toDate(),
      }));

      const sortedPayments = paymentsWithDates.sort((a,b) => b.createdAt - a.createdAt);

      setHistoryPayment(sortedPayments);
    } catch (error) {
      throw new Error('Error fetching payments:',error)
    }
  };

  useEffect(() => {
    handleGetPayment();
  },[globalState?.currentCompany,globalState?.currentProject]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading size={'lg'}>Ads Payment History</Heading>
      </HStack>

      <TableContainer
        border={'1px'}
        borderRadius={'md'}
        shadow='base'
        borderColor={'gray.100'}
        my={5}>
        <Table variant='striped'>
          <Thead>
            <Tr>
              <Th align={'center'}>Date</Th>
              <Th align={'center'}>Campaign Name</Th>
              <Th align={'center'}>Amount</Th>
              <Th align={'center'}>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {historyPayment?.length > 0 ? (
              historyPayment.map((payment,i) => (
                <Tr key={i}>
                  <Td>{moment(payment.createdAt).format('LLLL')}</Td>
                  <Td>{payment.outlet_id}</Td>
                  <Td>{`Rp.${payment.expected_amount.toLocaleString()}`}</Td>
                  <Td>{payment.status}</Td>
                </Tr>
              ))
            ) : (
              <Center>
                <Text
                  color={'gray.400'}
                  fontWeight={500}>
                  No Data
                </Text>
              </Center>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaymentHistory;
