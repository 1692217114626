import { AspectRatio,Box,Button,Container,HStack,Heading,Image,Spacer,Stack,Text } from '@chakra-ui/react'
import React from 'react'
import { MdOutlinePhotoCamera } from 'react-icons/md'
import Slider from 'react-slick';
import { SlBubble,SlHeart,SlPaperPlane } from 'react-icons/sl'
import { HiDotsHorizontal } from 'react-icons/hi';
import PropTypes from 'prop-types';

const FacebookReels = ({ marketingState }) => {

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: '10px'
  };

  return (
    <>
      {marketingState?.adsCreation?.mediaLink?.length === 0 || (marketingState?.adsCreation?.format === 'carousel' && marketingState?.adsCreation?.carousel?.length <= 1) ? (
        <Text align={'center'} p={5} fontSize={12}>Add more media to see display</Text>
      ) : (
        <Container alignSelf={'start'} w={'60%'} >
          <Box w={300} border={'1px'} borderColor={'gray.100'} pos={'relative'}>

            <HStack alignItems={'center'} justifyItems={'center'} alignContent={'center'} p={4} pos={'absolute'} color={'white'} zIndex={1} bgGradient="linear(to-t, rgba(125, 125, 125,0) 0%, rgba(125, 125, 125,0.6) 100%)" w={'full'}>
              <Box>
                <Heading size={'md'} >
                                    Reels
                </Heading>
                {/* <Text fontSize={10}>Sponsored</Text> */}
              </Box>
              <Spacer />
              <MdOutlinePhotoCamera size={20} />

            </HStack>

            <Box maxWidth={300} maxH={600}>
              {marketingState?.adsCreation?.format === 'carousel' ? (
                <Slider {...settings}>
                  {marketingState?.adsCreation?.carousel?.map((x,i) => (
                    <Box borderRadius={'md'} key={i}>
                      <AspectRatio ratio={9 / 16}>
                        {x?.type === 'video' ? (
                          <iframe
                            // objectFit={'cover'}
                            src={
                              x?.link
                            }
                            alt="ads Image"
                          />
                        ) : (
                          <Image
                            objectFit={'cover'}
                            src={
                              x?.link
                            }
                            alt="ads Image"
                          />

                        )}
                      </AspectRatio>
                    </Box>
                  ))}
                </Slider>
              ) : (
                <AspectRatio maxWidth={300} maxH={600} ratio={9 / 16}>

                  {marketingState?.adsCreation?.mediaLink[0]?.type === 'video' ? (
                    <iframe
                      // objectFit={'cover'}
                      src={
                        marketingState?.adsCreation?.mediaLink[0]?.link
                      }
                      alt="ads Image"
                    />
                  ) : (
                    <Image
                      objectFit={'cover'}
                      src={
                        marketingState?.adsCreation?.mediaLink[0]?.link
                      }
                      alt="ads Image"
                    />

                  )}

                </AspectRatio>
              )}
            </Box>

            <Stack p={4} pos={'absolute'} zIndex={1} bgGradient="linear(to-b, rgba(125, 125, 125,0) 0%, rgba(125, 125, 125,0.6) 100%)" justify={'center'} w={'full'} bottom={0}>

              <HStack color={'white'}>
                <Spacer />
                <SlHeart size={20} />
              </HStack>
              <HStack color={'white'}>
                <Image
                  // w={75}
                  boxSize={'30px'}
                  borderRadius="full"
                  src={
                    marketingState?.adsCreation?.businessLogo === ''
                      ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                      : marketingState?.adsCreation?.businessLogo
                  }
                  alt="your brand name"
                />
                <Box>
                  <Heading size={'sm'} >
                    {
                      marketingState?.adsCreation?.businessName === ''
                        ? 'Business Name'
                        : marketingState?.adsCreation?.businessName
                    }
                  </Heading>
                </Box>
                <Spacer />

                <Spacer />
                <SlBubble size={20} />
              </HStack>
              <HStack w={'100%'} color={'white'}>
                <Button w={'70%'} size={'xs'} fontSize={14}>Send message</Button>
                <Spacer />
                <SlPaperPlane size={20} />
              </HStack>

              <HStack fontSize={12} w={'100%'} color={'white'}>
                <Text>Sponsored</Text>
                <Spacer />
                <HiDotsHorizontal size={20} />
              </HStack>
            </Stack>
          </Box>
        </Container>
      )}
    </>
  )
}

export default FacebookReels

FacebookReels.propTypes = {
  marketingState: PropTypes.any,
}