/* eslint-disable no-prototype-builtins */
import {Box, HStack,Select,Spacer,Stack,Text, useToast } from '@chakra-ui/react';

import React,{ useEffect,useState,useRef } from 'react'
import useUserStore from '../../../Hooks/Zustand/Store';
import _axios from '../../../Api/AxiosBarrier';
import InstagramPublishCard from '../../../Components/Card/InstagramPublishCard';
import TiktokPublishCard from '../../../Components/Card/TiktokPublishCard';
import FacebookPublishCard from '../../../Components/Card/FacebookPublishCard';
import TwitterPublishCard from '../../../Components/Card/TwitterPublishCard';

function SentTab() {

  const globalState = useUserStore();
  const [dataPost,setDataPost] = useState([]);
  const mediaTypeRef = useRef('');
  const availableMediaTypesRef = useRef([]);
  const toast = useToast()
  const getDataPost = async () => {
    globalState.setIsLoading(true);
    const data = {
      profileKey: globalState.socialKey,
      query: `/${globalState?.socialActive?.platform}`
    };
    try {
      const res = await _axios.post('/ayrshare-getAllPosts',data);
      if (res.data.status === 'success') {
        if (res.data.posts.length > 0) {
          let dataFix = [];
          const dataPosting = res.data.posts;
          const platformFilters = {
            instagram: (x) => x.mediaProductType === mediaTypeRef.current,
            tiktok: (x) => x.mediaType === mediaTypeRef.current,
            twitter: (x) => x.hasOwnProperty(mediaTypeRef.current),
            facebook: (x) => x.hasOwnProperty(mediaTypeRef.current),
            linkedin: (x) => x.mediaType === mediaTypeRef.current,
            youtube: (x) => x.mediaType === mediaTypeRef.current,
            // Tambahkan platform lain dengan aturan filter yang sesuai jika diperlukan
            // misalnya: twitter: (x) => x.someProperty === someValue
          };
          const activePlatform = globalState?.socialActive?.platform.toLowerCase();

          const filterFunction = platformFilters[activePlatform];
          if (filterFunction) {
            dataFix = dataPosting.filter(filterFunction);
          } else {
            // Jika platform tidak memiliki filter khusus, tampilkan semua data
            dataFix = [...dataPosting];
          }
          setDataPost(dataFix);
        }
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: 'Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const getDataAvailableFilter = () => {
    if (globalState?.socialActive?.platform === 'instagram') {
      availableMediaTypesRef.current = ['FEED','REELS']; // Misalnya, menambahkan opsi IGTV dan Story
    } else if (globalState?.socialActive?.platform === 'tiktok') {
      availableMediaTypesRef.current = ['video']; // Misalnya, opsi Video dan Live untuk TikTok
    } else if (globalState?.socialActive?.platform === 'twitter') {
      availableMediaTypesRef.current = ['post']; // Misalnya, opsi Video dan Live untuk TikTok
    } else if (globalState?.socialActive?.platform === 'facebook') {
      availableMediaTypesRef.current = ['post']; // Misalnya, opsi Video dan Live untuk TikTok
    }
  };

  useEffect(() => {
    if (availableMediaTypesRef.current.length > 0) {
      mediaTypeRef.current = availableMediaTypesRef.current[0]; // Mengat
    }

    getDataAvailableFilter();
    getDataPost(); // Panggil getDataPost setelah perubahan platform atau mediaType
  },[globalState.socialKey,globalState?.socialActive?.platform]);


  return (
    <Stack>
      <HStack>
        <Text fontSize={'xl'} fontWeight="bold" color={'gray.600'} textTransform={'capitalize'}>
                    Posting {globalState?.socialActive?.platform}
        </Text>
        <Spacer />
        <Box>
          <Select
            value={mediaTypeRef.current}
            onChange={(e) => {
              mediaTypeRef.current = e?.target.value;
              getDataPost(); // Panggil getDataPost setiap kali mediaType berubah
            }}
            placeholder="Select option"
          >
            {availableMediaTypesRef.current.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>

      {dataPost?.length > 0 && (

        <Stack>
          {dataPost.map((x,index) => {
            return (
              <Stack key={index}
              >
                {globalState?.socialActive?.platform === 'instagram' ? (
                  <InstagramPublishCard data={x} />
                ) : globalState?.socialActive?.platform === 'tiktok' ? (
                  <TiktokPublishCard data={x} />
                ) : globalState?.socialActive?.platform === 'facebook' ? (
                  <FacebookPublishCard data={x} />
                ) : globalState?.socialActive?.platform === 'twitter' ? (
                  <TwitterPublishCard data={x} />
                ) : globalState?.socialActive?.platform === 'linkedin' ? (
                  <TiktokPublishCard data={x} />
                ) : globalState?.socialActive?.platform === 'reddit' ? (
                  <TiktokPublishCard data={x} />
                ) : globalState?.socialActive?.platform === 'telegram' ? (
                  <TiktokPublishCard data={x} />
                ) : null}
              </Stack>
            )
          })}

        </Stack>
      )}

    </Stack>
  )
}

export default SentTab;
