import { Alert, AlertDescription, AlertIcon, AlertTitle, Container, Flex, Text } from '@chakra-ui/react';
import { Fragment } from 'react';
// import FooterComponent from "../Components/Footers/FooterComponent";
import HeaderComponent from '../Components/Header/HeaderComponents';
import SidebarComponentV3 from '../Components/Sidebar/SidebarComponentV3';
import themeConfig from '../Config/themeConfig';
import PropTypes from 'prop-types';
import React from 'react';

function Layout({ children }) {
  const contentWidth =
    themeConfig.contentWidth === 'full' ? 'full' : 'container.xl';



  return (
    <Fragment>
      <Alert
        status="warning"
        h={['30px', '30px', '40px']}
        overflowX="auto"
      >
        <AlertIcon />
        <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
                  Attention! 📢
        </AlertTitle>
        <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
          <Text>
                  Our platform is currently in the <span style={{ fontWeight: 'bold' }}>BETA</span> testing phase, and we appreciate your valuable feedback as we refine and develop our services.
          </Text>
        </AlertDescription>
      </Alert>
      <Flex height="100vh" w={'full'} bgColor={'gray.50'}>
        
        {<SidebarComponentV3 layout={themeConfig.layout} />}
        
        <Container maxW={'full'} overflowY={'scroll'} pt={'4'}>
          <HeaderComponent layout={themeConfig.layout} />

          <Container minH={'95vh'} maxW={contentWidth}>
            {children}
          </Container>

        </Container>
      </Flex>
    </Fragment>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.string,
}