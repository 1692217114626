/* eslint-disable react/prop-types */
import { Input, Select, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment';


function ScheduleInput({setSchedulePosting, schedulePosting, setData, data}) {

  const handleDateChange = (event) => {
    const { value } = event.target;
    const newDateTime = moment(value).format();
    setSchedulePosting(newDateTime);
    setData({ ...data, scheduleDate: newDateTime });
  };

  return (
    <Stack>
      <Text color={'gray.500'} fontSize="sm">Schedule Date</Text>
      <Input
        type="datetime-local"
        size={'sm'}
        onChange={handleDateChange}
      />
          
      {schedulePosting && (
        <Text fontSize={'sm'} color="gray.500">
          {moment(schedulePosting).format('YYYY-MM-DDTHH:mm:ssZ')}
        </Text>
      )}
    </Stack>
  )
}

export default ScheduleInput