import { AspectRatio,Box,Container,HStack,Heading,Image,Text } from '@chakra-ui/react'
import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types';

const InstagramtStories = ({ marketingState }) => {
  const carousels = marketingState?.adsCreation?.carousel

  const options = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: '10px'
  };

  return (
    <>
      {marketingState?.adsCreation?.mediaLink?.length === 0 || (marketingState?.adsCreation?.format === 'carousel' && marketingState?.adsCreation?.carousel?.length <= 1) ? (
        <Text align={'center'} p={5} fontSize={12}>Add more media to see display</Text>
      ) : (
        <Container alignSelf={'start'} w={'60%'} >
          <Box w={300} border={'1px'} borderColor={'gray.100'} pos={'relative'}>

            <HStack p={2} pos={'absolute'} zIndex={1} bgGradient="linear(to-t, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%)" w={'full'}>
              <Image
                // w={75}
                boxSize={50}
                borderRadius="full"
                src={
                  marketingState?.adsCreation?.businessLogo === ''
                    ? 'https://storage.googleapis.com/deoapp-indonesia.appspot.com/asset/noimage_800x800.jpeg'
                    : marketingState?.adsCreation?.businessLogo
                }
                alt="your brand name"
              />
              <Box>
                <Heading fontSize="14">
                  {marketingState?.adsCreation?.businessName === ''
                    ? 'Your Business Name'
                    : marketingState?.adsCreation?.businessName}
                </Heading>
                <Text fontSize={10}>Sponsored</Text>
              </Box>
            </HStack>
            <Box maxWidth={300} maxH={600}>
              {marketingState?.adsCreation?.format === 'carousel' ? (
                <Slider {...options}>
                  {carousels?.map((x,i) => (

                    <AspectRatio maxWidth={300} maxH={600} ratio={9 / 16} key={i}>
                      {x?.type === 'video' ? (
                        <iframe
                          src={
                            x?.link
                          }
                          alt="ads Image"
                        />
                      ) : (
                        <Image
                          objectFit={'cover'}
                          src={
                            x?.link
                          }
                          alt="ads Image"
                        />

                      )}

                    </AspectRatio>
                  ))}
                </Slider>
              ) : (
                <AspectRatio maxWidth={300} maxH={600} ratio={9 / 16}>

                  {marketingState?.adsCreation?.mediaLink[0]?.type === 'video' ? (
                    <iframe
                      src={
                        marketingState?.adsCreation?.mediaLink[0]?.link
                      }
                      alt="ads Image"
                    />
                  ) : (
                    <Image
                      objectFit={'cover'}
                      src={
                        marketingState?.adsCreation?.mediaLink[0]?.link
                      }
                      alt="ads Image"
                    />

                  )}

                </AspectRatio>
              )}
            </Box>
            <HStack p={2} pos={'absolute'} zIndex={1} bgGradient="linear(to-b, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%)" justify={'center'} w={'full'} bottom={0}>
              <Box bg={'white'} p={1} borderRadius={'md'} fontSize={14}>
                                LEARN MORE
              </Box>
            </HStack>
          </Box>
        </Container>
      )}
    </>
  )
}

export default InstagramtStories

InstagramtStories.propTypes = {
  marketingState: PropTypes.any,
  data: PropTypes.any,
  param: PropTypes.string,
}