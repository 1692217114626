export const servicesGrapesJs = () => {
  const casperServices1 =
  `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <div class="absolute bottom-0 right-0 mr-20 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="max-w-md mx-auto lg:max-w-none relative">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">Casper Services</h2>
            <p class="text-xl text-gray-500 tracking-tight mb-16">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/3 px-4 mb-20 lg:mb-0">
                <div>
                  <img class="block mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon1.png" alt="">
                  <h4 class="font-heading text-3xl font-medium text-white">Intelligent Conersations</h4>
                  <div class="h-1 bg-white bg-opacity-10 my-6 rounded-md"></div>
                  <p class="max-w-sm text-xl tracking-tight leading-relaxed text-violet-50">From answering questions to providing recommendations, our AI assistant will engage your visitors effortlessly.</p>
                </div>
              </div>
              <div class="w-full lg:w-1/3 px-4 mb-20 lg:mb-0">
                <div>
                  <img class="block mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon3.png" alt="">
                  <h4 class="font-heading text-3xl font-medium text-white">Intelligent Conersations</h4>
                  <div class="h-1 bg-white bg-opacity-10 my-6 rounded-md"></div>
                  <p class="max-w-xs text-xl tracking-tight leading-relaxed text-violet-50">By understanding your visitors' needs, our chat assistant creates a unique experience</p>
                </div>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <div>
                  <img class="block mb-6" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon2.png" alt="">
                  <h4 class="font-heading text-3xl font-medium text-white">Intelligent Conersations</h4>
                  <div class="h-1 bg-white bg-opacity-10 my-6 rounded-md"></div>
                  <p class="max-w-xs text-xl tracking-tight leading-relaxed text-violet-50">Say goodbye to static web pages and hello to an interactive, conversational platform</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperServices2 =
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <img class="absolute top-0 right-0 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
          <div class="absolute top-0 right-0 -mr-52 -mt-52 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl"></div>
          <div class="relative max-w-lg lg:max-w-4xl mx-auto lg:mx-0 mb-14">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-6">Look no further! Casper is here to revolutionize</h2>
            <p class="max-w-xl text-xl text-gray-500 tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
          </div>
          <div class="max-w-lg mx-auto lg:max-w-none">
            <div class="relative flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="relative h-full p-10 rounded-3xl overflow-hidden">
                  <img class="absolute top-0 left-0 w-full h-full object-cover" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/bg-gradient-square.png" alt="">
                  <div class="relative flex flex-col h-full items-start max-w-md">
                    <img class="block w-18 mb-16" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/icon-white-1.png" alt="">
                    <h4 class="text-3xl font-medium mt-auto mb-4">Intelligent Conversations</h4>
                    <p class="leading-snug mb-4">ChatBotX is powered by state-of-the-art artificial intelligence, ensuring natural interactions with your users</p>
                    <a class="group inline-flex w-full sm:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-900 bg-violet-600 hover:bg-white transition duration-200 rounded-full" href="#">
                      <span class="mr-2">SEE DETAILS</span>
                      <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="p-10 mb-8 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10">
                  <div class="relative flex flex-col h-full items-start max-w-md">
                    <img class="block w-18 mb-16" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/icon-white-2.png" alt="">
                    <h4 class="text-3xl font-medium text-white mt-auto mb-4">Intelligent Conversations</h4>
                    <p class="leading-snug text-white mb-4">ChatBotX is powered by state-of-the-art artificial intelligence, ensuring natural interactions with your users</p>
                    <a class="group inline-flex w-full sm:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-900 bg-violet-600 hover:bg-white transition duration-200 rounded-full" href="#">
                      <span class="mr-2">SEE DETAILS</span>
                      <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="p-6 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10">
                  <div class="relative sm:flex items-center">
                    <img class="block w-18" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/icon-white-3.png" alt="">
                    <div class="my-8 sm:my-0 sm:ml-9">
                      <h4 class="text-3xl font-medium text-white leading-8">
                        <span class="block">Seamless</span>
                        <span class="block">Integration</span>
                      </h4>
                    </div>
                    <a class="group inline-flex ml-auto h-14 w-14 items-center justify-center text-black hover:text-violet-900 bg-violet-600 hover:bg-white transition duration-200 rounded-full" href="#">
                      <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z" fill="currentColor"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperServices3 =
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <img class="hidden md:block absolute top-0 right-0 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
          <div class="absolute top-0 right-0 -mr-52 -mt-64 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative max-w-lg lg:max-w-4xl mx-auto lg:mx-0 mb-32">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-6">Our Services</h2>
            <p class="max-w-xl text-xl text-gray-500 tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
          </div>
          <div class="relative">
            <a class="group block cursor-pointer mb-14 pb-8 border-b-2 border-white border-opacity-10 hover:border-opacity-100 items-center transition duration-300" href="#">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full lg:w-4/12 px-4 mb-8 lg:mb-0">
                  <div class="flex items-start">
                    <span class="hidden group-hover:inline-block mr-5 font-heading text-4xl font-medium text-sky-900">01</span>
                    <h4 class="font-heading text-4xl font-medium text-white group-hover:text-sky-900">
                      <span class="block">Personalized</span>
                      <span>Experiences</span>
                    </h4>
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4 mb-8 lg:mb-0">
                  <div class="relative flex items-center">
                    <img class="hidden md:block lg:hidden xl:block absolute top-0 left-0 xl:-mt-14 ml-8 w-32 opacity-0 group-hover:opacity-100 transition duration-300" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/axe.png" alt="">
                    <p class="max-w-md ml-auto text-right text-white tracking-tight leading-snug">By understanding your visitors' needs, our chat assistant creates a unique experience that keeps them coming back for more unique process.</p>
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4 text-right">
                  <span class="inline-block text-white group-hover:text-sky-900 transform rotate-0 group-hover:rotate-90 transition duration-300">
                    <svg width="60" height="60" viewbox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5 42.5L42.5 17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M17.5 17.5H42.5V42.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </a>
            <a class="group block cursor-pointer mb-14 pb-8 border-b-2 border-white border-opacity-10 hover:border-opacity-100 items-center transition duration-300" href="#">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full lg:w-4/12 px-4 mb-8 lg:mb-0">
                  <div class="flex items-start">
                    <span class="hidden group-hover:inline-block mr-5 font-heading text-4xl font-medium text-sky-900">02</span>
                    <h4 class="font-heading text-4xl font-medium text-white group-hover:text-sky-900">
                      <span class="block">Analytics and</span>
                      <span>Insights</span>
                    </h4>
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4 mb-8 lg:mb-0">
                  <div class="relative flex items-center">
                    <img class="hidden xl:block absolute top-0 left-0 -mt-14 ml-8 w-32 opacity-0 group-hover:opacity-100 transition duration-300" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/box-items.png" alt="">
                    <p class="max-w-md ml-auto text-right text-white tracking-tight leading-snug">Our chat assistant is available round the clock, providing instant responses and support, even when your team is offline. Say goodbye to missed inquiries</p>
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4 text-right">
                  <span class="inline-block text-white group-hover:text-sky-900 transform rotate-0 group-hover:rotate-90 transition duration-300">
                    <svg width="60" height="60" viewbox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5 42.5L42.5 17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M17.5 17.5H42.5V42.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </a>
            <a class="group block cursor-pointer mb-14 pb-8 border-b-2 border-white border-opacity-10 hover:border-opacity-100 items-center transition duration-300" href="#">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full lg:w-4/12 px-4 mb-8 lg:mb-0">
                  <div class="flex items-start">
                    <span class="hidden group-hover:inline-block mr-5 font-heading text-4xl font-medium text-sky-900">03</span>
                    <h4 class="font-heading text-4xl font-medium text-white group-hover:text-sky-900">
                      <span class="block">Seamless</span>
                      <span>Integration</span>
                    </h4>
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4 mb-8 lg:mb-0">
                  <div class="relative flex items-center">
                    <img class="hidden xl:block absolute top-0 left-0 -mt-14 ml-8 w-32 opacity-0 group-hover:opacity-100 transition duration-300" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/bubble-robot.png" alt="">
                    <p class="max-w-md ml-auto text-right text-white tracking-tight leading-snug">Our chat assistant is available round the clock, providing instant responses and support, even when your team is offline. Say goodbye to missed inquiries</p>
                  </div>
                </div>
                <div class="w-full lg:w-2/12 px-4 text-right">
                  <span class="inline-block text-white group-hover:text-sky-900 transform rotate-0 group-hover:rotate-90 transition duration-300">
                    <svg width="60" height="60" viewbox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5 42.5L42.5 17.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M17.5 17.5H42.5V42.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>`
                
  const casperServices4 =
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <img class="hidden md:block absolute top-0 right-0 animate-spinStar z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/blink-star.png" alt="">
          <div class="absolute top-0 right-0 -mr-52 -mt-64 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 rounded-full filter blur-4xl"></div>
          <div class="relative max-w-md lg:max-w-4xl mx-auto lg:mx-0 mb-32">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-6">Our Services</h2>
            <p class="max-w-xl text-xl text-gray-500 tracking-tight">Casper is powered by state-of-the-art artificial intelligence and ensuring natural and meaningful interactions</p>
          </div>
          <div class="relative max-w-md lg:max-w-none mx-auto">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                <a class="group block h-auto xl:h-128 p-8 border-2 border-white border-opacity-10 hover:border-opacity-100 rounded-3xl transition duration-200" href="#">
                  <div class="flex h-full flex-col items-start">
                    <div class="flex w-full mb-20 items-center justify-between">
                      <img class="block w-18 h-18" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon1.png" alt="">
                      <span class="opacity-0 group-hover:opacity-100 transition duration-200">
                        <svg width="60" height="60" viewbox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 42.5L42.5 17.5" stroke="#1AC2E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M17.5 17.5H42.5V42.5" stroke="#1AC2E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </div>
                    <div class="mt-auto max-w-xs">
                      <h4 class="text-3xl sm:text-4xl mb-2 font-medium text-white group-hover:text-sky-900">Personalized Experiences</h4>
                      <p class="text-gray-500 tracking-tight leading-5">By understanding your visitors' needs, our chat assistant creates a unique experience that keeps them coming back for more unique process.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                <a class="group block h-auto xl:h-128 p-8 border-2 border-white border-opacity-10 hover:border-opacity-100 rounded-3xl transition duration-200" href="#">
                  <div class="flex h-full flex-col items-start">
                    <div class="flex w-full mb-20 items-center justify-between">
                      <img class="block w-18 h-18" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon3.png" alt="">
                      <span class="opacity-0 group-hover:opacity-100 transition duration-200">
                        <svg width="60" height="60" viewbox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 42.5L42.5 17.5" stroke="#1AC2E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M17.5 17.5H42.5V42.5" stroke="#1AC2E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </div>
                    <div class="mt-auto max-w-xs">
                      <h4 class="text-3xl sm:text-4xl mb-2 font-medium text-white group-hover:text-sky-900">Analytics and Insights</h4>
                      <p class="text-gray-500 tracking-tight leading-5">By understanding your visitors' needs, our chat assistant creates a unique experience that keeps them coming back for more unique process.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <a class="group block h-auto xl:h-128 p-8 border-2 border-white border-opacity-10 hover:border-opacity-100 rounded-3xl transition duration-200" href="#">
                  <div class="flex h-full flex-col items-start">
                    <div class="flex w-full mb-20 items-center justify-between">
                      <img class="block w-18 h-18" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon2.png" alt="">
                      <span class="opacity-0 group-hover:opacity-100 transition duration-200">
                        <svg width="60" height="60" viewbox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 42.5L42.5 17.5" stroke="#1AC2E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M17.5 17.5H42.5V42.5" stroke="#1AC2E7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </div>
                    <div class="mt-auto max-w-xs">
                      <h4 class="text-3xl sm:text-4xl mb-2 font-medium text-white group-hover:text-sky-900">Seamless Integration</h4>
                      <p class="text-gray-500 tracking-tight leading-5">Our user-friendly interface allows for quick setup, and our dedicated support team is ready to assist you every step of the way whether you're a tech guru</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>`
                
  const casperServices5 =
      `<section class="relative font-body py-12 md:py-24 bg-body overflow-hidden">
        <div class="relative container mx-auto px-4">
          <img class="hidden md:block absolute top-0 right-0 -mt-12 z-10" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/robot-skew.png" alt="">
          <div class="relative z-20 max-w-md lg:max-w-4xl mx-auto lg:mx-0 mb-16">
            <h2 class="font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-6">Our Services</h2>
            <p class="max-w-xl text-xl text-gray-500 tracking-tight">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
          </div>
          <div class="relative max-w-md lg:max-w-none mx-auto">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-18 lg:mb-0">
                <div class="relative inline-block max-w-md xl:max-w-none">
                  <button class="group absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 block z-10">
                    <div class="relative flex h-20 w-20 overflow-hidden items-center justify-center rounded-full">
                      <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 group-hover:bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-full transition duration-75"></div>
                      <svg class="relative w-6 h-6 text-white" viewbox="-3 0 28 28" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                        <g transform="translate(-419.000000, -571.000000)">
                          <path fill="currentColor" d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554"></path>
                        </g>
                      </svg>
                    </div>
                  </button>
                  <img class="relative block rounded-3xl" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/video.png" alt="">
                </div>
              </div>
              <div class="relative w-full lg:w-1/2 px-4">
                <div class="absolute top-0 right-0 -mt-96 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 rounded-full filter blur-4xl"></div>
                <div class="relative max-w-lg ml-auto">
                  <div class="mb-18">
                    <h4 class="text-4xl font-medium text-white">Personalized Experiences</h4>
                    <div class="h-1 my-6 bg-white bg-opacity-10 rounded-full"></div>
                    <div class="md:flex items-start">
                      <img class="flex-shrink-0 block w-18 h-18" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon1.png" alt="">
                      <div class="mt-6 md:mt-0 md:ml-6 max-w-sm">
                        <p class="text-xl text-white leading-relaxed">From answering questions to providing recommendations, our AI assistant will engage your visitors effortlessly.</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 class="text-4xl font-medium text-white">Analytics and Insights</h4>
                    <div class="h-1 my-6 bg-white bg-opacity-10 rounded-full"></div>
                    <div class="md:flex items-start">
                      <img class="flex-shrink-0 block w-18 h-18" src="https://static.shuffle.dev/components/preview/bbc8c57b-7d6e-46ce-9534-87b7374b4eae/assets/public/casper-assets/services/circle-icon3.png" alt="">
                      <div class="mt-6 md:mt-0 md:ml-6 max-w-sm">
                        <p class="text-xl text-white leading-relaxed">By understanding your visitors' needs, our chat assistant creates a unique experience</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>`

  const arr = [
    { label: 'casperServices1',content: casperServices1,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/service1.png' },
    { label: 'casperServices2',content: casperServices2,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/service2.png' },
    { label: 'casperServices3',content: casperServices3,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/service3.png' },
    { label: 'casperServices4',content: casperServices4,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/service4.png' },
    { label: 'casperServices5',content: casperServices5,image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/templates/asset-casper/service5.png' },
  ]
  return arr
}
