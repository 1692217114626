/* eslint-disable no-undef */
const Typesense = require('typesense');
const API = process.env.REACT_APP_TYPESENSE_API_KEY;

export const clientTypessense = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: '443',
      protocol: 'https',
    },
  ],
  apiKey: API,
  connectionTimeoutSeconds: 2,
});
