import { Box, Heading, Icon, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { data } from '../../../Components/Sidebar/DataMenu';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';

const MarketinngMetaAdsPage = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [dataConfig, setDataConfig] = useState({});

  const globalState = useUserStore();

  const handleNavigate = (value) => {
    if (!value.submenu || value?.submenu?.length === 0) {
      navigate(`${value.link}`);
    }
  };

  const getDataManagement = async () => {
    globalState.setIsLoading(true);

    try {
      const result = await getSingleDocumentFirebase('config', 'videoFront');

      const dataFind = result?.data.find((x) => x.title === 'marketing');
      setDataConfig(dataFind);
    } catch (error) {
      throw new Error(error)
    } finally {
      globalState.setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataManagement();

    return () => {
      setDataConfig({});
    };
  }, []);

  return (
    <Box p={[1, 1, 5]}>
      <Stack
        align={'center'}
        spacing={3}>
        <SimpleGrid
        // columns={[1, 1, 2]} gap={5} p={[1, 1, 5]}
        >
          {/* <Stack>
            {dataConfig?.video ? (
              <Stack
                justify={'center'}
                textAlign={'center'}
                bgRepeat={'no-repeat'}
                bgSize={'cover'}
                opacity={videoLoaded ? 1 : 0} // Atur opacity untuk efek transisi
                transition={'opacity 0.3s ease-in-out'} // Transisi opasitas
              >
                <AspectRatio ratio={16 / 9}>
                  <Box
                    as="iframe"
                    title="YouTube video player"
                    src={dataConfig?.video}
                    allowFullScreen
                    frameBorder="0"
                    onLoad={() => setVideoLoaded(true)} // Mengatur video sebagai loaded saat selesai dimuat
                    visibility={videoLoaded ? 'visible' : 'hidden'} // Atur visibility untuk efek transisi
                    transition={'visibility 0.3s ease-in-out'} // Transisi visibility
                  ></Box>
                </AspectRatio>
              </Stack>
            ) : (
              <Skeleton width="100%" height="100%" />
            )}
          </Stack>
          <Stack p={3} spacing={5}> */}
          <Heading>Meta Ads</Heading>

          {/* <Text
              align={['center', 'start', 'start']}
              display={['none', 'flex', 'flex']}
              color={'gray.500'}
            >
              {dataConfig?.description || ''}
            </Text>
          </Stack> */}
        </SimpleGrid>
      </Stack>

      <Box
        bg={'white'}
        my={7}
        p={4}
        shadow={'md'}>
        <Text
          color={'gray.500'}
          fontWeight={500}
          mb={5}>
          Meta Ads Menu
        </Text>
        <SimpleGrid
          columns={[1, 1, 4]}
          spacing={5}>
          {data
            .find((menu) => menu.name === 'Meta')
            ?.submenu?.map((x, i) => (
              <Stack key={i}>
                <Popover
                  // trigger={"click"}
                  placement='bottom'
                  isLazy
                  closeOnBlur={true}
                  // initialFocusRef={firstFieldRef}
                  arrowSize={0}>
                  <PopoverTrigger>
                    <Stack
                      p={3}
                      key={i}
                      border={'1px'}
                      shadow={'base'}
                      align={'center'}
                      cursor={'pointer'}
                      borderRadius={'md'}
                      borderColor={'gray.300'}
                      onClick={() => handleNavigate(x)}
                      _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}>
                      <Icon
                        as={x.icon}
                        boxSize={12}
                      />
                      <Text fontWeight={500}>{x.name}</Text>
                    </Stack>
                  </PopoverTrigger>
                  {!x.submenu ? null : (
                    <Portal>
                      <PopoverContent>
                        {/* <FocusLock returnFocus persistentFocus={false}> */}
                        <PopoverArrow />
                        <PopoverBody>
                          <SimpleGrid
                            columns={x.submenu?.length > 4 ? 2 : 1}
                            spacing={3}>
                            {x.submenu?.map((subitem, i) => (
                              <Box
                                p={3}
                                shadow={'base'}
                                borderRadius={'md'}
                                cursor={'pointer'}
                                onClick={() => navigate(`${subitem.link}`)}
                                key={i}
                                _hover={{
                                  transform: 'scale(1.03)',
                                  transition: '0.3s',
                                }}>
                                <Stack
                                  spacing='3'
                                  align={'center'}>
                                  <Icon
                                    as={subitem.icon}
                                    boxSize={6}
                                  />
                                  <Text
                                    pl={3}
                                    fontWeight={'500'}
                                    fontSize='sm'
                                    noOfLines={1}>
                                    {subitem.name}
                                  </Text>
                                </Stack>
                              </Box>
                            ))}
                          </SimpleGrid>
                        </PopoverBody>
                        {/* </FocusLock> */}
                      </PopoverContent>
                    </Portal>
                  )}
                </Popover>
              </Stack>
            ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default MarketinngMetaAdsPage;
